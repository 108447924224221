<template>
  <div class>
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="customtableheader">
            <h4 slot="header" class="card-title">{{$t("Table.Admins")}}</h4>
            <router-link to="/admins/create">
              <el-tooltip content="Create Admin" effect="light" :open-delay="300" placement="top">
                <base-button type="primary" round icon>
                  <i class="tim-icons icon-simple-add"></i>
                </base-button>
              </el-tooltip>
            </router-link>
          </div>

          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <Search :propsToSearch="propsToSearch" v-model="filter" />
            </div>

            <div class="striped-table">
              <el-table :data="user_list">
                <div slot="empty" v-if="$asyncComputed.user_list.state === 'updating'">Loading ...</div>
                <div slot="empty" v-if="$asyncComputed.user_list.state === 'error'">Error</div>
                <div
                  slot="empty"
                  v-if="$asyncComputed.user_list.state === 'success' && user_list.length == 0"
                >No Data</div>

                <el-table-column
                  sortable
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                ></el-table-column>
                <el-table-column :min-width="155" sortable align="right" :label="$t('Status')">
                  <div slot-scope="props">{{props.row.status}}</div>
                </el-table-column>

                <el-table-column :min-width="155" align="right" :label="$t('Actions')">
                  <div slot-scope="props">
                    <el-tooltip content="View" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="viewAdmin(props.row)"
                        class="like btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-notes"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Permissions"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="handlePermissions(props.row)"
                        class="edit btn-link"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-key-25"></i>
                      </base-button>
                    </el-tooltip>
                    <!-- <base-button
                    @click.native="handleEdit( props.row)"
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                    </base-button>-->
                    <el-tooltip content="Delete" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="handleDelete(props.$index, props.row)"
                        class="remove btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-simple-remove"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <TablePagination
            slot="footer"
            ref="paginator"
            v-if="total"
            :total="total"
            @change="updatePagination"
          ></TablePagination>
        </card>
      </div>
    </div>
    <!-- view -->
    <modal :show="showModal || showRulesModal" @close="close" :modalClasses="'fullscreenmodal'">
      <AdminView v-if="showModal" :admin="selected_admin" />
      <RulesModal v-if="showRulesModal" :init="selected_admin.rules" @done="closeRulesModal" />
    </modal>
  </div>
</template>
<script>
import { BasePagination } from "src/components";
//import users from "./users";
import swal from "sweetalert2";
import Search from "@/components/Search.vue";
import RulesModal from "./../Permissions/components/RulesModal";
import TablePagination from "@/components/TablePagination.vue";

export default {
  components: {
    BasePagination,
    Search,
    RulesModal,
    TablePagination
  },

  asyncComputed: {
    user_list: {
      get() {
        return this.$api
          .get(`admin/list/${this.limit}/${this.offset}`, {
            params: {
              ...this.filter
            }
          })
          .then(res => {
            if (res.data.msg === "success") {
              this.total = res.data.total_records;
              return res.data.data;
            }
            return [];
          })
          .catch(err => {
            console.log(err);
          });
      },
      default: []
    }
  },
  computed: {},
  data() {
    return {
      selected_admin: null,
      showModal: false,
      total: 0,
      offset: 0,
      limit: 10,

      filter: null,
      searchQuery: "",
      propsToSearch: [
        { value: "email", label: this.$i18n.t("Email") },
        { value: "first_name", label: this.$i18n.t("First Name") },
        { value: "last_name", label: this.$i18n.t("Last Name") }
      ],
      filterOptions: [
        { value: "email", label: this.$i18n.t("Email") },
        { value: "email", label: this.$i18n.t("Email") },
        { value: "email", label: this.$i18n.t("Email") },
        { value: "email", label: this.$i18n.t("Email") }
      ],

      tableColumns: [
        {
          prop: "first_name",
          label: this.$i18n.t("Name"),
          minWidth: 200
        },
        {
          prop: "last_name",
          label: this.$i18n.t("Last Name"),
          minWidth: 200
        },

        {
          prop: "email",
          label: this.$i18n.t("Email"),
          minWidth: 200
        },
        {
          prop: "phone",
          label: this.$i18n.t("Phone"),
          minWidth: 200
        }
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      showRulesModal: false
    };
  },
  methods: {
    createadmin() {},
    handlePermissions(admin) {
      console.log(admin);
      this.selected_admin = admin;
      this.showRulesModal = true;
    },
    closeRulesModal(newRules) {
      this.showRulesModal = false;
      if (this.isRulesChanged(this.selected_admin.rules, newRules)) {
        this.$store.commit("setSpinner", true);
        this.$api
          .patch(`user/admin/${this.selected_admin.id}/rules`, {
            rules: newRules
          })
          .then(res => {
            console.log(res);
            this.selected_admin.rules = newRules;
          })
          .catch(console.error);
      }
    },
    isRulesChanged(oldRules, newRules) {
      if (oldRules.length != newRules.length) {
        return true;
      }
      oldRules = oldRules.sort();
      newRules = newRules.sort();

      for (let i = 0; i < oldRules.length; i++) {
        if (oldRules[i] != newRules[i]) {
          return true;
        }
      }
      return false;
    },
    viewAdmin(admin) {
      //this.selected_admin = admin;
      //this.showModal = true;
      this.$router.push({ path: `/profiles/user/` + admin.id });
    },
    close() {
      this.selected_admin = null;
      this.showModal = false;
      this.showRulesModal = false;
    },
    handleEdit(row) {
      console.log(row);
      this.$router.push({ path: `/admins/update/${row.id}` });
    },
    handleDelete(index, row) {
      swal({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteAdmin(row);
        }
      });
    },
    deleteAdmin(row) {
      this.$api
        .delete(`/people/${row.id}`)
        .then(_ => {
          this.deleteRow(row);
          swal({
            title: "Deleted!",
            text: `You deleted ${row.email}`,
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false
          });
        })
        .catch(console.error);
    },
    deleteRow(row) {
      let indexToDelete = this.user_list.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.user_list.splice(indexToDelete, 1);
      }
    },
    updatePagination(data) {
      this.limit = data.limit;
      this.offset = data.offset;
    }
  },
  watch: {
    filter() {
      if (this.$refs.paginator) {
        this.offset = 0;
        this.$refs.paginator.goTo(this.limit, this.offset);
      }
    }
  }
};
</script>
<style scoped>
</style>
