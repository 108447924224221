<template>
  <card style="height:98%" v-loading="loading">
    <template slot="header">
      <div
        class="d-flex align-items-center justify-content-between border-bottom pb-2"
      >
        <div class="d-flex align-items-center">
          <inline-svg
            :src="require(`@/assets/Icons/forklift/noun_transport.svg`)"
            width="25"
            height="25"
          />
          <h5 class="ml-2 font-color-4 font-weight-bold">Alarms</h5>
        </div>
        <filter-tabs
          tabClasses="pl-2 pr-2"
          :options="filterTypes"
          v-model="currentType"
          :allowClear="true"
        >
        </filter-tabs>
        <el-popover placement="bottom-end" width="200" trigger="click">
          <div class="d-flex flex-column">
            <h4 class="font-color-3 border-bottom pb-1">Alarms settings</h4>
            <div class="d-flex justify-content-between">
              <span class="label-switch">Geofencing</span>
              <base-switch
                v-model="data.geofencing"
                onClasses="background-good"
                offClasses="background-problem"
              />
            </div>
            <div class="d-flex justify-content-between">
              <span class="label-switch">Overspeeding</span>
              <base-switch
                v-model="data.overspeeding"
                onClasses="background-good"
                offClasses="background-problem"
              />
            </div>
            <div class="d-flex justify-content-between">
              <span class="label-switch">Idling</span>
              <base-switch
                v-model="data.idling"
                onClasses="background-good"
                offClasses="background-problem"
              />
            </div>
          </div>
          <el-button type="text" slot="reference">
            <inline-svg
              :src="require(`@/assets/Icons/forklift/noun_Settings.svg`)"
              width="25"
              height="25"
              :class="svgColor"
            />
          </el-button>
        </el-popover>
      </div>
    </template>
    <div class="row font-weight-bold mt-2">
      <div class="col-6">
        <span class="mr-2 font-color-1">Last time move</span>
        <span class="font-color-3">
          {{ getDateString(alarms.last_moved_at) }}
        </span>
      </div>
      <div class="col-6">
        <div>
          <base-select
            :inline="true"
            :light="true"
            v-model="currentPeriodIndex"
            :options="periodsOptions"
          />
        </div>
      </div>
      <div class="col-12">
        <div v-if="currentType === 'All' || currentType.title === 'All'">
          <movement-alarms :data="alarms.movement"></movement-alarms>
          <communication-alarms :data="alarms.communication"></communication-alarms>
        </div>
        <movement-alarms
          v-if="currentType === 'Movement' || currentType.title === 'Movement'"
          :data="alarms.movement"

        />
        <communication-alarms
          v-if="
            currentType === 'Communication' ||
            currentType.title === 'Communication'
          "
          :data="alarms.communication"
        />
        <communication-alarms
          v-if="
            currentType === 'Geofacing' || currentType.title === 'Geofacing'
          "
          :data="alarms.geofencing"
        />
      </div>
    </div>
  </card>
</template>

<script>
import FilterTabs from "@/components/Filter-Tabs";
import MovementAlarms from "./MovementAlarms";
import CommunicationAlarms from "./CommunicationAlarms";
import { BaseSwitch } from "src/components";
import ForkliftService from "../../../../services/forklift.service";
import { getPercentColor,getDurationString, getDateString } from '@/util/helper';
import { onePeriod, onePeriodInTwoDays } from "@/util/periodOptions";

export default {
  name: "ForkliftAlarmCard",
  components: {
    FilterTabs,
    CommunicationAlarms,
    MovementAlarms,
    BaseSwitch
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    svgColor() {
      return this.$store.getters.themMode == "light" ? "" : "light-svg";
    }
  },
  data: () => ({
    currentType: "Movement",
    filterTypes: [
      {
        title: "All",
        id: 0
      },
      {
        title: "Movement",
        id: 1
      },
      {
        title: "Communication",
        id: 2
      },
      {
        title: "Geofencing",
        id: 3
      }
    ],
    alarmsSettings: {
      geofencing: false,
      overspeeding: true,
      idling: false
    },
    date: [new Date(Date.now() - 24 * 60 * 60 * 1000), new Date()],
    loading: false,
    alarms: {},
    periodsOptions: onePeriodInTwoDays,
    currentPeriodIndex: 5
  }),
  
  methods: {
    getDurationString,
    getDateString,
    async getAlarms() {
      this.loading = true;
      
      let start = this.date[0].toISOString();
      let end = this.date[1].toISOString();
      let params = `?start=${start}&end=${end}`
      let data = await ForkliftService.getAlarms(this.$route.params.id, params);
      this.alarms = data.data.data;
      this.loading = false;
    }
  },
  watch: {
    $route(val) {
      this.getAlarms();
    },
    currentPeriodIndex(val) {
      this.date[0] = this.periodsOptions[val].from().toDate();
      this.date[1] = this.periodsOptions[val].to().toDate();
      this.getAlarms();
    }
  },
  mounted() {
    this.getAlarms();
  }
};
</script>

<style scoped>
.point {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
</style>
