<template>
  <card class="mb-2">
    <template slot="header">
      <div
        class="d-flex align-items-center justify-content-between border-bottom pb-2"
      >
        <div class="d-flex align-items-center">
          <inline-svg
            :src="require('@/assets/Icons/forklift/noun_Forklift.svg')"
            width="25"
            height="25"
          />
          <h5 class="ml-2 font-color-4 font-weight-bold">Load</h5>
        </div>
        <div>
          <span class="font-color-1">Status&nbsp;</span>
          <span class="good font-weight-bold primary-color">
            Safe
          </span>
        </div>
      </div>
    </template>
    <div class="row font-color-1 font-weight-bold mb-2">
      <div class="col-9">
        <inline-svg :class="svgColor" :src="require('@/assets/Icons/forklift/noun_Alert_-1.svg')" width="20" height="20" />
        <span class="ml-2 font-color-1">Overload</span>
      </div>
      <div class="col-3">
        <span class="point background-problem"></span>
        <span class="font-color-4 font-weight-bold ml-3">
          {{ data.overload }}
        </span>
      </div>
    </div>
  </card>
</template>

<script>
export default {
  name: 'Load',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed:{
    svgColor() {
      return this.$store.getters.themMode == "light" ? '' : 'light-svg';
    },
  }
};
</script>

<style lang="scss">
.point {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
</style>