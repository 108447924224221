<template>
  <div id="custom-table">
    <div class="row">
      <div class="col-12">
        <button
          class="btn btn-primary rounded-circle settings-btn d-flex justify-content-center align-items-center"
          @click="drawer = true"
        >
          <i class="fas fa-cog" />
        </button>
      </div>
      <div class="col-12">
        <slot />
      </div>
    </div>
    <el-drawer
      title="Column Settings"
      :visible.sync="drawer"
      direction="rtl"
    >
      <form class="scrollable-form">
        <draggable
          :list="columnsCopy"
          :options="{ group: 'columns' }"
          handle=".handle"
        >
          <div
            v-for="(column, index) in columnsCopy"
            :key="index"
            class="row px-3 py-2"
          >
            <div class="col-12 mt-2">
              <div
                v-if="!editing[index]"
                :ref="column.originalName.toLowerCase()"
                class="d-flex justify-content-between ailgn-items-center"
              >
                <div class="d-flex align-items-center">
                  <i class="handle fas fa-arrows-alt" />
                  <base-checkbox
                    v-model="column.visible"
                    :checked="column.visible"
                    class="mx-2"
                  >
                    {{ column.label }}
                  </base-checkbox>
                </div>
                <i class="edit fas fa-edit" @click="editHandler(index)" />
              </div>
              <input
                v-else
                v-model="column.label"
                :placeholder="column.originalName"
                @focusout="cancelEditHandler(index)"
                ref="input"
                class="form-control"
                type="text"
              />
            </div>
          </div>
        </draggable>
      </form>
      <div
        class="drawer-actions d-flex justify-content-between p-2 position-absolute bottom-0"
      >
        <button class="btn btn-primary" @click="saveHandler"> Save </button>
        <button class="btn btn-info" @click="reset"> Reset </button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import { Drawer } from 'element-ui';
import { mergeColumnsConfigurationsArrays } from '@/modules/common/utils/helper';
import USER_PREFERENCES_ACTIONS from '../global/user-preferences-store/action-types';
import BaseCheckbox from '../components/Inputs/BaseCheckbox';
import BaseInput from '../components/Inputs/BaseInput';
import { mapActions } from 'vuex';

export default {
  name: 'CustomTable',
  props: {
    columns: Array,
    tableKey: String,
  },
  components: {
    [Draggable.name]: Draggable,
    [Drawer.name]: Drawer,
    [BaseCheckbox.name]: BaseCheckbox,
    [BaseInput.name]: BaseInput
  },
  data() {
    return {
      drawer: false,
      columnsCopy: [],
      editing: []
    };
  },
  created() {
    this.initColumnsConfiguration();
    this.fetchColumnsConfiguration();
    this.removeErrorClassIfExists();
  },
  methods: {
    ...mapActions("UserPreferencesStore",{
      getUserPreferencesItem: USER_PREFERENCES_ACTIONS.GET_ITEM_ACTION,
      setUserPreferencesItem: USER_PREFERENCES_ACTIONS.SET_ITEM_ACTION,
      deleteUserPreferencesItem: USER_PREFERENCES_ACTIONS.DELETE_ITEM_ACTION,
    }),
    initColumnsConfiguration() {
      this.columns.forEach((c, columnIndex) => {
        if (c.visible === undefined) {
          c.visible = true;
        }

        if (!c.sortOrder) {
          c.sortOrder = columnIndex;
        }

        c.originalName = c.label;
        this.columnsCopy.push({ ...c });
      });
    },
    async fetchColumnsConfiguration() {
      this.getUserPreferencesItem(this.tableKey)
      .then(response => {
        let columnsConfig = [];
        if (response.data) {
          columnsConfig = JSON.parse(response.data);
        }
        mergeColumnsConfigurationsArrays(this.columnsCopy, columnsConfig);
        this.columnsCopy.sort((a,b) => {
          if(a.sortOrder < b.sortOrder) return -1;
          if(a.sortOrder > b.sortOrder) return 1;
          return 0;
        });

        const columnsCopyForSend = this.initColumnsForSend();
        this.$emit('config-change', columnsCopyForSend);
      });
    },
    editHandler(index) {
      this.$set(this.editing, index, true);
      setTimeout(() => {
        this.$refs.input[0].focus();
      }, 300);
    },
    cancelEditHandler(index) {
      if (this.columnsCopy[index].label.trim() == '') {
        this.columnsCopy[index].label = this.columnsCopy[index].originalName;
      }
      this.$set(this.editing, index, false);
    },
    saveHandler() {
      // Check whether there is no duplicatation in columns labels
      if (!this.isDuplicatedColumnsLabels()) {
        const columnsCopyForSend = this.initColumnsForSend();
        this.setUserPreferencesItem({
          key: this.tableKey,
          value: columnsCopyForSend
        }).then(() => {
          this.$emit('config-change', columnsCopyForSend);
          this.drawer = false;
        });
      } else {
        this.$notify({
          title: 'Duplication',
          message: 'Please be sure that columns names are unique',
          type: 'warning'
        });
      }
    },
    isDuplicatedColumnsLabels() {
      let memo = {};
      let isDuplicated = false;

      for (const column of this.columnsCopy) {
        if (memo[column.label.toLowerCase()]) {
          isDuplicated = true;
          this.$refs[column.originalName.toLowerCase()][0].classList.add('error');
        }
        memo[column.label.toLowerCase()] = true;
      }

      return isDuplicated;
    },
    reset() {
      this.deleteUserPreferencesItem(this.tableKey)
      .then(() => {
        this.drawer = false;
        this.$emit('reset');
        this.fetchColumnsConfiguration();
        this.removeErrorClassIfExists();
      });
    },
    initColumnsForSend() {
      return this.columnsCopy.map((e, idx) => ({
        ...e,
        originalName: undefined,
        sortOrder: idx
      }));
    },
    removeErrorClassIfExists() {
      let elementsWithErrorClass = document.querySelectorAll('.error');
      for (let i = 0; i < elementsWithErrorClass.length; i++) {
        elementsWithErrorClass[i].classList.remove('error');
      }
    }
  },
  watch: {
    columns: {
      handler(newVal, oldVal) {
        this.columns.forEach((c, i) => {
          if (c.visible === undefined) {
            c.visible = true;
          }
          this.columns[i].originalName = this.columnsCopy[i].originalName;
        });
        this.columnsCopy = this.columns.map(e => ({ ...e }));
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
#custom-table {
  .handle {
    cursor: move;
    transform: translateY(4px);
  }

  .edit {
    cursor: pointer;
    transform: translateY(8px);
  }

  .edit:hover {
    color: #409eff;
  }
}

::v-deep .el-drawer {
  width: 30% !important;
}

::v-deep .el-drawer__header {
  height: 10vh;
  margin-bottom: 5px;
  border-bottom: 1px solid #444;
  padding: 10px;
}

.scrollable-form {
  height: 80vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.drawer-actions {
  height: 10vh;
  width: 100%;
}

.settings-btn {
  width: 40px;
  height: 40px;
  padding: 0;
}

.error {
  border-bottom: 1px solid red;
  padding-bottom: 5px;
}
</style>
