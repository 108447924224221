<template>
  <card class="mb-2">
    <template slot="header">
      <div
        class="d-flex align-items-center justify-content-between border-bottom pb-2"
      >
        <div class="d-flex align-items-center">
          <inline-svg
            :src="require('@/assets/Icons/forklift/noun_Forklift.svg')"
            width="25"
            height="25"
          />
          <h5 class="ml-2 font-color-4 font-weight-bold">Vehicle Part Status</h5>
        </div>
      </div>
    </template>
    <div class="row font-color-1 mb-4">
      <div class="col-12">
        <div class="row">
          <div class="col-6 d-flex align-items-center">
            <inline-svg
              :src="require('@/assets/Icons/forklift/noun_Battery.svg')"
              width="15"
              height="15"
              :class="svgColor"
            />
            <span class="ml-2 font-weight-bold">Battery Change</span>
          </div>
          <div class="col-6 d-flex align-items-center">
            <base-progress
              style="flex: 1 auto"
              :value="data.battery_life.percentage"
              :status="getPercentColor(data.battery_life.percentage)"
              :showValue="false"
            />
            <span class="font-color-4 font-weight-bold ml-2">
              {{ getFixed(data.battery_life.percentage) }}%
            </span>
          </div>
          <div class="col-12 d-flex justify-content-end">
            <span class="font-color-1 data-font-size">
              {{ millisToReadable(data.battery_life.time_left) }} Left
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="row">
          <div class="col-6 d-flex align-items-center">
            <inline-svg
              :src="require('@/assets/Icons/forklift/noun_Digitalization2.svg')"
              width="15"
              height="15"
              :class="svgColor"
            />
            <span class="ml-2 font-weight-bold">Tires Validity</span>
          </div>
          <div class="col-6 d-flex align-items-center">
            <base-progress
              style="flex: 1 auto"
              :value="data.tires_life.percentage"
              :status="getPercentColor(data.tires_life.percentage)"
              :showValue="false"
            />
            <span class="font-color-4 font-weight-bold ml-2">
              {{ getFixed(data.tires_life.percentage) }}%
            </span>
          </div>
          <div class="col-12 d-flex justify-content-end">
            <span class="font-color-1 data-font-size">
              {{ millisToReadable(data.tires_life.time_left) }} Left
            </span>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import BaseProgress from "@/components/BaseProgress";
import { getPercentColor,millisToReadable } from '@/util/helper';

export default {
  name: "VehiclePartStatus",
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BaseProgress
  },
  computed: {
    svgColor() {
      return this.$store.getters.themMode == "light" ? "" : "light-svg";
    }
  },
  methods: {
    getPercentColor,
    millisToReadable,
    getFixed(num) {
      return parseFloat(num.toFixed(2));
    }

  }
};
</script>

<style lang="scss" scoped>
.battery-indicator {
  font-size: 10px;
}

.data-font-size {
  font-size: 0.8em;
}
</style>
