<template>
  <div>
    <div class="row">
    
      <!-- right side -->
      <div class="col-lg-8">
        <!-- RFID Tag -->
        <div class="d-flex justify-content-between">
          <h4 class="col-lg-3"><p>RFID Tag:</p></h4>
          <div class="col-lg-9">
            <base-input v-model="rifd" type="text" :placeholder="'RFID Tag'">
              <template v-slot:addonRight>
                <base-button
                  style="padding: 0 20px; margin-left: 10px"
                  class="btn-primary"
                >
                  <p class="mt-2">
                    <span>Scan</span>
                  </p>
                </base-button>
              </template>
            </base-input>
          </div>
        </div>
        <!-- Barcode -->
        <div class="d-flex justify-content-between">
          <h4 class="col-lg-3"><p>Barcode:</p></h4>
          <div class="col-lg-9">
            <base-input v-model="barcode" type="text" :placeholder="'Barcode'">
              <template v-slot:addonRight>
                <base-button
                  style="padding: 0 20px; margin-left: 10px"
                  class="btn-primary"
                >
                  <p class="mt-2">
                    <span>Scan</span>
                  </p>
                </base-button>
              </template>
            </base-input>
          </div>
        </div>
        <!-- Sensor ID -->
        <div v-if="assetType === 'Indoor'" class="d-flex justify-content-between">
          <h4 class="col-lg-3"><p>Sensor ID</p></h4>
          <div class="col-lg-9">
            <base-select :options="sensorOptions" v-model="sensor">
            </base-select>
          </div>
        </div>
        <!-- Asset Type -->
        <div class="d-flex justify-content-between">
          <h4 class="col-lg-3"><p>Asset Type:</p></h4>
          <div class="col-lg-9">
            <base-select :options="assetOptions" v-model="assetType">
            </base-select>
          </div>
        </div>
        <!-- Structure -->
        <div v-if="assetType !== 'Outdoor'" class="d-flex justify-content-between">
          <h4 class="col-lg-3"><p>Structure / Space:</p></h4>
          <div class="col-lg-9">
            <base-input
              v-model="Structure"
              type="text"
              :placeholder="'Structure Space:'"
            >
              <template v-slot:addonRight>
                <base-button
                  style="padding: 0 25px; margin-left: 10px"
                  class="btn-primary"
                >
                  <p class="mt-2">
                    <i class="fa fa-link"></i>
                  </p>
                </base-button>
              </template>
            </base-input>
          </div>
        </div>
        <!-- switch between components -->
        <indoor v-if="assetType === 'Indoor'"></indoor>
        <outdoor v-if="assetType === 'Outdoor'"></outdoor>
        <wash-rooms v-if="assetType === 'Wash Rooms'"></wash-rooms>
        <!-- add asset button -->
        <div class="text-right my-4">
          <base-button class="btn-primary">Add Asset</base-button>
        </div>
      </div>

        <!-- left side -->
      <div class="col-lg-4 d-flex justify-content-center">
        <image-upload type="avatar" select-text="+ Add photo"> </image-upload>
      </div>
    </div>
  </div>
</template>

<script>
import { ImageUpload } from "src/components/index";
import Indoor from "./Indoor"
import Outdoor from './Outdoor.vue';
import WashRooms from './WashRooms.vue';

export default {
  components: {
  ImageUpload,
  Indoor,
  Outdoor,
  WashRooms },

  name: "AddAsset",

  data() {
    return {
      rifd: "",
      barcode: "",
      assetType: "Indoor",
      assetOptions: [
        { value: "Indoor" },
        { value: "Outdoor" },
        { value: "Wash Rooms" },
      ],
      sensor: "Sen12",
      sensorOptions: [
        { value: "Sen12" },
        { value: "Sen13" },
        { value: "Sen14" },
      ],
      Structure: "",
    };
  },
};
</script>

<style scoped>
.fileinput .thumbnail.img-circle {
  max-width: 300px;
}
.d-flex {
  margin: 15px 0;
}
.time-border {
  border-bottom: 1px solid #aaa;
}
.input-group {
  flex-wrap: nowrap !important;
}
.form-control {
  padding: 15px !important;
}
.time {
  color: #6cb999 !important;
}
.center input {
  text-align: center;
}
.center input::-webkit-outer-spin-button,
.center input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
