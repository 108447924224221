<template>
  <el-tooltip
    :content="msg ? msg : name + ' ' + lastValue"
    effect="light"
    :open-delay="300"
    placement="top"
    class="mx-1"
  >
    <div class="d-flex justify-content-center align-items-center sensor">
      <inline-svg
      :src="getSensorIcon(name)"
      :class="getSvgClass"
      width="100%"
      height="auto"
    />
    </div>
  </el-tooltip>
</template>

<script>
import { getSensorIcon } from "../util/helper";

export default {
  props: {
    name: String,
    status: String,
    width:{
      type:Number,
      default:25
    },
    height:{
      type:Number,
      default:25
    },
    lastValue: {
      type: String,
      default: "",
    },
    msg: {
      default: '',
    },
    classes: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    getSvgClass() {
      return `background-svg-${this.status}`;
    }
  },
  methods: {
    getSensorIcon
  },
};
</script>

<style lang="scss" scoped>
.sensor {
  width: 30px;
  height: 30px;
  svg {
    max-width: 90%;
    max-height: 90%;
  }
}
</style>
