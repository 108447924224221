<template>
  <card>
    <template slot="header">
      <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
        <div class="d-flex align-items-center">
          <inline-svg :src="require('@/assets/Icons/forklift/noun_Driver_1574355.svg')"/>
          <h4 class="ml-2 font-color-1 font-weight-bold">Driver Performance</h4>
        </div>
        <div slot="right-footer" class="overflow-hidden rounded">
          <base-button
            :round="false"
            size="sm"
            :type="'primary'"
            :simple="!type.dataType"
            class="py-1"
            v-for="(type, i) in types"
            :key="i"
            @click="changeDateType(type)"
            >{{ $t(`${type.title}`) }}</base-button>
        </div>
      </div>
    </template>
    <div class="row pb-3">
      <div class="col-12">
        <el-table
          :data="driversPerformanceData"
          max-height="350px"
          v-loading="loadingPerformance">
          <el-table-column prop="driver_name" label="Driver" width="160" />
          <el-table-column prop="hours" label="Hours" width="100" />
          <el-table-column prop="accidents" label="Accidents" width="100" />
          <el-table-column prop="alarms" label="Alarms" width="100">
            <template slot-scope="scope">
              <div class="d-flex align-items-center">
                <span class="point background-problem mr-1"></span>
                <span>{{ scope.row.alarms }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="supervisor_name" label="Supervisor Info" width="150" />
          <el-table-column prop="department" label="Department" width="150" />
          <el-table-column prop="rating" label="Rating" width="180">
            <template slot-scope="scope">
              <div class="d-flex align-items-center">
                <span
                  v-for="point in points"
                  :key="point"
                  class="point "
                  :class=" scope.row.rating >= point ? scope.row.rating >= 50 ? 'background-good' : 'background-problem' : 'background-grey'"></span>
                <span class="ml-2"></span>
                <span>{{ scope.row.rating }} %</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <TablePagination
          class="mt-3"
          slot="footer"
          ref="paginator"
          v-if="performanceTotal"
          :total="performanceTotal"
          @change="updatePagination"
        ></TablePagination>
      </div>
    </div>
  </card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TablePagination from "@/components/TablePagination.vue";

export default {
  name: "DriverPerformance",
  components: {TablePagination},
  data() {
    return {
      points: [25, 50, 75, 100],
      types: [
        {
          dataType: true,
          title: "Today",
          date: "",
        },
        {
          dataType: false,
          title: "Month",
          date: "",
        },
        {
          dataType: false,
          title: "Year",
          date: "",
        },
      ],
      pagination: {
        offset: 0,
        limit: 5,
      },
      loadingPerformance: false,
      performanceDateType: {
        start: new Date().toISOString(),
        end: new Date(new Date().getTime() - (24 * 60 * 60 * 1000)).toISOString()
      },
    };
  },
  computed:{
    ...mapGetters('ForkliftStore/Portfolio',[
      "driversPerformanceData",
      'performanceTotal',
    ])
  },
  methods: {
    ...mapActions("ForkliftStore/Portfolio", [
      "fetchDriverPerformance",
    ]),
    changeDateType(type) {
      this.loadingPerformance = true
      this.types.forEach((tab) => {
        if (type.title == tab.title) {
          tab.dataType = true;
          this.loadingPerformance = true
          switch(type.title){
            case 'Today':
              this.performanceDateType.end = new Date(new Date().getTime() - (24 * 60 * 60 * 1000)).toISOString();
              this.fetchData().then(() => {this.loadingPerformance = false});
              break;
            case 'Month':
              this.performanceDateType.end = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString();
              this.fetchData().then(() => {this.loadingPerformance = false});
              break;
            case 'Year':
              this.performanceDateType.end = new Date(new Date().getFullYear(), 0, 1).toISOString();
              this.fetchData().then(() => {this.loadingPerformance = false});
              break;
          }
        } else {
          tab.dataType = false;
        }
      });
    },
    async fetchData(){
      const payload = {
        limit: this.pagination.limit,
        offset: this.pagination.offset,
        start: this.performanceDateType.start,
        end: this.performanceDateType.end,
        type: this.type
      };
      await this.fetchDriverPerformance(payload)
    },
    updatePagination(data) {
      this.pagination.limit = data.limit;
      this.pagination.offset = data.offset;
      this.fetchData()      
    },
  },
  created(){
    this.$emit('performanceDateType', this.performanceDateType)
  }
};
</script>

<style lang="scss" scoped>
.card {
  button {
    margin: 0;
    border-radius: 0;
    border: 1px solid $primary !important;
    width: 80px;
  }
  .btn-primary:active,
  .btn-primary:visited {
    background: $primary !important;
  }
  .btn-primary:hover {
    border-color: $primary !important;
    background: $primary !important;
    color: white;
    transform: translateY(0px);
  }
  .btn-primary:focus {
    background: $primary !important;
    color: white;
    transform: translateY(0px);
  }
}

.point {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-inline-start: 5px;
}

.background-grey {
  background-color: #ccc;
}
</style>