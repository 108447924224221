<template>
  <el-dialog width="350px" :visible.sync="show" custom-class="lock-quick-access text-center">  
    <img :src="qr.qrUrl" alt="">    
    <div>
      <h2 class="title mb-0 mt-4">
        {{qr.number}}
      </h2>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: "create-quicke-access",
  props:{
    isOpen: {
        type: Boolean
    },
    type:{
      type: String
    },
    qr:{
      type: Object,
    }
  },
  data(){
    return{
      qrUrl: ""
    }
  },
  computed:{
    show:{
        get(){
          return this.isOpen;
        },
        set(val){
          this.$emit('close')
        }
    }
  },
  methods:{
    close(){
      this.$emit('close')
    }
  },
  created(){
    

  }
}
</script>

<style >
.white-content .lock-quick-access{
  background: white !important 
}
</style>