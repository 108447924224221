<template>
  <div>
    <div
      :id="id"
      :style="`width: 100%; height: ${height}; overflow: hidden;`"
    ></div>
  </div>
</template>

<script>
import { load } from "./Zingchart/helpers/loader";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      required: true,
    },
    values: {
      required: true,
    },
    height: {
      type: String,
      default: "350px",
    },
  },
  methods: {
    render() {
      // if(this.values.length){
      load().then((_) => {
        zingchart.render({
          id: this.id,
          data: this.data,
          width: "100%",
          height: this.height,
        });
      });
      // }
    },
  },
  created() {
    this.render();
  },
  destroyed() {
    zingchart.exec(this.id, "destroy");
  },
  watch: {
    data: {
      handler() {
        this.render();
      },
      deep: true,
    },
    values: {
      handler() {},
      deep: true,
    },
  },
};
</script>

<style></style>
