<template>
  <card
    :bodyClasses="'d-flex justify-content-center align-items-center'"
    class="high-card-shadow my-card"
  >
    <el-divider slot="header">{{title}}</el-divider>
    <p v-if="!value || value.length==0">--</p>
    <el-tooltip v-else effect="light" :content="value+''" placement="top">
      <p>{{value}}</p>
    </el-tooltip>
  </card>
</template>

<script>
export default {
  props: ["title", "value"]
};
</script>

<style scoped lang="scss">
.my-card {
  height: 90%;
}
.my-card::v-deep .card-header {
  padding: 0;
}
.my-card::v-deep .card-body {
  padding-top: 0;
}
.my-card::v-deep .card-body p {
  text-transform: capitalize;
  font-size: 1.1rem;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  min-width: fit-content;
}
::v-deep .el-divider__text {
  color: $primary !important;
  text-align: center;
}
::v-deep .el-divider {
  background-color: $primary !important;
}
</style>