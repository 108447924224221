<template>
  <card>
    <template slot="header">
      <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
        <div class="d-flex align-items-center">
          <inline-svg
            :src="require('@/assets/Icons/forklift/noun_Alarm.svg')"
            width="25"
            height="25"
          />
          <h4 class="ml-2 font-color-1 font-weight-bold">Vehicle Status Alarms</h4>
        </div>
        <!-- <span class="font-color-4 font-weight-bold">5</span> -->
      </div>
    </template>
    <div class="row pb-3">
      <div class="col-12">
        <el-table :data="vehicleStatusAlarmsData">
          <el-table-column
            prop="device_id"
            label="Vehicle"
            width="150">
            <template slot-scope="scope">
              <span>Vehicle {{ scope.row.device_id }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="component"
            label="Status"
            width="180"
          />
          <el-table-column
            prop="must_resolve_after"
            label="Date"
            width="200"
          >
            <template slot-scope="scope">
              <div class="d-flex align-items-center">
                <span>{{ scope.row.must_resolve_after ? futureDate('',scope.row.must_resolve_after) : "no date" }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="driver_name"
            label="Driver Info"
            width="180"
          />
          <el-table-column
            prop="supervisor_name"
            label="Supervisor Info"
            width="180"
          />
          <el-table-column
            prop="last_reported_at"
            label="Last reported at"
            width="200"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.last_reported_at ? dateFormat(scope.row.last_reported_at) : "no date" }}</span>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot-scope="{}">
              <base-button
                simple
                class="btn-escalate"
                size="sm"
                type="primary"
              >{{ $t('escalate') }}</base-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { dateFormat, futureDate } from "@/util/helper"

export default {
  name: 'VehicleStatusAlarms',
  computed: {
    ...mapGetters('ForkliftStore/Portfolio/VehicleStatusAlarms', ['vehicleStatusAlarmsData'])
  },
  methods:{
    ...mapActions('ForkliftStore/Portfolio/VehicleStatusAlarms', [
      'fetchStatusAlarms'
    ]),
    dateFormat,
    futureDate
  },
  created() {
    this.fetchStatusAlarms()
  }
}
</script>

<style>

</style>