<template>
  <div>
    <CommentsBox
      @close="closeCommentsBox"
      @send-msg="addComment"
      :isChatOpen="showCommentBox"
      :users="commentsUsers"
      :messageList="comments"
    />
    <EscalateModal 
      :isOpen="showEscalateModal"
      :deviceCompanies="deviceCompanies"
      @close="actions('closeEscalateModal')"
      @submit="actions('closeEscalateModal', $event)"
    />
    <AcknowledgmentModal
      :isOpen="showAcknowledgmentModal"
      @submit="submitAcknowledgment"
      @close="submitAcknowledgment"
    />
  </div>
</template>

<script>
import CommentsBox from "@/pages/SWAM/components/AlarmsComponents/CommentsBox";
import EscalateModal from "@/pages/SWAM/components/AlarmsComponents/EscalateModal.vue";
import AcknowledgmentModal from "@/pages/SWAM/components/AlarmsComponents/AcknowledgmentModal.vue";

export default {
  props: {
    sourceStore: {
      type: String,
      required: true,
    },
  },
  computed: {
    showCommentBox() {
      return this.getters("currentAction") == "comments";
    },
    commentsUsers() {
      return this.getters("commentsUsers");
    },
    comments() {
      return this.getters("comments");
    },
    showEscalateModal() {
      return this.getters("currentAction") == "escelate";
    },
    deviceCompanies() {
      return this.getters("deviceCompanies");
    },
    showAcknowledgmentModal() {
      return this.getters("currentAction") == "ack";
    },
  },
  methods: {
    getters(getterName) {
      return this.$store.getters[`${this.sourceStore}/${getterName}`];
    },
    actions(actionName, data) {
      return this.$store.dispatch(`${this.sourceStore}/${actionName}`, data);
    },
    closeCommentsBox() {
      return this.actions("closeCommentsBox");
    },
    addComment(msg) {
      return this.actions("addComment", msg);
    },
    submitEscalation(data) {
      return this.actions("closeEscalateModal", data);
    },
    submitAcknowledgment(data) {
      return this.actions("closeAcknowledgmentModal", data);
    },
  },
  components: {
    CommentsBox,
    EscalateModal,
    AcknowledgmentModal,
  },
};
</script>

<style>
</style>