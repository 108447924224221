<template>
  <card class="h-100 tel-widget">
    <div class="d-flex mr-2 ml-2 justify-content-between">
      <div>
        <h2 class="m-0">Covid 19 Tests</h2>
        <h5>
          total:
          <span>19</span>
        </h5>
      </div>

      <img src="@/assets/Icons/covid.svg" class="imgicon" />
    </div>
    

    <div class="d-flex justify-content-between mt-3 mr-2 ml-2">
      <span>
        <inline-svg
          :src="require('@/assets/Icons/noun_Error.svg')"
          width="20"
          height="20"
          class="mr-2 svgicon align-top"
          fill="#000"
        />Staff on High Alart
      </span>
      <span class="border d-block text-center rounded num">3</span>
    </div>
    <div class="d-flex justify-content-between mt-3 mr-2 ml-2">
      <span>
        <inline-svg
          :src="require('@/assets/Icons/nurse.svg')"
          width="20"
          height="20"
          class="mr-2 svgicon align-top"
          fill="#000"
        />Staff test Positive
      </span>
      <span class="border d-block text-center rounded num">3</span>
    </div>
    <div class="d-flex justify-content-between mt-3 mr-2 ml-2">
      <span>
        <inline-svg
          :src="require('@/assets/Icons/social-distancing.svg')"
          width="20"
          height="20"
          class="mr-2 svgicon align-top"
          fill="#000"
        />Staff on Quarantine
      </span>
      <span class="border d-block text-center rounded num">3</span>
    </div>
  </card>
</template>
<script>
export default {};
</script>
<style scoped>
</style>
  

