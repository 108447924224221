<template>
  <card class="h-100">
    <div class="d-flex pr-1 pl-1 justify-content-between align-items-start">
      <h4>Current Status</h4>
    </div>
    <div class="row">
      <div class="col-md-6 ">
          <div class="mt-3">
            <CurrentStatusChart  />
          </div>
        
      </div>
      <div class="col-md-6">
        <div>
          <div class="mt-2">
            <div class="d-flex justify-content-between">
              <span>Driving</span>
              <span>60</span>
            </div>
            <div class="driving-percent">
              <div></div>
            </div>
          </div>
          <div class="mt-2">
            <div class="d-flex justify-content-between">
              <span>Parking</span>
              <span>60</span>
            </div>
            <div class="driving-percent">
              <div></div>
            </div>
          </div>
          <div class="mt-2">
            <div class="d-flex justify-content-between">
              <span>Inactive</span>
              <span>60</span>
            </div>
            <div class="driving-percent">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>
<script>
import CurrentStatusChart from "@/components/Charts/CurrentStatusChart.vue";
export default {
  components: {
    CurrentStatusChart,
  },
};
</script>
<style >
.driving-percent {
  height: 10px;
  width: 100%;
  background-color: #fff;
}
.driving-percent div {
  width: 60%;
  height: 10px;
  background-color: crimson;
}
</style>