<template>
  <div v-loading="loading">
    <div class="row">
      <div class="col-md-7 d-flex align-items-stretch">
        <forkMap :data="vehicle" @updateFilter="updateFilter" />
      </div>
      <div class="col-md-5 d-flex align-items-stretch">
        <div class="row">
          <div class="col-12 d-flex align-items-stretch">
            <vehicle-info @updateVehicle="updateVehicle"></vehicle-info>
          </div>
          <div class="col-12 d-flex align-items-stretch">
            <driver-info  ></driver-info>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        <div class="row">
          <div class="col-md-6">
            <movement-speed style="height:98%" :data="vehicle" />
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12 d-flex align-items-stretch">
                <battery :data="vehicle" />
              </div>
              <div class="col-12 d-flex align-items-stretch">
                <ignition :data="vehicle" />
              </div>
              <div class="col-12 d-flex align-items-stretch">
                <load :data="vehicle" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <forklift-alarm-card :data="{  ...vehicle }" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        <div class="row h-100">
          <div class="col-6 d-flex align-items-stretch">
            <vehicle-servicing-status :data="vehicle" />
          </div>
          <div class="col-6 d-flex align-items-stretch">
            <vehicle-part-status :data="vehicle" />
          </div>
        </div>
      </div>
      <div class="col-md-5 d-flex align-items-stretch">
        <vehicle-oil-status :data="vehicle" />
      </div>
    </div>
  </div>
</template>

<script>
import VehicleSummary from "./components/VehicleSummary";
import VehicleInfo from "../../core/VehicleInfo/index";
import DriverInfo from "../../core/DriverInfo";
import forkMap from "../../components/ForkliftMap";
import Ignition from "./components/Ignition";
import Load from "./components/Load";
import MovementSpeed from "./components/MovementSpeed";
import Battery from "./components/Battery";
import GSM from "./components/GSM";
import DigitalStatus from "./components/DigitalStatus";
import GNSS from "./components/GNSS";
import ForkliftAlarmCard from "./components/Alarms/Alarms";
import VehicleServicingStatus from './components/VehicleServicingStatus';
import { mapActions, mapGetters, mapState } from "vuex";
import { dateFormat } from "@/util/helper";
import VehiclePartStatus from './components/VehiclePartStatus.vue';
import VehicleOilStatus from './components/VehicleOilStatus.vue';

export default {
  components: {
    VehicleSummary,
    VehicleInfo,
    DriverInfo,
    Ignition,
    Load,
    MovementSpeed,
    Battery,
    GSM,
    DigitalStatus,
    GNSS,
    ForkliftAlarmCard,
    forkMap,
    VehicleServicingStatus,
    VehiclePartStatus,
    VehicleOilStatus
  },
  created() {
    // this.getDevice();
  },
  computed: {
    ...mapGetters("ForkliftStore/Device", ["device", "dashboard", "loading"]),
    isDark() {
      return this.$store.getters.themMode == "light" ? false : true;
    },
    currentParam() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapActions("ForkliftStore/Device", ["fetchDeviceInfo", "fetchDashboardInfo"]),
    getDevice(filter = {}) {
      this.fetchDeviceInfo({
        id: this.currentParam,
        /* params: `start=${filter.start}&end=${filter.end}` */
      }).then(() => {
        this.getDashboard();
      });
    },
    getDashboard(filter = {}) {
      this.fetchDashboardInfo({
        id: this.device.device_id,
        /* params: `start=${filter.start}&end=${filter.end}` */
      });
    },
    updateVehicle(data) {
      this.vehicle = data;
    },
    updateFilter(data) {
      this.getDevice(data);
    }
  },
  data() {
    return {
      communicationAlarmProps: {
        icon: "noun_signal",
        title: "Communication Alarm",
        timeAgo: "1 min",
        timeMsg: "Last time sync",
        items: [
          {
            icon: "noun_hibernate",
            label: "Jamming",
            value: "jamming"
          },
          {
            icon: "noun_signal2",
            label: "Low GSM Signal",
            value: "low_gsm_signal"
          },
          {
            icon: "noun_signal2",
            label: "Low GNSS Signal",
            value: "low_gnss_signal"
          }
        ]
      },
      movmentAlarmProps: {
        icon: "noun_transport",
        title: "Movment Alarms",
        timeAgo: "1 hr ago",
        timeMsg: "Last time move",

        items: [
          {
            icon: "Union 8",
            label: "10 degrees move",
            value: "10_degrees_move"
          },
          {
            icon: "noun_distance",
            label: "Vehicle drives 100m",
            value: "drives_100m"
          },
          {
            icon: "noun_hibernate",
            label: "300 seconds passes",
            value: "300_seconds_passes"
          },
          {
            icon: "noun_hibernate",
            label: "Idling",
            value: "idling"
          },
          {
            icon: "noun_speed",
            label: "Overspeeding",
            value: "overspeeding"
          }
        ]
      },
      filter: { date: [new Date().getTime() - 3600 * 1000 * 24, new Date()] },
      vehicle: {}
    };
  }
};
</script>

<style lang="scss">
.light-svg {
  filter: brightness(0) invert(0.8);
}
</style>
