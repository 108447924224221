<template>
  <card card-body-classes="table-full-width">
    <div>
      <!-- content -->
      <div class="row">
        <div class="col-md-3">
          <base-input>
            <el-date-picker
              type="datetime"
              :placeholder="$t('from')"
              v-model="datefrom"
            ></el-date-picker>
          </base-input>
        </div>
        <div class="col-md-3">
          <base-input>
            <el-date-picker
              type="datetime"
              :placeholder="$t('to')"
              v-model="dateto"
            ></el-date-picker>
          </base-input>
        </div>

        <!--  -->
        <div class="col-md-5">
              <div class="row mb-3 ml-3 justify-content-center">
          <div
            class="btn-group btn-group-toggle"
            :class="isRTL ? 'float-left' : 'float-right'"
            data-toggle="buttons"
          >
            <label
              class="btn btn-sm btn-primary btn-simple"
              :class="{'active':duration === 1 && !isCustomDate}"
              @click="setDuration(1)"
            >
              <input type="radio" name="options" autocomplete="off" />
              <span class="d-none d-sm-block">{{$t('last24Hours')}}</span>
            </label>
            <label
              class="btn btn-sm btn-primary btn-simple"
              :class="{'active':duration === 7 && !isCustomDate}"
              @click="setDuration(7)"
            >
              <input type="radio" name="options" autocomplete="off" />
              <span class="d-none d-sm-block">{{$t('last7Days')}}</span>
            </label>
            <label
              class="btn btn-sm btn-primary btn-simple"
              :class="{'active':duration === 30 && !isCustomDate}"
              @click="setDuration(30)"
            >
              <input type="radio" name="options" autocomplete="off" />
              <span class="d-none d-sm-block">{{$t('last30Days')}}</span>
            </label>
            <label
              class="btn btn-sm btn-primary btn-simple"
              :class="{'active':duration === 365 && !isCustomDate}"
              @click="setDuration(365)"
            >
              <input type="radio" name="options" autocomplete="off" />
              <span class="d-none d-sm-block">{{$t('last365Days')}}</span>
            </label>
          </div>

          <!--  -->
        </div>

        </div>
        <div class="col-1 d-flex justify-content-around align-items-center">
          <el-tooltip
            :content="$t('instructions.downloadPDF')"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <img
              style="max-width: 30px; margin-left: 4px; cursor: pointer"
              class="aspointer"
              src="@/assets/Icons/pdf-colord.svg"
              @click="$emit('downloadPDF')"
              alt=""
            />
          </el-tooltip>
          <el-tooltip
            :content="$t('report.statusHistory')"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <button
              class="btn btn-icon btn-round btn-twitter m-0"
              style="margin-bottom: -13px"
              @click="gotoAlarmHistory"
            >
              <inline-svg
                :src="require('@/assets/Icons/alarm-history.svg')"
                width="20"
                height="20"
                class="alarm-history"
              />
            </button>
          </el-tooltip>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t("dateTime") }}</th>
                <th v-for="(sensor, index) in sensors" :key="index">
                  {{ $t(sensor.name) }}
                </th>
              </tr>
            </thead>
            <tbody
              style="text-align: center"
              v-if="queriedData.dates.length == 0"
            >
              <span class="el-table__empty-text">No Data</span>
            </tbody>
            <tbody v-else>
              <tr v-for="(read, index) in queriedData.dates" :key="index">
                <td class="tdrtl">{{ gDateFormat(read) }}</td>

                <template v-for="(snr,index2) in sensors">
                  <td :key="'snr'+index2" class="tdrtl">
                      <span class ="value">{{queriedData[snr.id].values[index+from]}}</span>
                      <StatusView
                        :label="$t($WarningIndectorName(queriedData[snr.id].statuses[index+from]))"
                        :state="queriedData[snr.id].statuses[index+from] + '-background'"
                      />
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div>
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
      </div>
      <div class="div-select">
        <el-select
          class="select-primary mb-3 pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('Per page')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </div>

      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      ></base-pagination>
    </div>
  </card>
</template>
<script>
import { Table, TableColumn, DatePicker, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
//import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import Search from "@/components/Search.vue";
import moment from "moment";
import StatusView from '@/components/StatusView';
export default {
  components: {
    [DatePicker.name]: DatePicker,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Search,
    StatusView
  },

  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    selected_device() {
      return this.$store.state.devicesstore.selected_device;
    },
    sensors() {
      if (this.selected_device) {
        return this.selected_device.sensors;
      }
      return [];
    },
    isRTL() {
      return this.$rtl.isRTL;
    },

    datefrom: {
      get() {
        return this.$store.state.devicesstore.datefrom;
      },
      set(value) {
        this.$store.commit("devicesstore/setDuration", {
          name: "datefrom",
          value,
        });
      },
    },
    dateto: {
      get() {
        return this.$store.state.devicesstore.dateto;
      },
      set(value) {
        this.$store.commit("devicesstore/setDuration", {
          name: "dateto",
          value,
        });
      },
    },
    duration() {
      return this.$store.state.devicesstore.duration;
    },

    queriedData() {
      return {
        ...this.$store.state.devicesstore.values,
        dates: this.$store.state.devicesstore.values.dates.slice(
          this.from,
          this.to
        ),
      };
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.$store.state.devicesstore.values.dates.length;
    },
    isCustomDate() {
      return this.datefrom && this.dateto;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 3,
        currentPage: 1,
        perPageOptions: [3, 10, 25, 50],
        total: 0,
      },
    };
  },
  methods: {
    gotoAlarmHistory() {
      let id = this.selected_device.id;
      this.$router.push({ path: `/device/status-history/${id}` });
    },
    getSensorValue(sensor, read) {
      let reads = read.reads;
      let snr = reads.find((re) => re.typeId === sensor.id);
      if (snr) {
        return snr.value;
      }
      return "-";
    },
    getType(sensor, read) {
      let reads = read.reads;
      let snr = reads.find((re) => re.typeId === sensor.id);
      if (snr) {
        return snr.status;
      }
      return "problem";
    },
    getDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getTime(date) {
      return moment(date).format("hh:mm");
    },
    setDuration(value) {
      this.$store.commit("devicesstore/setDuration", {
        name: "duration",
        value,
      });
    },
  },
};
</script>
<style scoped>
.value{
  width: 40%;
  display: inline-block;
}
.alarm-history{
  cursor: pointer;
}
@media (max-width: 575.98px) {
  tbody {
    font-size: x-small;
  }
  .stateW {
    display: block;
  }
}

@media (max-width: 767.98px) {
  .stateW {
    display: block;
  }
  tbody {
    font-size: x-small;
  }
}

@media (max-width: 991.98px) {
  .stateW {
    display: block;
  }
  tbody {
    font-size: x-small;
  }
}

@media (max-width: 1199.98px) {
  tbody {
    font-size: x-small;
  }
}
.devicereads {
  margin-top: 10px;
}
table.devicereads td:nth-child(odd) {
  border-right: 0px;
}
table.devicereads td:nth-child(even) {
  border-left: 0px;
}
.rtl .tdrtl {
  text-align: right;
}
.devicereads td {
  border: 1px solid;
}
/* .circle-problem {
  color: rgb(242, 57, 59);
  padding-left: 4px;
  padding-right: 4px;
}
.circle-ok {
  color: rgb(232, 217, 29);
  padding-left: 4px;
  padding-right: 4px;
}
.circle-good {
  color: rgb(63, 172, 121);
  padding-left: 4px;
  padding-right: 4px;
}
.circle-poor {
  color: rgb(230, 162, 60);
  padding-left: 4px;
  padding-right: 4px;
} */
body:not([class*="white-content"]) .style-state {
  color: white;
}
body:not([class*="white-content"]) .device-read-value {
  padding-left: 4px;
  color: white;
}
.device-read-value {
  padding-left: 4px;
}
.pagination-select,
.search-input {
  width: 130px;
}
</style>


