<template>
  <div>
    <!-- some inputs -->
    <div class="d-flex" style="flex-wrap: wrap">
      <h4 class="col-lg-3"><p>Latitude:</p></h4>
      <div class="col-lg-3">
        <base-input v-model="latitude" :placeholder="'Latitude'" type="text">
        </base-input>
      </div>
      <h4 class="col-lg-3 text-lg-center"><p>Longitude:</p></h4>
      <div class="col-lg-3">
        <base-input v-model="longitude" :placeholder="'Longitude'" type="text">
        </base-input>
      </div>
      <h4 class="col-lg-3"><p>Road Name:</p></h4>
      <div class="col-lg-3">
        <base-input v-model="roadName" :placeholder="'Road Name'" type="text">
        </base-input>
      </div>
      <h4 class="col-lg-3 text-lg-center"><p>Community:</p></h4>
      <div class="col-lg-3">
        <base-input v-model="community" :placeholder="'Community'" type="text">
        </base-input>
      </div>
      <h4 class="col-lg-3"><p>City:</p></h4>
      <div class="col-lg-3">
        <base-input v-model="city" :placeholder="'City'" type="text">
        </base-input>
      </div>
      <h4 class="col-lg-3 text-lg-center"><p>Country:</p></h4>
      <div class="col-lg-3">
        <base-input v-model="country" :placeholder="'Country'" type="text">
        </base-input>
      </div>
    </div>
    <!-- AMC -->
    <div class="d-flex justify-content-between">
      <h4 class="col-lg-3"><p>AMC:</p></h4>
      <div class="col-lg-9">
        <base-select :options="companyOptions" v-model="companyType">
          <template v-slot:addonRight>
            <base-button
              style="padding: 0 10px; margin-left: 10px"
              class="btn-primary"
            >
              <p class="mt-2">
                <i class="fa fa-plus"></i>
              </p>
            </base-button>
          </template>
        </base-select>
      </div>
    </div>
    <!-- AMC -->
    <div class="d-flex justify-content-between">
      <h4 class="col-lg-3"><p>AMC:</p></h4>
      <div class="col-lg-9">
        <base-select :options="companyOptions2" v-model="companyType2">
          <template v-slot:addonRight>
            <base-button
              style="padding: 0 10px; margin-left: 10px"
              class="btn-primary"
            >
              <p class="mt-2">
                <i class="fa fa-plus"></i>
              </p>
            </base-button>
          </template>
        </base-select>
      </div>
    </div>

    <!-- yes && no radio -->
    <div class="d-flex">
      <h4 class="mt-3 col-8">
        Do Yo Need Sparrate Logic Setting Frequency On Weekends?
      </h4>
      <!-- <div class="d-flex"> -->
      <base-radio class="col-2" name="Tissues1" v-model="TissuesRadio" inline
        >Yes</base-radio
      >
      <base-radio class="col-2" name="Tissues2" v-model="TissuesRadio" inline
        >No</base-radio
      >
      <!-- </div> -->
    </div>
    <div v-if="TissuesRadio === 'Tissues1'">
      <!-- fill level -->
      <div class="d-flex my-0">
        <h4 class="col-lg-6 mt-3">
          <p>Fill Level Required For Bin Serving:</p>
        </h4>
        <div class="col-lg-2 px-2 d-flex">
          <base-input v-model="fillLevel" type="number"> </base-input>
          <span class="m-2">%</span>
        </div>
      </div>
      <!-- Servicing Interval -->
      <div class="d-flex">
        <h4 class="col-lg-6 mt-3"><p>Servicing Interval:</p></h4>
        <div class="col-lg-4 px-2 d-flex">
          <base-input v-model="servicingInterval.hrs" type="number">
          </base-input>
          <span class="m-2">Hrs</span>
          <base-input v-model="servicingInterval.mins" type="number">
          </base-input>
          <span class="m-2">Mins</span>
        </div>
      </div>
      <!-- auto saving -->

      <div class="d-flex">
        <el-popover
          placement="bottom-start"
          width="300"
          trigger="hover"
          content="Can be applied after 60 days"
        >
          <i
            slot="reference"
            style="font-size: 20px"
            class="el-icon-info mt-2 pl-2"
          ></i>
        </el-popover>
        <h4 class="col-lg-6 mt-2">Auto frequency:</h4>
        <base-radio class="col-lg-2 px-3" name="Auto1" v-model="Auto" inline
          >Yes</base-radio
        >
        <base-radio class="col-lg-2 px-3" name="Auto2" v-model="Auto" inline
          >No</base-radio
        >
      </div>
    </div>

    <!-- type of waste: -->
    <div class="d-flex justify-content-between">
      <h4 class="col-lg-4"><p>type of waste:</p></h4>
      <div class="col-lg-8">
        <base-select :options="wasteOptions" v-model="wasteType">
          <template v-slot:addonRight>
            <base-button
              style="padding: 0 10px; margin-left: 10px"
              class="btn-primary"
            >
              <p class="mt-2">
                <i class="fa fa-plus"></i>
              </p>
            </base-button>
          </template>
        </base-select>
      </div>
    </div>
  </div>
</template>

<script>
import { TimePicker, Alert } from "element-ui";
import { BaseRadio } from "src/components";

export default {
  name: "Outdoor",

  components: {
    [TimePicker.name]: TimePicker,
    [Alert.name]: Alert,
    BaseRadio
  },

  data() {
    return {
      companyType: "Company 01",
      TissuesRadio: "Tissues1",
      companyOptions: [
        { value: "Company 01" },
        { value: "Company 02" },
        { value: "Company 03" },
        { value: "Company 04" }
      ],
      companyType2: "Company 11",
      companyOptions2: [
        { value: "Company 11" },
        { value: "Company 12" },
        { value: "Company 13" },
        { value: "Company 14" }
      ],
      value: 0,
      days: [
        {
          name: "Saturday",
          value1: ["", ""]
        },
        {
          name: "Sunday",
          value1: ["'10:30 AM'", "'11:30 AM'"]
        },
        {
          name: "Monday",
          value1: ["", ""]
        },
        {
          name: "Tusday",
          value1: ["'10:30 AM'", "'11:30 AM'"]
        },
        {
          name: "Wedenesday",
          value1: ["", ""]
        },
        {
          name: "Thursday",
          value1: ["'10:30 AM'", "'11:30 AM'"]
        },
        {
          name: "Friday",
          value1: ["", ""]
        }
      ],
      fillLevel: 0,
      dirtLevel: 0,
      servicingInterval: {
        hrs: 0,
        mins: 0
      },
      Auto: "Auto1",
      wasteType: "",
      wasteOptions: [
        { value: "waste 01" },
        { value: "waste 02" },
        { value: "waste 03" },
        { value: "waste 04" }
      ]
    };
  },

  methods: {
    plus() {
      this.value++;
    },
    min() {
      this.value--;
    },
    addTime(index) {
      this.days.splice(index + 1, 0, {
        name: "",
        from: "",
        to: ""
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.time-border * {
  font-size: 0.8rem;
}
</style>
