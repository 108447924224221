<template>
        <zingchart
        :data="chartConfig"
        :height="'100px'"
        :width="'100%'"
      ></zingchart>
</template>
<script>
export default {
  data() {
    return {
      chartConfig: {
        type: "ring",
        // globals: {
        //   fontFamily: "Lato",
        //   fontWeight: "100",
        // },
        backgroundColor: "transparent",
        plot: {
          valueBox: {
            type: "first",
            text: "<span style='font-size:20px;'>60%</span><br>Driving",
            connected: false,
            fontColor: "#fff",
            fontSize: "10px",
            placement: "center",
            rules: [
              {
                rule: "%v > 50",
                visible: false,
              },
            ],
            visible: true,
          },
          animation: {
            delay: 0,
            effect: "ANIMATION_EXPAND_VERTICAL",
            method: "ANIMATION_LINEAR",
            sequence: "ANIMATION_BY_PLOT",
            speed: "600",
          },
          detach: false,
          hoverState: {
            visible: false,
          },
          refAngle: 270,
          slice: 30,
        },
        plotarea: {
          margin: "0% 0% 0% 0%",
        },
        tooltip: {
          visible: false,
        },
        series: [
          {
            values: [25],
            backgroundColor: "#FDFD47",
            borderColor: "#282E3D",
            borderWidth: "2px",
            shadow: false,
          },
          {
            values: [75],
            backgroundColor: "#35D884",
            borderColor: "#282E3D",
            borderWidth: "2px",
            shadow: false,
          },
          {
            values: [30],
            backgroundColor: "#35D884",
            borderColor: "#282E3D",
            borderWidth: "2px",
            shadow: false,
          },
        ],
      },
    };
  },
};
</script>
<style scoped>
</style>