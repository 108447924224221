<template>
  <div>
    <create-quick-access :isOpen="createQuickPopupVisible" @close="close" />
    <create-scheduled-access :isOpen="createScheduledPopupVisible" @close="close"  />
    <alert-settings :isOpen="alertSettingsPopupVisible" @close="close"  />
    <qr-code :isOpen="qrCodePopupVisible" @close="close"  :qr="qr"/>
  </div>
</template>

<script>
import CreateQuickAccess from '../modals/CreateQuickAccess'
import CreateScheduledAccess from '../modals/CreateScheduledAccess'
import AlertSettings from '../modals/AlertSettings'
import QrCode from '../modals/QrCode'
export default {
  name: "",
  props:{
    title: {
      type: String
    },
    createQuickPopupVisible:{
      type: Boolean
    },
    createScheduledPopupVisible: {
      type: Boolean
    },
    alertSettingsPopupVisible: {
      type: Boolean
    },
    qrCodePopupVisible:{
      type: Boolean
    },
    qr:{
      type: Object
    }
  },
  components:{
    CreateQuickAccess,
    CreateScheduledAccess,
    AlertSettings,
    QrCode
  },
  computed:{
    show:{
        get(){
          return this.isOpen;
        },
        set(val){
          this.$emit('close')
        }
    }
  },
  methods:{
    close(){
      this.$emit('close')
    },
  },
}
</script>