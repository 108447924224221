<template>
  <card v-loading="loading">
    <template slot="header">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <inline-svg
            :src="require('@/assets/Icons/forklift/noun_transport.svg')"
            width="25"
            height="25"
          />
          <h5 class="ml-2">Movement Speed</h5>
        </div>
        <div>
          <base-select v-model="periodIndex" :options="periodOptions" light />
        </div>
      </div>
    </template>
    <div class="d-flex" v-if="speed.avg === null">
      <el-alert
        title="We don't recieve any data in this range"
        type="warning"
        effect="dark"
      >
      </el-alert>
    </div>
    <div class="d-flex mt-3 justify-content-between">
      <div>
        <span class="font-color-1">Speed limit</span>
        <span class="font-color-3 font-weight-bold ml-2"
          >{{ speed.speed_limit }} km/h</span
        >
      </div>
      <div>
        <span class="font-color-1">Maximum</span>
        <span class="font-color-3 font-weight-bold ml-2">
          {{ speed.max }} km/h
        </span>
      </div>
    </div>
    <div class="mt-4 text-center">
      <speedometer
        :value="speed.avg"
        :tickValue="speed.speed_limit"
        :minValue="0"
        :maxValue="100"
        :ranges="[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        unit="km/h"
      />
    </div>
    <div
      class="col-12 d-flex font-color-1 justify-content-between mt-5 custom-data-show"
    >
      <div class="d-flex ml-2">
        <inline-svg
          :class="`mr-2 ${svgColor}`"
          :src="require(`@/assets/Icons/forklift/noun_speed.svg`)"
          width="20"
          height="20"
        />
        <span>Overspeeding</span>
      </div>
      <div class="d-flex align-items-center mr-2">
        <span class="point background-problem mr-1"></span>
        <span>
          {{ speed.overspeeding }}
        </span>
      </div>
    </div>
  </card>
</template>

<script>
import Speedometer from "src/components/Charts/SpeedometerChart";
import ForkliftService from "../../../services/forklift.service.js";
import { onePeriod, onePeriodInTwoDays } from "@/util/periodOptions";
import { Alert } from 'element-ui'
export default {
  name: "movement-speed",
  components: {
    [Speedometer.name]: Speedometer,
    [Alert.name]: Alert
  },
  methods: {
    async getSpeedInfo() {
      let start = this.date[0].toISOString();
      let end = this.date[1].toISOString();
      this.loading = true;
      let data = await ForkliftService.getSpeedInfo(
        this.$route.params.id,
        `?start=${start}&end=${end}`
      );
      this.speed = data.data.data;
      this.loading = false;
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    this.date[0] = this.periodOptions[this.periodIndex].from().toDate();
    this.date[1] = this.periodOptions[this.periodIndex].to().toDate();
    this.getSpeedInfo();
  },
  computed: {
    svgColor() {
      return this.$store.getters.themMode == "light" ? "" : "light-svg";
    }
  },
  data() {
    return {
      periodIndex: 7,
      date: [new Date(Date.now() - 24 * 60 * 60 * 1000), new Date()],
      periodOptions: onePeriodInTwoDays,
      speed: {},
      loading: false
    };
  },
  watch: {
    periodIndex(val) {
      this.date[0] = this.periodOptions[val].from().toDate();
      this.date[1] = this.periodOptions[val].to().toDate();
      this.getSpeedInfo();
    },
    $route: function(val) {
      this.getSpeedInfo();
    }
  }
};
</script>

<style lang="scss" scoped>
.point {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.custom-data-show {
  border-radius: 25px;
  padding: 5px;
}
</style>
