<template>
  <div class="row">
    <!-- filters -->
    <div class="col-12">
      <router-link to="./operational/history">
        <base-button class="abs" size="sm" type="primary" simple >History</base-button>
      </router-link>
      <AlarmsFilter
        :initialFilter="getInitialFilter()"
        v-loading="loadingState"
        @filter="updateFilter"
      >
        <template slot="extra" slot-scope="{ filter }">
          <div class="row">
            <StatusFilter
            :initialFilter="getInitialFilter().status"
              :filter="filter"
              :options="statusFilterOptions"
            ></StatusFilter>
          </div>
        </template>
      </AlarmsFilter>
    </div>
    <!-- chart -->
    <div class="col-12">
      <AlarmsCount
        :cardClasses="'mb-2'"
        :data="chartFilter"
        :enableSolutionSelect="false"
        :enableStructureSelect="false"
      />
    </div>
    <!-- table -->
    <div class="col-12">
      <card v-loading="loadingState" class="mb-2">
        <OperationalTable
          :data="tableData"
          @change-ack-state="openAcknowledgmentModal"
          @escalate="openEscalateModal"
          @open-comments="openCommentsBox"
        />
        <TablePagination
          slot="footer"
          ref="paginator"
          v-if="tableData.length"
          :total="totalAlarms"
          @change="updatePagination"
        />
      </card>
    </div>
    <AlarmsActions sourceStore="SwamStore/OperationalAlarms" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import AlarmsFilter from "@/pages/SWAM/components/AlarmsComponents/AlarmsFilter.vue";
import AlarmsActions from "@/pages/SWAM/components/AlarmsComponents/AlarmsActions.vue";
import OperationalTable from "./components/OperationalTable";
import AlarmsCount from "../../core/Alarms-Count";
import TablePagination from "@/components/TablePagination.vue";
import StatusFilter from "@/pages/SWAM/components/AlarmsComponents/StatusFilter";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "user",
      userPlaceholderImage: "userPlaceholderImage",
    }),
    ...mapGetters("SwamStore/OperationalAlarms", {
      tableData: "alarms",
      filter: "filter",
      totalAlarms: "totalAlarms",
      pagination: "pagination",
      loadingState: "loading",
      statusFilterOptions: "statusFilterOptions",
    }),
    chartFilter() {
      return {
        structure_id: this.filter.structureId,
        solution_id: this.filter.solutionId,
        chart_type: "area",
      };
    },
  },
  components: {
    OperationalTable,
    AlarmsFilter,
    AlarmsCount,
    TablePagination,
    AlarmsActions,
    StatusFilter,
  },
  methods: {
    getInitialFilter(){
      return  {
        ...this.$store.getters['SwamStore/OperationalAlarms/filter'],
        status:this.$route.query.status?[this.$route.query.status]:undefined,
        structureId:this.$route.query.structure_id?+this.$route.query.structure_id:undefined,
      };
    },
    ...mapActions("SwamStore/OperationalAlarms", {
      fetchData: "updateFilter",
      openCommentsBox: "openCommentsBox",
      openEscalateModal: "openEscalateModal",
      openAcknowledgmentModal: "openAcknowledgmentModal",
      goLive: "goLive",
      disconnect: "disconnect",
    }),
    ...mapMutations("SwamStore/OperationalAlarms", {
      setPaginationFilter: "setPaginationFilter",
      resetPaginationFilter: "resetPaginationFilter",
      setFilter: "setFilter",
    }),
    updateFilter(data) {
      this.setFilter(data);
      this.resetPaginationFilter();
      this.fetchData();
    },
    updatePagination(data) {
      this.setPaginationFilter(data);
      this.fetchData();
    },
  },
  watch: {
    pagination: {
      handler(v) {
        if (this.$refs.paginator) this.$refs.paginator.goTo(v.limit, v.offset);
      },
    },
  },
  created() {
    // this.goLive();
  },
  destroyed() {
    // this.disconnect();
  },
};
</script>

<style lang="scss" scoped>
.abs {
  position: absolute;
  right: 30px;
  top: 10px;
  z-index: 10
}

[dir="rtl"] .abs {
  left: 30px;
  right: unset;
}
</style>