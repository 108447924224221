<template>
  <form>
   <div class="device-photo"><img src="@/assets/Icons/gps.png" style="max-width:19%" /></div>
   
    <div class="row m-0">
      <div class="col-12">
        <base-input placeholder="Name" v-model="$v.name.$model">
          <template v-if="$v.name.$error" v-slot:error>
            <label class="error" v-if="!$v.name.required">Name is required</label>
          </template>
        </base-input>
      </div>
      <!-- imei -->
      <div class="col-12">
        <base-input placeholder="IMEI" v-model="$v.imei.$model">
          <template v-if="$v.imei.$error" v-slot:error>
            <label class="error" v-if="!$v.imei.required">IMEI is required</label>
          </template>
        </base-input>
      </div>
      <!-- mac -->
      <div class="col-12">
        <base-input placeholder="MAC" v-model="$v.mac.$model">
          <template v-if="$v.mac.$error" v-slot:error>
            <label class="error" v-if="!$v.mac.required">MAC is required</label>
          </template>
        </base-input>
      </div>

      <!-- <div class="col-12">
        <BaseAutoComplete
          :placeholder="$t('Device manufacturer Search')"
          :source="ManufacturerEndpoint"
          v-model="$v.manufacturer.$model"
        >
          <template v-if="$v.manufacturer.$error" v-slot:error>
            <label
              class="error"
              style="color: rgb(236, 37, 13);"
              v-if="!$v.manufacturer.required"
            >{{$t("requireds.manufacturer")}}</label>
          </template>
        </BaseAutoComplete>
      </div>-->
      <div class="col-12">
        <base-input 
        placeholder="Select Space" 
        @focus="structureBrowser=true"
        :value="spaceName">
          <template v-if="$v.selectedSpace.$error" v-slot:error>
            <label class="error" v-if="!$v.selectedSpace.required">Space is required</label>
          </template>
        </base-input>
        <!-- <input
          @focus="structureBrowser=true"
          :value="spaceName"
          placeholder="Select Space"
          type="text"
          class="form-control"
        />
        <template v-if="$v.selectedSpace.$error" >
            <label class="error" v-if="!$v.selectedSpace.required">Space is required</label>
          </template> -->
      </div>
      <div class="col-12 mt-2">
        <textarea class="form-control" placeholder="Device Details" v-model="device_details"></textarea>
      </div>

      <div class="col-12 text-center">
        <base-button native-type="submit" class="mt-3" size="sm" @click.prevent="submit">Submit</base-button>
      </div>
    </div>
    <el-dialog
      title="Structure Browser"
      width="70%"
      top="7vh"
      :append-to-body="true"
      :visible.sync="structureBrowser"
      :destroy-on-close="true"
    >
      <StructureMiniBrowser @select="spaceSelected" :structure="{id:lot.structure_id}" />
    </el-dialog>
  </form>
</template>
<script>
import StructureMiniBrowser from "../../Structure/Structures/Structure-Mini-Browser/mini-browser";
import { Dialog } from "element-ui";
import {
  required,
  //sameAs,
  minLength,
  email
  //numeric
} from "vuelidate/lib/validators";
import BaseAutoComplete from "@/components/BaseAutoComplete.vue";

export default {
  props: ["lot", "device"],
  data() {
    return {
      id: null,
      name: "",
      //
      imei: "",
      mac: "",
      manufacturer: null,
      structureBrowser: false,
      selectedSpace: null,
      spaceName: null,
      device_details: ""
    };
  },
  components: {
    BaseAutoComplete,
    StructureMiniBrowser,
    [Dialog.name]: Dialog
  },
  validations: {
    name: {
      required
    },
    imei: {
      required
    },
    mac: {
      required:false
    },
    selectedSpace:{
      required
    }
    
  },

  mounted() {
    if (this.device) {
      this.id = this.device.id;
      this.name = this.device.device_name;
      this.imei = this.device.imei;
      this.mac = this.device.mac;

      this.device_details = this.device.device_details;
      this.spaceName = this.device.spaceAddress;
    //   this.manufacturer = {
    //     display: this.device.manufacturer,
    //     selectedObject: {
    //       id: this.device.manufacturer_id
    //     }
    //   };
      this.selectedSpace = { id: this.device.space_id };
    } else {
      this.id = null;
      this.name = "";
      this.imei = '';
      this.mac = '';
      this.device_details = "";
      //this.manufacturer = null;
      this.structureBrowser = false;
      this.selectedSpace = null;
      this.spaceName = null;
    }
  },

  methods: {
    spaceSelected(data) {
      console.log(data)
      if(data.targetType != 'space'){
        console.error('type should be a space!');
        return;
      }
      this.structureBrowser = false;
      this.selectedSpace = data.selectedSpace;
      this.spaceName = data.targetAddress;
    },
    // ManufacturerEndpoint(input) {
    //   return `${this.$api.defaults.baseURL}manufacture/search/${input}`;
    // },
    // getSpaceName() {
    //   let spName = "";
    //   if(!this.device){
    //     if (this.selectedSpace.floor_name) {
    //       spName += "Floor: " + this.selectedSpace.floor_name + "    >>    ";
    //     }
    //     if (this.selectedSpace.outdoorSpace) {
    //       spName += "Space: " + this.selectedSpace.outdoorSpace + "    >>    ";
    //     }
    //     spName += this.selectedSpace.display;
    //   }else{
    //     spName = this.device.spaceAddress;
    //   }
    //   return spName;
    // },

    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$emit("close");
        let data = {
          device_name: this.name,
          imei: this.imei,
          mac:this.mac,
          template_id: this.lot.template_id,
          // solution_id: this.lot.solution_id,
          device_details:
            this.spaceName +
            (this.device_details && this.device_details != ""
              ? "\n" + this.device_details
              : "\n"),
          //deviceType: this.lot.solution.group_id,
          //manufacturer_id: this.manufacturer.selectedObject.id,
          order_detail_id: this.lot.id,
          space_id: this.selectedSpace.id
        };

        console.log("add device data:", data);

        if (this.id) {
          data.id = this.id;
          this.$api
            .put(`teltonika/devices/${this.id}/update`, data)
            .then(res => {
              if (res.status === 200) {
                //data.manufacturer = this.manufacturer.display;
                data.device_details = this.device_details;
                data.spaceAddress = this.spaceName;
                this.$store.dispatch("workorderstore/updateDevice", data);
              }
            })
            .catch(err => {
              console.log(err);
            });
          // update
        } else {
          // create
          this.$api
            .post(`teltonika/devices/create`, data)
            .then(res => {
              console.log("create device", res);
              if (res.status === 201) {
                let id = res.data.data;
                data.id = id;
                // data.manufacturer = this.manufacturer.display;
                data.device_details = this.device_details;
                data.spaceAddress = this.spaceName;
                this.$store.dispatch("workorderstore/addDevice", data);
              }

              this.$store.commit("setSpinner", false);
            })
            .catch(err => {
              this.$store.commit("setSpinner", false);
              console.log(err);
            });
        }
      }
    }
  }
};
</script>
<style scoped>
::v-deep .el-dialog__body {
  padding-top: 0;
}
textarea {
  height: 50rem;
  font-size: 0.9rem;
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.2);
  padding: 6px;
  border-radius: 7px;
}
</style>
