<template>
  <card class="mb-0">
    <div>
      <p class="rules-title"> {{$t("Rules List")}} </p>
    </div>
    <el-table :data="tableData"
              height="50vh"
              style="width: 100%"
              ref="multipleTable"
              @selection-change="handleSelectionChange">
      <el-table-column type="expand">
        <template slot-scope="{row}">
          <div class="row">
            <div v-for="per in row.permissions" :key="per.action_id" class="col-md-6 text-center">
              <span class="badge badge-dark permission">{{per.key}}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('Name')" prop="name"></el-table-column>
      <el-table-column :label="$t('Permissions Count')">
        <template slot-scope="{row}">
          <div>{{row.permissions.length}}</div>
        </template>
      </el-table-column>
      <el-table-column type="selection" width="55"></el-table-column>
    </el-table>
    <div slot="footer">
      <base-button size="sm" @click="submit">
        {{$t("Submit")}}
      </base-button>
    </div>
  </card>
</template>

<script>
  export default {
    props: {
      init: {
        default: () => []
      }
    },
    data() {
      return {
        tableData: [],
        multipleSelection: [],
        startWith: []
      };
    },
    created() {
      this.$store.commit("setSpinner", true);
      this.$api
        .get("permission/rules")
        .then(res => {
          this.tableData = res.data.rules;
          this.tableData.forEach(r => {
            r.permissions.filter(p=>p.key).forEach(p => {
              p.key = p.key.replace(/\_/g, ' ');
            })
          })
          if (this.init && this.init.length != 0) {
            this.startWith = this.tableData.filter(rule => {
              return this.init.some(id => {
                if (id == rule.id) {
                  return true;
                }
              });
            });
            this.$nextTick().then(_ => {
              this.toggleSelection();
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    mounted() {
      console.log(this.$refs.multipleTable);
    },
    methods: {
      toggleSelection(rows) {
        if (this.startWith && this.startWith.length != 0) {
          console.log(this.startWith);
          this.startWith.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      submit() {
        this.$emit('done', this.multipleSelection.map(r => r.id))
      }
    }
  };
</script>

<style scoped>
  ::v-deep .el-table__expanded-cell {
    background: transparent !important;
  }

  .permission {
    font-size: 1rem;
    text-transform: capitalize;
  }

  .rules-title {
    color: var(--light) !important;
  }
</style>

<style>
  .white-content .rules-title {
    color: var(--dark) !important;
  }
</style>
