<template>
  <card class="overflow-hidden full-height-card">
    <template slot="header">
      <div class="d-flex">
        <filter-tabs :options="cities" v-model="city" v-if="cities[0]"/>
      </div>
    </template>
    <base-map
      :zoom="15"
      :minZoom="9"
      :maxZoom="18"
      :center="center"
      style="height: 100%"
    >
      <template v-for="(marker, index) in mapMarkers">
        <base-map-marker
          :key="index"
          :position="marker"
          :title="marker.Device_name"
          :icon="require('src/assets/icons-2/google-map-marker.svg')"
        />
      </template>
    </base-map>
  </card>
</template>

<script>
import BaseMap from "@/components/BaseMap/Map";
import MapMarker from "@/components/BaseMap/MapMarker.vue";
import FilterTabs from "@/components/Filter-Tabs";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PortfolioMap",
  components: {
    [BaseMap.name]: BaseMap,
    [MapMarker.name]: MapMarker,
    FilterTabs,
  },
  data() {
    return {
      markers: [],
      center: {
        lat: 0,
        lng: 0
      }
    };
  },
  computed: {
    mapMarkers() {
      return this.markers.map(s => ({...s, lat: +s.lat, lng: +s.lng }));
    },
    ...mapGetters('ForkliftStore/Portfolio/PortfolioMap', [
      'cities',
      'mapData',
    ]),
    city:{
      get(){
        return this.cities[0].title
      },
      set(val){
        this.fetchMapData(val);
      }
    }
  },
  watch: {
    mapData: {
      handler() {
        this.changeMapCenter();
      },
      deep: true,
    },
  },
  methods:{
    ...mapActions('ForkliftStore/Portfolio/PortfolioMap', [
      'fetchCities',
      'fetchMapData'
    ]),
    changeMapCenter(){
      this.markers = this.mapData
      this.center.lat = this.mapData[0].lat
      this.center.lng = this.mapData[0].lng
    }
  },
  created(){
    this.fetchCities().then(() => {
      this.fetchMapData(this.cities[0])
    });
  }
};
</script>

<style>
</style>