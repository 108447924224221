<template>
    <div class="tracking">
        <div class="row">
            <div class="col-md-12 p-1">
                    <over-view />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4 p-1">
                <Alerts />
            </div>
            <div class="col-md-6 col-lg-4 p-1">
                <!-- fuel -->
                <Fuel />
            </div>
            <div class="col-md-12 col-lg-4 p-1">
                <!-- Maintenance -->
                <Maintenance />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-12 ">
                <div class="row">
                    <div class="col-md-6 col-lg-12 p-1">
                        <current-status />
                    </div>
                    <div class="col-md-6 col-lg-12 p-1">
                        <Distance />
                    </div>
                </div>
                
            </div>
            <div class="col-lg-8 col-md-12 p-1">
                <AllStatusMap />
            </div>
        </div>

        <div class="row">
            <div class="col-12 p-1">
                <Events />
            </div>
        </div>

        <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-12 p-1">
                <total-devices />
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 p-1">
                <Accidents />
            </div>
            
            <div class="col-xl-4 col-lg-12 col-md-12 p-1">
                <RiskExposure/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-1">
                    <DriverScore />
            </div>
        </div>

        <div class="row">
            <div class="col-12 p-1">
                    <TrackingTable />
            </div>
        </div>
        

    </div>



    
</template>
<script>
import OverView from './Overview.vue';
import Alerts from './Alerts.vue';
import Fuel from './Fuel.vue';
import Maintenance from './Maintenance.vue';
import CurrentStatus from './Current_Status.vue';
import AllStatusMap from 'src/pages/TeltonikaDevices/AdminDashboard/AllStatusMap.vue';
import Distance from './Distance.vue';
import Accidents from './Accidents.vue';
import TotalDevices from './TotalDevices.vue';
import RiskExposure from './RiskExposure.vue';
import DriverScore from './DriverScore.vue';
import Events from './Events.vue';
import TrackingTable from './TrackingTable.vue'



export default {
    components:{
        OverView,
        Alerts,
        Fuel,
        Maintenance,
        CurrentStatus,
        AllStatusMap,
        Distance,
        Accidents,
        TotalDevices,
        RiskExposure,
        DriverScore,
        Events,
        TrackingTable

    }
}
</script>
<style >
    .tracking{
        color:#fff;
    }
    .tracking span{
        font-size: 12px;
        font-weight: bolder;
    }
    

</style>