<template>
  <card class="mb-0" :headerClasses="'high-card-shadow pb-2'" :bodyClasses="'strc-info'">
    <el-page-header @back="back" slot="header" content="Device Profile"></el-page-header>
    <slot name="path"></slot>
    <div class="row m-0 mt-3">
      <div class="col-lg-4 col-md-6 col-12 De-Rd" v-for="card in statsCards" :key="card.title">
        <stats-card :title="card.title" :sub-title="card.subTitle" :type="card.type">
          <div slot="footer" v-html="card.footer"></div>
          <img slot="icon" :src="card.svg" alt="Communication Icon 1950311" />
        </stats-card>
      </div>
      <div class="col-12 De-Rd">
        <stats-card :title="details.title" :sub-title="details.subTitle" :type="details.type">
          <div slot="footer" v-html="details.footer"></div>
          <img slot="icon" :src="details.svg" alt="Communication Icon 1950311" />
        </stats-card>
      </div>
      <div class="col-12 text-center mt-3">
          <base-button @click.native="deviceReadings" type="warning">See Device Readings</base-button>
      </div>
    </div>
  </card>
</template>

<script>
import { PageHeader } from "element-ui";
import { futureDate } from "@/util/helper.js";
import { mapGetters } from "vuex";
import StatsCard from "src/components/Cards/StatsCard";
export default {
  components: {
    [PageHeader.name]: PageHeader,
    StatsCard
  },
  computed: {
    ...mapGetters({
      selected_device: "selectedDevice"
    }),
    statsCards() {
      if (this.selected_device) {
       /*let sensorLife = this.selected_device.sensors
          .map(snr => `${snr.name} : ${snr.life_remaining} %`)
          .join("_");*/
        return [
          {
            title: this.selected_device.device_name,
            subTitle: "Device Name",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Device.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> '
          },
          {
            title: this.selected_device.communication,
            subTitle: "Communication",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Communication.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> '
          },
          {
            title: futureDate(
              this.selected_device.contract_duration,
              this.selected_device.created_at
            ),
            subTitle: "Contract Expiry Date",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Contract.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> '
          },
          {
            title: this.selected_device.customer,
            subTitle: "Paying Customer Name",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Pay.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> '
          },
          {
            title: this.selected_device.fmc,
            subTitle: "FM Company Name",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Company.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> '
          },
         /* {
            title: this.selected_device.amc,
            subTitle: "AMC Company Name",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Company.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> '
          },*/
          /*{
            title: this.selected_device.operator,
            subTitle: "Operator",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Company.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> '
          },
          {
            title: this.selected_device.battery_percentage + "%",
            subTitle: "Battery Life",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Company.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> '
          },
          {
            title: sensorLife,
            subTitle: "Sensor Life",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Se.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> '
          }*/
        ];
      }
      return [];
    },
    details(){
         return {
            title: this.selected_device.device_details,
            subTitle: "Details",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Details.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> '
          }
    }
  },
  created() {
    this.$emit("explor");
  },
  methods: {
    back() {
      this.$emit("explor");
      this.$store.dispatch("goBack");
    },
    deviceReadings(){
        this.$router.push({path:`/device/reading/${this.selected_device.id}`})
    }
  }
};
</script>

<style scpoed>
.De-Rd {
  padding-right: 5px;
  padding-left: 0;
}
.De-Rd .card-stats {
  height: 82px !important;
}
.De-Rd-Un {
  margin-top: 30px;
}
</style>