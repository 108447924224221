<template>
  <card class="h-100">
    <div class="row">
      <div class="col-3">
        <h4>Speed</h4>
      </div>
      <div class="col-md-6">
        <base-select v-model="duration" :options="durationOptions"></base-select>
      </div>
      <div class="position-absolute top-1 right-1">
        <inline-svg :src="require('@/assets/Icons/speed.svg')" width="40" height="40" class="speed" />


      </div>
    </div>

    <div>
      <div class="d-flex justify-content-between mt-3 mr-2 ml-2">
        <span>
          <inline-svg
            :src="require('@/assets/Icons/speed.svg')"
            width="20"
            height="20"
            class="mr-2  align-top"
          />Minimum
        </span>
        <span class="d-block">0 Km/h</span>
      </div>
      <hr/>

      <div class="d-flex justify-content-between mt-3 mr-2 ml-2">
        <span>
          <inline-svg
            :src="require('@/assets/Icons/speed.svg')"
            width="20"
            height="20"
            class="mr-2  speed-avg align-top"
          />Avarege
        </span>
        <span class="d-block">{{AVG_speed}} Km/h</span>
      </div>
      <hr/>
      <div class="d-flex justify-content-between mt-3 mr-2 ml-2">
        <span>
          <inline-svg
            :src="require('@/assets/Icons/speed.svg')"
            width="20"
            height="20"
            class="mr-2  speed-max align-top"
          />Maximum
        </span>
        <span class="d-block">0 Km/h</span>
      </div>




    </div>
  </card>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {
  },
  data() {
    return {
      MultiProgressData: [{ value: 80 }, { value: 20 }],
      duration: 1,
      durationOptions: [
        {
          value: 1,
          label: "This Day",
        },
      ],
    };
  },
  computed:{
    ...mapState("CarTrackerStore", {
      carData: (state) => state.carData,
    }),
    AVG_speed(){
      return this.carData.speed;
    }
  }
};
</script>
<style >
    .speed g path{
        fill:#f74b68;
    }
    .speed-avg g path{
        fill:#fde959 ;
    }
    .speed-max g path{
        fill: #f74b68;
    }
</style>