<template>
    <div>
        <el-table :data="data">
            <template v-for="(column, index) in columns">
                <el-table-column
                    :key="index"
                    v-bind="column"
                    :align="'center'"
                    v-if="column.customTemplate"
                >
                    <template slot-scope="{ row }">
                        <template v-if="column.prop=='actions'">
                            <el-tooltip content="Edit" effect="light" :open-delay="300" placement="top">
                                <base-button
                                  @click.native="handleEdit(row)"
                                  class="edit btn-link"
                                  type="warning"
                                  size="sm"
                                  icon
                                >
                                  <i class="tim-icons icon-pencil"></i>
                                </base-button>
                            </el-tooltip>
                            <el-tooltip content="Delete" effect="light" :open-delay="300" placement="top" v-if="row.createdAt">
                                <base-button
                                  @click.native="handleDelete(row)"
                                  class="btn remove btn-link btn-icon btn-fab btn-danger btn-sm"
                                  type="warning"
                                  size="sm"
                                  icon
                                >
                                  <i class="tim-icons icon-simple-remove"></i>
                                </base-button>
                            </el-tooltip>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column 
                    v-else
                    :key="index"
                    v-bind="column"
                    :align="'center'"
                />     
             </template>
        </el-table>
        <WorkOrderDeviceModal :data="deviceData" ref="drawer" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import swal from "sweetalert2";
import WorkOrderDeviceModal from './WorkOrderDeviceModal';

export default {
    components: {
        WorkOrderDeviceModal
    },
    props: {
        data: {
            type: Array,
        },
        columns: {
            type: Array,
        },
    },
    data() {
        return {
            deviceData: undefined
        }
    },
    methods: {
        ...mapActions('VsaasStore/WorkOrder',{
            getDeviceData: 'getDeviceData',
            deleteDevice: 'deleteDevice',
        }),
        handleEdit(row) {
            if(row.createdAt)
                this.deviceData = {...row};
            else
                this.deviceData = undefined;
            this.$refs['drawer'].open();
        },
        handleDelete(row) {
            swal({
                title: "Are you sure?",
                text: `You won't be able to revert this!`,
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Yes, delete it!",
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                  this.deleteDevice(row.id);
                  swal({
                    title: "Deleted!",
                    text: `You deleted ${this.device.device_name}`,
                    type: "success",
                    confirmButtonClass: "btn btn-success btn-fill",
                    buttonsStyling: false,
                  });
                }
            });
        }
    }
}
</script>

<style>

</style>