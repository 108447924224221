<template>
  <el-dialog title="Alert Settings" width="900px" :visible.sync="show" custom-class="lock-quick-access px-3">
    <div>
      <base-checkbox checked="false">
        <h4 class="mb-1 font-weight-bold">Lock Unlocked Remotely</h4>
      </base-checkbox>
      <div class="px-4">
        <div class="d-flex align-items-center w-50 my-4">
          <el-date-picker class="mr-3" type="date" placeholder="Start" v-model="start_date"></el-date-picker>
          <el-date-picker type="date" placeholder="End" v-model="start_date"></el-date-picker>
        </div>
        <div class="mt-4">
          <span class="font-weight-bold">Select Days</span>
          <div class="row">
            <div class="col-lg-8">
              <div class="row">
             <div class="col-lg-4" v-for="(item,i) in days" :key="i">
                  <base-checkbox :checked="false" class="mx-2">
                    {{ item.label }}
                  </base-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <span class="font-weight-bold">Send alert via</span>
          <div class="row mt-3">
            <div class="col-lg-6">
              <span>Secondary Supervisor </span>
              <div class="row mt-2">
             <div class="col-lg-4 px-1" v-for="(item,i) in via" :key="i">
                  <base-checkbox :checked="false" class="mx-2">
                    {{ item.label }}
                  </base-checkbox>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <span>Alert User</span>
              <div class="row mt-2">
             <div class="col-lg-4 px-1" v-for="(item,i) in via" :key="i">
                  <base-checkbox :checked="false" class="mx-2">
                    {{ item.label }}
                  </base-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <base-checkbox checked="false">
        <span class="font-weight-bold">Lock outside geofence zone</span>
      </base-checkbox>
      <div class="px-4">
        <div class="my-2 d-flex align-items-center justify-content-between  w-75">
          <span class="font-weight-bold">Select geofence limit </span>
          <base-select
            v-model="value"
            :options="options"
            placeholder="100 m"
            class="mb-0"
            style="box-shadow: none !important"
          />
          <base-button style="border-radius: 1px" class="btn btn-primary ">Show on Map</base-button>
        </div>
        <div class="d-flex align-items-center w-50 my-4">
          <el-date-picker class="mr-3" type="date" placeholder="Start" v-model="start_date"></el-date-picker>
          <el-date-picker type="date" placeholder="End" v-model="start_date"></el-date-picker>
        </div>
        <div class="mt-4">
          <span class="font-weight-bold">Select Days</span>
          <div class="row mt-2">
            <div class="col-lg-10">
              <div class="row">
             <div class="col-lg-4 px-1" v-for="(item,i) in days" :key="i">
                  <base-checkbox :checked="false" class="mx-2">
                    {{ item.label }}
                  </base-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <span class="font-weight-bold">Send alert via</span>
          <div class="row mt-2">
            <div class="col-lg-6">
              <span>Secondary Supervisor </span>
              <div class="row mt-2">
             <div class="col-lg-4 px-1" v-for="(item,i) in via" :key="i">
                  <base-checkbox :checked="false" class="mx-2">
                    {{ item.label }}
                  </base-checkbox>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <span>Alert User</span>
              <div class="row mt-2">
             <div class="col-lg-4 px-1" v-for="(item,i) in via" :key="i">
                  <base-checkbox :checked="false" class="mx-2">
                    {{ item.label }}
                  </base-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <base-checkbox checked="false">
        <span class="font-weight-bold">Unlocked for __ hours</span>
      </base-checkbox>
      <div class="px-4">
        <div class="my-2 d-flex align-items-center justify-content-between  w-75">
          <span class="font-weight-bold">Unlocked time exceeds</span>
          <base-input stlye="box-shadow: none !important" placeholder="Hours" class="mb-0"></base-input>
          <base-input stlye="box-shadow: none !important" placeholder="Mins" class="mb-0"></base-input>
        </div>
        <div class="mt-4">
          <span class="font-weight-bold">Send alert via</span>
          <div class="row mt-2">
            <div class="col-lg-6">
              <span>Secondary Supervisor </span>
              <div class="row mt-2">
             <div class="col-lg-4 px-1" v-for="(item,i) in via" :key="i">
                  <base-checkbox :checked="false" class="mx-2">
                    {{ item.label }}
                  </base-checkbox>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <span>Alert User</span>
              <div class="row mt-2">
             <div class="col-lg-4 px-1" v-for="(item,i) in via" :key="i">
                  <base-checkbox :checked="false" class="mx-2">
                    {{ item.label }}
                  </base-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <base-checkbox checked="false">
        <span class="font-weight-bold">Lock Unlocked/Attempt to unlock by unauthorized</span>
      </base-checkbox>
    </div>
    <div class="mt-2">
      <base-checkbox checked="false">
        <span class="font-weight-bold">Low Battery</span>
      </base-checkbox>
    </div>
    <div class="mt-2">
      <base-checkbox checked="false">
        <span class="font-weight-bold">Tamper Detected</span>
      </base-checkbox>
    </div>
    <div class="mt-2">
      <base-checkbox checked="false">
        <span class="font-weight-bold">Device not reporting</span>
      </base-checkbox>
    </div>
    <div class="d-flex align-items-center justify-content-between mt-3 ">
      <base-button style="border-radius: 1px" class="btn btn-primary btn-simple" @click="close">Cancel</base-button>
      <base-button style="border-radius: 1px" class="btn btn-primary ">Submit</base-button>
    </div>
      <!-- 

                <base-checkbox :checked="false" class="mx-2">
                  {{ item.label }}
                </base-checkbox>
 -->
  </el-dialog>
</template>

<script>

export default {
  name: "create-quicke-access",
  props:{
    isOpen: {
        type: Boolean
    },
    type:{
      type: String
    }
  },
  data(){
    return{
      options: [],
      value: "",
      checked: "Yes",
      via: [
        {
          label: "Whatsapp",
          visible: false
        },
        {
          label: "Email",
          visible: false
        },
        {
          label: "Sms",
          visible: false
        },
        {
          label: "Telegram",
          visible: true
        },
        {
          label: "Signal",
          visible: true
        },
      ],
      days: [
        {
          label: "Sunday",
          visible: false
        },
        {
          label: "Monday",
          visible: false
        },
        {
          label: "Tuesday",
          visible: false
        },
        {
          label: "Wednesday",
          visible: true
        },
        {
          label: "Thursday",
          visible: true
        },
        {
          label: "Friday",
          visible: true
        },
      ],
      start_date: "",
      end_date: "",
    }
  },
  computed:{
    show:{
        get(){
          return this.isOpen;
        },
        set(val){
          this.$emit('close')
        }
    }
  },
  methods:{
    close(){
      this.$emit('close')
    }
  }
}
</script>

<style >
.white-content .lock-quick-access{
  background: white !important 
}
.lock-quick-access .date-class{
  width: 50% !important
}
</style>