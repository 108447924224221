<template>
  <div>
    <base-input>
      <div class="input-group">
        <div v-if="extra">
          <base-button
            class="but-filter text-capitalize"
            @click="showModal = true"
            aria-label="User"
            data-toggle="dropdown"
            simple
            type="success"
          >
            <img src="./../assets/Icons/Filter.svg" alt />
            {{$tc('search.filter', 3)}}
          </base-button>
        </div>
        <div class="input-group-prepend">
          <base-dropdown
            title-classes="dropdown-toggle btn btn-primary btn-block searchdropdown"
            :title="selected_column  && $t(selected_column.label)"
            :menuOnRight="isRTL"
          >
            <a
              class="dropdown-item"
              href="#"
              v-for="(col,index) in propsToSearch"
              :key="index"
              @click="selected_column= col"
            >{{$t(col.label)}}</a>
          </base-dropdown>
        </div>
        <input
          type="text"
          class="form-control search-filter"
          :placeholder="$t('Search')"
          @input="onInput"
        />
      </div>
    </base-input>
    <modal :show="showModal" @close="closeModal" :modalClasses="'fullscreenmodal'">
      <slot v-if="showModal" :row="closeModal"></slot>
    </modal>
  </div>
</template>
<script>
import { ReplaceSpecialChar } from "@/util/helper.js";

export default {
  props: {
    propsToSearch: {
      type: Array,
      required: true
    },
    value: {
      type: Object
    },
    extra: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected_column: this.propsToSearch[0],
      showModal: false
    };
  },
  mounted() {
    console.log("slots", this.$slots);
    console.log("children", this.$children);
  },
  methods: {
    onInput(e) {
      let o = {};
      o[this.selected_column.value] = ReplaceSpecialChar(e.target.value);
      const em = () => {
        this.$emit("input", o);
      };
      this.$helper.debounce(em)();
    },
    closeModal() {
      console.log("modal close");
      this.showModal = false;
    }
  },
  computed: {
    isRTL() {
      return this.$rtl.isRTL;
    }
  }
};
</script>
<style scoped>
.searchdropdown {
  border-radius: 0;
}

.but-filter img {
  max-width: 15%;
}

.but-filter {
  max-width: 90px;
  padding-right: 0;
  padding-left: 0;
  margin-inline-end: 5px;
}

::v-deep .btn.dropdown-toggle {
  padding: 11px 25px !important;
  margin: 4px 1px !important;
  margin-right: 0px !important;
}

.search-filter {
  margin-top: 5px !important;
  margin-bottom: 4px !important;
}
</style>
