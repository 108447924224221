<template>
  <div class>
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="customtableheader">
            <h4 slot="header" class="card-title">{{$t("Table.Workorder")}}</h4>
            <el-tooltip content="Create New Order" effect="light" :open-delay="300" placement="top">
              <router-link to="/order/create">
                <base-button type="primary" round icon>
                  <i class="tim-icons icon-simple-add"></i>
                </base-button>
              </router-link>
            </el-tooltip>
          </div>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="ml-3">
                <base-checkbox v-model="filters.active">Active</base-checkbox>
              </div>

              <Search
                :propsToSearch="propsToSearch"
                @input="(value)=>{filters ={ ...filters,...value}}"
              />
            </div>
            <div class="striped-table">
              <el-table :data="workorder_list">
                <!-- id -->

                <el-table-column :min-width="100" align="left" :label="$t('ID')">
                  <div slot-scope="props">
                    <span>{{$generateID(props.row.id,'WO')}}</span>
                  </div>
                </el-table-column>

                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                ></el-table-column>

                <el-table-column :min-width="100" align="left" :label="$t('Created at')">
                  <div slot-scope="props">
                    <span>{{gDateFormat(props.row.created_at)}}</span>
                  </div>
                </el-table-column>

                <el-table-column :min-width="135" align="right" :label="$t('Actions')">
                  <div slot-scope="props">
                    <el-tooltip
                      content="Show Order Blocks"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="showlots(props.row.id)"
                        class="like btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-notes"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="Edit" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="handleEdit(props.row)"
                        class="edit btn-link"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-pencil"></i>
                      </base-button>
                    </el-tooltip>

                    <!-- <base-button
                    @click.native="handleDelete(props.$index, props.row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                    </base-button>-->
                  </div>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <TablePagination
            slot="footer"
            ref="paginator"
            v-if="total"
            :total="total"
            @change="updatePagination"
          ></TablePagination>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";

import Search from "@/components/Search.vue";
import TablePagination from "@/components/TablePagination.vue";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Search,
    TablePagination
  },

  data() {
    return {
      workorder_list: [],
      total: 0,
      pagination: {
        offset: 0,
        limit: 10,
      },
      propsToSearch: [
        { value: "id", label: this.$i18n.t("ID") },
        // {value:"amc_id",label:this.$i18n.t("AMC")},
        { value: "customer_name", label: this.$i18n.t("Customer") }
      ],

      tableColumns: [
        {
          prop: `customers.name`,
          label: this.$i18n.t("Customer"),
          minWidth: 200
        },
        {
          prop: `mainamc.name`,
          label: this.$i18n.t("AMC Company"),
          minWidth: 200
        },

        {
          prop: `total_devices`,
          label: this.$i18n.t("Total Devices"),
          minWidth: 100
        },
        {
          prop: `pending_devices`,
          label: this.$i18n.t("Pending Devices"),
          minWidth: 150
        }
      ],
      filters: {
        customer_name: "",
        active: false,
        id: ""
      }
    };
  },
  watch: {
    filters: {
      handler() {
        if (this.$refs.paginator) {
          this.offset = 0;
          this.$refs.paginator.goTo(this.pagination.limit, this.pagination.offset);
        }
        this.fetchOrdersList();
      },
      deep: true
    },
    pagination(){
        this.fetchOrdersList();
    }
  },
  mounted() {
    let { active } = this.$route.query;
    if (active) {
      this.filters.active = true;
    }
    this.fetchOrdersList();
  },
  methods: {
    updatePagination(data) {
      this.pagination = data;
    },
    fetchOrdersList() {
      let { customer_name, active, id } = this.filters;

      this.$api
        .post(`orders?limit=${this.pagination.limit}&offset=${this.pagination.offset}`, {
          customer_name,
          active,
          id
        })
        .then(res => {
          if (res.data.msg === "success") {
            let result = res.data.data;

            this.total = res.data.total_records;
            this.workorder_list = result;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    showlots(id) {
      this.$store.dispatch("workorderstore/getOrderDetails", id);
    },

    handleEdit(row) {
      this.$router.push({ path: `/order/updatemain/${row.id}` });
    }
  }
};
</script>