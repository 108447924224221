<template>
  <div v-loading="loading">
    <div class="row">
      <div class="col-7" style="min-height: 70vh">
        <portfolio-map :markers="mapMarkers" :center="center"/>
      </div>
      <div class="col-5">
        <div class="row">
          <div class="col-12">
            <portfolio-vehicle-info />
          </div>
        </div>
        <div class="row">
          <div class="col-12 ">
            <portfolio-vehicle-alarms />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-7 d-flex align-items-stretch">
        <driver-performance @performanceDateType="setPerformanceDateType"/>
      </div>
      <div class="col-5 d-flex align-items-stretch">
        <portfolio-needs-attention />
      </div>
    </div>
    <div class="row">
      <div class="col-7 d-flex align-items-stretch">
        <div class="row" style="width: 104%">
          <div class="col-12 d-flex align-items-stretch">
            <vehicle-list :data="vehicleList" width=" 100% "/>
          </div>
          <div class="col-12 d-flex align-items-stretch">
            <vehicle-repair-inventory />
          </div>
        </div>
      </div>
      <div class="col-5 d-flex align-items-stretch">
        <vehicle-status-alarms />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forklifts-speed-comparison />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <interval-speed-comparison />
      </div>
    </div>
  </div>
</template>

<script>
import PortfolioMap from "../../core/PortfolioMap";
import PortfolioVehicleInfo from "../../core/PortfolioVehicleInfo";
import PortfolioVehicleAlarms from "../../core/PortfolioVehicleAlarms";
import PortfolioNeedsAttention from "../../core/PortfolioNeedsAttention";
import DriverPerformance from "./components/DriverPerformance";
import VehicleList from "./components/VehicleList";
import VehicleRepairInventory from "../../core/VehicleRepairInventory";
import VehicleStatusAlarms from "../../core/VehicleStatusAlarms";
import { mapGetters, mapActions } from "vuex";
import ForkliftsSpeedComparison from "./components/ForkliftsSpeedComparison.vue";
import IntervalSpeedComparison from "./components/IntervalSpeedComparison.vue";

export default {
  components: {
    PortfolioMap,
    PortfolioVehicleInfo,
    PortfolioVehicleAlarms,
    PortfolioNeedsAttention,
    DriverPerformance,
    VehicleList,
    VehicleRepairInventory,
    VehicleStatusAlarms,
    ForkliftsSpeedComparison,
    IntervalSpeedComparison,
  },
  data() {
    return {
      center: {
        lng: 0,
        lat: 0
      },
      mapMarkers: [],
      repairPagination:{
        offset: 0,
        limit: 5,
      },
      performanceDateType: null
    };
  },
  computed: {
    ...mapGetters("ForkliftStore/Portfolio", [
      "vehicleRepairInventoryData",
      "vehicleList",
      'loading'
    ]),
  },
  methods: {
    ...mapActions("ForkliftStore/Portfolio", [
      "fetchDriverPerformance",
      'fetchVehicleRepairInventoryData',
      'fetchVehicleList',
    ]),
    setPerformanceDateType(date){
      this.performanceDateType = date
    }
  },
  mounted() {
    this.fetchDriverPerformance(this.performanceDateType);
    this.fetchVehicleRepairInventoryData(this.repairPagination);
    this.fetchVehicleList(this.repairPagination);
  },
  
};
</script>

<style>
</style>