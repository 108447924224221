<template>
  <card class="mb-3">
    <template slot="header">
      <div
        class="d-flex align-items-center justify-content-between border-bottom pb-2"
      >
        <div class="d-flex align-items-center">
          <inline-svg
            :src="require('@/assets/Icons/forklift/noun_Battery.svg')"
            width="25"
            height="25"
          />
          <h5 class="ml-2 font-color-4 font-weight-bold">Battery</h5>
        </div>
        <div>
          <span class="font-color-1">Last charge&nbsp;</span>
          <span class="good font-weight-bold primary-color">
            {{ getDateString(data.last_charged_at) }}
          </span>
        </div>
      </div>
    </template>
    <div class="row font-color-1 mb-4">
      <div class="col-12 d-flex align-items-center">
        <span class="mr-2 font-weight-bold">Battery Level</span>
        <base-progress
          style="flex: 1 auto"
          :value="getFixed(data.battery_level)"
          :status="getPercentColor(data.battery_level)"
          valuePosition="right"
        />
        <span class="font-color-1 ml-2 battery-indicator">
          {{ millisToReadable(data.battery_time_left) }}
        </span>
      </div>
      <div class="col-12 d-flex align-items-center">
        <span class="mr-2 font-weight-bold">Battery Life</span>
        <base-progress
          style="flex: 1 auto"
          :value="getFixed(data.battery_life.percentage)"
          :status="getPercentColor(data.battery_life.percentage)"
          valuePosition="right"
        />
        <span class="font-color-1 ml-2 battery-indicator"
          >{{ data.battery_life.cycles.left }}/{{
            data.battery_life.cycles.total
          }}
          charges left</span
        >
      </div>
      <div class="col-12 d-flex justify-content-end">
        <span class="font-color-1 ml-2 battery-indicator text-right"
          >{{ batteryLifeTime }} Left</span
        >
      </div>
    </div>
    <div class="row font-color-1">
      <div class="col-12 d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <inline-svg
            :class="svgColor"
            :src="require('@/assets/Icons/forklift/noun_Lightning.svg')"
            width="20"
            height="20"
          />
          <span class="ml-2">Voltage</span>
          <span class="ml-2 font-weight-bold">{{ data.battery_voltage }}v</span>
        </div>
        <div class="d-flex align-items-center ml-4">
          <inline-svg
            :class="svgColor"
            :src="require('@/assets/Icons/forklift/noun_Lightning.svg')"
            width="20"
            height="20"
          />
          <span class="ml-2">Current</span>
          <span class="ml-2 font-weight-bold">
            {{ data.battery_current }}A
          </span>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import moment from "moment";
import BaseProgress from "@/components/BaseProgress";
import {
  getPercentColor,
  getDateString,
  millisToReadable
} from "@/util/helper";

export default {
  name: "Battery",
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BaseProgress
  },
  mounted() {
    setTimeout(function() {
      console.log("ddd");
    }, 3000);
  },
  computed: {
    svgColor() {
      return this.$store.getters.themMode == "light" ? "" : "light-svg";
    },
    batteryLifeTime() {
      return this.millisToReadable(this.data.battery_life.time_left);
      return moment(
        this.data.battery_life.time_left + new Date().getMilliseconds()
      ).from(this.data.battery_life.time_left);
    }
  },
  methods: {
    millisToReadable,
    getPercentColor,
    getDateString,
    getFixed(num) {
      return num.toFixed(2);
    }
  }
};
</script>

<style lang="scss" scoped>
.battery-indicator {
  font-size: 10px;
}
</style>
