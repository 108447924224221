<template>
  <card class="h-100">
    <div class="d-flex pr-1 pl-1 justify-content-between align-items-start">
      <h4>Risk Exposure</h4>
    </div>
    <div class="row">
        <div class="col-12">
            <RiskExposureChart />
        </div>
    </div>
  </card>
</template>
<script>
import RiskExposureChart from "@/components/Charts/RiskExposureChart.vue";
export default {
  components: {
    RiskExposureChart,
  },
};
</script>
<style  scoped>
</style>