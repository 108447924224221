<template>
  <card class="h-100">
    <div class="row align-items-center">
      <div class="col-md-2">
        <h4>Events By Time</h4>
      </div>
      <div class="col-lg-3 col-md-4">
        <base-select class="mw-50" v-model="duration" :options="durationOptions"></base-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <EventsChart />
      </div>
    </div>
  </card>
</template>
<script>
import EventsChart from "@/components/Charts/EventsChart.vue";

export default {
  components: {
    EventsChart,
  },
  data() {
    return {
      duration: 1,
      durationOptions: [
        {
          value: 1,
          label: "This Month",
        },
      ],
    };
  },
};
</script>