<template>
  <div class="row flex-column-reverse flex-md-column mt-1" style="position:relative;">
    <div></div>
    <transition name="slide">
      <div class="col-md-4" v-if="expanded">
        <FloorsSpacesList />
      </div>
    </transition>

    <div
      class="t mb-3 mb-md-0 path"
      :class="{'col-md-9':!expanded,'col-md-8':expanded,'t9':!expanded,'t8':expanded}"
    >
      <el-breadcrumb
        class="mb-2 mt-n4"
        v-if="currentPath.length>1"
        :separator-class="separatorClass"
      >
        <el-breadcrumb-item v-for="(item, index) in currentPath" :key="index">{{item}}</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <keep-alive> -->
      <component class="test" :is="rightWindow" @explor="expanded=!expanded"></component>
      <!-- </keep-alive> -->
    </div>
    <el-drawer ref="drawer" :visible.sync="openDrawer" :direction="'rtl'">
      <template v-if="toolsWindow=='newSpace'">
        <div slot="title">
          <h4>Create New Space</h4>
        </div>
        <NewSpace />
      </template>
      <template v-else-if="toolsWindow=='duplicateSpace'">
        <div slot="title">
          <h4>Duplicate a Space</h4>
        </div>
        <DuplicateSpace />
      </template>
    </el-drawer>
  </div>
</template>

<script>
import StructureInfo from "./StructureInfo";
import FloorInfo from "./FloorInfo";
import SpaceInfo from "./SpaceInfo";
import DeviceInfo from "./DeviceInfo";
import FloorsSpacesList from "./Floors-Spaces-devices-List";
import NewSpace from "./New-Space";
import DuplicateSpace from "./DuplicateSpace";
import { mapGetters } from "vuex";
import { Drawer, Divider, Breadcrumb, BreadcrumbItem } from "element-ui";
import SingleInfoCard from "./../SingleInfoCard";
import vue from "vue";
vue.component(Divider.name, Divider);
vue.component("SingleInfoCard", SingleInfoCard);
export default {
  props: ["id"],
  data() {
    return {
      expanded: false,
      resetForm: false,
      lang: localStorage.getItem("lang"),
      separatorClass:
        localStorage.getItem("lang") == "ar"
          ? "el-icon-arrow-left"
          : "el-icon-arrow-right"
    };
  },
  computed: {
    ...mapGetters({
      rightWindow: "rightWindow",
      toolsWindow: "toolsWindow",
      currentPath: "currentPath"
    }),
    // toolsWindow: {
    //   get() {
    //     return this.tools;
    //   },
    //   set(value) {
    //     this.$store.commit("toolsWindow", value);
    //   }
    // },
    openDrawer:{
      get() {
         return this.toolsWindow?true:false;
      },
      set(value) {
        this.$store.commit("toolsWindow", value);
      }
    }
  },
  created() {
    this.$store.dispatch("getStructure", this.id);
  },
  methods: {},
  components: {
    StructureInfo,
    FloorsSpacesList,
    NewSpace,
    DuplicateSpace,
    [Drawer.name]: Drawer,
    FloorInfo,
    SpaceInfo,
    DeviceInfo,
    [Breadcrumb.name]: Breadcrumb,
    [BreadcrumbItem.name]: BreadcrumbItem
  },
  destroyed() {
    this.$store.commit("reset_structure_store");
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .t8 {
    animation: slide-in2 0.3s linear forwards;
  }
  .t9 {
    animation: slide-out2 0.3s linear forwards;
  }
  .t {
    position: absolute;
    transition: all 0.3s linear;
  }
}
@keyframes slide-in2 {
  from {
    right: 13%;
  }
  to {
    right: 0%;
  }
}
@keyframes slide-out2 {
  from {
    right: 0%;
  }
  to {
    right: 13%;
  }
}

.slide-enter-active {
  animation: slide-in 0.3s linear forwards;
}
.slide-leave-active {
  animation: slide-out 0.3s linear forwards;
}
@keyframes slide-in {
  from {
    transform: translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes slide-out {
  from {
    transform: translateX(0px);
    opacity: 1;
  }
  to {
    transform: translateX(-100px);
    opacity: 0;
  }
}
::v-deep .path .el-breadcrumb__inner {
  color: #a7a7a7;
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0.7px;
}
</style>

<style>
.strc-info {
  height: 75vh;
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: overlay;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>