<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card
          card-body-classes="table-full-width"
          v-loading="state == 'loading'"
        >
          <div class="customtableheader">
            <h4 slot="header" class="card-title">{{ $tc("deviceWords.deviceTable") }}</h4>
            <el-tooltip :content="$t('deviceWords.orderNewDevice')">
              <router-link to="/order/create">
                <base-button type="primary" round icon>
                  <i class="tim-icons icon-simple-add"></i>
                </base-button>
              </router-link>
            </el-tooltip>
          </div>
          <div class="row justify-content-center mb-5">
            <filter-tabs
              tabClasses="pl-2 pr-2"
              :options="solutionOptions"
              v-model="selectedSolution"
              :allowClear="true"
            >
            </filter-tabs>
          </div>

          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <base-select
                v-model="selectedStructureId"
                :options="structureOptions"
                class="d-flex align-items-baseline pl-2 pr-2"
              >
                <p class="filter-label" slot="label">{{$tc('structureWords.structure')}} &nbsp; : &nbsp;</p>
              </base-select>
              <base-select
                class="d-flex align-items-baseline pl-2 pr-2"
                v-model="selectedDeviceState"
                :options="operationalOption"
              >
                <p class="filter-label" slot="label">{{ $t('sensorWords.state') }} &nbsp; : &nbsp;</p>
              </base-select>

              <Search :propsToSearch="propsToSearch" @input="name = ($event || {}).name" />
            </div>

            <devices-list
              @item-deleted="onDelete"
              :data="device_list"
              :state="state"
            />
          </div>
          <TablePagination
            slot="footer"
            ref="paginator"
            v-if="total"
            :total="total"
            @change="updatePagination"
          ></TablePagination>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import FilterTabs from "@/components/Filter-Tabs";
import DevicesList from "@/pages/SWAM/components/DevicesList";
import { BasePagination } from "src/components";
import Search from "@/components/Search.vue";
import TablePagination from "@/components/TablePagination.vue";

export default {
  components: {
    BasePagination,
    Search,

    FilterTabs,
    DevicesList,
    TablePagination,
  },
  computed: {
    filterInfos() {
      return {
        structure: this.selectedStructureId,
        solution_id: (this.selectedSolution || {}).id,
        name: this.name,
        on: this.selectedDeviceState,
      };
    },
    currentQuery() {
      return {
        limit: this.limit,
        offset: this.offset,
        ...this.filterInfos,
      };
    },
    ...mapGetters({
      availableSolutions: "SwamStore/solutions",
      structures:"SwamStore/haveDevices"
    }),
    device_list() {
      return this.$store.getters["SwamStore/DevicesList/items"](this.currentQuery);
    },
    solutionOptions() {
      return Object.keys(this.availableSolutions).map((key) => {
        return {
          id: key,
          title: (this.availableSolutions[key].name + ""),//.replace(/ monitoring/i,""),
          ...this.availableSolutions[key],
        };
      });
    },
    structureOptions() {
      const data = this.structures.map((s) => {
        return {
          label: s.structure_name,
          value: s.id,
        };
      });
      data.unshift({
        label: this.$i18n.t("All"),
        value: undefined,
      });
      return data;
    },
    operationalOption() {
      return [
        { value: undefined, label: this.$i18n.t("All") },
        { value: true, label: this.$i18n.t("On") },
        { value: false, label: this.$i18n.t("Off") },
      ];
    },
    propsToSearch() {
      return [{ value: "name", label: "name" }];
    },

  },
  data() {
    return {
      selectedSolution: undefined,
      selectedStructureId: null,
      selectedDeviceState: null,
      state: "",
      name: null,
      total: 0,
      offset: 0,
      limit: 10,

    };
  },
  created() {
    if (this.structures.length == 0) {
      this.$store.dispatch("SwamStore/FETCH_ITEMS",{
        key:'all',
      });
    }
    let { solution_id, structure_id, on } = this.$route.query;

    this.selectedSolution = (
      this.solutionOptions.filter((s) => s.value == solution_id)[0] || {}
    ).id;
    this.selectedStructureId = (
      this.structureOptions.filter((s) => s.value == structure_id)[0] || {}
    ).value;
    this.selectedDeviceState = on == "true" ? true : (on=="false"? false : undefined);

    this.fetchData();
  },
  methods: {
    updatePagination(data) {
      this.limit = data.limit;
      this.offset = data.offset;
    },
    onDelete(id) {
      this.total--;
    },
    fetchData() {
      this.state = "loading";
      this.$store
        .dispatch("SwamStore/DevicesList/FETCH_ITEMS", {
          key: `iot devices list`,
          query: this.currentQuery,
        })
        .then((response) => {
          this.state = "loaded";
          this.total = response.total_records;
        })
        .catch((error) => {
          this.state = "error";
          console.error("Error while fetching devices list", error);
        });
    },
  },
  watch: {
    currentQuery: {
      handler() {
        this.fetchData();
      },
    },
    filterInfos: {
      handler() {
        if(this.$refs.paginator)
        {
          this.offset = 0;
          this.$refs.paginator.goTo(this.limit,this.offset);
        }
      },
    },
  },
};
</script>
<style scoped>
.device-list-button {
  font-size: 10px;
  max-width: 17%;
}
::v-deep .btn-group .btn-simple {
  padding: 5px 15px;
  min-width: 170px;
}
.filter-label{
  font-size: 1rem;
  padding: 0 10px;
}
</style>
