<template>
  <div class>
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="customtableheader">
            <h4 slot="header" class="card-title">{{$t("Table.Company")}}</h4>
            <router-link to="/company/create">
              <el-tooltip content="Create Company" effect="light" :open-delay="300" placement="top">
                <base-button type="primary" round icon>
                  <i class="tim-icons icon-simple-add"></i>
                </base-button>
              </el-tooltip>
            </router-link>
          </div>

          <!-- <div class="row mb-3 ml-3 justify-content-center">
            <div class="btn-group">
              <button type="button"
                      class="btn btn-info btn-outline"
                      :class="{'active':user_type === 'amc'}"
                      @click="change('amc')">
                {{$t("AMC")}}
              </button>
              <button type="button"
                      class="btn btn-info btn-outline"
                      :class="{'active':user_type === 'fmc'}"
                      @click="change('fmc')">
                {{$t("FMC")}}
              </button>
              <button type="button"
                      class="btn btn-info btn-outline"
                      :class="{'active':user_type === 'authority'}"
                      @click="change('authority')">
                {{$t("Authorities")}}
              </button>
              <button type="button"
                      class="btn btn-info btn-outline"
                      :class="{'active':user_type === 'customer'}"
                      @click="change('customer')">
                {{$t("Customer")}}
              </button>
              <button type="button"
                      class="btn btn-info btn-outline"
                      :class="{'active':user_type === 'operator'}"
                      @click="change('operator')">
                {{$t("Operator")}}
              </button>
            </div>
          </div>-->

          <!-- pink -->
          <div class="row mb-3 ml-3 justify-content-center">
            <div
              class="btn-group btn-group-toggle"
              :class="isRTL ? 'float-left' : 'float-right'"
              data-toggle="buttons"
            >
              <!-- amc -->
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{'active':user_type === 'amc'}"
                @click="change('amc')"
              >
                <input type="radio" name="options" autocomplete="off" />
                <span class="d-none d-sm-block">{{$t("AMC")}}</span>
              </label>
              <!-- fmc -->
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{'active':user_type === 'fmc'}"
                @click="change('fmc')"
              >
                <input type="radio" name="options" autocomplete="off" />
                <span class="d-none d-sm-block">{{$t("FMC")}}</span>
              </label>
              <!-- auth -->
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{'active':user_type === 'authority'}"
                @click="change('authority')"
              >
                <input type="radio" name="options" autocomplete="off" />
                <span class="d-none d-sm-block">{{$t("Authorities")}}</span>
              </label>
              <!-- customer -->
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{'active':user_type === 'customer'}"
                @click="change('customer')"
              >
                <input type="radio" name="options" autocomplete="off" />
                <span class="d-none d-sm-block">{{$t("Customer")}}</span>
              </label>
              <!-- op -->
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{'active':user_type === 'operator'}"
                @click="change('operator')"
              >
                <input type="radio" name="options" autocomplete="off" />
                <span class="d-none d-sm-block">{{$t("Operator")}}</span>
              </label>
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{'active':user_type === 'police_station'}"
                @click="change('police_station')"
              >
                <input type="radio" name="options" autocomplete="off"  />
                <span class="d-none d-sm-block">{{$t("police_station")}}</span>
              </label>
            </div>
          </div>

          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <!-- <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search')"
                  v-model="filterValue"
                  v-on:keydown.enter="applyFilter"
                  aria-controls="datatables"
                ></el-input>
              </base-input>-->

              <Search :propsToSearch="propsToSearch" @input="(value)=>{filter = value}" />
            </div>

            <div class="striped-table">
              <el-table :data="company_list">
                <div
                  slot="empty"
                  v-if="$asyncComputed.company_list.state === 'updating'"
                >Loading ...</div>
                <div slot="empty" v-if="$asyncComputed.company_list.state === 'error'">Error</div>
                <div
                  slot="empty"
                  v-if="$asyncComputed.company_list.state === 'success' && company_list.length == 0"
                >No Data</div>

                <el-table-column
                  sortable
                  :sort-method="(a,b) => a > b"
                  :min-width="200"
                  align="left"
                  :label="$t('ID')"
                >
                  <div slot-scope="props">
                    <span>{{$generateID(props.row.id,user_type)}}</span>
                  </div>
                </el-table-column>

                <el-table-column
                  sortable
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                ></el-table-column>

                <el-table-column
                  sortable
                  :sort-method="(a,b) => a > b"
                  :min-width="200"
                  align="left"
                  :label="$t('Date')"
                >
                  <div slot-scope="props">
                    <span>{{gDateFormat(props.row.created_at)}}</span>
                  </div>
                </el-table-column>

                <el-table-column :min-width="155" align="right" :label="$t('Actions')">
                  <div slot-scope="props">
                    <el-tooltip content="View" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="viewCom(props.row)"
                        class="like btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-notes"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Permissions"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="handlePermissions(props.row)"
                        class="edit btn-link"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-key-25"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip content="Delete" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="handleDelete(props.$index, props.row)"
                        class="remove btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-simple-remove"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="Webhook" effect="light" :open-delay="300" placement="top">
                    <base-button
                      @click.native="goToWebhooks(props.row)"
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon
                    >
                     <inline-svg :src="require('@/assets/Icons/web.svg')" width="20" height="20"  />
                    </base-button>
                  </el-tooltip>

                    <el-tooltip
                      content="View logs"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="ViewLogs(props.row)"
                        class="like btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="fa fa-history"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <TablePagination
            slot="footer"
            ref="paginator"
            v-if="total"
            :total="total"
            @change="updatePagination"
          ></TablePagination>
        </card>
      </div>
    </div>
    <!--  -->
    <modal :show="showModal || showRulesModal" @close="close" :modalClasses="'fullscreenmodal'">
      <RulesModal v-if="showRulesModal" :init="selected_company.rules" @done="closeRulesModal" />
    </modal>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
//import users from "./users";
import Search from "@/components/Search.vue";
import swal from "sweetalert2";

import Vue from "vue";
import moment from "moment";
import RulesModal from "./../Permissions/components/RulesModal";
import TablePagination from "@/components/TablePagination.vue";

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY hh:mm");
  }
});
export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Search,
    RulesModal,
    TablePagination
  },

  data() {
    return {
      selected_company: null,
      showModal: false,
      showRulesModal: false,

      total: 0,
      limit: 10,
      offset: 0,

      propsToSearch: [
        { value: "name", label: this.$i18n.t("Name") },
        { value: "address", label: this.$i18n.t("address") },
        { value: "id", label: this.$i18n.t("ID") }
      ],

      tableColumns: [
        {
          prop: "name",
          label: this.$i18n.t("Company Name"),
          minWidth: 200
        },
        {
          prop: "address",
          label: this.$i18n.t("address"),
          minWidth: 200
        }
        // {
        //   prop: "created_at",
        //   label: this.$i18n.t("Date"),
        //   minWidth: 200
        // }
      ],
      tableData: [],

      tableId: "table" + Math.floor(Math.random() * 1000),
      columns: [
        { id: "id", label: this.$i18n.t("ID") },
        { id: "name", label: this.$i18n.t("Company Name") },
        { id: "address", label: this.$i18n.t("address") },
        // { id: "created_at", label: "Date" },
        { id: "users", label: this.$i18n.t("Users") }
      ],

      filter: null,
      user_type: "amc",
      show: false,
      modalClass: "modal-xxl",
      id: ""
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  asyncComputed: {
    company_list: {
      get() {
        console.log(this.user_type);
        if (this.user_type) {
          if (this.filter) {
            if (this.filter.id) {
              this.filter.id = +this.filter.id;
            }
          }
          return this.$api
            .post(
              `${this.user_type}/listfilter/${this.limit}/${this.offset}`,
              this.filter
            )
            .then(res => {
              this.total = res.data.total_records;
              return res.data.data;
            })
            .catch(err => {
              console.log(err);
            });
        } else return [];
      },
      default: []
    }
  },
  methods: {
    goToWebhooks(company){
      this.$router.push({path:`${company.id}/webhook`,query:{name:company.name}});
    },
    updatePagination(data) {
      this.limit = data.limit;
      this.offset = data.offset;
    },
    handlePermissions(company) {
      console.log(company);
      this.selected_company = company;
      this.showRulesModal = true;
    },
    closeRulesModal(newRules) {
      this.showRulesModal = false;
      if (this.isRulesChanged(this.selected_company.rules, newRules)) {
        this.$store.commit("setSpinner", true);
        this.$api
          .patch(`company/${this.selected_company.id}/rules`, {
            rules: newRules
          })
          .then(res => {
            console.log(res);
            this.selected_company.rules = newRules;
          })
          .catch(console.error);
      }
    },
    isRulesChanged(oldRules, newRules) {
      if (oldRules.length != newRules.length) {
        return true;
      }
      oldRules = oldRules.sort();
      newRules = newRules.sort();

      for (let i = 0; i < oldRules.length; i++) {
        if (oldRules[i] != newRules[i]) {
          return true;
        }
      }
      return false;
    },
    ViewLogs(company) {
      this.$router.push({
        path: `/logs/list`,
        query: {
          target_type: "company_id",
          target_id: company.id,
          target_name: company.name
        }
      });
    },
    viewCom(company) {
      // this.selected_company = company;
      // this.showModal = true;
      // this.$router.push({ path: `view/${company.id}/${this.user_type}` });
      this.$router.push({
        path: `/profiles/company/${this.user_type}/${company.id}`
      });
    },
    close() {
      this.selected_company = null;
      this.showModal = false;
      this.showRulesModal = false;
    },
    handleDelete(index, row) {
      swal({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteCompany(row.id);
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.company_list.findIndex(
        tableRow => tableRow.id === row
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },

    reloadData() {
      this.$asyncComputed["company_list"].update();
      // this.page = 1;
      //  this.$forceUpdate();
      //this.recompute("key");
      // vm.$recompute('company_list');
      //this.company_list;
    },
    deleteCompany(id) {
      this.$store.commit("setSpinner", true);
      this.$api
        .delete(`${this.user_type}/${id}`)
        .then(() => {
          this.reloadData();
          swal({
            title: "Deleted!",
            text: `You deleted ${row.name}`,
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false
          });
        })
        .catch(err => {
          console.log(err);
        });
    },

    clickModal(id) {
      this.id = id;
      this.show = true;
      this.$modal.show("company-view");
    },
    change(type) {
      console.log(type);
      this.page = 1;
      this.user_type = type;
    },
    myCallback() {},
    sortTable(n) {
      let table,
        rows,
        switching,
        i,
        x,
        y,
        shouldSwitch,
        dir,
        switchcount = 0;

      table = document.getElementById(this.tableId);
      switching = true;
      dir = "asc";

      while (switching) {
        switching = false;
        rows = table.rows;
        for (i = 1; i < rows.length - 1; i++) {
          shouldSwitch = false;

          x = rows[i].getElementsByTagName("TD")[n];
          y = rows[i + 1].getElementsByTagName("TD")[n];

          if (dir == "asc") {
            if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
              shouldSwitch = true;
              break;
            }
          } else if (dir == "desc") {
            if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
              shouldSwitch = true;
              break;
            }
          }
        }
        if (shouldSwitch) {
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
          switchcount++;
        } else {
          if (switchcount == 0 && dir == "asc") {
            dir = "desc";
            switching = true;
          }
        }
      }
    }
  },
  watch: {
    filter() {
      if (this.$refs.paginator) {
        this.offset = 0;
        this.$refs.paginator.goTo(this.limit, this.offset);
      }
    },
    user_type() {
      if (this.$refs.paginator) {
        this.offset = 0;
        this.$refs.paginator.goTo(this.limit, this.offset);
      }
    }
  }
};
</script>

<style scoped>
th {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  text-align: left;
}

td {
  text-align: left;
}

.abtn {
  background-color: #30404d;
  border: 1px solid #596778;
  color: #839bbb;
  border-radius: 10px;
}

.vue-my .vue-treeselect__control {
  background-color: red !important;
}
</style>
