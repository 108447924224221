<template>
  <card type="chart" class="device-chart">
    <template slot="header">
      <h4>Average Daily interactions</h4>
    </template>
      <zingchart :data="chartConfig" ref="tempchart" :height="'100%'" :width="'100%'" :series="series"></zingchart>
    
  </card>
</template>
<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      chartConfig: {
        "background-color": "transparent",
        type: "bar",
        'scale-y':{
            values:'0:20:1'
        },
        plot: {
          stacked: true,
          stackType: "normal"
        },
        // series: [
        //   {
        //     values: [['sun',20], ['mon',40],['tus', 25], ['wen',50], ['thu',15], ['fri',45], ['sat',33]]
        //   },
          
        // ]
      }
    };
  },
  computed:{
      ...mapState('TeltonikaStore',{
          series:state=>state.interactions
      })
  }
};
</script>
<style scoped>
.device-chart {
  height: 100%;
  margin: 0;
}
/* .chart-container {
  height: 100%;
  min-height: 300px;
} */
</style>

