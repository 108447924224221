<script>
  export default {
    name: "base-map-polyline",
    props: {
      path: {
        type: Array,
        default: () => []
      },
      options: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
      };
    },
    mounted() {
      console.log(1111111111111111111)

      this.$parent.getMap(map => {
        const flightPlanCoordinates = [
          { lat:25.1525217, lng:55.29445630000001 },
          { lat:25.1525222, lng:55.29445630000001 },
          { lat:25.2535300, lng:55.29445630000001 },
          { lat:25.3545400, lng:55.29445630000001 },
          { lat:25.2545400, lng:55.39445630000001 },
          { lat:25.1545400, lng:55.49445630000001 },
        ];
        const flightPath = new google.maps.Polyline({
          path: flightPlanCoordinates,
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2,
          ...this.options
        });
        flightPath.setMap(map);
      });
    },
    watch: {

    },
    beforeDestroy() {

    },
    render() {
      return null;
    }
  };
</script>