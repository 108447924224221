<template>
  <card class="h-100">
    <div class="row">
      <div class="col-3">
          <h4>Oil Level</h4>
      </div>
     
      <div class="position-absolute top-1 right-1">
          <inline-svg :src="require('@/assets/Icons/oil.svg')" width="40" height="40"  />
      </div>
      
      
    </div>

    <div >
      <div class="row">
        <!-- <div class="col-4 text-left fuel-min">
          <span>Avarege</span>
          <h1 class="font-weight-bolder">20%</h1>
        </div>
        <div class="col-4 text-left fuel-avg ">
          <span>Now</span>
          <h1 class="font-weight-bolder">45%</h1>
        </div> -->
        <!-- <div class="col-4 text-left fuel-max">
          <span>Max</span>
          <h1 class="font-weight-bolder">80%</h1>
        </div> -->
      </div>
    <span class="d-block">Engine Oil Level</span>
      <div class="row mt-3">
        
        <div class="col-4 text-left fuel-min">
          <span>Avarege</span>
          <h1 class="font-weight-bolder">{{avg_engine_oil_level}}%</h1>
        </div>
        <div class="col-4 text-left fuel-avg ">
          <span>Now</span>
          <h1 class="font-weight-bolder">{{engine_oil_level}}%</h1>
        </div>
        <!-- <div class="col-4 text-left fuel-max">
          <span>Max</span>
          <h1 class="font-weight-bolder">80%</h1>
        </div> -->
      </div>

     

      


    </div>
  </card>
</template>
<script>
  import {mapState} from 'vuex';

export default {
  components: {
  },
  data(){
    return{
      MultiProgressData:[
          {value:80},
          {value:20},
      ],
      duration: 1,
      durationOptions: [
        {
          value: 1,
          label: "This Month",
        },
      ],

    }
  },
  computed:{
      ...mapState('CarTrackerStore',{
          carData:state=>state.carData
      }),

      engine_oil_level(){
          return this.carData.engine_oil_level || 0;
      },
      avg_engine_oil_level(){
          return this.carData.avg_engine_oil_level || 0;
      },
      
  }
};
</script>
<style >
  .fuel-min, .fuel-min h1{
    color:#d84fd4 !important ;
  } 
  .fuel-avg, .fuel-avg h1{
    color:#3ca271 !important ;
  } 
  .fuel-max, .fuel-max h1{
    color:#22abf7 !important ;
  } 
  .fuel-drain{
    color:#e89f33 !important;
  }
  .fuel-drain-avg{
    color:#3ca271;
  }
  .drain{
    background-color: #fa537c;
  }
  .refill{
    background-color: #4af93b;
  }

</style>