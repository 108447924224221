<template>
  <card class="h-100">
    <div class="d-flex pr-1 pl-1 justify-content-between align-items-start">
      <h4>Distance</h4>
    </div>
    <div>
      <DistanceChart />
    </div>
  </card>
</template>
<script>
import DistanceChart from "@/components/Charts/DistanceChart.vue";
export default {
  components: {
    DistanceChart,
  },
};
</script>
<style  scoped>
</style>