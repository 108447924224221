<template>
  <el-dialog :visible.sync="show" :modal="false">
    <slot name="title">
      <div class="text-left" style="margin-top: -40px">
        <h4>{{ $t('acknowledgement') + ' ' + $t('alarm') }}</h4>
      </div>
    </slot>
    <div class="col-12">
      <label for="comment">{{ $tc('comment') }}</label>
      <base-input
        name="comment"
        id="comment"
        v-model="comment"
        :placeholder="$tc('comment')"
      >
        <template v-if="$v.comment.$error" v-slot:error>
          <div class="position-absolute">
            <label class="error" v-if="!$v.comment.required"
              >{{ $tc('theComment') + ' ' + $tc('validations.isRequired') }} </label
            >
            <label class="error" v-if="!$v.comment.maxLength"
              >{{ $t('theComment') + ' ' + $t('validations.lessThan', ['100']) }} </label
            >
            <label class="error" v-if="!$v.comment.minLength"
              >{{ $t('theComment') + ' ' + $t('validations.moreThan', [10])}}</label
            >
          </div>
        </template>
      </base-input>
    </div>

    <div class="d-flex justify-content-around mt-4">
      <button @click="submit" class="btn btn-primary">{{ $t('Submit') }}</button>
      <button @click="submit(true)" class="btn btn-info">
        {{$t('Submit') + ` ${$t('And')} ` + $t('Escalate') }}
      </button>
    </div>
  </el-dialog>
</template>

<script>
import { required, maxLength, minLength } from "vuelidate/lib/validators";

export default {
  props: ["isOpen"],
  data() {
    return {
      show: false,
      comment: "",
    };
  },
  created() {
    this.show = this.isOpen;
  },
  methods: {
    submit(escalate) {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$emit("submit", {
        comment: this.comment,
        escalate: typeof escalate == "boolean" ? escalate : undefined,
      });
    },
  },
  watch: {
    isOpen: {
      handler(v) {
        this.show = v;
        if (v) {
          this.$v.$reset();
          this.comment = "";
        }
      },
    },
    show: {
      handler(v) {
        if (!v) {
          this.$emit("close");
        }
      },
    },
  },
  validations: {
    comment: {
      required,
      maxLength: maxLength(100),
      minLength: minLength(10),
    },
  },
};
</script>

<style>
</style>