<template>
  <card>
    <div class="device-status">
      <div class="device-status-header">
        <h4>Devices Status</h4>
      </div>
    </div>

    <div class="device-status-number">
      <h4>Number Of Devices</h4>
      <div class="cl-type">100</div>
    </div>
    <div class="device-status-switch">
      <div >
        <div class="on">
          <h4>Devices On</h4>
        </div>

        <h4>80</h4>
      </div>
      <div>
        <div class="off">
          <h4>Devices Off</h4>
        </div>
        <h4>20</h4>
      </div>
    </div>

    <base-progress :value="80" label value-position="right" :status="'on'" />
  </card>
</template>
<script>
import { BaseProgress } from "src/components";
export default {
  components: {
    BaseProgress
  }
};
</script>
<style scoped>
.device-status-number {
  text-align: center;
}
.device-status-switch {
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.on,
.off,.cl-type {
  padding: 0 10px;
  margin: 10 0;
  /* margin: 10px 0px; */
  border-radius: 10px;
}
.on{
    background-color: green;
}
.off{
    background-color: red;
}
.cl-type{
    background-color: #fff;
    display: inline;
}
</style>