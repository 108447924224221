<template>
  <card>
    <template slot="header">
      <div
        class="d-flex align-items-center justify-content-between border-bottom pb-2"
      >
        <div class="d-flex align-items-center ">
          <inline-svg
            :src="require('@/assets/Icons/forklift/noun_Driver_1574355.svg')"
          />
          <h4 class="ml-2 font-color-4 font-weight-bold">Driver</h4>
        </div>
        <span class="font-color-1">
          Last Driven By
        </span>
        <base-select
          style="height: 27.5px"
          :inline="true"
          v-model="currentDriver"
          :options="driversOptions"
        />
        <inline-svg
          :src="require('@/assets/Icons/forklift/Layer 32.svg')"
          width="25"
          height="25"
        />
      </div>
    </template>
    <div class="row">
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Driver Name
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ driver.name }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Age
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ driver.age }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Company Name
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ driver.company_name }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Gender
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ driver.gender }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            License Expiry Date
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ showDate(driver.license_expires_at) }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Driver ID
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ driver.id }}
          </span>
        </div>
      </div>
      <!-- <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-weight-bold font-color-1">
            Location
          </span>
          <span class="value font-color-1">
            {{ driver.location }}
          </span>
        </div>
      </div> -->
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Driver's License No
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ driver.license_no }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Department
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ driver.department }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Supervisor Name
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ driver.supervisor_name }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Contact Number
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ driver.phone }}
          </span>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { getDeep, getDate } from "@/util/helper";

export default {
  props: {
    namespace: {
      type: String,
      default: "ForkliftStore/Device"
    },
    moduleName: {
      type: String,
      default: "DriverInfo"
    }
  },
  data: () => ({
    currentDriver: -1
  }),
  computed: {
    storePath() {
      return this.namespace + "/" + this.moduleName;
    },
    ...mapState({
      drivers(state, getters) {
        return getters[`ForkliftStore/Device/DriverInfo/drivers`];
      }
    }),
    driversOptions() {
      if (!this.drivers) return [];
      return this.drivers.map(element => ({
        label: `${element.name}`,
        value: element.id
      }));
    },
    driver() {
      if (!this.drivers) return {};
      return this.drivers.find(e => e.id === this.currentDriver) || {};
    }
  },
  created() {
    this.fetchDrivers(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      fetchDrivers(dispatch, payload) {
        return dispatch(`${this.storePath}/fetchDrivers`, { id: payload });
      }
    }),
    showDate(date) {
      return getDate(date);
    }
  },
  watch: {
    currentDriver(val) {
      this.currentDriver = val;
    },
    drivers(val) {
      console.log("here", val);
      this.currentDriver = val[0].id;
    },
    $route(val) {
      this.fetchDrivers(this.$route.params.id);
    }
  }
};
</script>

<style></style>
