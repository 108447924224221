<template>
  <div class="modal fadeIn modal-active overflow-auto">
    <div class="modal-dialog modal-dialog-centered w-100 mw-100">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" @click="$emit('close')" class="close">
            <img src="@/assets/Icons/close.svg" style="max-width:30px" />
          </button>
          <h4 class="modal-title">Assign Person</h4>
        </div>
        <div class="modal-body">
          <slot>
            <card>
              <div class="row">
                <div class="col-md-12">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(person,index) in persons" :key="index">
                          <td>{{index + 1}}</td>
                        <td>
                          <base-radio class="assign-radio" v-model="selected_person" :name="''+person.id">{{person.email}}</base-radio>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </card>
          </slot>
        </div>
        <div class="modal-footer mx-auto">
          <button type="button" class="btn my-2 mx-3" @click="$emit('close')">{{$t("Close")}}</button>
          <button type="button" class="btn my-2 mx-3" @click.prevent="save">{{$t("Save")}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseRadio } from 'src/components/index';
import {mapActions} from 'vuex';
export default {
  props: {
    id: [Number, String]
  },
  components:{
      BaseRadio
  },
  data() {
    return {
      selected_person: "",
      persons: []
    };
  },

  mounted() {
    if (this.id) {
      this.$api
        .get(`teltonika/devices/${this.id}/people`)
        .then(res => {
          if (res.status === 200) {
            this.persons = res.data.data;
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  methods:{
      ...mapActions('TeltonikaStore',[
          'setSelectedDevice']
        ),
      save(){
          this.$emit('close')
          this.$api.patch(`teltonika/devices/${this.id}/assignTo/${this.selected_person}`).then(res=>{
              console.log(res);
              if(res.status === 200){
                  this.setSelectedDevice({id:this.id})
              }
          }).catch(err=>{console.log(err)})
      }
  }
};
</script>
<style >

    .assign-radio  .form-check-sign:before {
            top:0 !important;
    }
    .assign-radio .form-check-sign:after{
        top: 6px !important;
        left: 10px !important;
    }

</style>