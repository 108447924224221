<template>
  <div
    class="btn-group btn-group-toggle"
    :class="isRTL ? 'float-left' : 'float-right'"
  >
    <label
      v-for="(option, index) in options"
      :key="index"
      class="btn btn-sm btn-primary btn-simple"
      :class="isActive(index) ? 'active' : ''"
    >
      <input
        type="radio"
        name="options"
        autocomplete="off"
        @click="change(index)"
      />
      <span class="d-none d-sm-block">{{ $t(option.title) }}</span>
    </label>
  </div>
</template>

<script>
/**
 * the (options) should be like this
 * [
 *      {
 *          title:string  || the value that will be displyed
 *      }
 * ]
 *
 */
export default {
  props: {
    value: {
      default: 0,
    },
    options: {
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.actives = [];
    for(let i=0;i<this.options.length;i++) {
      if(this.options[i].title === this.value) {
        this.actives.push(i);
      }
    }
  },
  data() {
    return {
      actives: [0], // active index(s) of options
    };
  },
  computed: {
    isRTL() {
      return this.$rtl.isRTL;
    },
  },
  methods: {
    setActive(index) {
      this.actives = [];
      this.actives.push(index);
    },
    isActive(index) {
      return (this.actives.includes(index));
    },
    change(index) {
      if(this.multiple) { // select multiple options
        if(this.isActive(index)) { // disactive option
          if(this.actives.length !== 1 || this.allowClear) {
            this.actives.splice(this.actives.indexOf(index),1);
            this.getData();
          } else {
            this.$notify({
              type: "warning",
              title: "warning",
              message: "You have To Select At Least One",
            });
          }
        } else { // active new option
          this.actives.push(index);
          this.getData();
        }
      } else {
        if(this.actives.includes(index) && this.allowClear) { // allowClear -> UnSelect
          this.actives = [];
        } else {
          this.actives = [index];
        }
        this.getData();
      }
    },
    // return data to parent
    getData() {
      if(!this.multiple) {
        if(this.actives.length === 0) {
          this.$emit("input", null);
        } else {
          let data = this.options[this.actives[0]];
          this.$emit("input", data);
        }
        return;
      }
      // push selected options to data and return it
      let data = [];
      for(let i=0;i<this.actives.length;i++) {
        data.push(this.options[this.actives[i]]);
      }
      this.$emit("input", data);
    },
    addActive(index) {
      this.actives.push(index);
    },
  },
};
</script>

<style scoped>
span.d-none.d-sm-block {
  text-transform: capitalize;
  font-size: 0.9rem;
}
.btn-sm.btn-simple {
  padding: 4px 10px;
}
</style>
