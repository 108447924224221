<template>
    <card v-loading="loading" class="pt-3 pb-3">
        <div class="d-flex justify-content-around">
            <div class="d-flex flex-column">
                <div>
                    <span class="h4 font-color-1 font-weight-bold">Nvr Quantity: </span>
                    <span class="font-color-1 font-weight-bold ml-3"> {{ data.nvrQuantity }} </span>
                </div>
                <div>
                    <span class="h4 font-color-1 font-weight-bold">Camera Quantity:</span>
                    <span class="font-color-1 font-weight-bold ml-3"> {{ data.cameraQuantity }} </span>
                </div>
            </div>
            <div class="d-flex flex-column">
                <div>
                    <span class="h4 font-color-1 font-weight-bold">Customer:</span>
                    <span class="font-color-1 font-weight-bold ml-3"> {{ data.customerId }} </span>
                </div>
                <div>
                    <span class="h4 font-color-1 font-weight-bold">Structure:</span>
                    <span class="font-color-1 font-weight-bold ml-3"> {{ data.structureId }} </span>
                </div>
            </div>
            <div class="d-flex flex-column">
                <div>
                    <span class="h4 font-color-1 font-weight-bold">Amc:</span>
                    <span class="font-color-1 font-weight-bold ml-3"> {{ data.amcId }} </span>
                </div>
                <div>
                    <span class="h4 font-color-1 font-weight-bold">Operator:</span>
                    <span class="font-color-1 font-weight-bold ml-3"> {{ data.operatorId }} </span>
                </div>
            </div>
        </div>
    </card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    props: {
        namespace: {
            type: String,
            default: 'VsaasStore/WorkOrder'
        },
        moduleName: {
            type: String,
            default : 'WorkOrderDetails'
        },
        orderId: {}
    },
    computed: {
        storePath() {
            return this.namespace + '/' + this.moduleName;
        },
        ...mapState({
            loading(state,getters) {
                return getters[this.storePath + '/loading'];
            },
            data(state,getters) {
                return getters[this.storePath + '/data'];
            }
        }),
    },
    methods: {
        ...mapActions({
            fetchData(dispatch,payload) {
                return dispatch(this.storePath + '/fetchData');
            }
        })
    },
    created() {
        this.$store.commit(this.storePath + '/setOrderId',this.orderId);
        this.fetchData();
    }
}
</script>

<style>

</style>