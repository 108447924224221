<template>
  <div>
      <!-- some inputs -->
    <div class="d-flex" style="flex-wrap: wrap">
      <h4 class="col-lg-4"><p>Number Of Cubicles:</p></h4>
      <div class="col-lg-2">
        <base-input v-model="Cubicles" type="number"> </base-input>
      </div>
      <h4 class="col-lg-4 text-lg-center"><p>Number Of Bins:</p></h4>
      <div class="col-lg-2">
        <base-input v-model="Soap" type="number"> </base-input>
      </div>
    </div>
    <!-- yes && no radio -->
    <div>
      <h4 class="mt-3 col-12">Do You Want Activate The Following Options?</h4>
      <div class="d-flex">
        <p class="offset-3 col-2 mt-2">Tissues:</p>
        <base-radio class="col-2" name="Tissues1" v-model="TissuesRadio" inline
          >Yes</base-radio
        >
        <base-radio class="col-2" name="Tissues2" v-model="TissuesRadio" inline
          >No</base-radio
        >
      </div>
      <div class="d-flex">
        <p class="offset-3 col-2 mt-2">Soap:</p>
        <base-radio class="col-2" name="Soap1" v-model="SoapRadio" inline
          >Yes</base-radio
        >
        <base-radio class="col-2" name="Soap2" v-model="SoapRadio" inline
          >No</base-radio
        >
      </div>
    </div>
     <h4 class="p-2 btn-primary">Cleaning Days</h4>
    <div
      v-for="(day, index) in days"
      :key="index"
      class="row justify-content-between time-border py-3"
    >
      <div class="m-0col-lg-5 col-sm-5 col-9 d-flex justify-content-between">
        <h4 class="w-25">{{ day.name }}</h4>
        <p class="time mx-2" v-for="(x, index) in day.value1" :key="index">
          {{ x }}
        </p>
      </div>
      <el-time-picker
        style="background-color: transparent; border: 1px solid #555"
        class="col-lg-5 col-sm-5 col-9"
        is-range
        format="hh:mm A"
        value-format="hh:mm A"
        v-model="day.value1"
        range-separator="To"
        start-placeholder="Start time"
        end-placeholder="End time"
        :clearable="true"
      >
      </el-time-picker>
      <div class="mt-2 offset-lg-1 col-md-12 col-10">
        <!-- fill level -->
        <div class="d-flex m-0">
          <h4 class="col-lg-6 mt-3">
            <p>Fill Level Required For Bin Serving:</p>
          </h4>
          <div class="col-lg-2 px-2 d-flex m-0">
            <base-input v-model="fillLevel" type="number"> </base-input>
            <span class="m-2">%</span>
          </div>
        </div>
        <!-- dirt level -->
        <div class="d-flex m-0">
          <h4 class="col-lg-6 mt-3"><p>Dirt Level Of Washroom:</p></h4>
          <div class="col-lg-2 px-2 d-flex m-0">
            <base-input v-model="dirtLevel" type="number"> </base-input>
            <span class="m-2">%</span>
          </div>
        </div>
        <!-- Servicing Interval -->
        <div class="d-flex m-0">
          <h4 class="col-lg-6 mt-3"><p>Servicing Interval:</p></h4>
          <div class="col-lg-4 px-2 d-flex m-0">
            <base-input v-model="servicingInterval.hrs" type="number">
            </base-input>
            <span class="m-2">Hrs</span>
            <base-input v-model="servicingInterval.mins" type="number">
            </base-input>
            <span class="m-2">Mins</span>
          </div>
        </div>
        <!-- auto saving -->
        <div class="d-flex m-0">
          <h4 class="col-lg-6 mt-2">Auto Serving:</h4>
          <base-radio class="col-lg-2 px-3" name="Auto1" v-model="Auto" inline
            >Yes</base-radio
          >
          <base-radio class="col-lg-2 px-3" name="Auto2" v-model="Auto" inline
            >No</base-radio
          >
        </div>
      </div>
      <!-- add button -->
      <div class="col-sm-2 col-4 m-auto">
        <base-button @click="addTime(index)" class="btn-primary p-2"
          >+ Add Timing</base-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { BaseRadio } from "src/components";
import { Alert,TimePicker } from "element-ui";

export default {
  name: "WashRooms",

  components: {
    BaseRadio,
    [Alert.name]: Alert,
    [TimePicker.name]: TimePicker
  },

  data() {
    return {
       days: [
        {
          name: "Sunday",
          value1: ["'10:30 AM'", "'11:30 AM'"],
        },
        {
          name: "Monday",
          value1: ["", ""],
        },
        {
          name: "Tusday",
          value1: ["'10:30 AM'", "'11:30 AM'"],
        },
        {
          name: "Wedenesday",
          value1: ["", ""],
        },
        {
          name: "Thursday",
          value1: ["'10:30 AM'", "'11:30 AM'"],
        },
        {
          name: "Friday",
          value1: ["", ""],
        },
        {
          name: "Saturday",
          value1: ["", ""],
        },
      ],
      latitude: "",
      longitude: "",
      roadName: "",
      community: "",
      city: "",
      country: "",
      companyType: "Company 01",
      companyOptions: [
        { value: "Company 01" },
        { value: "Company 02" },
        { value: "Company 03" },
        { value: "Company 04" }
      ],
      companyType2: "Company 11",
      companyOptions2: [
        { value: "Company 11" },
        { value: "Company 12" },
        { value: "Company 13" },
        { value: "Company 14" }
      ],
      value: 0,
      TissuesRadio: "Tissues1",
      fillLevel: 0,
      dirtLevel: 0,
      servicingInterval: {
        hrs: 0,
        mins: 0
      },
      Auto: "Auto1",
      wasteType: "",
      wasteOptions: [
        { value: "waste 01" },
        { value: "waste 02" },
        { value: "waste 03" },
        { value: "waste 04" }
      ]
    };
  },

  methods: {
    plus() {
      this.value++;
    },
    min() {
      this.value--;
    },
    addTime(index) {
      this.days.splice(index + 1, 0, {
        name: "",
        from: "",
        to: "",
      });
    },
  }
};
</script>

<style lang="scss" scoped></style>
