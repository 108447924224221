<template>
    <zingchart :data="chartConfig" :height="'300px'" :width="'100%'"></zingchart>
</template>
<script>
export default {
  data() {
    return {
      chartConfig: {
        type: "bar",
        backgroundColor: "transparent",
        plot: {
          stacked: true,
        },
        scaleX: {
          values: ["J","F","M", "A", "M", "J"],
          guide: {
            visible: false,
          },
          item: {
            fontColor: "#999",
          },
          lineColor: "none",
          lineWidth: "0px",
          tick: {
            visible: false,
          },
        },
        scaleY: {
          guide: {
            lineStyle: "solid",
          },
          item: {
            fontColor: "#999",
          },
          lineColor: "none",
          lineWidth: "0px",
          maxValue: "495px",
          minValue: "0px",
          step: 123.75,
          tick: {
            visible: false,
          },
        },
        tooltip: {
          text:
            "<div class='zingchart-tooltip'><div class='scaley-value'>%v</div></div>",
          padding: "0px",
          backgroundColor: "none",
          htmlMode: true,
          placement: "node:center",
        },
        series: [
          {
            values: [100, 180, 120, 120,60,55],
            alpha: 1,
            backgroundColor: "#4184f3",
            hoverState: {
              backgroundColor: "#2956A0",
            },
          },
          {
            values: [125, 125, 125, 125,120,45],
            alpha: 1,
            backgroundColor: "#db4437",
            hoverState: {
              backgroundColor: "#901E15",
            },
          },
          {
            values: [125, 145, 135, 165,33,40],
            alpha: 1,
            backgroundColor: "#f4b400",
            borderRadiusTopLeft: "3px",
            borderRadiusTopRight: "3px",
            hoverState: {
              backgroundColor: "#8C6A0B",
            },
          },
        ],
      },
    };
  },
};
</script>
<style  scoped>
</style>