<template>
  <div>
     <!-- AMC -->
    <div class="d-flex justify-content-between">
      <h4 class="col-lg-3"><p>AMC:</p></h4>
      <div class="col-lg-9">
        <base-select :options="companyOptions" v-model="companyType">
          <template v-slot:addonRight>
            <base-button
              style="padding: 0 10px; margin-left: 10px"
              class="btn-primary"
            >
              <p class="mt-2">
                <i class="fa fa-plus"></i>
              </p>
            </base-button>
          </template>
        </base-select>
      </div>
    </div>
    <!-- PMC -->
    <div class="d-flex justify-content-between">
      <h4 class="col-lg-3"><p>PMC:</p></h4>
      <div class="col-lg-9">
        <base-select :options="companyOptions2" v-model="companyType2">
          <template v-slot:addonRight>
            <base-button
              style="padding: 0 10px; margin-left: 10px"
              class="btn-primary"
            >
              <p class="mt-2">
                <i class="fa fa-plus"></i>
              </p>
            </base-button>
          </template>
        </base-select>
      </div>
    </div>
     <!-- Bin Size -->
    <div class="d-flex">
      <h4 class="mt-3 col-3">Size Of Bin:</h4>
      <div class="col-md-3">
        <base-input class="center" type="number" v-model="value">
          <template v-slot:addonRight>
            <base-button
              style="padding: 0 10px"
              class="btn-primary"
              @click="plus"
            >
              <p class="mt-2">
                <i class="fa fa-plus"></i>
              </p>
            </base-button>
          </template>
          <template v-slot:addonLeft>
            <base-button
              style="padding: 0 10px"
              class="btn-primary"
              @click="min"
            >
              <p class="mt-2">
                <i class="fa fa-minus"></i>
              </p>
            </base-button>
          </template>
        </base-input>
        
      </div>
      <div class="col-3">
        <h4 class="mt-3">Litres</h4>
      </div>
    </div>
    <!-- times -->
    <!-- <h4 class="p-2 btn-primary">Cleaning Days</h4>
    <div
      v-for="(day, index) in days"
      :key="index"
      class="row justify-content-between time-border p-2"
    >
      <div class="my-2 col-lg-5 col-sm-5 col-9 d-flex justify-content-between">
        <h4 class="w-25">{{ day.name }}</h4>
        <p class="time mx-2" v-for="(x, index) in day.value1" :key="index">
          {{ x }}
        </p>
      </div>
      <el-time-picker
        style="background-color: transparent; border: 1px solid #555"
        class="col-lg-5 col-sm-5 col-9"
        is-range
        format="hh:mm A"
        value-format="hh:mm A"
        v-model="day.value1"
        range-separator="To"
        start-placeholder="Start time"
        end-placeholder="End time"
        :clearable="true"
      >
      </el-time-picker>
      <div class="col-sm-2 col-4">
        <base-button @click="addTime(index)" class="btn-primary px-4 p-2"
          >+</base-button
        >
      </div>
    </div> -->
  
       <!-- times -->
    <h4 class="p-2 btn-primary">Cleaning Days</h4>
    <div
      v-for="(day, index) in days"
      :key="index"
      class="row justify-content-between time-border py-3"
    >
      <div class="m-0col-lg-5 col-sm-5 col-9 d-flex justify-content-between">
        <h4 class="w-25">{{ day.name }}</h4>
        <p class="time mx-2" v-for="(x, index) in day.value1" :key="index">
          {{ x }}
        </p>
      </div>
      <el-time-picker
        style="background-color: transparent; border: 1px solid #555"
        class="col-lg-5 col-sm-5 col-9"
        is-range
        format="hh:mm A"
        value-format="hh:mm A"
        v-model="day.value1"
        range-separator="To"
        start-placeholder="Start time"
        end-placeholder="End time"
        :clearable="true"
      >
      </el-time-picker>
      <div class="mt-2 offset-lg-1 col-md-12 col-10">
        <!-- fill level -->
        <div class="d-flex m-0">
          <h4 class="col-lg-6 mt-3">
            <p>Fill Level Required For Bin Serving:</p>
          </h4>
          <div class="col-lg-2 px-2 d-flex m-0">
            <base-input v-model="fillLevel" type="number"> </base-input>
            <span class="m-2">%</span>
          </div>
        </div>
        <!-- dirt level -->
        <div class="d-flex m-0">
          <h4 class="col-lg-6 mt-3"><p>Dirt Level Of Washroom:</p></h4>
          <div class="col-lg-2 px-2 d-flex m-0">
            <base-input v-model="dirtLevel" type="number"> </base-input>
            <span class="m-2">%</span>
          </div>
        </div>
        <!-- Servicing Interval -->
        <div class="d-flex m-0">
          <h4 class="col-lg-6 mt-3"><p>Servicing Interval:</p></h4>
          <div class="col-lg-4 px-2 d-flex m-0">
            <base-input v-model="servicingInterval.hrs" type="number">
            </base-input>
            <span class="m-2">Hrs</span>
            <base-input v-model="servicingInterval.mins" type="number">
            </base-input>
            <span class="m-2">Mins</span>
          </div>
        </div>
        <!-- auto saving -->
        <div class="d-flex m-0">
          <h4 class="col-lg-6 mt-2">Auto Serving:</h4>
          <base-radio class="col-lg-2 px-3" name="Auto1" v-model="Auto" inline
            >Yes</base-radio
          >
          <base-radio class="col-lg-2 px-3" name="Auto2" v-model="Auto" inline
            >No</base-radio
          >
        </div>
      </div>
      <!-- add button -->
      <div class="col-sm-2 col-4 m-auto">
        <base-button @click="addTime(index)" class="btn-primary p-2"
          >+ Add Timing</base-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { TimePicker } from "element-ui";
import { BaseRadio } from "src/components";

export default {
  name: "Indoor",

  components: {
    BaseRadio,
    [TimePicker.name]: TimePicker,
  },

  data() {
    return {
      Cubicles: 0,
      Soap: 5,
      TissuesRadio: "Tissues1",
      SoapRadio: "Soap1",
      companyType: "Company 01",
      companyOptions: [
        { value: "Company 01" },
        { value: "Company 02" },
        { value: "Company 03" },
        { value: "Company 04" },
      ],
      companyType2: "Company 11",
      companyOptions2: [
        { value: "Company 11" },
        { value: "Company 12" },
        { value: "Company 13" },
        { value: "Company 14" },
      ],
      days: [
        {
          name: "Sunday",
          value1: ["'10:30 AM'", "'11:30 AM'"],
        },
        {
          name: "Monday",
          value1: ["", ""],
        },
        {
          name: "Tusday",
          value1: ["'10:30 AM'", "'11:30 AM'"],
        },
        {
          name: "Wedenesday",
          value1: ["", ""],
        },
        {
          name: "Thursday",
          value1: ["'10:30 AM'", "'11:30 AM'"],
        },
        {
          name: "Friday",
          value1: ["", ""],
        },
        {
          name: "Saturday",
          value1: ["", ""],
        },
      ],
      fillLevel: 0,
      dirtLevel: 0,
      servicingInterval: {
        hrs: 0,
        mins: 0,
      },
      value: 0,
      Auto: "Auto1",
    };
  },

  methods: {
    plus() {
      this.value++;
    },
    min() {
      this.value--;
    },
    addTime(index) {
      this.days.splice(index + 1, 0, {
        name: "",
        from: "",
        to: "",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
