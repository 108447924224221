<template>
  <card class="h-100">
    <div class="row align-items-center">
        <div class="col-md-4 p-1">
                <h4>Total Devices</h4>
        </div>
        <div class="col-md-4 p-1">
                <base-select  v-model="duration" :options="durationOptions"></base-select>
        </div>
        <div class="col-md-4 p-1">
               <base-select  v-model="duration" :options="durationOptions"></base-select>
        </div>

    </div>
    <div class="row">
        <div class="col-12">
            <TrackingDevicesChart />
        </div>
    </div>
  </card>
</template>
<script>
    import TrackingDevicesChart from '@/components/Charts/TrackingDevicesChart.vue'
export default {
    components:{
        TrackingDevicesChart
    },
    data() {
    return {
      duration: 1,
      durationOptions: [
        {
          value: 1,
          label: "This Month",
        },
      ],
    };
  },
};
</script>
<style  scoped>
</style>