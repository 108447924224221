<template>
  <card class="h-100">
    <div class="row">
      <div class="col-3">
        <h4>Fuel</h4>
      </div>
      <div class="col-md-6">
        <base-select
          v-model="duration"
          :options="durationOptions"
        ></base-select>
      </div>
      <div class="position-absolute top-1 right-1">
        <inline-svg
          :src="require('@/assets/Icons/fuel.svg')"
          width="40"
          height="40"
        />
      </div>
    </div>

    <div>
      <span class="d-block mb-3">Fuel Level</span>
      <div class="row">
        <div class="col-4 text-left fuel-min">
          <span>Avarege</span>
          <h1 class="font-weight-bolder">{{ avg_fuel_percentage }}%</h1>
        </div>
        <div class="col-4 text-left fuel-avg">
          <span>Now</span>
          <h1 class="font-weight-bolder">{{ fuel_percentage }}%</h1>
        </div>
        <!-- <div class="col-4 text-left fuel-max">
          <span>Max</span>
          <h1 class="font-weight-bolder">80%</h1>
        </div> -->
      </div>

      <span class="d-block">Fuel Drain pre hour</span>
      <div>
        <h1 class="font-weight-bolder fuel-drain">
          {{ fuel_drain_per_hour }} lt
        </h1>
      </div>
      <div class="d-flex justify-content-between mb-2">
        <span>Total Fuel drain</span>
        <span>Total Fuel refill</span>
      </div>
      <ProgressBar
        :data="total_fuel_consumed"
      />
    </div>
  </card>
</template>
<script>
import ProgressBar from "src/components/progress-bar.vue";
import { mapState } from "vuex";

export default {
  components: {
    ProgressBar,
  },
  data() {
    return {
      MultiProgressData: [{ value: 80 }, { value: 20 }],
      duration: 1,
      durationOptions: [
        {
          value: 1,
          label: "This Month",
        },
      ],
    };
  },
  computed: {
    ...mapState("CarTrackerStore", {
      carData: (state) => state.carData,
    }),
    fuel() {
      return this.carData.fuel;
    },
    fuel_percentage() {
      return this.carData.fuel_percentage || 0;
    },
    fuel_drain_per_hour() {
      return this.carData.fuel_drain_per_hour || 0;
    },
    total_fuel_consumed() {
      let total_consumed = this.carData.total_fuel_consumed;
      let fuel_refill = 0;
      return [{ value: total_consumed + ' lt', state: 'drain' }, { value: fuel_refill + ' lt', state: 'refill' }];
    },
    avg_fuel_percentage() {
      return this.carData.avg_fuel_percentage || 0;
    },
  },
};
</script>
<style >
.fuel-min,
.fuel-min h1 {
  color: #d84fd4 !important ;
}
.fuel-avg,
.fuel-avg h1 {
  color: #3ca271 !important ;
}
.fuel-max,
.fuel-max h1 {
  color: #22abf7 !important ;
}
.fuel-drain {
  color: #e89f33 !important;
}
.fuel-drain-avg {
  color: #3ca271;
}
.drain {
  background-color: #fa537c;
}
.refill {
  background-color: #4af93b;
}
</style>