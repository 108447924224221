<template>
  <div class="row">
    <!-- filters -->
    <div class="col-12">
      <router-link to="./status/history">
        <base-button class="abs" size="sm" type="primary" simple >History</base-button>
      </router-link>
      <AlarmsFilter
        :initialFilter="getInitialFilter()"
        v-loading="loadingState"
        @filter="updateFilter"
      >
        <template slot="extra" slot-scope="{ filter }">
          <div class="row">
            <StatusFilter
              :initialFilter="getInitialFilter().status"
              :filter="filter"
              :options="statusFilterOptions"
            ></StatusFilter>
          </div>
        </template>
      </AlarmsFilter>
    </div>
    <div class="col-12"></div>
    <!-- table -->
    <div class="col-12">
      <card v-loading="loadingState" class="mb-2">
        <StatusTable
          :data="tableData"
          @change-ack-state="openAcknowledgmentModal"
          @escalate="openEscalateModal"
          @open-comments="openCommentsBox"
        />
        <TablePagination
          slot="footer"
          ref="paginator"
          v-if="tableData.length"
          :total="totalAlarms"
          @change="updatePagination"
        />
      </card>
    </div>
    <AlarmsActions sourceStore="SwamStore/StatusAlarms" />
  </div>
</template>

<script>
import AlarmsFilter from "@/pages/SWAM/components/AlarmsComponents/AlarmsFilter.vue";
import StatusTable from "./components/StatusTable";
import TablePagination from "@/components/TablePagination.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import AlarmsActions from "@/pages/SWAM/components/AlarmsComponents/AlarmsActions.vue";
import StatusFilter from "@/pages/SWAM/components/AlarmsComponents/StatusFilter";

export default {
  components: {
    StatusTable,
    AlarmsFilter,
    TablePagination,
    AlarmsActions,
    StatusFilter,
  },
  computed: {
    ...mapGetters("SwamStore/StatusAlarms", {
      tableData: "alarms",
      filter: "filter",
      totalAlarms: "totalAlarms",
      pagination: "pagination",
      loadingState: "loading",
      statusFilterOptions: "statusFilterOptions",
    }),
  },
  methods: {
    getInitialFilter(){
      let ack = undefined;
      if(this.$route.query.acknowledgement) {
        ack = (this.$route.query.acknowledgement == 'true');
      }
      return  {
        ...this.$store.getters['SwamStore/StatusAlarms/filter'],
        status:this.$route.query.status?[this.$route.query.status]:this.$route.query.status,
        structureId:this.$route.query.structure_id ? +this.$route.query.structure_id : undefined,
        acknowledgement:ack
      };
    },
    ...mapActions("SwamStore/StatusAlarms", {
      fetchData: "updateFilter",
      openCommentsBox: "openCommentsBox",
      openEscalateModal: "openEscalateModal",
      openAcknowledgmentModal: "openAcknowledgmentModal",
    }),
    ...mapMutations("SwamStore/StatusAlarms", {
      setPaginationFilter: "setPaginationFilter",
      resetPaginationFilter: "resetPaginationFilter",
      setFilter: "setFilter",
    }),
    updateFilter(data) {
      this.setFilter(data);
      this.resetPaginationFilter();
      this.fetchData();
    },
    updatePagination(data) {
      this.setPaginationFilter(data);
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.abs {
  position: absolute;
  right: 30px;
  top: 10px;
  z-index: 10
}

[dir="rtl"] .abs {
  left: 30px;
  right: unset;
}
</style>
