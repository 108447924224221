<template>
  <card v-loading="loading">
    <div class="row">
      <div class="col-12">
        <div class="d-flex">
          <solution-icon :solution-name="solutionName" :key="solutionName" />
          <h2 class="font-weight-bold d-inline">{{ solutionName }}</h2>
          <span
            v-if="structureName"
            class="align-items-center d-flex font-weight-bold pl-1"
            >{{ "( " + structureName + " )" }}</span
          >
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column justify-content-around"
      style="height: 225px"
    >
      <div class="row">
        <div class="col-6 d-flex align-items-center mb-2 font-weight-bold">
          <div class="col-10 d-flex align-items-center">
            <i
              class="fas fa-microchip devices-icon"
              style="transform: rotate(90deg)"
            ></i>
            <h4 class="mb-0 pl-2 font-color-4 font-weight-bold">
              Number Of Devices
            </h4>
          </div>
          <div class="col-2">
            <p class="mb-0 font-color-5">{{ solutionData.devices || "-" }}</p>
          </div>
        </div>
        <div class="col-5 d-flex align-items-center font-weight-bold">
          <div class="col-10 p-0 d-flex align-items-center">
            <i class="el-icon-sunrise devices-icon"></i>
            <h4 class="mb-0 pl-2 font-color-4 font-weight-bold">
              Operational Alarms
            </h4>
          </div>
          <div class="col-2">
            <p class="mb-0 font-color-5">{{ totalOperationalAlarms }}</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-11">
          <operationalAlarmsDetails
            :data="{ ...solutionData.operationalAlarms, title: '' }"
          />
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import operationalAlarmsDetails from "@/pages/SWAM/components/operational-alarms-details";
import SolutionIcon from "@/pages/SWAM/components/SolutionIcon";
import { getDeep } from "@/util/helper";

export default {
  components: {
    operationalAlarmsDetails,
    SolutionIcon
  },
  props: {
    namespace: {
      type: String,
      default: "SwamStore/SolutionPortfolio"
    },
    moduleName: {
      type: String,
      default: "SolutionStatisticsCount"
    },
    data: {}
  },
  computed: {
    storePath() {
      return this.namespace + "/" + this.moduleName;
    },
    ...mapState({
      loading(state, getters) {
        return getters[this.storePath + "/loading"];
      },
      solutionData(state, getters) {
        return getters[this.storePath + "/data"];
      },
      totalOperationalAlarms(state, getters) {
        return getters[this.storePath + "/totalOperationalAlarms"];
      }
    }),
    ...mapGetters({
      solutionsOptions: "SwamStore/solutionsOptions",
      structuresOptions: "SwamStore/structuresOptions"
    }),
    currentStructureId: {
      get() {
        return getDeep(
          this.$store.state,
          this.storePath + "/currentStructureId"
        );
      },
      set(value) {
        this.$store.commit(this.storePath + "/selectStructure", value);
      }
    },
    currentSolutionId: {
      get() {
        return getDeep(
          this.$store.state,
          this.storePath + "/currentSolutionId"
        );
      },
      set(value) {
        this.$store.commit(this.storePath + "/selectSolution", +value);
      }
    },
    solutionName() {
      return this.solutionsOptions.find(
        sol => sol.value == this.currentSolutionId
      ).label;
    },
    structureName() {
      if (!this.currentStructureId) {
        return "";
      }
      return this.structuresOptions.find(
        structure => structure.value == this.currentStructureId
      ).label;
    }
  },
  methods: {
    ...mapActions({
      fetchSolutionData(dispatch, payload) {
        return dispatch(this.storePath + "/fetchSolutionData");
      }
    })
  },
  mounted() {
    this.currentStructureId = this.data.structureId;
    this.currentSolutionId = this.data.solutionId;
    if (this.currentSolutionId) this.fetchSolutionData();
  },
  watch: {
    data: {
      handler() {
        this.currentStructureId = this.data.structureId;
        this.currentSolutionId = this.data.solutionId;
        if (this.currentSolutionId) this.fetchSolutionData();
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.devices-icon {
  font-size: 1.5rem;
  padding-left: 5px;
}
</style>
