<template>
  <div class>
    <!-- <el-breadcrumb class="mb-2 mt-n4" v-if="currentPath.length>1" :separator-class="separatorClass">
        <el-breadcrumb-item v-for="(item, index) in currentPath" :key="index">{{item}}</el-breadcrumb-item>
    </el-breadcrumb>-->
    <el-collapse v-model="currentStep" accordion v-loading="loading">
      <el-collapse-item name="1" v-if="!structure">
        <template slot="title" v-if="!community">{{$t('instructions.selectCommunity')}}</template>
        <template slot="title" v-else>
          <span class="title">{{$t('community')}} :</span>
          <span class="value">{{community.Community_English}}</span>
        </template>
        <BaseAutoComplete
          id="community"
          :placeholder="$t('community')"
          input-class="form-control"
          ref="autocomplete"
          :source="communitySearch"
          results-property="data"
          :results-display="lang==='en'? 'Community_English': 'Community_Arabic'"
          @input="selectCommunity"
        ></BaseAutoComplete>
      </el-collapse-item>
      <el-collapse-item :disabled="!enableStructure" v-if="!structure" name="2">
        <template slot="title" v-if="!selectedStructure">{{$tc('structure', 2)}}</template>
        <template slot="title" v-else>
          <span class="title">{{$tc('structure')}} :</span>
          <span class="value">{{selectedStructure.structure_name}}</span>
        </template>
        <el-table
          style="width:100%"
          :data="structures"
          :height="300"
          highlight-current-row
          ref="structuresTable"
          @current-change="selectStructure"
        >
          <el-table-column :min-width="100" align="left" :label="$t('id')">
            <div slot-scope="props">
              <span>{{$generateID(props.row.id,'STR')}}</span>
            </div>
          </el-table-column>
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :label="$t(column.label)"
          ></el-table-column>
        </el-table>
      </el-collapse-item>
      <el-collapse-item :disabled="!enableInfo" name="3">
        <template
          slot="title"
          v-if="!selectedFloor && !selectedStructureSpace"
        >{{$t('maintenanceOrder.innerStructureInfo')}} / {{$tc('structureWords.floor', 1)}}</template>

        <template slot="title" v-else-if="selectedFloor">
          <span class="title">{{$t('structureWords.floorName')}} :</span>
          <span class="value">
            {{selectedFloor.name}}
            <span>&nbsp; #{{selectedFloor.number}}</span>
          </span>
        </template>
        <template slot="title" v-else-if="selectedStructureSpace">
          <span class="title">{{$t('space')}} :</span>
          <span class="value">{{selectedStructureSpace.display}}</span>
        </template>

        <!-- ******************************************************************************************* -->
        <div class="row floors-spaces high-card-shadow">
          <el-divider>
            <p>{{ $tc('structureWords.space', 2) }}</p>
          </el-divider>
          <div v-for="(space) in spaces" :key="space.id" class="col-12 col-md-6">
            <card
              :bodyClasses="'p-1'"
              class="high-card-shadow"
              :class="{'selectedCard':space==selectedStructureSpace}"
              @click.native="openStructureSpace(space)"
              style="cursor: pointer;"
            >
              <el-divider>
                <el-tooltip :content="space.display" placement="top-start">
                  <p class="pink-title">{{space.display}}</p>
                </el-tooltip>
              </el-divider>
              <label class="position-relative d-block mt-n2 mb-0">{{ $t(`spaceTypes.${space.type.name}`) }}</label>
            </card>
          </div>

          <!-- floors -->
          <el-divider>
            <p>{{$tc('structureWords.floor', 2)}}</p>
          </el-divider>
          <div class="col-12 col-md-6" v-for="(floor, index) in floors" :key="'floor'+index">
            <card
              :bodyClasses="'p-1'"
              class="high-card-shadow"
              @click.native="openFloor(floor,index)"
              :class="{'selectedCard':floor==selectedFloor}"
              style="cursor: pointer;"
            >
              <label class="position-absolute">#{{index+1}}</label>
              <label class="spaces-count" v-if="floor.spaces_count">{{ $tc('structureWords.space', 2)}} : {{floor.spaces_count}}</label>
              <el-divider>
                <p class="pink-title">{{floor.name}}</p>
              </el-divider>
            </card>
          </div>
        </div>

        <!-- ******************************************************************************************* -->
      </el-collapse-item>
      <el-collapse-item :disabled="!selectedFloor" :title="$t('structureWords.floorSpaces')" name="4">
        <template slot="title" v-if="selectedFloorSpace">
          <span class="title">{{$t('structureWords.spaceName')}} :</span>
          <span class="value">{{selectedFloorSpace.display}}</span>
        </template>
        <div class="row floors-spaces" v-if="selectedFloor">
          <div v-for="(space) in selectedFloor.spaces" :key="space.id" class="col-12 col-md-6">
            <card
              :bodyClasses="'p-1'"
              class="high-card-shadow"
              @click.native="openFloorSpace(space)"
              :class="{'selectedCard':space==selectedFloorSpace}"
              style="cursor: pointer;"
            >
              <el-divider>
                <el-tooltip :content="space.display" placement="top-start">
                  <p class="pink-title">{{space.display}}</p>
                </el-tooltip>
              </el-divider>
              <label class="position-relative d-block mt-n2 mb-0">{{ $t(`spaceTypes.${space.type.name}`) }}</label>
            </card>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        :disabled="!selectedStructureSpace && !selectedFloorSpace"
        :title="$t('structureWords.indoorSpaces')"
        name="5"
      >
        <template slot="title" v-if="selectedIndoorSpace">
          <span class="title">{{$t('structureWords.indoorSpaceName')}} :</span>
          <span class="value">{{selectedIndoorSpace.display}}</span>
        </template>
        <div v-if="indoorSpaces && indoorSpaces.length" class="row floors-spaces high-card-shadow">
          <div v-for="(space) in indoorSpaces" :key="space.id" class="col-12 col-md-6">
            <card
              :bodyClasses="'p-1'"
              class="high-card-shadow"
              @click.native="openIndoorSpace(space)"
              :class="{'selectedCard':space==selectedIndoorSpace}"
              style="cursor: pointer;"
            >
              <el-divider>
                <el-tooltip :content="space.display" placement="top-start">
                  <p class="pink-title">{{space.display}}</p>
                </el-tooltip>
              </el-divider>
              <label class="position-relative d-block mt-n2 mb-0">{{$t(`spaceTypes.${space.type.name}`)}}</label>
            </card>
          </div>
        </div>
        <p v-else class="text-center">{{$t('structureWords.noIndoorSpaces')}}</p>
      </el-collapse-item>
      <el-collapse-item v-if="deviceMode" :title="$tc('deviceWords.device', 2)" name="6" :disabled="!currentSpace">
        <template slot="title" v-if="selectedDevice">
          <span class="title">{{$t('deviceWords.deviceName')}} :</span>
          <span class="value">{{selectedDevice.device_name}}</span>
        </template>
        <div v-if="!devices">
          <p class="text-center" dir="auto">{{$t('loading')}}</p>
        </div>
        <div v-else-if="devices.length==0">
          <p class="text-center">{{ $t('no') + ' ' + $t('deviceWords.device', 2) }}</p>
        </div>
        <div class="row" v-else-if="currentSpace">
          <div class="col-12 col-md-6" v-for="(device, index) in devices" :key="'device'+index">
            <card
              class="high-card-shadow"
              :bodyClasses="'p-1'"
              @click.native="selectDevice(device)"
              style="cursor: pointer;"
              :class="{'selectedCard':device==selectedDevice}"
            >
              <label
                class="position-absolute"
                v-if="!selectedIndoorSpace && currentSpace.id!=device.space_id"
              >In {{device.space_name}}</label>
              <el-divider>
                <p class="pink-title">{{device.device_name}}</p>
              </el-divider>
            </card>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>

    <div class="row mt-3">
      <div class="col-6">
        <base-button @click.native="submit" :disabled="!canSubmit" size="sm">{{$t('instructions.select')}}</base-button>
      </div>
    </div>
  </div>
</template>

<script>
class BrowserResult {
  community = null;
  structure = null;
  structure_space = null;
  floor = null;
  floor_space = null;
  indoor_space = null;
  device = null;
  selectedSpace = null; //refreance to structure_space || floor_space || indoor_space
  targetType = null;
  targetAddress = "";
  constructor(
    community,
    structure,
    structure_space,
    floor,
    floor_space,
    indoor_space,
    device
  ) {
    this.community = community;
    this.structure = structure;
    this.structure_space = structure_space;
    this.floor = floor;
    this.floor_space = floor_space;
    this.indoor_space = indoor_space;
    this.device = device;
    let p = "";
    if (community) {
      p +=
        "community: " +
        community[
          localStorage.getItem("lang") == "ar"
            ? "Community_Arabic"
            : "Community_English"
        ];
      p += "   >>   ";
    }
    if (structure && structure.name) {
      p += "Structure: " + structure.structure_name;
      p += "   >>   ";
    }
    if (structure_space) {
      p += "Space :   " + structure_space.display;
      this.selectedSpace = structure_space;
    } else {
      p += "Floor :  " + floor.name;
      p += "   >>   ";
      p += "Space :  " + floor_space.display;
      this.selectedSpace = floor_space;
    }
    if (indoor_space) {
      p += "   >>   ";
      p += "Indoor Space :  " + indoor_space.display;
      this.selectedSpace = indoor_space;
    }
    this.targetType = "space";
    if (device) {
      p += "   >>   ";
      this.targetType = "device";
      p += "Device :  " + device.device_name;
    }
    this.targetAddress = p;
  }
}
import BaseAutoComplete from "@/components/BaseAutoComplete";
import Axios from "axios";
import { Collapse, CollapseItem, Divider } from "element-ui";
import debounce from "lodash/debounce";
export default {
  props: {
    deviceMode: {
      type: Boolean,
      default: false
    },
    structure: {
      type: Object
    }
  },
  data() {
    return {
      lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
      separatorClass:
        localStorage.getItem("lang") == "ar"
          ? "el-icon-arrow-left"
          : "el-icon-arrow-right",
      community: null,
      structures: [],
      tableColumns: [
        {
          prop: "structure_name",
          label: "structureWords.name",
          minWidth: 325
        },
        {
          prop: "structure_number",
          label: "structureWords.number",
          minWidth: 125
        }
      ],
      communities: {},
      loading: false,
      currentStep: "1",
      selectedStructure: null,
      selectedFloor: null,
      selectedStructureSpace: null,
      selectedFloorSpace: null,
      selectedIndoorSpace: null,
      indoorSpaces: null,
      spaces: [],
      floors: [],
      devices: [],
      selectedDevice: null
    };
  },
  created() {
    if (this.structure) {
      this.currentStep = "3";
      this.selectStructure(this.structure);
    }
  },
  computed: {
    enableStructure() {
      return this.community;
    },
    enableInfo() {
      return (this.enableStructure && this.selectedStructure) || this.structure;
    },
    canSubmit() {
      return (
        this.selectedStructureSpace ||
        this.selectedFloorSpace ||
        this.selectedDevice
      );
    },
    thereIsDevice() {
      return false;
    },
    currentSpace() {
      if (this.selectedIndoorSpace) {
        return this.selectedIndoorSpace;
      }
      if (this.selectedFloorSpace) {
        return this.selectedFloorSpace;
      }
      if (this.selectedStructureSpace) {
        return this.selectedStructureSpace;
      }
      return null;
    }
  },
  methods: {
    submit() {
      const result = new BrowserResult(
        this.community,
        this.selectedStructure,
        this.selectedStructureSpace,
        this.selectedFloor,
        this.selectedFloorSpace,
        this.selectedIndoorSpace,
        this.selectedDevice
      );
      this.$emit("select", result);
      // if (this.selectedIndoorSpace) {
      //   this.$emit("select", {
      //     eventType: this.selectedDevice ? "device" : "space",
      //     ...this.selectedIndoorSpace,
      //     structure_name: this.selectedStructure.structure_name,
      //     floor_name: this.selectedFloor ? this.selectedFloor.name : undefined,
      //     outdoorSpace: this.selectedStructureSpace
      //       ? this.selectedStructureSpace.display
      //       : this.selectedFloorSpace.display,
      //     selectedDevice: this.selectedDevice
      //   });
      // } else if (this.selectedStructureSpace || this.selectedFloorSpace) {
      //   this.$emit("select", {
      //     eventType: this.selectedDevice ? "device" : "space",
      //     ...(this.selectedStructureSpace
      //       ? this.selectedStructureSpace
      //       : this.selectedFloorSpace),
      //     structure_name: this.selectedStructure.structure_name,
      //     floor_name: this.selectedFloor ? this.selectedFloor.name : undefined,
      //     selectedDevice: this.selectedDevice
      //   });
      // }
    },
    selectCommunity(data) {
      if (this.community && this.community.id != data.selectedObject.id) {
        this.selectedStructure = null;
        this.selectedStructureSpace = null;
        this.selectedFloor = null;
        this.selectedFloorSpace = null;
        this.selectedIndoorSpace = null;
        this.selectedDevice = null;
      }
      if (data) {
        this.community = data.selectedObject;
        this.loadStructures();
      } else {
        this.community = null;
      }
    },
    selectStructure(str) {
      if (this.selectedStructure && this.selectedStructure.id != str.id) {
        this.selectedStructureSpace = null;
        this.selectedFloor = null;
        this.selectedFloorSpace = null;
        this.selectedIndoorSpace = null;
        this.selectedDevice = null;
      }

      this.selectedStructure = str;
    },
    openFloor(floor, index) {
      if (!this.selectedFloor || this.selectedFloor.id != floor.id) {
        this.selectedStructureSpace = null;
        this.selectedFloorSpace = null;
        this.selectedIndoorSpace = null;
        this.selectedDevice = null;
      }

      this.selectedFloor = floor;
      this.selectedFloor.number = index + 1;
    },
    openStructureSpace(space) {
      if (
        !this.selectedStructureSpace ||
        this.selectedStructureSpace.id != space.id
      ) {
        this.selectedFloor = null;
        this.selectedFloorSpace = null;
        this.selectedIndoorSpace = null;
        this.selectedDevice = null;
      }

      this.selectedStructureSpace = space;
    },
    openFloorSpace(space) {
      if (!this.selectedFloorSpace || this.selectedFloorSpace.id != space.id) {
        this.selectedIndoorSpace = null;
        this.selectedDevice = null;
      }

      this.selectedFloorSpace = space;
    },
    openIndoorSpace(space) {
      if (this.selectedIndoorSpace && this.selectedIndoorSpace.id == space.id) {
        this.selectedIndoorSpace = null;
        this.devices = this.currentSpace.devices;
      } else {
        this.selectedIndoorSpace = space;
        this.devices = space.devices;
      }
    },
    selectDevice(device) {
      if (this.selectedDevice && this.selectedDevice.id == device.id) {
        this.selectedDevice = null;
      } else this.selectedDevice = device;
    },
    isLoaded() {
      this.communities[`${this.community.id}`] &&
        this.communities[`${this.community.id}`].length;
    },
    loadSpaceInfo(space) {
      this.loading = true;
      this.$api
        .get(`spaces/${space.id}/info?withDevices=${this.deviceMode}`)
        .then(({ data }) => {
          data.data.forEach(s => {
            s.display = s.name;
          });
          space.indoorSpaces = data.data;
          this.indoorSpaces = data.data;
          const devices = data.devices;
          const childrenSpaces = {};
          devices.forEach(d => {
            if (d.space_id != space.id) {
              if (childrenSpaces[`${d.space_id}`]) {
                childrenSpaces[`${d.space_id}`].push(d);
              } else {
                childrenSpaces[`${d.space_id}`] = [d];
              }
            }
          });
          space.indoorSpaces.forEach(s => {
            s.display = s.name;
            if (childrenSpaces[`${s.id}`]) {
              s.devices = childrenSpaces[`${s.id}`];
              s.devices.forEach(d => {
                d.space_name = s.name;
              });
            }
          });
          space.devices = devices;
          this.devices = devices;
        })
        .catch(console.error)
        .finally(_ => {
          this.loading = false;
        });
    },
    loadFloorSpaces() {
      this.loading = true;
      this.$api
        .get(`floors/${this.selectedFloor.id}/spaces`)
        .then(({ data }) => {
          data.data.forEach(s => {
            s.display = s.number;
          });
          this.selectedFloor.spaces = data.data;
        })
        .catch(console.error)
        .finally(_ => {
          this.loading = false;
        });
    },
    loadStructureInfo() {
      this.loading = true;
      this.$api
        .get(`structures/${this.selectedStructure.id}/info`)
        .then(({ data }) => {
          console.log(data);
          this.spaces = data.data.spaces;
          this.spaces.forEach(s => {
            s.display = s.name;
          });
          this.floors = data.data.floors;
        })
        .catch(console.error)
        .finally(_ => {
          this.loading = false;
        });
    },
    loadStructures() {
      if (this.isLoaded()) {
        this.structures = this.communities[`${this.community.id}`];
      } else {
        this.loading = true;
        this.$api
          .post(`structures/${1000000}/${0}`, {
            community_id: this.community.id
          })
          .then(({ data }) => {
            this.structures = data.data;
            this.communities[`${this.community.id}`] = data.data;
          })
          .catch(console.error)
          .finally(_ => {
            this.loading = false;
          });
      }
    },
    communitySearch(input) {
      return Axios.defaults.baseURL + `communities/${this.lang}/${input}`;
    },
    setSelectedStructure(str) {
      this.$refs.structuresTable.setCurrentRow(str);
    }
  },
  watch: {
    selectedStructure: debounce(function(newVal) {
      if (newVal) this.loadStructureInfo();
    }, 700),
    selectedFloor: debounce(function(newVal) {
      if (newVal) this.loadFloorSpaces();
    }, 700),
    selectedStructureSpace: debounce(function(newVal) {
      this.indoorSpaces = null;
      if (newVal) this.loadSpaceInfo(newVal);
    }, 700),
    selectedFloorSpace: debounce(function(newVal) {
      this.indoorSpaces = null;
      if (newVal) this.loadSpaceInfo(newVal);
    }, 700)
  },
  components: {
    BaseAutoComplete,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Divider.name]: Divider
    // [Breadcrumb.name]:Breadcrumb,
    // [BreadcrumbItem.name]:BreadcrumbItem
  }
};
</script>

<style scoped lang="scss">
span.title {
  width: 20%;
}
::v-deep .el-collapse-item__header,
::v-deep .el-collapse-item__wrap {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.8);
}
::v-deep .el-collapse-item__content {
  color: rgba(255, 255, 255, 0.7);
}
::v-deep .el-collapse-item__wrap {
  overflow: initial;
}
::v-deep .high-card-shadow .el-divider__text {
  color: $primary !important;
  text-align: center;
  width: fit-content;
  max-width: 70%;
}
::v-deep .high-card-shadow .el-divider {
  background-color: $primary !important;
}
.spaces-count {
  position: absolute;
  bottom: 0;
  right: 5px;
}
.floors-spaces {
  max-height: 35vh;
  overflow-x: auto;
  padding: 0 5px;
}
.floors-spaces::-webkit-scrollbar {
  width: 6px !important;
}
.pink-title {
  color: $primary-states !important;
  cursor: pointer;
  width: fit-content;
  max-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
::v-deep .card.selectedCard {
  background-color: #444761 !important;
}
::v-deep .card.selectedCard .el-divider__text {
  background-color: #444761 !important;
}
::v-deep .el-table__row {
  cursor: pointer;
}
</style>