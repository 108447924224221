<template>
  <div class="striped-table">
    <CustomTable
      :columns="columns"
      :table-key="tableKey"
      @config-change="configChangeHandler"
      @reset="resetHandler"
    >
      <el-table
          :data="data"
          highlight-current-row
        >
        <template v-for="(column, index) in columns.filter(e => e.visible)">
          <el-table-column
            :key="index"
            v-bind="column"
            v-if="column.customTemplate"
          >
            <template slot-scope="{ row }">
                  <router-link v-if="column.prop=='device.device_unique'" @click="log(row)" :to="`/device/reading/${row.device.id}`"> 
                    <base-button
                      class="w-100"
                      type="primary"
                      size="sm"
                    >
                      {{row.device.device_unique}}
                    </base-button>
                  </router-link>
                  <template  v-else-if="column.prop=='acknowledged_at'">
                    <p class="m-0">
                      {{ row.email ? row.email : "--" }}
                    </p>
                    <span v-if="row.email" style="font-size: 0.7rem">
                      {{ gDateFormat(row.acknowledged_at) }}
                    </span>
                  </template>
                  <template v-else-if="column.prop=='battery_level'">
                    <radial-progress-bar
                      :diameter="80"
                      :completed-steps="Math.round(+row.battery_level)"
                      :total-steps="100"
                      :startColor="getBatteryStatus(row.battery_level,row.battery_ranges)"
                      :innerStrokeWidth="5"
                      :strokeWidth="5"
                    >
                      <p>{{Math.round(+row.battery_level)}}%</p>
                    </radial-progress-bar>
                  </template>

            </template>
          </el-table-column>
          
          <el-table-column 
            v-else
            :key="index"
            v-bind="column"
          />
        </template>
          <OperationsColumn
            v-if="data.length"
            @open-comments="openComments"
            @change-ack-state="changeAckState"
            @escalate="escalate"
          ></OperationsColumn>
        </el-table>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from "@/components/CustomTable.vue";
import RadialProgressBar from "vue-radial-progress";
import OperationsColumn from '@/pages/SWAM/components/AlarmsComponents/OperationsColumn';
import { batteryStatus } from "src/util/helper.js";
import { mapGetters } from "vuex";
import { USER_PREFERENCES_KEYS } from "@/global/user-preferences-store/keys";

export default {
  props: {
    data: {
      requierd: true,
    },
  },
  components:{
    RadialProgressBar,
    OperationsColumn,
    CustomTable,
  },
  data() {
    return {
      columns: [],
      tableKey: USER_PREFERENCES_KEYS.SWAM_STATUS_ALARMS_TABLE_CONFIGURATIONS,
    }
  },
  computed: {
    ...mapGetters("SwamStore/StatusAlarms", {
      initColumns: "columns"
    }),
  },
  created() {
    this.resetHandler();
  },
  methods:{
    getBatteryStatus(batteryLevel,ranges) {
      return batteryStatus(ranges, batteryLevel);
    },
    goTo(id) {
      this.$router.push({ path: `/device/reading/${id}` });
    },
    openComments(row) {
      this.$emit('open-comments', row);
    },
    changeAckState(row) {
      this.$emit('change-ack-state', row);
    },
    escalate(row) {
      this.$emit('escalate', row);
    },
    configChangeHandler(configurations) {
      this.columns = configurations;
    },
    resetHandler() {
      this.columns = this.initColumns.map(e => ({ ...e }));
    }
  },
};
</script>

<style>
::v-deep .el-table__fixed-right {
  direction: ltr !important;
}
</style>
