<template>
  <card class="p-2">
    <template slot="header">
      <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
        <div class="d-flex align-items-center">
          <inline-svg
            :src="require('@/assets/Icons/forklift/Group.svg')"
            width="25"
            height="25"
          />
          <h5 class="ml-2 font-color-4 font-weight-bold">Vehicle Alarms</h5>
        </div>
        <div>
          <span class="font-color-4 font-weight-bold">{{ totalRecords }}</span>
        </div>
      </div>
    </template>
    <div class="row font-weight-bold font-color-1 mb-4">
      <div class="col-12">
        <div class="d-flex">
          <div
            :class="['d-flex pb-1 cursor-pointer mx-4', { active: tab.isActive }]"
            v-for="(tab, i) in tabs"
            :key="i"
            @click="selectTab(tab)">
            <inline-svg
              :src="tab.src"
              width="20"
              height="20"
              :class="{ 'background-svg-good': tab.isActive }"
            />
            <span class="ml-1">{{ tab.title }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 background-3 mt-2 mb-3">
        <el-table
          :data="alarms"
          :show-header="false"
          :row-class-name="customRowClassName"
          max-height="350px">
          <el-table-column width="180">
            <template slot-scope="scope">
              <inline-svg
                :src="require('@/assets/Icons/forklift/noun_Forklift2.svg')"
                width="20"
                height="20"
              />
              {{ scope.row.device_name }}
            </template>
          </el-table-column>
          <el-table-column width="180">
            <template slot-scope="scope">
              <inline-svg
                :src="require('@/assets/Icons/forklift/noun_Driver2.svg')"
                width="20"
                height="20"
              />
              {{ scope.row.driver_name }}
            </template>
          </el-table-column>
          <el-table-column width="180">
            <template slot-scope="scope">
              <inline-svg
                :src="require('@/assets/Icons/forklift/Layer 32.svg')"
                width="20"
                height="20"
              />
              <span>{{ scope.row.created_at ? dateFormat(scope.row.created_at) : "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot-scope="scope">
              <div class="d-flex align-items-center">
                <span :class="['point', scope.row.idling === true ? 'background-good' : 'background-problem']" />
                <span >{{ scope.row.idling ? 'On' : 'Off' }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="type == 'geofencing'" width="180">
            <template slot-scope="scope">
              <inline-svg
                :src="require('@/assets/Icons/location-marker.svg')"
                width="20"
                height="20"
              />
              <span>{{ scope.row.location ? scope.row.location : "no location" }}</span>
            </template>
          </el-table-column>
          <!-- Metre -->
          <!-- <el-table-column v-if="type == 'geofencing'" width="150">
            <template slot-scope="scope">
              <inline-svg
                :src="require('@/assets/Icons/location-marker.svg')"
                width="20"
                height="20"
              />
              <span>{{ scope.row.location ? dateFormat(scope.row.location) : "no location" }}</span>
            </template>
          </el-table-column> -->
          <el-table-column width="150">
            <template slot-scope="scope">
              <!-- <inline-svg
                :src="require('@/assets/Icons/location-marker.svg')"
                width="20"
                height="20"
              /> -->
              <span>{{ scope.row.event}}</span>
            </template>
          </el-table-column>
          <el-table-column width="120">
            <template>
              <base-button
                simple
                class="btn-escalate"
                size="sm"
                type="primary"
                >{{ $t("escalate") }}</base-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <TablePagination
        slot="footer"
        ref="paginator"
        :initialPerPage="5"
        v-if="totalRecords"
        :total="totalRecords"
        @change="updatePagination"
      ></TablePagination>
    </div>
  </card>
</template>

<script>
import { mapGetters } from "vuex";
import TablePagination from "@/components/TablePagination.vue";
import { dateFormat } from "@/util/helper"

export default {
  name: "PortfolioVehicleAlarms",
  components: {
    TablePagination,
  },
  computed: {
    ...mapGetters(`ForkliftStore/Portfolio/PortfolioVehicleAlarms/`, [
      "alarms",
      "loading",
      "totalRecords",
    ]),
  },
  data() {
    return {
      active: true,
      pagination: {
        offset: 0,
        limit: 5,
      },
      tabs: [
        {
          src: require("@/assets/Icons/forklift/Ellipse.svg"),
          title: "active",
          isActive: true,
        },
        {
          src: require("@/assets/Icons/forklift/noun_Car Accident.svg"),
          title: "accidents",
          isActive: false,
        },
        {
          src: require("@/assets/Icons/forklift/noun_Map2.svg"),
          title: "geofencing",
          isActive: false,
        },
        {
          src: require("@/assets/Icons/forklift/noun_speed2.svg"),
          title: "overspeeding",
          isActive: false,
        },
      ],
      type: 'active',
      Geofencing: false
    };
  },
  methods: {
    fetchAlarms() {
      const payload = {
        limit: this.pagination.limit,
        offset: this.pagination.offset,
        type: this.type
      };
      this.$store.dispatch(
        `ForkliftStore/Portfolio/PortfolioVehicleAlarms/fetchAlarms`,
        payload
      );
    },
    customRowClassName({ row, rowIndex }) {
      return "background-3";
    },
    updatePagination(data) {
      this.pagination.limit = data.limit;
      this.pagination.offset = data.offset;
      this.fetchAlarms();
    },
    selectTab(selectedTab) {
      this.tabs.forEach((tab) => {
        if(tab.title == selectedTab.title){
          tab.isActive = true
          this[tab.title] = true
          this.type= selectedTab.title
        }else{
          tab.isActive = false
          this[tab.title] = false
        }
      });
      this.fetchAlarms()
    },
    dateFormat
  },
  created() {
    this.fetchAlarms()
  },
};
</script>

<style lang="scss" scoped>
$primary: #42b883;

.active {
  color: $primary;
  border-bottom: 3px solid $primary;
}

.cursor-pointer {
  cursor: pointer;
}
.point {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-inline-start: 5px;
  margin-right: 5px
}
</style>