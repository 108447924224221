<template>
  <div class="d-flex justify-content-between align-content-center">
 
        <div class="d-flex align-items-center">
          <inline-svg
            :src="require('@/assets/Icons/forklift/noun_hibernate2.svg')"
            width="20"
            height="20"
          />
          <el-tooltip
            :content="`From ${fromDate()}`"
            placement="top"
          >
            <h4 class="font-color-4 font-weight-bold ml-2 underline">
              {{ data.status.name }}
            </h4>
            
          </el-tooltip>
        </div>
        <div class="d-flex align-items-center">
          <inline-svg
            :src="require('@/assets/Icons/forklift/noun_Driver_1574355.svg')"
          />
          <h4
            class="font-color-4 font-weight-bold mb-0 ml-2"
            v-if="drivers.length > 0"
          >
            {{ drivers[0].name }}
          </h4>
        </div>
        <div class="d-flex align-items-center">
       
          <span class="font-color-4 font-weight-bold mx-2">Total kms</span>
          <span
            class="font-color-1 font-weight-bold"
          >
            {{ data.odometer }}km
          </span>
        </div>
        <div class="d-flex align-items-center">
          <inline-svg
            :src="require('@/assets/icons-2/Ellipse.svg')"
            width="15"
            height="15"
            :class="{ 'background-svg-problem': !data.connected }"
          />
          <span class="font-color-4 font-weight-bold mx-2">Status</span>
          <span
            :class="['font-weight-bold', data.connected ? 'good' : 'problem']"
          >
            {{ data.connected ? "ON" : "OFF" }}
          </span>
        </div>
        <div class="d-flex align-items-center">
          <span class="font-color-4 font-weight-bold">GNSS</span>
          <inline-svg
            :class="`mx-2`"
            :src="
              require('@/assets/Icons/forklift/noun_GSM Signal_2272461.svg')
            "
          />
          <span
            :class="['font-weight-bold', data.gnss_status ? 'good' : 'problem']"
          >
            {{ data.gnss_status ? "ON" : "OFF" }}
          </span>
        </div>
        <div class="d-flex align-items-center">
          <span class="font-color-4 font-weight-bold">GMS</span>
          <inline-svg
            :class="`mx-2`"
            :src="require('@/assets/Icons/forklift/noun_GSM Signal_-1.svg')"
          />
          <span
            :class="['font-weight-bold', data.gms_status ? 'good' : 'problem']"
          >
            {{ data.gms_status ? "ON" : "OFF" }}
          </span>
        </div>
      </div>
</template>

<script>
import { Tooltip, Popover } from "element-ui";
import { getPercentColor, getDurationString,getDateString } from "@/util/helper";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "VehicleSummary",
  components: {
    [Tooltip.name]: Tooltip,
    [Popover.name]: Popover
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  methods:{
    getDurationString,
    getDateString,
    showBrief(txt) {
     if(txt) return `${txt.substr(0, 20)}...`
    },
    fromDate() {
      return this.getDateString(this.data.status.from).split('ago').join('')
    }
  },
  computed: {
     ...mapState({
      drivers(state, getters) {
        return getters[`ForkliftStore/Device/DriverInfo/drivers`];
      }
    }),
    svgColor() {
      return this.$store.getters.themMode == "light" ? "" : "light-svg";
    }
  }
};
</script>

<style lang="scss" scoped>
.underline {
  border-bottom: 0.5px solid #7da995;
}
</style>
