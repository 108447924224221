<template>
  <card class="p-2">
    <template slot="header">
      <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
        <div class="d-flex align-items-center">
          <inline-svg :src="require('@/assets/Icons/forklift/noun_Alarm.svg')" width="25" height="25" />
          <h5 class="ml-2 font-color-4 font-weight-bold">Needs Attention</h5>
        </div>
        <div>
          <span class="font-color-4 font-weight-bold">{{totalRecords}}</span>
        </div>
      </div>
    </template>
    <div class="row font-weight-bold font-color-1 mb-4">
      <div class="col-12">
        <div class="d-flex portfolio-tabs">
          <div
            :class="['d-flex pb-1 cursor-pointer mx-4', { active: tab.isActive }]"
            v-for="(tab, i) in tabs"
            :key="i"
            @click="selectTab(tab)"
          >
            <inline-svg
              :src="tab.src"
              width="20"
              height="20"
              :class="{ 'background-svg-good': tab.isActive }"
            />
            <span class="ml-1">{{ tab.title }}</span>
            <!-- <span class="ml-1">{{ tab.total }}</span> -->
          </div>
        </div>
      </div>
      <div class="col-12 background-3 mt-2">
        <el-table
          :data="attentionAlarms"
          :show-header="false"
          :row-class-name="customRowClassName"
        >
          <el-table-column width="180">
            <template slot-scope="scope">
              <inline-svg
                :src="require('@/assets/Icons/forklift/noun_Forklift2.svg')"
                width="20"
                height="20" 
              />
              {{ scope.row.device_name }}
            </template>
          </el-table-column>
          <el-table-column width="180">
            <template slot-scope="scope">
              <inline-svg
                :src="require('@/assets/Icons/forklift/noun_Driver2.svg')"
                width="20"
                height="20"
              />
              {{ scope.row.driver_name}}
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot-scope="scope">
              <inline-svg
                :src="require('@/assets/Icons/forklift/Layer 32.svg')"
                width="20"
                height="20"
              />
              <inline-svg :src="require('@/assets/Icons/forklift/Layer 3.svg')"/>
              <span class="ml-2">{{ scope.row.date ? dateFormat(scope.row.date) : "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column width="120">
            <template>
              <base-button
                simple
                class="btn-escalate"
                size="sm"
                type="primary"
              >{{ $t('escalate') }}</base-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <TablePagination
        class="mt-3"
        slot="footer"
        ref="paginator"
        :initialPerPage="5"
        v-if="totalRecords"
        :total="totalRecords"
        @change="updatePagination"
      ></TablePagination>
    </div>
  </card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TablePagination from "@/components/TablePagination.vue";
import { dateFormat } from "@/util/helper"

export default {
  name: 'PortfolioNeedsAttention',
  components: {TablePagination},
  data() {
    return {
      active: true,
      pagination: {
        offset: 0,
        limit: 5,
      },
      tabs: [
        {
          src: require("@/assets/Icons/forklift/noun_Car Accident.svg"),
          title: "vehicle",
          isActive: true,
        },
        {
          src: require("@/assets/Icons/forklift/noun_Map2.svg"),
          title: "insurance",
          isActive: false,
        },
        {
          src: require("@/assets/Icons/forklift/noun_speed2.svg"),
          title: "license",
          isActive: false,
        },
      ],
      Geofencing: false,
      type: 'vehicle'
    };
  },
  computed: {
    ...mapState({
      attentionAlarms(state, getters) {
        return getters[`ForkliftStore/Portfolio/PortfolioNeedsAttention/attentionAlarms`];
      },
      totalRecords(state, getters) {
        return getters[`ForkliftStore/Portfolio/PortfolioNeedsAttention/totalRecords`];
      },
    }),
  },
  methods: {
    ...mapActions(`ForkliftStore/Portfolio/PortfolioNeedsAttention`, [
      'fetchAttentionAlarms'
    ]),
    customRowClassName({ row, rowIndex }) {
      return 'background-3';
    },
    selectTab(selectedTab) {
      this.tabs.forEach((tab) => {
        if(tab.title == selectedTab.title){
          tab.isActive = true
          this[tab.title] = true
          this.type=selectedTab.title
        }else{
          tab.isActive = false
          this[tab.title] = false
        }
      });
      this.fetchAttention()
    },
    updatePagination(data) {
      this.pagination.limit = data.limit;
      this.pagination.offset = data.offset;
      this.fetchAttention();
    },
    fetchAttention(){
      const payload = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
        type: this.type
      }
      this.fetchAttentionAlarms(payload)
    },
    dateFormat
  },
  mounted() {
    this.fetchAttention()
  },
}
</script>

<style lang="scss" scoped>
$primary: #42b883;

.active {
  color: $primary;
  border-bottom: 3px solid $primary;
}

.cursor-pointer {
  cursor: pointer;
}

.portfolio-tabs {
  max-width: 500px;
  overflow-x: auto;

  .portfolio-tab {
    min-width: 150px;
  }
}
</style>