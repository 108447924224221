<template>
  <card class="h-100">
    <div slot="header">
      <div class="d-flex justify-content-between align-items-start">
        <h2>Vehicles</h2>
        <base-input>
          <div class="input-group">
            <div class="input-group-prepend">
              <base-dropdown
                title-classes="dropdown-toggle btn btn-primary btn-block searchdropdown"
                :title="'Name'"
              >
                <a class="dropdown-item" href="#">Name</a>
              </base-dropdown>
            </div>
            <input
              type="text"
              class="form-control search-filter"
              :placeholder="$t('Search')"
              v-model="searchQuery"
            />
          </div>
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <!--  -->
        <el-table :data="device_list">
          <el-table-column min-width="50" label="#" align="center">
            <div class="tel-photo" slot-scope="props">
              <img
                src="@/assets/Icons/gps.png"
                alt="Table image"
                style="cursor: pointer;"
                @click="setDevice(props.row)"
              />
            </div>
          </el-table-column>
          <el-table-column prop="device_name" sortable min-width="200" label="Name" align="center">
            <button
              slot-scope="props"
              @click="setDevice(props.row)"
              class="btn btn-simple btn-primary btn-sm mt-1 w-100"
            >
              <span>{{props.row.device_name}}</span>
            </button>
          </el-table-column>
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :label="column.label"
          ></el-table-column>

          <el-table-column :min-width="100" align="left" :label="$t('Date')">
            <div slot-scope="props">{{gDateFormat(props.row.created_at)}}</div>
          </el-table-column>

          <!-- <el-table-column min-width="150" header-align="right" align="right" label="Actions">
            <div slot-scope="{ row }" class="text-right table-actions">
              <el-tooltip content="set zone" effect="light" :open-delay="300" placement="top">
                <img src="@/assets/Icons/location_zone.svg" @click="setDeviceZones(row)" />
              </el-tooltip>
            </div>
          </el-table-column>-->
        </el-table>
      </div>
    </div>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class>
        <p class="card-category">Showing {{ from }} to {{ to }} of {{ total }} entries</p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      ></base-pagination>
    </div>
  </card>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import Fuse from "fuse.js";
export default {
  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["staffName"],
      threshold: 0.3,
      findAllMatches: true,
    });
  },
  data() {
    return {
      device_list: [],
      total: 0,
      fuseSearch: null,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      searchQuery: "",
      tableColumns: [
        // {
        //   prop: "device_name",
        //   label: this.$i18n.t("Name"),
        //   minWidth: 200
        // },
        {
          prop: "customer",
          label: this.$i18n.t("Customer"),
          minWidth: 100,
        },
        {
          prop: "fmc",
          label: this.$i18n.t("FMC"),
          minWidth: 200,
        },

        {
          prop: "structure",
          label: this.$i18n.t("Structure"),
          minWidth: 200,
        },
      ],

      // tableData: [
      //   {
      //     id: 1,
      //     img: "img/default-avatar.png",
      //     name:'Yasser',
      //     number: "123AB",
      //     model: "HONDA",
      //     status: "on duty",
      //   },
      //   {
      //     id: 1,
      //     img: "img/default-avatar.png",
      //     name:'hani',
      //     number: "1247AB",
      //     model: "KIA",
      //     status: "on duty",
      //   },
      //   {
      //     id: 1,
      //     img: "img/default-avatar.png",
      //     name:'mansor',
      //     number: "177AB",
      //     model: "KIA",
      //     status: "on duty",
      //   },
      //   {
      //     id: 1,
      //     img: "img/default-avatar.png",
      //     name:'hamed',
      //     number: "123AB",
      //     model: "TOYOTA",
      //     status: "on duty",
      //   },
      //   {
      //     id: 1,
      //     img: "img/default-avatar.png",
      //     name:'maher',
      //     number: "103AB",
      //     model: "ISUZU",
      //     status: "on duty",
      //   },
      //   {
      //     id: 1,
      //     img: "img/default-avatar.png",
      //     name:'marwan',
      //     number: "123AB",
      //     model: "ISUZU",
      //     status: "on duty",
      //   },

      // ],

      searchedData: [],
    };
  },
  // watch: {
  //   searchQuery(value) {
  //     let result = this.tableData;
  //     if (value !== "") {
  //       result = this.fuseSearch.search(this.searchQuery);
  //     }
  //     this.searchedData = result;
  //   },
  // },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    // queriedData() {
    //   let result = this.tableData;
    //   if (this.searchedData.length > 0) {
    //     result = this.searchedData;
    //   }
    //   return result.slice(this.from, this.to);
    // },
    // to() {
    //   let highBound = this.from + this.pagination.perPage;
    //   if (this.total < highBound) {
    //     highBound = this.total;
    //   }
    //   return highBound;
    // },
    // from() {
    //   return this.pagination.perPage * (this.pagination.currentPage - 1);
    // },
    // total() {
    //   return this.searchedData.length > 0
    //     ? this.searchedData.length
    //     : this.tableData.length;
    // },

    to() {
      return this.pagination.perPage * this.pagination.currentPage;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1) ;
    },
  },

  mounted() {
    this.fetchDevies();
  },
  methods: {
    fetchDevies() {
      let { perPage, currentPage } = this.pagination;
      let limit = perPage;
      let offset = perPage * (currentPage - 1);

      this.$api
        .get(`teltonika/devices/list?type=tracker&limit=${limit}&offset=${offset}`)
        .then((res) => {
          if (res.data.msg === 'success') {
            this.device_list = res.data.data;
            //this.total = res.data.total_records;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    setDevice(device) {
      this.$router.push({ path: `car-tracker/${device.id}` });
    },
  },
};
</script>
<style >
</style>