<template>
  <card v-loading="loading">
    <template slot="header">
      <div class="border-bottom pb-2">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <inline-svg
              :src="require('@/assets/Icons/forklift/noun_Forklift.svg')"
              width="25"
              height="25"
            />
            <h4 class="ml-2 font-color-4 font-weight-bold">Vehicle Info</h4>
          </div>
          <span class="custom-badge" v-if="!vehicleInfo.in_repair" @click="goInRepair(true)">In repair</span>
          <span class="custom-badge" v-else @click="goInRepair(false)">Ready</span>
          <base-select
            style="height: 27.5px"
            :inline="true"
            v-model="currentVehicleId"
            :options="vehiclesOptions"
          />
          <inline-svg
            :src="require('@/assets/Icons/forklift/Layer 32.svg')"
            width="25"
            height="25"
          />
        </div>
        <router-link :to="{ path: `/forklift/report/2`,}">
          <base-button type="primary" class="mr-2 px-3">
          Create Service Request
          </base-button>
        </router-link>
    </div>

    </template>
    <div class="row">
      <div class="col-6 highlighted">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1 font-weight-bold">
            Total kms
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ vehicleInfo.odometer }}km
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Vehicle ID
          </span>
          <span class="value font-color-1 font-weight-bold">
            TFT{{ vehicleInfo.id }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Vehicle Type
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ vehicleInfo.type }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Company Info
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ vehicleInfo.company_name }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Registeration Expiry
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ showDate(vehicleInfo.registration_expiry) }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Vehicle Model
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ vehicleInfo.model }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Insurance Date
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ showDate(vehicleInfo.insuranced_at) }}
          </span>
        </div>
      </div>
      <!-- <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-weight-bold font-color-1">
            Registration Date
          </span>
          <span class="value font-color-1">
            {{ showDate(vehicleInfo.registered_at) }}
          </span>
        </div>
      </div> -->
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Depart Info
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ vehicleInfo.department_name }}
          </span>
        </div>
      </div>
       <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Registration Plate
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ vehicleInfo.registration_plate }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Chassis No.
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ vehicleInfo.chassis_no }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Device IMEI
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ vehicleInfo.device_imei }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Device No.
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ vehicleInfo.device_id }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Year of Build
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ new Date(vehicleInfo.built_at).getFullYear() }}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Year of Purchase
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{ new Date(vehicleInfo.purchased_at).getFullYear() }}
          </span>
        </div>
      </div>
      
    </div>
  </card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { getDeep,getDate } from "@/util/helper";
import Badge from '@/components/Badge';
import ForkliftService from '../../services/forklift.service';

export default {
  props: {
    namespace: {
      type: String,
      default: "ForkliftStore/Device"
    },
    moduleName: {
      type: String,
      default: "VehicleInfo"
    }
  },
  components: {
    Badge
  },
  created() {
    this.currentVehicleId = this.$route.params.id;
    this.fetchVehicles();
  },
  data: () => ({
    currentVehicleId: undefined
  }),
  computed: {
    storePath() {
      return this.namespace + "/" + this.moduleName;
    },
    ...mapState({
      loading(state, getters) {
        return getters[`${this.storePath}/loading`];
      },
      vehicles(state, getters) {
        return getters[`${this.storePath}/vehicles`];
      },
      vehicleInfo(state, getters) {
        return getters[`${this.storePath}/vehicleInfo`];
      }
      // currentVehicleId: {
      //   get() {
      //     return getDeep(
      //       this.$store.state,
      //       `${this.storePath}/currentVehicleId`
      //     );
      //   },
      //   set(value) {
      //     this.$store.commit(`${this.storePath}/selectVehicle`, value);
      //   }
      // }
    }),
    vehiclesOptions() {
      return this.vehicles.map(element => ({
        label: `${element.device_imei}`,
        value: element.id
      }));
    }
  },
  watch: {
    currentVehicleId(val) {
      this.$router.push(`/forklift/device/${val}`)
      this.updateVehicle(val);
    },
    vehicles(val) {
      this.currentVehicleId = this.$route.params.id
    }
  },
  methods: {
    async goInRepair(status) {
      await ForkliftService.goInRepair(this.$route.params.id,{in_repair: status});
      await this.updateVehicle(this.$route.params.id)
    },
    async updateVehicle(id){
      await this.fetchVehicleInfo({ id: id });
      this.$emit('updateVehicle', this.vehicleInfo);
    },
    ...mapActions({
      fetchVehicleInfo(dispatch, payload) {
        return dispatch(`${this.storePath}/fetchVehicleInfo`, payload);
      },
      fetchVehicles(dispatch, payload) {
        return dispatch(`${this.storePath}/fetchVehicles`, payload);
      }
    }),
    showDate(date) {
      return getDate(date)
    },
    getKey(key) {
      return key.split("_").join(" ");
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-badge {
  border: 1px solid $primary;
  color: $primary;
  border-radius: 4px;
  padding: 2px 8px;
  cursor: pointer;
}
.highlighted{
    background: #42b88329;
    border-left: 6px solid #42b883;
}
</style>
