<template>
    <zingchart :data="chartConfig" :height="'350px'" :width="'100%'"></zingchart>
</template>
<script>
import {colors} from 'src/util/helper.js';

export default {
  data() {
    return {
      chartConfig: {
        type: "hbar",
        backgroundColor: "transparent",
        plot: {
          stacked: true,
        },
        legend: {
          align: "center",
          backgroundColor: "none",
          borderWidth: "0px",
          offsetY:'-50px',
          item: {
            cursor: "hand",
            fontColor: "#808080",
          },

          verticalAlign: "bottom",
        },
        scaleX: {
          
          values: ["J","M","A","M","F","J"],
          guide: {
            visible: false,
          },
          item: {
            fontColor: "#999",
          },
          lineColor: "none",
          lineWidth: "0px",
          tick: {
            visible: false,
          },
        },
        scaleY: {
         placement: "opposite",
         values: [0, 20, 40, 60,80,100],
          guide: {
            lineStyle: "solid",
            visible:false
          },
          item: {
            fontColor: "#999",
          },
          lineColor: "none",
          lineWidth: "0px",
          maxValue: "495px",
          minValue: "0px",
          step: 123.75,
          tick: {
            visible: false,
          },
         
        },
        tooltip: {
          text:
            "<div class='zingchart-tooltip'><div class='scaley-value'>%v</div></div>",
          padding: "0px",
          backgroundColor: "none",
          htmlMode: true,
          placement: "node:center",
        },
        series: [
            //poor
        {
            values: [10,5,15, 5, 14, 20],
            alpha: 1,
            backgroundColor: colors.problem,
            hoverState: {
              backgroundColor: "#2956A0",
            },
          },
        //   poor
          {
            values: [30,35,40, 45, 25, 30],
            alpha: 1,
            backgroundColor: colors.poor,
            hoverState: {
              backgroundColor: "#2956A0",
            },
          },
          //ok
           {
            values: [40,55,51, 47, 40, 10],
            alpha: 1,
            backgroundColor: colors.ok,
            hoverState: {
              backgroundColor: "#2956A0",
            },
          },
          //good
          {
            values: [20,70,75, 3, 21, 40],
            alpha: 1,
            backgroundColor: colors.good,
            hoverState: {
              backgroundColor: "#2956A0",
            },
          },

          
        ],
      },
    };
  },
};
</script>
<style  scoped>
</style>