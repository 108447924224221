<template>
  <card>
    <template slot="header">
      <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
        <div class="d-flex align-items-center">
          <inline-svg :src="require('@/assets/Icons/forklift/noun_signal.svg')" width="25" height="25" />
          <h5 class="ml-2 font-color-1">GSM</h5>
        </div>
        <div>
          <span class="font-color-4">Operator&nbsp;</span>
          <span class="good font-color-4 font-weight-bold">{{ data.gsm_operator }}</span>
        </div>
        <div>
          <span class="font-color-4">Status&nbsp;</span>
          <span class="good font-color-4 font-weight-bold">
            {{ data.gsm_status }}
          </span>
        </div>
      </div>
    </template>
    <div class="row font-weight-bold font-color-1 mb-4">
      <div class="col-12 d-flex align-items-center">
        <span class="mr-2">GSM Signal</span>
        <base-progress style="flex: 1 auto" :value="data.gsm_signal" status="success" valuePosition="right" />
      </div>
    </div>
    <div class="row font-color-1">
      <div class="col-12 d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <span>Cell ID</span>
          <span class="ml-2 font-weight-bold">
            {{ data.gsm_cell_id }}
          </span>
        </div>
        <div class="d-flex align-items-center">
          <span class="ml-2">Area Code</span>
          <span class="ml-2 font-weight-bold">
            {{ data.gsm_area_code }}
          </span>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import BaseProgress from '@/components/BaseProgress';

export default {
  name: 'GSM',
  props:{
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BaseProgress
  }
}
</script>

<style>

</style>