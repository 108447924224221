<template>
  <card>
    <div slot="header">
      <h2 class="card-title">Indevidula Staff Details</h2>
    </div>
    <div class="row">
      <div class="col-md-9 p-2">
        <card class="border h-100 rounded-corner">
          <div class="row">
            <div class="col-md-2">
              <img src="img/default-avatar.png" class="mw-100 mh-100 rounded-circle" />
            </div>
            <div class="col-md-8 col-sm-10">
              <h2 class="m-0">{{user.staffName}}</h2>
              <h4>#ID:1234</h4>
              <table class="table mt-5">
                <tr>
                  <td class="border border-0 p-0">
                    <h4>Date Joined</h4>
                  </td>
                  <td class="border border-0 p-0">
                    <h4>11/2/2020</h4>
                  </td>
                </tr>
                <tr>
                  <td class="border border-0 p-0">
                    <h4>Address</h4>
                  </td>
                  <td class="border border-0 p-0">
                    <h4>Dubai</h4>
                  </td>
                </tr>
                <tr>
                  <td class="border border-0 p-0">
                    <h4>Age</h4>
                  </td>
                  <td class="border border-0 p-0">
                    <h4>33</h4>
                  </td>
                </tr>
                <tr>
                  <td class="border border-0 p-0">
                    <h4>Mobile Number</h4>
                  </td>
                  <td class="border border-0 p-0">
                    <h4>+962 992394949</h4>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-3 p-2">
        <card class="border h-100 rounded-corner">
          <div class="row">
            <div class="col-md-12 text-center">
              <img src="@/assets/Icons/working-laptop.svg" class="mb-3" />
              <h2>Staff Status</h2>
              <div class="duty-status mb-3">
                <span class="mt-1">On Duty</span>
              </div>
              <h4>For last {{user.overtime}}</h4>
            </div>
          </div>
        </card>
      </div>
    </div>
    <!-- analytice -->
    <div class="row">
      <div class="col-md-12 p-2">
        <card class="border h-100 rounded-corner">
          <div slot="header">
            <h2 class="card-title text-center">Analytics</h2>
          </div>
          <div class="row">
            <div class="col-md-3 text-center">
              <img src="@/assets/Icons/attendance.svg" />
              <h4>% Of Attendance</h4>
              <h2>{{user.attendencePercent}}</h2>
            </div>
            <div class="col-md-3 text-center">
              <img src="@/assets/Icons/plus-sign.svg" />
              <h4>OverTime</h4>
              <h2>{{user.overtime}}</h2>
            </div>
            <div class="col-md-3 text-center">
              <img src="@/assets/Icons/noun_walk.svg" />
              <h4>Holiday</h4>
              <h2>{{user.holiday}}</h2>
            </div>
            <div class="col-md-3 text-center">
              <img src="@/assets/Icons/chat.svg" />
              <h4>Last Time Report</h4>
              <h2>{{user.lasttimereport}}</h2>
            </div>
          </div>
        </card>
      </div>
    </div>
    <!-- performnce -->
    <div class="row">
      <div class="col-lg-9 col-md-8 p-2">
        <card class="border h-100 rounded-corner">
          <div class="d-flex justify-content-between align-items-start">
            <h2>Overall Performence</h2>

            <base-dropdown
              :menu-on-right="!$rtl.isRTL"
              title-tag="a"
              class="nav-item"
              menu-classes="dropdown-navbar"
            >
              <template slot="title">
                <div class="photo">
                  <img src="@/assets/Icons/settings.svg" style="width:40px" fill="black" />
                </div>
              </template>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">option 1</a>
              </li>

              <div class="dropdown-divider"></div>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">option 2</a>
              </li>
            </base-dropdown>
            <!-- <img src="@/assets/Icons/settings.svg" /> -->
          </div>
        </card>
      </div>
      <div class="col-lg-3 col-md-4 p-2">
        <card class="border h-100 rounded-corner">
          <div class="row">
            <div class="col-md-12">
              <h2>Daily Performence</h2>
              <div class="row">
                <div class="col-6">
                  <h4>Saturday</h4>
                </div>
                <div class="col-6">
                  <h4 class="ml-3">
                    <i class="fas fa-circle fa-xs mr-2" :style="{color:colors.good}"></i>95%
                  </h4>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <h4>Sunday</h4>
                </div>
                <div class="col-6">
                  <h4 class="ml-3">
                    <i class="fas fa-circle fa-xs mr-2" :style="{color:colors.ok}"></i>60%
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h4>Monday</h4>
                </div>
                <div class="col-6">
                  <h4 class="ml-3">
                    <i class="fas fa-circle fa-xs mr-2" :style="{color:colors.problem}"></i>30%
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h4>Tuseday</h4>
                </div>
                <div class="col-6">
                  <h4 class="ml-3">
                    <i class="fas fa-circle fa-xs mr-2" :style="{color:colors.problem}"></i>15%
                  </h4>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <h4>Wednesday</h4>
                </div>
                <div class="col-6">
                  <h4 class="ml-3">
                    <i class="fas fa-circle fa-xs mr-2" :style="{color:colors.poor}"></i>40%
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h4>Thursday</h4>
                </div>
                <div class="col-6">
                  <h4 class="ml-3">
                    <i class="fas fa-circle fa-xs mr-2" :style="{color:colors.good}"></i>75%
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </card>
</template>
<script>
import {colors} from 'src/util/helper.js';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed:{
    colors(){
      return colors;
    }
  }
};
</script>
<style scoped>
.duty-status {
  width: 139px;
  height: 39px;
  margin: 0 auto;
  border: 2px solid #2b6df6;
  border-radius: 10px;
}
.duty-status span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-weight: bolder;
  color:#fff;
}
.white-content .duty-status span{
  color:#1d253b;
}


</style>
