<template>
  <div>
    <base-map :zoom="12" :center="center" style="height: 400px">
      <template v-for="(marker, index) in markers">
        <base-map-marker
          :key="index"
          :position="marker.position"
          :label="marker.label"
          @click="center = marker.position"
        />
      </template>
    </base-map>
  </div>
</template>
<script>
//import {Marker} from 'VueGoogleMaps'
import BaseMap from 'src/components/BaseMap/Map'
import MapMarker from "src/components/BaseMap/MapMarker.vue";


export default {
  components: {
    [BaseMap.name]: BaseMap,
    [MapMarker.name]: MapMarker
  },
  data() {
    return {
      options: {
        //mapTypeId:'satellite',
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      },
      shape: {
        coords: [10, 10, 10, 15, 15, 15, 15, 10],
        type: "poly"
      }
    };
  },
  computed: {
    markers(){
      let devices = this.$store.state.TeltonikaStore.devices;
      let markers = devices
            .filter(device => device.last_location != undefined)
            .map(device => {
              let last_location = device.last_location;

              return {
                position: {
                  lat: last_location.lat,
                  lng: last_location.lng
                },
                label: device.device_name
              };
            });
        return markers;
    },
    center(){
      if(this.markers.length > 0){
        return this.markers[0].position;
      }
      return {lat:10,lng:10};
    }
  
  },
  
};
</script>
<style scoped>
</style>