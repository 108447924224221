<template>
  <card class="px-3 py-2"  v-loading="loading">
    <template slot="header">
      <div class="row">
        <div class="col-6 font-weight-bold">
          <div class="d-flex align-items-center">
            <inline-svg :src="require('@/assets/Icons/forklift/noun_Inventory Control.svg')" width="25" height="25" />
            <h5 class="ml-2 font-color-4">Vehicle Inventory</h5>
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex align-items-center">
            <inline-svg :src="require('@/assets/Icons/forklift/noun_Driver_1574355.svg')" width="25" height="25" />
            <h5 class="ml-2 font-color-4">Drivers</h5>
          </div>
        </div>
      </div>
    </template>
    <div class="row" >
      <div class="col-6 background-3" v-if="portfolioVehicleInfo.inventory">
        <div class="d-flex justify-content-between pb-3 border-bottom mt-3">
          <span class="font-color-1">Total</span>
          <span class="font-color-4">{{portfolioVehicleInfo.inventory.total}}</span>
        </div>
        <div class="d-flex justify-content-between pb-3 border-bottom mt-3">
          <span class="font-color-1">Operational Today</span>
          <span class="font-color-4">{{portfolioVehicleInfo.inventory.operational_today}}</span>
        </div>
        <div class="d-flex justify-content-between pb-3 border-bottom mt-3">
          <span class="font-color-1">Operational Yesterday</span>
          <span class="font-color-4">{{portfolioVehicleInfo.inventory.operational_yesterday}}</span>
        </div>
        <div class="d-flex justify-content-between pb-3 border-bottom mt-3">
          <span class="font-color-1">Repair Inventory</span>
          <span class="font-color-4">{{portfolioVehicleInfo.inventory.repair}}</span>
        </div>
      </div>
      <div class="col-6 background-3" v-if="portfolioVehicleInfo.drivers">
        <div class="d-flex justify-content-between pb-3 border-bottom mt-3">
          <span class="font-color-1">Total</span>
          <span class="font-color-4">{{portfolioVehicleInfo.drivers.total}}</span>
        </div>
        <div class="d-flex justify-content-between pb-3 border-bottom mt-3">
          <span class="font-color-1">Reported Today</span>
          <span class="font-color-4">{{portfolioVehicleInfo.drivers.reported_today}}</span>
        </div>
        <div class="d-flex justify-content-between pb-3 border-bottom mt-3">
          <span class="font-color-1">Reported Yesterday</span>
          <span class="font-color-4">{{portfolioVehicleInfo.drivers.reported_yesterday}}</span>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { onePeriodInOneDay } from '@/util/periodOptions';
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'PortfolioVehicleInfo',
  data() {
    return {
      periodsOptions: onePeriodInOneDay,
      filter: {
        mapType: 1,
        currentPeriodIndex: 4,
        date: [new Date(Date.now() - 48 * 60 * 60 * 1000), new Date()]
      }
    };
  },
  computed:{
    ...mapGetters('ForkliftStore/Portfolio/PortfolioVehicleInfo/',['portfolioVehicleInfo', 'loading'])
  },
  methods:{
    ...mapActions('ForkliftStore/Portfolio/PortfolioVehicleInfo/',[
      'fetchPortfolioVehicleInfo'
    ])
  },
  created(){
    this.fetchPortfolioVehicleInfo()
  }
}
</script>

<style>

</style>