<template>
  <card class="mb-2">
    <h3 slot="header" class="mb-2 header-title font-weight-bold">{{ $tc('search.filter', 2) }}</h3>
    <div class="row">
      <div class="col-md-2-5">
        <base-select
          :label="$tc('solutionWords.solution')"
          v-model="filter.solutionId"
          :filterable="true"
          :options="solutionsOptions"
        ></base-select>
      </div>
      <div class="col-md-2-5">
        <base-select
          :label="$tc('structureWords.structure')"
          v-model="filter.structureId"
          :options="structuresOptions"
          :filterable="true"
        ></base-select>
      </div>
      <div class="col-md-2-5">
        <base-select
          :label="$t('solutionWords.area')"
          v-model="filter.communityId"
          :filterable="true"
          :options="communitiesOptions"
        ></base-select>
      </div>
      <div class="col-md-2-5">
        <base-select
          :label="$t('city')"
          v-model="filter.cityId"
          :filterable="true"
          :options="citiesOptions"
        ></base-select>
      </div>
      <div class="col-md-2-5">
        <base-select
          :label="$t('acknowledgment')"
          v-model="filter.acknowledgement"
          :options="acknowledgementOptions"
        ></base-select>
      </div>
    </div>
    <slot name="extra" v-bind:filter="filter"></slot>
    <div
      slot="footer"
      class="d-flex mt-4 justify-content-between"
      style="width: 175px"
    >
      <base-button
        :disabled="!isChanged"
        type="info"
        size="sm"
        class="btn-apply font-size-16 font-weight-bold"
        @click="apply"
        >{{ $t("instructions.apply") }}</base-button
      >
      <base-button class="btn-reset font-size-16 font-weight-normal" :disabled="disableReset" size="sm" @click="reset">{{
        $t("instructions.reset")
      }}</base-button>
    </div>
  </card>
</template>

<script>
import { mapGetters } from "vuex";
import helper from "@/util/helper";
export default {
  props: {
    initialFilter: {
      description: "a filter to start the component with,",
    },
  },
  data() {
    return {
      old: null,
      filter: {},
    };
  },
  created() {
    this.$set(this.filter, "solutionId", this.initialFilter.solutionId);
    this.$set(this.filter, "structureId", this.initialFilter.structureId);
    this.$set(this.filter, "cityId", this.initialFilter.cityId);
    this.$set(this.filter, "communityId", this.initialFilter.communityId);
    this.$set(this.filter, "acknowledgement", this.initialFilter.acknowledgement);
    this.$set(this.filter, "status", this.initialFilter.status);
    this.apply();
  },
  computed: {
    ...mapGetters({
      solutionsOptions: "SwamStore/solutionsOptions",
      structuresOptions: "SwamStore/structuresOptions",
      communitiesOptions: "SwamStore/communitiesOptions",
      citiesOptions: "SwamStore/citiesOptions",
    }),
    isChanged() {
      return JSON.stringify(this.filter) != this.old;
    },
    disableReset() {
      return JSON.stringify(this.filter) == "{}";
    },
    acknowledgementOptions() {
      return [
        { label: "All" },
        { value: false, label: this.$t("notAcknowledged") },
        { value: true, label: this.$t("acknowledged") },
      ];
    },
  },
  methods: {
    reset() {
      this.filter.solutionId = undefined;
      this.filter.structureId = undefined;
      this.filter.cityId = undefined;
      this.filter.communityId = undefined;
      this.filter.acknowledgement = undefined;
      this.filter.status = undefined;
      this.filter.alarm_id = undefined;
      this.filter.device_id = undefined;
      this.filter.start = undefined;
      this.filter.end = undefined;
      this.filter.type = undefined;
      this.old = null;
      this.apply();
    },
    apply() {
      this.old = JSON.stringify(this.filter);
      this.$emit("filter", { ...this.filter });
    },
  },
  watch: {
    filter: {
      handler: helper.debounce(function (newVal) {
        if (this.isChanged) {
          const old = JSON.parse(this.old);

          this.filter.solutionId = old.solutionId;
          this.filter.structureId = old.structureId;
          this.filter.cityId = old.cityId;
          this.filter.communityId = old.communityId;
          this.filter.acknowledgement = old.acknowledgement;
          this.filter.status = old.status;
          this.filter.alarm_id = old.alarm_id;
          this.filter.device_id = old.device_id;
          this.filter.start = old.start;
          this.filter.end = old.end;
          this.filter.type = old.type;
        }
      }, 10000),
      deep: true,
    },
  },
};
</script>

<style scoped>
::v-deep .card-footer {
  padding-top: 0;
}
::v-deep .card-body {
  padding-bottom: 0;
}
.header-title {
  font-size: 26px;
  color: #27293D;
}
.btn-apply {
  background-color: #41B581;
  color: #fff;
}

.btn-reset {
  background-color: #707070;
  color: #fff;
}
</style>
