<template>
  <div class="">
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="customtableheader">
            <h4 slot="header" class="card-title">{{$t("Table.Structure")}}</h4>
            <router-link to="/structure/create">
              <el-tooltip
                content="Create Structure"
                effect="light"
                :open-delay="300"
                placement="top"
              >
                <base-button type="primary" round icon>
                  <i class="tim-icons icon-simple-add"></i>
                </base-button>
              </el-tooltip>
            </router-link>
          </div>

          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <Search :propsToSearch="propsToSearch" @input="(value)=>{filter = value}" />
            </div>

            <div class="striped-table">
              <el-table
              v-loading="$asyncComputed.structure_list.state === 'updating'"
              :data="structure_list"
            >
              <div
                slot="empty"
                v-if="$asyncComputed.structure_list.state === 'updating'"
              >Loading ...</div>
              <div slot="empty" v-if="$asyncComputed.structure_list.state === 'error'">Error</div>
              <div
                slot="empty"
                v-if="$asyncComputed.structure_list.state === 'success' && structure_list.length == 0"
              >No Data</div>
              <el-table-column sortable :min-width="135" align="left" :label="$t('ID')">
                <div slot-scope="props">
                  <span>{{$generateID(props.row.id,'STR')}}</span>
                </div>
              </el-table-column>
              <el-table-column
                sortable
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              ></el-table-column>

              <el-table-column :min-width="135" align="right" :label="$t('Actions')">
                <div slot-scope="props">
                  <el-tooltip content="View" effect="light" :open-delay="300" placement="top">
                    <base-button
                      @click.native="viewStr(props.row)"
                      class="like btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-notes"></i>
                    </base-button>
                  </el-tooltip>
                  <!-- <base-button
                    @click.native="handleEdit( props.row)"
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>-->
                  <el-tooltip content="Delete" effect="light" :open-delay="300" placement="top">
                    <base-button
                      @click.native="handleDelete(props.$index, props.row)"
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
            </div>
          </div>
          <TablePagination
            slot="footer"
            ref="paginator"
            v-if="total"
            :total="total"
            @change="updatePagination"
          ></TablePagination>
        </card>
      </div>
    </div>
    <!-- view -->
    <!-- <modal :show="true" @close="close" :modalClasses="'fullscreenmodal'">
    </modal>-->
    <!-- <el-dialog title="Structure Browser" :visible.sync="show">
      <MiniBrowser :deviceMode="true"/>
    </el-dialog>-->
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import swal from "sweetalert2";
import Search from "@/components/Search.vue";
import { Dialog } from "element-ui";
import TablePagination from "@/components/TablePagination.vue";

export default {
  components: {
    BasePagination,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Search,
    TablePagination,
    //  MiniBrowser
  },

  asyncComputed: {
    structure_list: {
      get() {
        if (this.filter) {
          if (this.filter.id) {
            this.filter.id = +this.filter.id;
          }
        }
        return (
          this.$api
            //.get(
            //    `structures/list/${this.limit}/${this.offset}`, this.filter)
            .post(`structures/${this.limit}/${this.offset}`, this.filter)
            .then((res) => {
              if (res.data.msg === "success") {
                this.total = res.data.total_records.count;
                return res.data.data;
              }
              return [];
            })
            .catch((err) => {
              console.log(err);
              return [];
            })
        );
      },
      default: [],
    },
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */


  },
  data() {
    return {
      show: true,
      selected_str: null,
      total: 0,
      offset: 0,
      limit: 10,
      searchQuery: "",
      propsToSearch: [
        { value: "structure_name", label: this.$i18n.t("Name") },
        { value: "structure_number", label: this.$i18n.t("Number") },
        { value: "address", label: this.$i18n.t("address") },
      ],

      tableColumns: [
        {
          prop: "structure_name",
          label: this.$i18n.t("Name"),
          minWidth: 200,
        },
        {
          prop: "structure_number",
          label: this.$i18n.t("Number"),
          minWidth: 200,
        },
        {
          prop: "floors",
          label: this.$i18n.t("floors"),
          minWidth: 100,
        },
        {
          prop: "parking",
          label: this.$i18n.t("Parking"),
          minWidth: 100,
        },
        {
          prop: "structure_type",
          label: this.$i18n.t("Structure Type"),
          minWidth: 150,
        },
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      filter: null,
    };
  },
  methods: {
    updatePagination(data) {
      this.limit = data.limit;
      this.offset = data.offset;
    },
    close() {
      this.selected_str = null;
    },
    viewStr(structure) {
      // this.selected_str = structure;
      this.$router.push({ path: `/structure/${structure.id}/browse` });
    },
    createadmin() {},
    handleLike(index, row) {
      swal({
        title: `You liked ${row.name}`,
        buttonsStyling: false,
        type: "success",
        confirmButtonClass: "btn btn-success btn-fill",
      });
    },
    handleEdit(row) {
      this.$router.push({ path: `/structure/update/${row.id}` });
    },
    handleDelete(index, row) {
      swal({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        console.log(result);
        if (result.value) {
          this.$api
            .delete(`structures/${row.id}`)
            .then((_) => {
              swal({
                title: "Deleted!",
                text: `You deleted ${row.name}`,
                type: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
              this.structure_list.splice(index, 1);
            })
            .catch((_) => {
              console.log(_);
            });
        }
      });
    },
    deleteRow(index, row) {},
  },
  watch:{
    filter()
    {
      if(this.$refs.paginator)
      {
        this.offset = 0;
        this.$refs.paginator.goTo(this.limit,this.offset);
      }
    }
  }
};
</script>
<style scoped>
::v-deep .el-dialog__body {
  padding-top: 0;
}
::v-deep .el-dialog {
  width: 70% !important;
  margin-top: 7vh !important;
}
</style>
