<template>
  <card class="mb-0" :headerClasses="'high-card-shadow pb-2'" :bodyClasses="'strc-info'">
    <el-page-header @back="back" slot="header" content="Space Profile"></el-page-header>
    <slot name="path"></slot>
    <div class="row p-2" v-if="space">
      <div class="col-md-4">
        <SingleInfoCard title="Name" :value="space.name" />
      </div>
      <div class="col-md-4">
        <SingleInfoCard title="Number" :value="space.number" />
      </div>
      <div class="col-md-4">
        <SingleInfoCard title="Type" :value="space.type.name" />
      </div>
      <div class="col-8">
        <SingleInfoCard title="Description" :value="space.description" />
      </div>

      <template v-if="selectedSpace.indoorSpaces&&selectedSpace.indoorSpaces.length && !selectedIndoorSpace">
        <el-divider
          slot="header"
        >Total Spaces Types</el-divider>
        <div v-for="(value,key) in spacesTypes" :key="key" class="col-6 col-md-4">
          <SingleInfoCard :title="key+'s'" :value="value" />
        </div>
      </template>
    </div>
  </card>
</template>

<script>
import { PageHeader } from "element-ui";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      spacesTypes: null
    };
  },
  computed: {
    ...mapGetters({
      selectedSpace: "selectedSpace",
      selectedIndoorSpace: "selectedIndoorSpace"
    }),
    space() {
      return this.selectedIndoorSpace
        ? this.selectedIndoorSpace
        : this.selectedSpace;
    }
  },
  mounted() {
    this.calc();
  },
  watch: {
    selectedSpace: {
      handler(newVal) {
        if (newVal) {
          this.calc();
        }
      }
    },
    selectedIndoorSpace: {
      handler(newVal) {
        if (newVal) {
          this.calc();
        }
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type == "updateSpaces") {
        this.calc();
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    back() {
      this.$store.dispatch("goBack");
    },
    calc() {
      if (this.selectedIndoorSpace) {
        this.spacesTypes = this.selectedIndoorSpace.spacesTypes;
      } else if (this.selectedSpace) {
        this.spacesTypes = this.selectedSpace.spacesTypes;
      }
    }
  },
  components: {
    [PageHeader.name]: PageHeader
  }
};
</script>

<style scoped>
</style>