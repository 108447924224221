 <template>
  <div :class="{ 'text-right': isRTL }">
    <card type="chart">
      <template slot="header">
        <div class="row">
          <div class="col-lg-6 d-flex">
            <span class="margin-end">{{ $t(title) }}</span>
            <info
              v-if="sensor.description"
              :information="{ ...sensor.description, name: sensor.name }"
            ></info>
          </div>

          <div class="col-lg-6 d-flex d-sm-block">
            <div
              class="btn-group btn-group-toggle"
              :class="isRTL ? 'float-left' : 'float-right'"
              data-toggle="buttons"
            >
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: chart === 'bar' }"
                @click="chart = 'bar'"
              >
                <input
                  type="radio"
                  name="options"
                  autocomplete="off"
                />
                <span class="d-none d-sm-block">{{ $t("bar") }}</span>
              </label>
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: chart === 'line' }"
                @click="chart = 'line'"
              >
                <input
                  type="radio"
                  name="options"
                  autocomplete="off"
                />
                <span class="d-none d-sm-block">{{ $t("line") }}</span>
              </label>
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: chart === 'area' }"
                @click="chart = 'area'"
              >
                <input
                  type="radio"
                  name="options"
                  autocomplete="off"
                />
                <span class="d-none d-sm-block">{{ $t("area") }}</span>
              </label>
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: chart === 'avg' }"
                @click="chart = 'avg'"
              >
                <input
                  type="radio"
                  name="options"
                  autocomplete="off"
                />
                <span class="d-none d-sm-block">{{ $t("avg") }}</span>
              </label>
            </div>
          </div>
        </div>
      </template>
      <div class="chart-area" style="direction: ltr !important">
        <CustomZingchart
          :height="'300px'"
          :id="getFileName"
          :values="currentChart.values"
          :data="currentChart.data"
        />
        <!-- <zingchart
          v-if="chart == 'line'"
          :data="chartConfig"
          ref="devicechart"
          :height="'300px'"
          :series="series"
        ></zingchart>

        <zingchart
          v-if="chart == 'bar'"
          :data="chartConfigBar"
          ref="devicechart"
          :height="'300px'"
          :series="series"
        ></zingchart>

        <zingchart
          v-if="chart == 'area'"
          :data="chartConfigArea"
          ref="devicechart"
          :height="'300px'"
          :series="series"
        ></zingchart>
        <zingchart
          v-if="chart == 'avg'"
          :data="newGauge"
          ref="devicechart"
          :height="'100px'"
        ></zingchart> -->
      </div>
      <template slot="footer">
        <div class="met-result">
          <div class="met-result-item">
            <span class="met-result-item-t">{{ $t("minimum") }}</span>
            <span class="met-result-item-b">{{ statistics.min }}</span>
          </div>
          <div class="met-result-item">
            <span class="met-result-item-t">{{ $t("maximum") }}</span>
            <span class="met-result-item-b">{{ statistics.max }}</span>
          </div>
          <div class="met-result-item">
            <span class="met-result-item-t">{{ $t("average") }}</span>
            <span class="met-result-item-b">{{ statistics.avg }}</span>
          </div>
          <div class="met-result-item">
            <span class="met-result-item-t">{{
              $t("sensorWords.lastReading")
            }}</span>
            <span class="met-result-item-b">{{ statistics.latest }}</span>
          </div>
        </div>
        <br />
        <div class="row" style="padding: 0px 25px; direction: ltr">
          <template v-for="(value, key) in ranges">
            <div
              class="t-b-1 div-head-state"
              :key="key"
              v-if="
                value[0].start_range != value[0].end_range ||
                (value[1] && value[1].start_range != value[1].end_range)
              "
            >
              <div align="right">
                <div class="row">
                  <div class="col p-1">
                    <span class="state-main">
                      <i
                        class="fas fa-circle fa-xs mr-1"
                        :class="getType(key)"
                      ></i>
                      {{ $t($WarningIndectorName(key)) }}
                    </span>
                    <template v-if="value[0].start_range != value[0].end_range">
                      <span>{{ value[0].start_range }}</span>
                      <span>-</span>
                      <span>{{ value[0].end_range }}</span>
                    </template>
                    <template
                      v-if="
                        value[1] && value[1].start_range != value[1].end_range
                      "
                    >
                      <span>{{ value[1].start_range }}</span>
                      <span>-</span>
                      <span>{{ value[1].end_range }}</span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </card>
  </div>
</template>
<script>
import { colors, getConfigGauge } from "src/util/helper.js";
import info from "../../components/InformationPopover.vue";
import CustomZingchart from "./Custom-Zingchart";
import { groupBy } from "../../util/helper";

export default {
  props: ["sensor"],
  data() {
    return {
      chart: "line",
      // area

      chartConfigArea: {
        type: "area",
        backgroundColor: "transparent",
        plot: {
          rules: this.setRules(),
          "background-color": colors.problem,
          tooltip: {
            text: this.sensor.unit_name
              ? `<div style="text-align: center; line-height: 14px; padding-top: 5px;">%kt<br><br><b>%t: %node-value ${this.sensor.unit_name}</b></div>`
              : `<div style="text-align: center; line-height: 14px; padding-top: 5px;">%kt<br><br><b>%t: %node-value</b></div>`,
            marginTop: "5px",
            padding: "5px 15px",
            fontFamily: "Roboto",
            htmlMode: true,
          },

          //activeArea: true,
          alphaArea: 1,
          aspect: "spline",
          marker: {
            borderColor: "#ffffff",
            shadow: false,
            size: "3px",
            "background-color": colors.problem,
            rules: this.setRules(),
          },
          shadow: false,
        },

        scaleX: {
          label: { text: this.$t("date"), color: "#7f8fa4" },

          item: {
            color: "#7f8fa4",
            fontFamily: "Roboto",
            offsetY: "2px",
            rules: [
              {
                offsetX: "20px",
              },
            ],
          },

          transform: {
            type: "date",
            all: "%d %M %Y <br> %H:%i",
          },
          zooming: true,
        },
        scaleY: {
          item: { color: "#7f8fa4" },
          offsetStart: "10%",
          offsetEnd: "10%",
          guide: {
            visible: false,
          },
          label: {
            text: this.$t(this.sensor.unit_name)
              ? `${this.$t(this.sensor.name)} (${this.sensor.unit_name})`
              : this.$t(this.sensor.name),
            color: "#7f8fa4",
          },
        },
        // set margins for labels around chart
        plotarea: {
          margin: "40 dynamic dynamic dynamic",
        },

        zoom: {
          preserveZoom: false, // saves state so appears to not reload
        },
        preview: {
          adjustLayout: true,
          //marginRight: '20px',
          //marginBottom: "5px",
          // marginLeft: '20px',
          alpha: 0.8,
          borderColor: "#aeaeae",
          borderWidth: "1px",
          handleTop: {
            borderColor: "#ccc",
            borderWidth: "1px",
          },
          handleRight: {
            borderColor: "#ccc",
            borderWidth: "1px",
          },
          handleBottom: {
            borderColor: "#ccc",
            borderWidth: "1px",
          },
          handleLeft: {
            borderColor: "#ccc",
            borderWidth: "1px",
          },
          height: "55px",
          //live: true,
          mask: {
            backgroundColor: "#c7c7c7",
          },
          //preserveZoom: true,
          label: {
            color: "#7f8fa4",
          },
        },
        series: [],
      },

      // line
      chartConfig: {
        backgroundColor: "transparent",
        //theme: "classic",

        type: "line",

        plot: {
          
          hoverMarker: {
            type: "circle",
            borderWidth: "1px",
            size: "4px",
          },
          lineWidth: "3px",
          marker: {
            rules: this.setRules(),
            type: "circle",
            size: "4px",
            backgroundColor: colors.problem,
          },
          shadow: false,
          //tooltipText: "%t : %v<br>"
        },
        tooltip: {
          text: this.sensor.unit_name
            ? `<div style="text-align: center; line-height: 14px; padding-top: 5px;">%kt<br><br><b>%t: %node-value ${this.sensor.unit_name}</b></div>`
            : `<div style="text-align: center; line-height: 14px; padding-top: 5px;">%kt<br><br><b>%t: %node-value</b></div>`,
          marginTop: "5px",
          padding: "5px 15px",
          fontFamily: "Roboto",
          htmlMode: true,
        },

        scaleX: {
          item: {
            color: "#7f8fa4",
          },
          // set scale label
          label: { text: this.$t("date"), color: "#7f8fa4" },
          zooming: true,
          // enable auto-date formatting
          transform: {
            type: "date",
            all: "%d %M %Y <br> %H:%i",
          },
          guide: {
            visible: false,
          },
        },
        // enable scrollbar
        scrollX: {},
        zoom: {
          preserveZoom: false, // saves state so appears to not reload
        },
        // enable click and drag window
        preview: {
          adjustLayout: true,
          label: {
            color: "#7f8fa4",
          },
        },
        scaleY: {
          item: { color: "#7f8fa4" },
          guide: {
            visible: false,
          },
          // scale label with unicode character
          label: {
            text: this.$t(this.sensor.unit_name)
              ? `${this.$t(this.sensor.name)} (${this.sensor.unit_name})`
              : this.$t(this.sensor.name),
            color: "#7f8fa4",
          },
          offsetStart: "10%",
          offsetEnd: "10%",
          // enable abbreviated units
          //short: true,
          // "min-value": this.sensor.min,
          // "max-value": this.sensor.max
        },
        // set margins for labels around chart
        plotarea: { margin: "40 dynamic dynamic dynamic" },

        series: [{}],
      },

      // bar
      chartConfigBar: {
        backgroundColor: "transparent",
        type: "bar",
        plot: {
          "bar-width": "15px",
          "border-width": 2,
          "border-color": "rgba(255, 255, 255, 0.1)",
          rules: this.setRules(),

          "background-color": colors.problem,
          hoverMarker: {
            type: "circle",
            borderWidth: "1px",
            size: "4px",
          },
          lineWidth: "3px",
          marker: {
            type: "circle",
            size: "3px",
          },
          shadow: false,
          //tooltipText: "%t : %v"
        },
        tooltip: {
          text: this.sensor.unit_name
            ? `<div style="text-align: center; line-height: 14px; padding-top: 5px;">%kt<br><br><b>%t: %node-value ${this.sensor.unit_name}</b></div>`
            : `<div style="text-align: center; line-height: 14px; padding-top: 5px;">%kt<br><br><b>%t: %node-value</b></div>`,
          marginTop: "5px",
          padding: "5px 15px",
          fontFamily: "Roboto",
          htmlMode: true,
          backgroundColor: "#7f8fa4",
        },

        scaleX: {
          item: { color: "#7f8fa4" },
          // set scale label
          label: { text: this.$t("date"), color: "#7f8fa4" },
          zooming: true,
          // enable auto-date formatting
          transform: {
            type: "date",
            all: "%d %M %Y <br> %H:%i",
          },
        },
        zoom: {
          preserveZoom: false, // saves state so appears to not reload
        },
        // enable scrollbar
        scrollX: {},
        // enable click and drag window
        preview: {
          adjustLayout: true,
          label: {
            color: "#7f8fa4",
          },
        },
        scaleY: {
          item: { color: "#7f8fa4" },
          offsetStart: "10%",
          offsetEnd: "10%",
          guide: {
            visible: false,
          },
          // scale label with unicode character
          label: {
            text: this.$t(this.sensor.unit_name)
              ? `${this.$t(this.sensor.name)} (${this.sensor.unit_name})`
              : this.$t(this.sensor.name),
            color: "#7f8fa4",
          },
        },
        // set margins for labels around chart
        plotarea: { margin: "40 dynamic dynamic dynamic" },

        series: [],
      },
    };
  },

  computed: {
    getFileName() {
      const d = this.$store.state.devicesstore.selected_device;
      if (d)
        return (
          d.structure +
          " " +
          d.device_name +
          " " +
          this.sensor.name
        ).replace(/ /g, "-");
      return "device-sensor-data";
    },
    currentChart() {
      if (this.chart == "line") {
        // eslint-disable-next-line
        this.chartConfig.series = this.series;
        return {
          values: this.series,
          data: this.chartConfig,
        };
      } else if (this.chart == "area") {
        // eslint-disable-next-line
        this.chartConfigArea.series = this.series;
        return {
          values: this.series,
          data: this.chartConfigArea,
        };
      } else if (this.chart == "bar") {
        // eslint-disable-next-line
        this.chartConfigBar.series = this.series;
        return {
          values: this.series,
          data: this.chartConfigBar,
        };
      } else {
        return {
          data: this.newGauge,
          values: [
            this.statistics.min,
            this.statistics.max,
            this.statistics.avg,
          ], //useless
        };
      }
    },
    newGauge() {
      let newRanges = [];

      let name = this.sensor.name;
      let selected_device = this.$store.state.devicesstore.selected_device;

      let template_sensors = selected_device.template_sensors;
      let template = template_sensors.find((temp) => temp.name === name);
      if (template) {
        newRanges = template.ranges;
      }
      console.log("the statistics is ");
      console.log(this.statistics);
      console.log(newRanges);
      return getConfigGauge(newRanges, this.statistics);
    },
    title() {
      return this.sensor.name;
    },
    solution() {
      let selected_device = this.$store.state.devicesstore.selected_device;
      return selected_device.solution_name;
    },
    description() {
      let result = "";
      let solution = this.solution;
      let name = this.title.toLowerCase();

      let solution_info = sensorInfo[solution];
      if (solution_info) {
        if (solution_info[name]) {
          result = solution_info[name];
        }
      }

      return result;
    },
    ranges() {
      let selected_device = this.$store.state.devicesstore.selected_device;
      let result = [];
      if (selected_device) {
        let snr = selected_device.template_sensors.find(
          (snr) => snr.type == this.sensor.id
        );
        if (snr) {
          result = groupBy(snr.ranges, "status");
        }
      }
      return result;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    values() {
      let _values = this.$store.state.devicesstore.sortedValues;
      if (!_values[this.sensor.id]) {
        return [];
      }
      _values = _values[this.sensor.id].values
        .map((sensorRead, index) => {
          if (!_values.dates[index]) {
            return null;
          }
          let time = new Date(_values.dates[index]).getTime();

          if (typeof sensorRead == "number") {
            return [time, sensorRead];
          }
          return null;
        })
        .filter((v) => v != null);

      return _values.sort((x,y)=>x[0]-y[0]);
    },

    series() {
      return [
        {
          text: this.$t(this.sensor.name),
          values: this.values,
          lineColor: "#7f8fa4",
          //backgroundColor: colors.problem,
        },
      ];
    },

    statistics() {
      let min = Number.POSITIVE_INFINITY,
        max = Number.NEGATIVE_INFINITY,
        sum = 0;
      this.values.forEach((rec) => {
        const value = rec[1];
        sum += value;
        if (value < min) {
          min = value;
        }
        if (value > max) {
          max = value;
        }
      });
      const latest =
        typeof this.values[this.values.length - 1] == "object"
          ? this.round(this.values[this.values.length - 1][1])
          : null;
      return {
        min: +this.round(min),
        max: +this.round(max),
        latest,
        avg: +this.round(sum / this.values.length),
      };
    },
    avgSeries() {
      return [
        {
          values: [this.statistics.avg],
          scales: "scale-2,scale-r-2,scale,scale-r",
          valueBox: {
            text: "%v",
            fontColor: "#7f8fa4",
            fontSize: "20px",
            offsetY: "80px",
            placement: "center",
          },
          backgroundColor: "#F8B237",
        },
      ];
    },
  },
  mounted() {
    // console.log(this.sensor.description);
    try {
      this.sensor.description = JSON.parse(this.sensor.description);
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    round(val) {
      return (Math.round(val * 100) / 100).toFixed(2);
    },
    getchart() {
      return this.$refs.devicechart;
    },
    setRules() {
      let rules = [];
      let name = this.sensor.name;
      let selected_device = this.$store.state.devicesstore.selected_device;

      let template_sensors = selected_device.template_sensors;
      let template = template_sensors.find((temp) => temp.name === name);
      if (template) {
        let ranges = template.ranges;

        for (var i = 0; i < ranges.length; i++) {
          let range = ranges[i];
          let start = range.start_range;
          let end = range.end_range;
          let rule = "";

          if (range.status == "good") {
            rule = "%v >= " + start + " && " + "%v <= " + end;
          } else {
            if (range.inclusive == "left") {
              rule = "(%v >= " + start + " && " + "%v < " + end + ")";
            } else {
              rule = "%v > " + start + " && " + "%v <= " + end;
            }
          }
          if (start != end) {
            rules.push({
              rule,
              "background-color": colors[range.status],
            });
          }
        }
      }

      return rules;
    },

    getType(type) {
      if (type == "problem") return "circle-problem";
      else if (type == "ok") return "circle-ok";
      else if (type == "good") return "circle-good";
      else {
        return "circle-poor";
      }
    },
  },
  components: {
    [info.name]: info,
    CustomZingchart,
  },
};
</script>


<style scoped>
.info-icon {
  cursor: pointer;
}
.t-b-1 span {
  margin: 0 3px;
  font-size: 10px;
}
.good > span:nth-child(1) {
  padding: 2px 5px;
  border-radius: 5px;
}
.ok > span:nth-child(1) {
  padding: 2px 5px;
  border-radius: 5px;
}
.poor > span:nth-child(1) {
  padding: 2px 5px;
  border-radius: 5px;
}
.problem > span:nth-child(1) {
  padding: 2px 5px;
  border-radius: 5px;
}

body:not([class*="white-content"]) .state-main {
  color: white;
}
.state-main {
  font-size: 11px !important;
  width: 82px;
  display: inline-block;
}
body[class*="white-content"] .state-main ~ span {
  color: rgb(25, 28, 37);
}
.div-head-state {
  display: block;
}
[dir="rtl"] .met-result-item {
  padding-left: unset;
  border-left: unset;
  padding-right: 10px;
  border-right: 1px solid #7f8fa4;
}
</style>
<style >
.sensor-popover {
  max-width: 500px;
  width: 500px;
}
.sensor-info h4,
.sensor-info p,
.sensor-info li {
  color: #000 !important;
}
.sensor-info {
  padding: 10px;
}
.margin-end {
  margin-left: 10px;
  margin-inline-end: 10px;
}
</style>
