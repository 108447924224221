<template>
  <div>
    
    <card class="tel-user-devices tel-widget">
      <div slot="header"><h4 class="card-title">Users</h4></div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="device_list">

            <el-table-column min-width="50" label="#" align="center">
              <div class="tel-photo" slot-scope="props"> 
                <img src="@/assets/Icons/gps.png" alt="Table image" style="cursor: pointer;" @click="setDevice(props.row)"/>
                
                
              </div>
            </el-table-column>
            <el-table-column prop="device_name" sortable min-width="200" label="Name" align="center">
              
              <button
              slot-scope="props"
              @click="setDevice(props.row)"
              class="btn btn-simple btn-primary btn-sm w-100 mt-1"
            >
              <span>{{props.row.device_name}}</span>
            </button>
            </el-table-column>
            <el-table-column
                
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              ></el-table-column>

              <el-table-column :min-width="100" align="left" :label="$t('Date')">
                <div slot-scope="props">{{gDateFormat(props.row.created_at)}}</div>
              </el-table-column>
            


           
            <el-table-column
              min-width="150"
              header-align="right"
              align="right"
              label="Actions"
            >
              <div
                slot-scope="{ row }"
                class="text-right table-actions"
              >
                <el-tooltip
                  content="set zone"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <img src="@/assets/Icons/location_zone.svg" @click="setDeviceZones(row)" />
                </el-tooltip>
                
              </div>
            </el-table-column>
          </el-table>
        </div>
        <!-- pagination -->
        <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">Showing {{ from }} to {{ to }} of {{ total }} entries</p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
      </div>


    </card>

    

    <!-- here -->
    
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
//import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import Search from "@/components/Search.vue";
import { mapState,mapActions } from "vuex";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Search,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    // ...mapState("TeltonikaStore", {
    //   device_list: state => state.devices,
    //   pagination: state => state.pagination
    // }),

    // currentPage: {
    //   get() {
    //     return this.pagination.currentPage;
    //   },
    //   set(value) {
    //     this.$store.dispatch("TeltonikaStore/setPage", value);
    //   }
    // },

    to() {
      return this.pagination.perPage * this.pagination.currentPage;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1) + 1;
    },

    
  },
  data() {
    return {
      device_list:[],
      total: 0,
      pagination: {
            perPage: 3,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            
          },
      
      searchQuery: "",
      propsToSearch: [{ value: "name", label: this.$i18n.t("Name") }],

      tableColumns: [
        // {
        //   prop: "device_name",
        //   label: this.$i18n.t("Name"),
        //   minWidth: 200
        // },
        {
          prop: "customer",
          label: this.$i18n.t("Customer"),
          minWidth: 100
        },
        {
          prop: "fmc",
          label: this.$i18n.t("FMC"),
          minWidth: 200
        },

        {
          prop: "structure",
          label: this.$i18n.t("Structure"),
          minWidth: 200
        }
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      filter: null
    };
  },

  watch:{
    pagination:{
      handler(){
        this.fetchDevies();
      },
      deep:true
    }
  },
  mounted() {

    this.fetchDevies();
    
  },
  methods: {
    fetchDevies(){
        let {perPage,currentPage } = this.pagination;
        let limit = perPage;
        let offset = perPage * (currentPage - 1);

            this.$api.get(`teltonika/devices/list?limit=${limit}&offset=${offset}&type=healthcare`).then(res=>{
                if(res.status === 200){
                    this.device_list = res.data.data;
                    this.total = res.data.total_records;
                }
            }).catch(err=>{console.log(err)})
    },
    // ...mapActions('TeltonikaStore',[
    //     'setSelectedDevice'
    // ]),
    setDevice(device){
        this.$router.push({path:`deviceinfo/${device.id}`});

    },
    setDeviceZones(device){
      //this.$router.push({path:`devicezone/${device.id}`})
    }
  }
};
</script>
<style >
.checkbox-cell {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .tel-photo{
  width:40px;
  height: 40px;
}
.tel-photo img{
  max-width: 60%;
} */
.tel-user-devices img{
  max-width: 28px;
}
</style>
