<template>
<div class="striped-table">
  <CustomTable
    :columns="columns"
    :table-key="tableKey"
    @config-change="configChangeHandler"
    @reset="resetHandler"
  >
    <el-table
      :data="data"
      highlight-current-row
      ref="dragToScroll"
    >
        <template v-for="(column, index) in columns.filter(e => e.visible)">
          <el-table-column
            :key="index"
            v-bind="column"
            v-if="column.customTemplate"
          >
            <template slot-scope="{ row }">
              <template v-if="column.prop=='device.id'">
                <router-link :to="`/device/reading/${row.device.id}`" class="font-weight-bold">
                  {{ row.device.device_name }}
                </router-link>
              </template>
              <template v-if="column.prop=='status'">
                <div class="d-flex align-items-center">
                  <sensor-icon
                    :msg="row.info"
                    :name="row.sensor.type_name"
                    :status="row.status"
                  />
                  <div>
                    <div>
                      {{ row.sensor.type_name }}
                    </div>
                    <div class="d-flex">
                      <label class="sensor-status">{{ $t(row.status) }}</label>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="column.prop=='acknowledgement.created_at'">
                <p class="m-0">
                  {{ row.acknowledgement ? row.acknowledgement.email : "--" }}
                </p>
                <span v-if="row.acknowledgement" style="font-size: 0.7rem">
                  {{ gDateFormat(row.acknowledgement.created_at) }}
                </span>
              </template>
            </template>
          </el-table-column>
          <el-table-column 
            v-else
            :key="index"
            v-bind="column"
          />
        </template>
      <OperationsColumn
        v-if="data.length"
        @open-comments="openComments"
        @change-ack-state="changeAckState"
        @escalate="escalate"
      ></OperationsColumn>
    </el-table>
  </CustomTable>
</div>
</template>

<script>
import OperationsColumn from "@/pages/SWAM/components/AlarmsComponents/OperationsColumn";
import SensorIcon from "@/components/SensorIcon.vue";
import dragToScroll from "@/pages/SWAM/components/AlarmsComponents/drag-to-scroll";
import { mapGetters } from "vuex";
import { USER_PREFERENCES_KEYS } from "@/global/user-preferences-store/keys";
import CustomTable from "@/components/CustomTable.vue";

export default {
  props: {
    data: {
      required: true,
    },
  },
  mounted() {
    let element = this.$refs.dragToScroll.$el;
    element = element.querySelector(".el-table__body-wrapper");

    dragToScroll(element);
  },
  data() {
    return {
      columns: [],
      tableKey: USER_PREFERENCES_KEYS.SWAM_OPERATIONAL_ALARMS_TABLE_CONFIGURATIONS,
    }
  },
  computed: {
    ...mapGetters("SwamStore/OperationalAlarms", {
      initColumns: "columns"
    }),
  },
  created() {
    this.resetHandler();
  },
  methods: {
    openComments(row) {
      this.$emit("open-comments", row);
    },
    changeAckState(row) {
      this.$emit("change-ack-state", row);
    },
    escalate(row) {
      this.$emit("escalate", row);
    },
    configChangeHandler(configurations) {
      this.columns = configurations;
    },
    resetHandler() {
      this.columns = this.initColumns.map(e => ({ ...e }));
    }
  },
  components: {
    SensorIcon,
    OperationsColumn,
    CustomTable
  },
};
</script>

<style scoped>
.device-name-button {
  color: #27293d;
  font-size: 0.9rem;
}
.sensor-status {
  font-size: 0.75rem;
  color: #ffffff7e;
  line-height: 1;
  padding-left: 3px;
}
</style>
