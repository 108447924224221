<template>
        <zingchart
          :data="chartConfig"
          
          
        ></zingchart>
</template>
<script>
export default {
  data() {
    return {
      chartConfig: {
        type: "bar",
        backgroundColor: "transparent",
        globals: {
          fontSize: "14px",
        },
        title: {
          text: "Daily Work Productivity",
          paddingLeft: "50px",
          fontSize: "24px",
          textAlign: "left",
          // will force margin and padding on title
          // adjustLayout: true
        },
        legend: {
          cursor: "hand",
          draggable: true,
          dragHandler: "icon",
          toggleAction: "remove",
          minimize: true,
          header: {
            height: "20px",
            text: "Daily Activities",
          },
        },
        plotarea: {
          margin: "50px dynamic dynamic dynamic",
        },
        // plot represents general series, or plots, styling
        plot: {
          stacked: true,
          // hoverstate
          tooltip: {
            visible: false,
          },
          // animation docs here:
          // https://www.zingchart.com/docs/tutorials/design-and-styling/chart-animation/#animation__effect
          animation: {
            effect: "ANIMATION_SLIDE_TOP",
            method: "ANIMATION_BOUNCE_EASE_OUT",
            sequence: "ANIMATION_NO_SEQUENCE",
            speed: 975,
          },
          lineWidth: "3px",
          // line node styling
          marker: {
            borderWidth: "0px",
            size: "6px",
          },
        },
        scaleX: {
          // set scale label
          label: {
            text: "Days",
          },
          // convert text on scale indices
          labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        scaleY: {
          // scale label with unicode character
          label: {
            text: "Work Productivity",
          },
          format: "%v%",
        },
        crosshairX: {
          plotLabel: {
            padding: "10px 15px",
            borderRadius: "3px",
            sortByValue: "asc",
          },
          lineWidth: "100%",
          alpha: 0.28,
        },
        series: [
          {
            text: "Working",
            // plot values
            values: [20, 30, 50, 50, 40, 0, 0],
            backgroundColor: "#03a9f4",
          },
          {
            text: "Finding a New Zoom Filter",
            // plot values
            values: [60, 30, 0, 20, 0, 0, 0],
            backgroundColor: "#ff9800",
          },
          {
            text: "Feeding my Kids",
            // plot values
            values: [20, 30, 30, 20, 30, 10, 20],
            backgroundColor: "#4caf50",
          },
          {
            text: "Drinking Alcohol",
            // plot values
            values: [0, 10, 20, 10, 30, 90, 80],
            backgroundColor: "#ff5722",
          },
        ],
      },
    };
  },
};
</script>
<style lang="stylus" scoped></style>