<template>
  <card class="h-100 tel-widget">
    <div class="d-flex mr-2 ml-2 justify-content-between">
      
        <h2 class="m-0">Atendence</h2>
       
        <img src="@/assets/Icons/attendance.svg" style="max-width:40px" />
      
    </div>
    

    <div class="d-flex justify-content-between mt-3 mr-2 ml-2">
      <span><img src="@/assets/Icons/attendance.svg" style="max-width:20px" class="mr-2" /> How many people are there</span>
      <span class=" border d-block text-center  rounded num">3</span>
    </div>
    <div class="d-flex justify-content-between mt-3 mr-2 ml-2">
      <span><img src="@/assets/Icons/Subtraction 9.svg" style="max-width:20px" class="mr-2" /> How many people are not there</span>
      <span class=" border d-block text-center  rounded num">3</span>
    </div>
    <div class="d-flex justify-content-between mt-3 mr-2 ml-2">
      <span><img src="@/assets/Icons/Subtraction-11.svg" style="max-width:20px" class="mr-2" /> How many people are there</span>
      <span class=" border d-block text-center  rounded num">3</span>
    </div>
    <div class="d-flex justify-content-between mt-3 mr-2 ml-2">
      <span><img src="@/assets/Icons/Subtraction-14.svg" style="max-width:20px" class="mr-2" /> How many people are there</span>
      <span class=" border d-block text-center  rounded num">3</span>
    </div>
   
  </card>
</template>
<script>
export default {};
</script>
<style scoped>

</style>