<template>
  <card class="h-100">
    <div class="row align-items-center">
      <div class="col-md-2">
        <h4>Driver Score</h4>
      </div>
      <div class="col-lg-3 col-md-4">
        <base-select class="mw-50"  v-model="duration" :options="durationOptions"></base-select>
      </div>
      <div class="col-lg-3 col-md-4">
        <base-select class="mw-50"  v-model="duration" :options="durationOptions"></base-select>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <ScoreChart />
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="row">
            <!--  -->
          <div class="col-lg-6 col-md-6">
            <ScorePie />
          </div>
          <!--  -->
          <div class="col-lg-6 col-md-6">
            <div class="mt-5 border-left pl-3">
              <h4>Drivers Details</h4>
              <!-- poor -->
              <div class="row">
                <div class="col-6">
                  <h4>
                    <i class="fas fa-circle fa-xs mr-2" :style="{color:colors.problem}"></i> poor
                  </h4>
                </div>
                <div class="col-6">
                  <h4 class="ml-3">10</h4>
                </div>
              </div>
              <!-- avg -->
              <div class="row">
                <div class="col-6">
                  <h4>
                    <i class="fas fa-circle fa-xs mr-2" :style="{color:colors.poor}"></i> Avarege
                  </h4>
                </div>
                <div class="col-6">
                  <h4 class="ml-3">35</h4>
                </div>
              </div>
              <!-- good -->
              <div class="row">
                <div class="col-6">
                  <h4>
                    <i class="fas fa-circle fa-xs mr-2" :style="{color:colors.ok}"></i> Good
                  </h4>
                </div>
                <div class="col-6">
                  <h4 class="ml-3">60</h4>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <h4>
                    <i class="fas fa-circle fa-xs mr-2" :style="{color:colors.good}"></i> Excellent
                  </h4>
                </div>
                <div class="col-6">
                  <h4 class="ml-3">85</h4>
                </div>
              </div>

            </div>
          </div>
          <!--  -->
        </div>
      </div>
    </div>
  </card>
</template>
<script>
import ScoreChart from "@/components/Charts/ScoreChart.vue";
import ScorePie from "@/components/Charts/ScorePie.vue";
import {colors} from 'src/util/helper.js';
export default {
  components: {
    ScoreChart,
    ScorePie,
  },
  data(){
    return{
      colors,
      duration: 1,
      durationOptions: [
        {
          value: 1,
          label: "This Month",
        },
      ],
    }
  }
};
</script>
<style scoped>
</style>