<template>
  <el-dialog title="Create Scheduled Access" width="900px" :visible.sync="show" custom-class="lock-quick-access">
    <div class="d-flex align-items-center justify-content-between border-bottom pb-2 mb-2">
      <div class="d-flex align-items-center">
        <inline-svg
          :src="require('@/assets/Icons/forklift/noun_Forklift.svg')"
          width="25"
          height="25"
        />
        <h4 class="ml-2 mb-0 font-color-1 font-weight-bold">Vehicle <b>{{forkliftID}}</b> Repair History</h4>
      </div>
      <div class="d-flex align-items-center">
        <span class="font-color-4 font-weight-bold">{{total}} Repaired</span>
      </div>
    </div>
    <div class="row pb-3">
      <div class="col-12" >
        <el-table :data="data.result" max-height="300px" v-loading="loadingHistory"> 
          <el-table-column prop="device_name" label="Name" width="100" />
          <el-table-column
            label="Date of entry"
            width="250">
            <template slot-scope="scope">
              <div class="d-flex align-items-center">
                <span>{{ scope.row.created_at ? dateFormat(scope.row.created_at) : "no date" }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Checkout date"
            width="250">
            <template slot-scope="scope">
              <div class="d-flex align-items-center">
                <span>{{ scope.row.ended_at ? dateFormat(scope.row.ended_at) : "no date" }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <TablePagination
          class="mt-3"
          slot="footer"
          ref="paginator"
          v-if="repairHistoryTotal"
          :total="repairHistoryTotal"
          @change="updatePagination"
        ></TablePagination>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import TablePagination from "@/components/TablePagination.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { dateFormat } from "@/util/helper"

export default {
  name: 'VehicleRepairInventory',
  components:{
    TablePagination
  },
  props:{
    data:{
      default: () => {}
    },
    forkliftID:{},
    isOpen: {
        type: Boolean
    },
    total: {}
  },
  data(){
    return {
      pagination: {
        offset: 0,
        limit: 5,
      },
    }
  },
  computed:{
    ...mapGetters('ForkliftStore/Portfolio/VehicleRepairInventory', [
      'loadingHistory',
      'repairHistoryTotal'
    ]),
    show:{
        get(){
          return this.isOpen;
        },
        set(val){
          this.$emit('close')
        }
    }
  },
  methods:{
    close(){
      this.$emit('close')
    },
    ...mapMutations('ForkliftStore/Portfolio/VehicleRepairInventory', [
      'SET_REPAIRHISTORY'
    ]),
    fetchData(){
      const payload = {
        id: this.forkliftID,
        limit: this.pagination.limit,
        offset: this.pagination.offset,
      }
      this.fetchRepairHistoryData(payload);
    },
    updatePagination(data) {
      this.pagination.limit = data.limit;
      this.pagination.offset = data.offset;
      this.fetchData()
    },
    ...mapActions('ForkliftStore/Portfolio/VehicleRepairInventory', [
      'fetchRepairHistoryData'
    ]),
    // fetchData(){
    //   const payload ={
    //     id: this.vehicleID,
    //     limit: this.pagination.limit,
    //     offset: this.pagination.offset
    //   }
    //   this.fetchRepairHistoryData(payload)
    // },
    dateFormat
  },
  created(){
    // this.fetchData()
  }
}
</script>

<style lang="scss" scoped>
.history-popup{
  height: 400px !important
}
</style>