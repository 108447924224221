import { render, staticRenderFns } from "./ComparisionOfinteractions.vue?vue&type=template&id=f77f8e1a&scoped=true&"
import script from "./ComparisionOfinteractions.vue?vue&type=script&lang=js&"
export * from "./ComparisionOfinteractions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f77f8e1a",
  null
  
)

export default component.exports