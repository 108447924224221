<template>
  <div class>
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="customtableheader">
            <h4 slot="header" class="card-title">{{$t("Table.Template")}}</h4>

            <router-link to="/template/create">
              <el-tooltip
                content="Create Template"
                effect="light"
                :open-delay="300"
                placement="top"
              >
                <base-button type="primary" round icon>
                  <i class="tim-icons icon-simple-add"></i>
                </base-button>
              </el-tooltip>
            </router-link>
          </div>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <!-- <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                ></el-input>
              </base-input>-->
              <Search :propsToSearch="propsToSearch" @input="(value)=>{filter = value}" />
            </div>
            <div class="striped-table">
              <el-table :data="template_list">
                <div
                  slot="empty"
                  v-if="$asyncComputed.template_list.state === 'updating'"
                >Loading ...</div>
                <div slot="empty" v-if="$asyncComputed.template_list.state === 'error'">Error</div>
                <div
                  slot="empty"
                  v-if="$asyncComputed.template_list.state === 'success' && template_list.length == 0"
                >No Data</div>
                <el-table-column
                  sortable
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="$tc(column.label)"
                ></el-table-column>

                <el-table-column sortable :min-width="135" align="right" :label="$t('Type')">
                  <div slot-scope="props">{{caetgory_name(props.row.category_id)}}</div>
                </el-table-column>

                <el-table-column :min-width="135" align="right" :label="$tc('report.action', 2)">
                  <div slot-scope="props">
                    <el-tooltip content="View" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="viewTemplate( props.row)"
                        class="like btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-notes"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="Edit" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="handleEdit(props.row)"
                        class="edit btn-link"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-pencil"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="Delete" effect="light" :open-delay="300" placement="top">
                      <base-button
                        @click.native="handleDelete(props.$index, props.row)"
                        class="remove btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-simple-remove"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <TablePagination
            slot="footer"
            ref="paginator"
            v-if="total"
            :total="total"
            @change="updatePagination"
          ></TablePagination>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
//import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import Search from "@/components/Search.vue";
import TablePagination from "@/components/TablePagination.vue";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Search,
    TablePagination
  },

  asyncComputed: {
    template_list: {
      get() {
        if (this.filter) {
          if (this.filter.id) {
            this.filter.id = +this.filter.id;
          }
        }
        return this.$api
          .post(`template/${this.limit}/${this.offset}`, this.filter)
          .then(res => {
            if (res.data.msg === "success") {
              this.total = res.data.total_records.count;
              return res.data.data;
            }
            return [];
          })
          .catch(err => {
            console.log(err);
          });
      },
      default: []
    }
  },

  data() {
    return {
      showModal: false,
      selected_template: null,
      total: 0,
      offset: 0,
      limit: 10,
      searchQuery: "",
      propsToSearch: [{ value: "name", label: this.$i18n.t("Name") }],

      tableColumns: [
        {
          prop: "id",
          label: "id",
          minWidth: 75
        },
        {
          prop: "name",
          label: "name",
          minWidth: 200
        },
        {
          prop: "vendor",
          label: "deviceWords.vendor",
          minWidth: 100
        },
        {
          prop: "solution",
          label: "solutionWords.solution",
          minWidth: 200
        },
        {
          prop: "communication",
          label: "deviceWords.communicationType",
          minWidth: 150
        }
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      filter: null
    };
  },
  methods: {
    updatePagination(data) {
      this.limit = data.limit;
      this.offset = data.offset;
    },
    caetgory_name(id) {
      let devices_categories = this.$store.state.device_categories;
      let category = devices_categories.find(cat => cat.id == id);
      if (category) {
        return category.name;
      }
      return "";
    },

    close() {
      this.showModal = false;
      this.selected_template = null;
    },
    viewTemplate(template) {
      //this.selected_template = template;
      //this.showModal = true;
      this.$router.push({ path: `view/${template.id}` });
    },
    handleEdit(row) {
      this.$router.push({
        path: `/template/update/${row.id}/${row.category_id}`
      });
    },
    handleDelete(index, row) {
      swal({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: "Deleted!",
            text: `You deleted ${row.name}`,
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },
  watch: {
    filter() {
      if (this.$refs.paginator) {
        this.offset = 0;
        this.$refs.paginator.goTo(this.limit, this.offset);
      }
    }
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
