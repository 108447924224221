<template>
  <span>
    <span class="point mx-2" :class="state"></span>
    <span class="font-color-5">{{ label }}</span>
  </span>
</template>

<script>
export default {
  props: {
    state: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    }
  },
};
</script>

<style scoped>

.state {
  text-transform: capitalize !important;
}

.point{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

</style>
