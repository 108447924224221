<template>
  <card>
    <template slot="header">
      <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
        <div class="d-flex align-items-center">
          <inline-svg :src="require('@/assets/Icons/forklift/noun_signal.svg')" width="25" height="25" />
          <h5 class="ml-2 font-color-1">GNSS</h5>
        </div>
        <div>
          <span class="font-color-4">Status&nbsp;</span>
          <span class="good font-color-4 font-weight-bold">{{ data.gnss_status }}</span>
        </div>
      </div>
    </template>
    <div class="row mb-4 font-color-1">
      <div class="col-12 d-flex justify-content-between">
        <span class="d-flex align-items-center">
          <span>GNSS PDOP Signal</span>
          <span class="ml-2 font-color-1">
            {{ data.gnss_pdop }}
          </span>
        </span>
        <span class="d-flex align-items-center">
          <span>GNSS HDOP Signal</span>
          <span class="ml-2 font-color-1">
            {{ data.gnss_hdop }}
          </span>
        </span>
      </div>
    </div>
    <div class="row font-weight-bold font-color-1">
      <div class="col-12 d-flex align-items-center">
        <span class="mr-2">GNSS Signal</span>
        <base-progress style="flex: 1 auto" :value="data.gsm_signal" status="success" valuePosition="right" />
      </div>
    </div>
  </card>
</template>

<script>
import BaseProgress from '@/components/BaseProgress';

export default {
  name: 'GNSS',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BaseProgress
  }
}
</script>

<style scoped>
.point {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
</style>