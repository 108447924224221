<template>
  <beautiful-chat
    style="z-index: 10"
    :participants="users"
    :onMessageWasSent="
      (msg) => {
        $emit('send-msg', msg);
      }
    "
    :messageList="messageList"
    :isOpen="isChatOpen"
    :close="() => $emit('close', true)"
    :icons="icons"
    :open="openChat"
    :showLauncher="false"
    :showCloseButton="true"
    :alwaysScrollToBottom="false"
    :messageStyling="true"
    :colors="colors"
    :placeholder="$t('instructions.writeMessage')"
  >
    <p
      slot="header"
      class="my-auto mx-4"
      style="color: white; font-size: 1.5rem; line-height: 2"
    >
      {{ $t('deviceWords.alarmsEvents') }}
    </p>
  </beautiful-chat>
</template>

<script>
import CloseIcon from "@/assets/Icons/chat/close-icon.png";
import OpenIcon from "@/assets/Icons/chat/logo-no-bg.svg";
import FileIcon from "@/assets/Icons/chat/file.svg";
import CloseIconSvg from "@/assets/Icons/chat/close.svg";

export default {
  props: {
    users: {
      default() {
        return [
          {
            id: "user1",
            name: "Matteo",
            imageUrl:
              "https://avatars3.githubusercontent.com/u/1915989?s=230&v=4",
          },
          {
            id: "user2",
            name: "Support",
            imageUrl:
              "https://avatars3.githubusercontent.com/u/37018832?s=200&v=4",
          },
        ];
      },
    },
    messageList: {
      default() {
        return [
          { type: "text", author: `me`, data: { text: `Say yes!` } },
          { type: "text", author: `user1`, data: { text: `Nooo.` } },
        ];
      },
    },
    isChatOpen: {
      default: true,
    },
  },
  data() {
    return {
      icons: {
        open: {
          img: OpenIcon,
          name: "default",
        },
        close: {
          img: CloseIcon,
          name: "default",
        },
        file: {
          img: FileIcon,
          name: "default",
        },
        closeSvg: {
          img: CloseIconSvg,
          name: "default",
        },
      },
    };
  },
  computed: {
    colors() {
      return {
        header: {
          bg: "#4e8cff",
          text: "#ffffff",
        },
        launcher: {
          bg: "#4e8cff",
        },
        messageList: {
          bg: "#ffffff",
        },
        sentMessage: {
          bg: "#42b883",
          text: "#ccc",
        },
        receivedMessage: {
          bg: "#42b883",
          text: "#ccc",
        },
        userInput: {
          bg: "#f4f7f9",
          text: "#565867",
        },
      };
    },
  },
  methods: {
    openChat() {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true;
    },
  },
};
</script>

<style lang="scss">
.sc-chat-window.opened {
  z-index: 9999;
}

[dir="rtl"] .sc-chat-window {
  right: unset;
  left: 25px;
}

[dir="rtl"] .sc-header--close-button {
  margin-right: auto;
  margin-left: 10px;
}

.sc-header {
  padding: 0 !important;
  min-height: 60px !important;
  background: #389466 !important;
}

.sc-message {
  &--text-content {
    color: white;
  }

  &--meta {
    color: #666 !important;
  }
}

.sc-user-input {
  direction: ltr;
  &--buttons {
    right: 0 !important;
    width: auto !important;
    padding: 0 10px;
  }
}
[dir="rtl"] .sc-user-input--text {
    direction: rtl;
}
</style>
<style>
.sc-message--meta {
  font-size: 11px!important;
}
</style>