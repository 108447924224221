<template>
  <div>
    <div align="right"></div>
    <div class="row devicereading">
      <span v-loading="!this.selected_device" class="w-100 d-block"></span>
      <div class="col-md-12" v-if="selected_device">
        <div class="row">
          <div
            class="col-lg-3 col-md-6 col-12 De-Rd "
            v-for="card in statsCards"
            :key="card.title"
          >
            <stats-card
              :title="card.title"
              :sub-title="card.subTitle"
              :type="card.type"
              class="sliceWords"
            >
              <div slot="footer" v-html="card.footer"></div>
              <img
                slot="icon"
                :src="card.svg"
                alt="Communication Icon 1950311"
              />
            </stats-card>
          </div>
        </div>
        <div class="row" align="center">
          <div class="col-md-12 De-Rd">
            <card class="mb-2">
              <div slot="header">
                <h4 class="card-title m-0"
                :class="isRTL ? 'text-right' : 'text-left'">{{$t('sensorsLife')}}</h4>
              </div>
              <div class="sensor-life-container">
                <div class="row card-center">
                  <div
                    class="col content-list"
                    v-for="sensor in sensors"
                    :key="sensor.id"
                  >
                    <div class="position-relative mr-3 d-inline-block">
                      <radial-progress-bar
                        :diameter="80"
                        :completed-steps="
                          Math.round(+sensor.lifePercentageRemaining)
                        "
                        :total-steps="100"
                        :innerStrokeWidth="5"
                        :strokeWidth="5">
                        <el-tooltip :content="$t(sensor.name)" effect="light" :open-delay="300" placement="top">
                          <p style="font-size:.7rem;">{{sensor.name.substring(0,5)}}</p>
                        </el-tooltip>
                        <p style="font-size:.7rem;">{{Math.round(+sensor.lifePercentageRemaining)}}%</p>
                      </radial-progress-bar>
                    </div>
                  </div>
                  <div class="col">
                    <div class="position-relative mr-3 d-inline-block">
                      <radial-progress-bar
                        :diameter="80"
                        :completed-steps="
                          Math.round(+this.selected_device.battery_percentage)
                        "
                        :total-steps="100"
                        :innerStrokeWidth="5"
                        :strokeWidth="5">
                        <el-tooltip content="BATTERY" effect="light" :open-delay="300" placement="top">
                          <p>BAT</p>
                        </el-tooltip>
                        <p>{{Math.round(+this.selected_device.battery_percentage)}}%</p>
                      </radial-progress-bar>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
      <div class="col-lg-12 p-0">
        <DeviceReadList v-on:downloadPDF="submit" />
      </div>
      <div class="col-lg-12 p-0" v-if="sensors.length > 1">
        <AllInOne />
      </div>
      <div class="col-lg-12">
        <div class="row">
          <div
            v-for="(sensor, index) in sensors"
            :key="index"
            :class="
              sensors.length % 2 == 1 && index == sensors.length - 1
                ? 'col-12'
                : 'col-lg-6'
            "
          >
            <BaseDeviceChart
              :key="sensor.id"
              :sensor="sensor"
              :id="'sensor' + sensor.id"
              ref="devicecharts"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StatsCard from "src/components/Cards/StatsCard";
import AllInOne from "@/components/Charts/all-in-one.vue";
import DeviceReadList from "./components/DeviceReadList.vue";
import BaseDeviceChart from "src/components/Charts/BaseDeviceChart.vue";
import { futureDate } from "@/util/helper.js";

import RadialProgressBar from "vue-radial-progress";
import { mapActions } from "vuex";
import ColorButton from "../../components/SensorsColors";

export default {
  //mixins: [mixin],
  props: ["id"],

  components: {
    ColorButton,
    AllInOne,
    DeviceReadList,
    StatsCard,
    BaseDeviceChart,
    RadialProgressBar,
  },

  computed: {
    selected_device() {
      return this.$store.state.devicesstore.selected_device;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    statsCards() {
      if (this.selected_device) {
        return [
          {
            title: this.selected_device.id,
            subTitle: 'deviceWords.deviceID',
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Device.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
          {
            title: this.selected_device.device_name,
            subTitle: this.$t("deviceWords.deviceName"),
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Device.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
          {
            title: this.selected_device.solution_name,
            subTitle: "solutionWords.solutionName",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Device.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
          {
            title: this.selected_device.communication,
            subTitle: this.$t("deviceWords.communicationType"),
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Communication.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
          {
            title: futureDate(
              this.selected_device.contract_duration,
              this.selected_device.created_at
            ),
            subTitle: this.$t("contractExpiryDate"),
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Contract.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
          {
            title: this.selected_device.customer,
            subTitle: this.$t("payingCustomerName"),
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Pay.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
          {
            title: this.selected_device.structure,
            subTitle: this.$t("structureWords.name"),
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Structure.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
          {
            title: this.selected_device.device_details,
            subTitle: this.$t("details"),
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Details.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
          {
            title: this.selected_device.fmc,
            subTitle: "FMC " + this.$t("companyWords.name"),
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Company.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
          {
            title: this.selected_device.amc,
            subTitle: "AMC " + this.$t("companyWords.name"),
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Company.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
          {
            title: this.selected_device.operator,
            subTitle: this.$t("companyWords.operator"),
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Company.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
          {
            title: this.selected_device.template_name,
            subTitle: 'templateName',
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Template.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
        ];
      }
      return [];
    },
    sensors() {
      if (this.selected_device) {
        return this.selected_device.sensors;
      }
      return [];
    },
  },

  mounted() {
    this.setSelectedDevice(this.id);
  },
  methods: {
    ...mapActions("devicesstore", ["setSelectedDevice", "downloadPDF"]),
    async submit() {
      let images = [];
      let refs = this.$refs.devicecharts;

      if (Array.isArray(refs) && refs.length > 0) {
        for (var i = 0; i < refs.length; i++) {
          let chart = refs[i];
          let devicechart = chart.getchart();

          try {
            let image = await this.getimagedata(devicechart);
            //var svg = atob(image.replace(/data:image\/jpeg\;base64,/, ''));
            images.push(image);
          } catch (e) {
            console.log(e);
          }
        }

        //console.log(images[0])

        this.downloadPDF(images);
      }
    },
    getimagedata(chart) {
      return new Promise((resolve, reject) => {
        console.log("getimagedata");
        chart.getimagedata({
          filetype: "png",
          callback: function (imagedata) {
            resolve(imagedata);
            //document.getElementById("output_image").src = imagedata;
          },
        });
      });
    },
  },
  destroyed() {
    this.$store.dispatch("devicesstore/destroyDevice");
  },
};
</script>
<style scoped>
.card-center {
  text-align: center;
  margin: 0 auto;
}
.content-list {
  display: inline-block;
  vertical-align: middle;
  float: none;
}
.sensor-life-container {
  align-items: center;
  justify-content: center;
}

.devicereading .card-body .card-title {
  font-size: 11px !important;
}
</style>
<style>
.De-Rd {
  padding-right: 5px;
  padding-left: 0;
}
[dir="rtl"] .De-Rd {
  padding-right: 5px !important;
  padding-left: 0 !important;
}
.De-Rd .card-stats {
  height: 82px !important;
}
.De-Rd-Un {
  margin-top: 30px;
}
  .card-title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
