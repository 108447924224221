<template>
    <div class="car-tracker">
        <div class="row">
            <div class="col-md-12 p-1">
                    <over-view />
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 p-1">
                    <Details/>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4 p-1">
                <alerts />
            </div>
            <div class="col-md-6 col-lg-4 p-1">
                <!-- fuel -->
                <fuel />
            </div>
            <div class="col-md-12 col-lg-4 p-1">
                
                <oil-level />
            </div>
        </div>

        <div class="row">
            <div class="col-12 p-1">
                <trip-map :id="id" />
            </div>
        </div>

        <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-6 p-1">
                <maintenance />
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 p-1">
                <speed/>
            </div>
            <div class="col-xl-4 col-lg-12 col-md-12 p-1">
                <distance />
            </div>
            
            
        </div>
        <div class="row">
            <div class="col-12 p-1">
                    <driver-score />
            </div>
        </div>

        
        

    </div>



    
</template>
<script>
import OverView from './Overview.vue';
import Alerts from './Alerts.vue';
import Fuel from './Fuel.vue';
import OilLevel from './OilLevel.vue';
import TripMap from './TripMap.vue';
import Maintenance from './Maintenance.vue';
import Distance from './Distance.vue';
import DriverScore from './DriverScore.vue';
import Speed from './Speed.vue'
import Details from './Details.vue';

import {mapActions} from 'vuex';


export default {
    props:['id'],
    components:{
        OverView,
        Alerts,
        Fuel,
        OilLevel,
        TripMap,
        Maintenance,
        Distance,
        DriverScore,
        Speed,
        Details
       

    },
    mounted(){
        this.fetchCarData(this.id);
    },
    methods:{
        ...mapActions('CarTrackerStore',[
            'fetchCarData'
        ])
    }
}
</script>
<style >
    .car-tracker{
        color:#fff;
    }
    .car-tracker span{
        font-size: 12px;
        font-weight: bolder;
    }
    

</style>