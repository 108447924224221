<template>
    <card class="h-100 car-details">
        <div class="row justify-content-around text-center">
            <div class="m-1">
                <inline-svg :src="require('@/assets/Icons/spark.svg')" width="40"  height="40" class="mb-1"  />
                <h4 class="m-0">Ignition</h4>
                <h4>{{ignition}}</h4>
            </div>
            <div class="m-1">
                <inline-svg :src="require('@/assets/Icons/signal.svg')" width="40"  height="40" class="mb-1"  />
                <h4 class="m-0">GSM Signal</h4>
                <h4>{{gsm_signal}}</h4>
            </div>
            <div class="m-1">
                <inline-svg :src="require('@/assets/Icons/car-battery.svg')" width="40"  height="40" class="mb-1"  />
                <h4 class="m-0">BTY vol</h4>
                <h4>{{battery_voltage}}</h4>
            </div>
            <div class="m-1">
                <inline-svg :src="require('@/assets/Icons/battery_2.svg')" width="40"  height="40" class="mb-1"  />
                <h4 class="m-0">External vol</h4>
                <h4>{{external_voltage}}</h4>
            </div>
            <div class="m-1">
                <inline-svg :src="require('@/assets/Icons/movement.svg')" width="40"  height="40" class="mb-1"  />
                <h4 class="m-0">Movement</h4>
                <h4>{{movement}}</h4>
            </div>
            <div class="m-1">
                <inline-svg :src="require('@/assets/Icons/satelite.svg')" width="40"  height="40" class="mb-1"  />
                <h4 class="m-0">GNSS pdop</h4>
                <h4>{{gnss_pdop}}</h4>
            </div>
            <div class="m-1">
                <inline-svg :src="require('@/assets/Icons/satelite.svg')" width="40"  height="40" class="mb-1"  />
                <h4 class="m-0">GNSS hdop</h4>
                <h4>{{gnss_hdop}}</h4>
            </div>
        </div>
    </card>
</template>
<script>
import {mapState} from 'vuex';

export default {
    computed:{
        ...mapState('CarTrackerStore',{
            carData:state=>state.carData
        }),
        ignition(){
            return this.carData.ignition != undefined ? this.carData.ignition : '--';
        },
        gsm_signal(){
            return this.carData.gsm_signal || 0;
        },
        battery_voltage(){
            return this.carData.battery_voltage != undefined ? this.carData.battery_voltage: '--';
        },
        movement(){
            return this.carData.movement != undefined ? this.carData.movement : '--';
        },
        gnss_pdop(){
            return this.carData.gnss_pdop || 0;
        },
        gnss_hdop(){
            return this.carData.gnss_hdop || 0;
        },
        external_voltage(){
            return this.carData.external_voltage != undefined ? this.carData.external_voltage : '--';
        }


    }
}
</script>
<style >
.car-details svg g path, .car-details svg path {
    fill: #02BAFF !important;
}

</style>