<template>
  <card class="h-100">
    <div class="row align-items-center">
      <div class="col-md-6 p-1">
        <h4>Accidents Reports</h4>
      </div>
      <div class="col-md-6 p-1">
        <base-select v-model="duration" :options="durationOptions"></base-select>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-12 text-center">
        <inline-svg :src="require('@/assets/Icons/accident.svg')" width="120" height="120" />
      </div>
    </div>
    <div class="row">
      <div class="col-6 text-center">
        <div>
          <h2 class="major rounded-pill">MAJOR</h2>
        </div>
        <h1 class="m-0 font-weight-bolder">0</h1>
      </div>
      <div class="col-6 text-center">
        <div>
          <h2 class="minor rounded-pill">MINOR</h2>
        </div>
        <h1 class="m-0 font-weight-bolder">1</h1>
      </div>
    </div>
  </card>
</template>
<script>
export default {
  data() {
    return {
      duration: 1,
      durationOptions: [
        {
          value: 1,
          label: "This Month",
        },
      ],
    };
  },
};
</script>
<style  scoped>
.major {
  height: 40px;
  background-color: crimson;
}
.minor {
  height: 40px;
  background-color: aqua;
}
</style>