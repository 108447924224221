<template>
  <div class="">
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="customtableheader">
            <h4 slot="header" class="card-title">{{$t("Table.Sensor")}}</h4>
            <router-link to="/SWAM/Sensors/create">
              <el-tooltip content="Create Sensor" effect="light" :open-delay="300" placement="top">
                <base-button type="primary" round icon>
                  <i class="tim-icons icon-simple-add"></i>
                </base-button>
              </el-tooltip>
            </router-link>
          </div>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <!-- <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                ></el-input>
              </base-input>-->
              <Search :propsToSearch="propsToSearch" @input="(value)=>{filter = value}" />
            </div>
            <!-- <div style="text-align:center" v-if="$asyncComputed.sensor_list.state === 'updating'">Loading</div> -->

            <div class="striped-table">
              <el-table :data="sensor_list">
              <div slot="empty" v-if="state === 'loading'">Loading ...</div>
              <div slot="empty" v-if="state === 'error'">Error</div>
              <div
                slot="empty"
                v-if="state === 'loaded' && sensor_list.length == 0"
              >No Data</div>
              <el-table-column
                sortable
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              ></el-table-column>
              <el-table-column :min-width="135" align="right" :label="$t('Actions')">
                <div slot-scope="props">
                  <el-tooltip content="View" effect="light" :open-delay="300" placement="top">
                    <base-button
                      @click.native="viewSensor(props.row)"
                      class="like btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-notes"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip content="Edit" effect="light" :open-delay="300" placement="top">
                    <base-button
                      @click.native="handleEdit(props.row)"
                      class="edit btn-link"
                      type="warning"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip content="Delete" effect="light" :open-delay="300" placement="top">
                    <base-button
                      @click.native="handleDelete(props.$index, props.row)"
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
            </div>
          </div>
          <TablePagination
            slot="footer"
            ref="paginator"
            v-if="total"
            :total="total"
            @change="updatePagination"
          ></TablePagination>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
//import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import Search from "@/components/Search.vue";
import TablePagination from "@/components/TablePagination.vue";
import { mapGetters } from "vuex";

export default {
  updated() {
    this.$nextTick(() => {
      console.log("Computation status", this.$asyncComputed);
    });
  },
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Search,
    TablePagination,
  },
  computed: {
    currentQuery() {
      return {
        limit: this.limit,
        offset: this.offset,
        ...this.filter,
      };
    },
    sensor_list() {
      return this.$store.getters["SwamStore/SensorsTemplates/items"](this.currentQuery);
    }
  },
  created() {
    this.state = "loading";
    this.$store
        .dispatch("SwamStore/SensorsTemplates/FETCH_ITEMS", {
          key: `sensors list`,
          query: this.currentQuery,
        })
        .then((response) => {
          this.state = "loaded";
          this.total = response.total_records;
        })
        .catch((error) => {
          this.state = "error";
          console.error("Error while fetching sensors list", error);
        });
  },
  data() {
    return {
      // tableShow: false,
      showModal: false,
      selected_sensor: null,
      total: 0,
      offset: 0,
      limit: 10,
      state: "",
      searchQuery: "",
      propsToSearch: [{ value: "name", label: this.$i18n.t("Name") }],

      tableColumns: [
        {
          prop: "id",
          label: this.$i18n.t("ID"),
          minWidth: 75,
        },
        {
          prop: "name",
          label: this.$i18n.t("Name"),
          minWidth: 250,
        },
        {
          prop: "sensor_life",
          label: this.$i18n.t("Sensor Life"),
          minWidth: 200,
        },
        // {
        //   prop: "min",
        //   label: this.$i18n.t("Min"),
        //   minWidth: 200,
        // },
        // {
        //   prop: "max",
        //   label: this.$i18n.t("Max"),
        //   minWidth: 200,
        // },
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      filter: null,
    };
  },
  methods: {
    updatePagination(data) {
      this.limit = data.limit;
      this.offset = data.offset;
    },
    close() {
      this.showModal = false;
      this.selected_sensor = null;
    },
    viewSensor(sensor) {
      //this.selected_sensor = sensor;
      //this.showModal = true;
      this.$router.push({ path: `view/${sensor.id}` });
    },
    handleEdit(row) {
      this.$router.push({ path: `update/${row.id}` });
    },
    handleDelete(index, row) {
      swal({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: "Deleted!",
            text: `You deleted ${row.name}`,
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
  },
  watch:{
    filter()
    {
      if(this.$refs.paginator)
      {
        this.offset = 0;
        this.$refs.paginator.goTo(this.limit,this.offset);
      }
    },
    currentQuery(){
      console.log("test")
      this.$store
        .dispatch("SwamStore/SensorsTemplates/FETCH_ITEMS", {
          key: `sensors list`,
          query: this.currentQuery,
        })
        .then((response) => {
          this.state = "loaded";
          this.total = response.total_records;
        })
        .catch((error) => {
          this.state = "error";
          console.error("Error while fetching sensors list", error);
        });
    }
  }
};
</script>
<style scoped>
</style>
