import { render, staticRenderFns } from "./RiskExposureChart.vue?vue&type=template&id=552ed6c4&scoped=true&"
import script from "./RiskExposureChart.vue?vue&type=script&lang=js&"
export * from "./RiskExposureChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "552ed6c4",
  null
  
)

export default component.exports