<template>
  <div>
    <h4 class="font-color-4 font-weight-bold mb-0">Locks</h4>
    <div class="d-flex align-items-center justify-content-between my-3">
      <div class="d-flex align-items-center justify-content-center">
        <base-input class="mb-0">
          <el-input
            type="search"
            class="mb-0 mr-5 search-input"
            clearable
            prefix-icon="el-icon-search"
            placeholder="Search"
            v-on:keydown.enter="applyFilter"
            aria-controls="datatables"
          ></el-input>
        </base-input>
        <base-select
          v-model="value"
          :options="options"
          placeholder="Last 7 Days"
          class="mb-0 mr-5 select"
          style="box-shadow: none !important"
        />
        <base-select
          v-model="value"
          :options="options"
          placeholder="Last 7 Days"
          class="mb-0 mr-5 select"
          style="box-shadow: none !important"
        />
      </div>
      <router-link to="/smartlock/add-lock">
        <base-button style="border-radius: 1px" class="btn btn-primary">Add</base-button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "locks-header"
}
</script>
