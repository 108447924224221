<template>
  <zingchart :data="chartConfig" :height="'300px'" :width="'100%'"></zingchart>
</template>
<script>
export default {
  data() {
    return {
      chartConfig: {
        type: "line",
        backgroundColor: "transparent",
        plot: {
          stacked: false,
          marker: {
            visible: false,
          },
        },
        legend: {
          align: "center",
          backgroundColor: "none",
          borderWidth: "0px",
          item: {
            cursor: "hand",
            fontColor: "#808080",
          },

          verticalAlign: "bottom",
        },
        
        scaleY: {
          guide: {
            lineStyle: "solid",
            lineColor: "#444551",
          },
        },

        series: [
          {
            values: [20, 40, 25, 50, 15, 45, 33, 34],
          },
          {
            values: [5, 30, 21, 18, 59, 50, 28, 33],
          },
          {
            values: [30, 5, 18, 21, 33, 41, 29, 15],
          },
        ],
      },
    };
  },
};
</script>