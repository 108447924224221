<template>
  <div class="striped-table">
    <CustomTable
      :columns="columns"
      :table-key="tableKey"
      @config-change="configChangeHandler"
      @reset="resetHandler"
    >
      <el-table
        :data="data"
        highlight-current-row
        ref="dragToScroll"
      >
        <template v-for="(column, index) in columns.filter(e => e.visible)">
          <el-table-column
            :key="index"
            v-bind="column"
            v-if="column.customTemplate"
          >
            <template slot-scope="{ row }">
              <template v-if="column.prop=='device.id'">
                <router-link :to="`/device/reading/${row.device.id}`" class="my-link">
                 {{ row.device.device_name }}
                </router-link>
              </template>
              <template v-if="column.prop=='event.user'">
                <p class="m-0">{{ row.event.user ? row.event.user.email : "--" }}</p>
              </template>
              <p v-if="column.prop=='event.escalated_to'">
                {{
                row.event.escalated_to
                ? row.event.escalated_to.type + ": " + row.event.escalated_to.name
                : "--"
                }}
              </p>
              <template v-if="column.prop=='alarm.status' && type != 'operational'">
                <StatusView :state="row.alarm.status" :label="$t(`alarmsTypes.${row.alarm.status}`)"></StatusView>
              </template>
              <template v-if="column.prop=='alarm.status' && type == 'operational'">
                <div class="d-flex align-items-center" style="margin-left: -10px">
                  <sensor-icon :name="row.alarm.sensor_type_name" :status="row.alarm.status" />
                  <div style="margin-left: 5px">
                    <div>{{ row.alarm.sensor_type_name }}</div>
                    <div class="d-flex">
                      <label class="sensor-status text-muted">{{ $t(row.alarm.status) }}</label>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            
          </el-table-column>
          <el-table-column 
            v-else
            :key="index"
            v-bind="column"
          />
        </template>
      </el-table>
    </CustomTable>
  </div>
</template>

<script>
import StatusView from "@/components/StatusView";
import SensorIcon from "@/components/SensorIcon.vue";
import dragToScroll from "@/pages/SWAM/components/AlarmsComponents/drag-to-scroll";
import CustomTable from "@/components/CustomTable.vue";
import { mapGetters } from "vuex";
import { USER_PREFERENCES_KEYS } from "@/global/user-preferences-store/keys";

export default {
  props: ["data", "type"],
  components: {
    StatusView,
    SensorIcon,
    CustomTable
  },
  data() {
    return {
      columns: [],
      tableKey: USER_PREFERENCES_KEYS.SWAM_OPERATIONAL_ALARMS_HISTORY_TABLE_CONFIGURATIONS,
    }
  },
  computed: {
    ...mapGetters("SwamStore/AlarmsHistory", {
      initColumns: "columns",
      operationalExtraColumns: "operationalExtraColumns",
      statusExtraColumns: "statusExtraColumns"
    }),
  },
  created() {
    this.tableKey = (this.type == 'operational' ? 
                      USER_PREFERENCES_KEYS.SWAM_OPERATIONAL_ALARMS_HISTORY_TABLE_CONFIGURATIONS : 
                      USER_PREFERENCES_KEYS.SWAM_STATUS_ALARMS_HISTORY_TABLE_CONFIGURATIONS 
                    );
    
    this.resetHandler();
  },
  mounted() {
    let element = this.$refs.dragToScroll.$el;
    element = element.querySelector(".el-table__body-wrapper");
    dragToScroll(element);
  },
  methods: {
    configChangeHandler(configurations) {
      this.columns = configurations;
    },
    resetHandler() {
      this.columns = this.initColumns.map(e => ({ ...e }));
      let toInsert = (this.type == 'operational' ? this.operationalExtraColumns : this.statusExtraColumns);
      toInsert.forEach(col => {
        this.columns.push(col);
      })
    }
  }
};
</script>

<style>
</style>
