<template>
  <div class="row attendence-module">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <card>
            <div slot="header">
              <div class="d-flex justify-content-between align-items-start">
                <h2> Attendance</h2>
                <base-input>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <base-dropdown
                        title-classes="dropdown-toggle btn btn-primary btn-block searchdropdown"
                        :title="'Name'"
                      >
                        <a class="dropdown-item" href="#">Name</a>
                      </base-dropdown>
                    </div>
                    <input
                      type="text"
                      class="form-control search-filter"
                      :placeholder="$t('Search')"
                      v-model="searchQuery"
                    />
                  </div>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <el-table :data="queriedData">
                  <el-table-column min-width="50" label="#" align="center">
                    <div class="photo" slot-scope="{ row }">
                      <img :src="row.img" alt="Table image" />
                    </div>
                  </el-table-column>
                  <el-table-column sortable prop="staffName"  min-width="150" label="Staff Name">
                    <div slot-scope="{ row }">
                      <a  href="#" @click.prevent="setUser(row)">{{row.staffName}}</a>
                    </div>
                  </el-table-column>

                  <el-table-column sortable min-width="200" prop="department" label="Department"></el-table-column>

                  <el-table-column
                    sortable
                    min-width="200"
                    prop="attendencePercent"
                    align="center"
                    label="% Of Attendance"
                  ></el-table-column>

                  <el-table-column sortable min-width="150" prop="overtime" align="right" label="Over Time"></el-table-column>

                  <el-table-column sortable min-width="120" prop="holiday" align="right" label="Holiday"></el-table-column>

                  <el-table-column
                    min-width="150"
                    prop="lasttimereport"
                    align="right"
                    label="Last Report Time"
                  ></el-table-column>

                  <el-table-column min-width="100" prop="status" align="right" label="Status"></el-table-column>

                </el-table>
              </div>
            </div>
            <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
          </card>
        </div>
      </div>
      <div class="row" v-if="selected_user">
        <div class="col-md-12">
          <IndividualStaffDetails :user="selected_user" :key="selected_user.id" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import IndividualStaffDetails from './IndividualStaffDetails.vue';
import Fuse from "fuse.js";
export default {
  components:{
    IndividualStaffDetails
  },

  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["staffName"],
      threshold: 0.3,
      findAllMatches: true,
    });
  },
  data() {
    return {
      selected_user:null,
      fuseSearch:null,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      
      searchQuery:'',
      
      tableData: [
        {
          id: 1,
          img: "img/default-avatar.png",
          staffName: "Tania Mike",
          department: "department-1",
          attendencePercent: "25%",
          overtime: "3 hrs",
          holiday: "2",
          lasttimereport: "2 days",
          status: "on duty",
        },
        {
          id: 2,
          img: "img/default-avatar.png",
          staffName: "yasser",
          department: "department-2",
          attendencePercent: "50%",
          overtime: "1 hrs",
          holiday: "0",
          lasttimereport: "10 min",
          status: "on duty",
        },
        {
          id: 3,
          img: "img/default-avatar.png",
          staffName: "Morhaf",
          department: "department-3",
          attendencePercent: "30%",
          overtime: "1 hrs",
          holiday: "0",
          lasttimereport: "24 min",
          status: "on duty",
        },
        {
          id: 4,
          img: "img/default-avatar.png",
          staffName: "Feras",
          department: "department-4",
          attendencePercent: "40%",
          overtime: "1 hrs",
          holiday: "0",
          lasttimereport: "10 min",
          status: "on duty",
        },
        {
          id: 5,
          img: "img/default-avatar.png",
          staffName: "Mohand",
          department: "department-5",
          attendencePercent: "70%",
          overtime: "1 hrs",
          holiday: "0",
          lasttimereport: "50 min",
          status: "on duty",
        },
        {
          id: 6,
          img: "img/default-avatar.png",
          staffName: "yasser",
          department: "department-6",
          attendencePercent: "50%",
          overtime: "1 hrs",
          holiday: "0",
          lasttimereport: "1 hr",
          status: "on duty",
        },
      ],

      searchedData: [],
    };
  },
  watch:{
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  methods:{
    setUser(user){
      if(this.selected_user){
        if(this.selected_user.id == user.id){
          this.selected_user = null;
          return;
        }
        
      }
        this.selected_user = user;
      
    }
  }
};
</script>
<style >
  .attendence-module img{
    max-width: 40px;
    max-height: 40px;
  }
  .rounded-corner{
    border-radius: 25px;
  }
</style>