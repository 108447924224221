<template>
  <div class="tel-admindash">
    <div class="row">
      <div class="col-lg-8 col-md-12 p-1">
        <AllStatusMap />
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="row">
          <div class="col-lg-12 col-md-6 p-1">
            <OperationalAlarms />
          </div>
          <div class="col-lg-12 col-md-6 p-1">
            <StatusAlarms />
          </div>

        </div>
        <!-- <div class="row">
          
        </div> -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 p-1">
        <Covid19Test />
      </div>
      <div class="col-md-4 p-1">
        <TotalCompanies />
      </div>
      <div class="col-md-4 p-1">
        <ManDownAlert />
      </div>
    </div>
    <!-- chart -->
    <div class="row">
      <div class="col-md-12 p-1">
        <ComparisionOfinteractions />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 p-1">
        <Attendence />
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12 p-1">
            <VisitorBreachAlarm />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 p-1">
            <StaffAreaBreachAlarm />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 p-1">
        <DevicesUser />
      </div>
    </div>
  </div>

  
</template>
<script>
import DevicesStatus from "./DevicesStatus.vue";
import AllStatusMap from "./AllStatusMap.vue";
import Chart from "./../Chart.vue";
import DevicesUser from "./DevicesUser.vue";
import { mapState } from "vuex";
import Covid19Test from "./Covid19Test.vue";
import TotalCompanies from "./TotalCompanies.vue";
import Attendence from "./Attendence.vue";
import ManDownAlert from "./ManDownAlert.vue";
import OperationalAlarms from "./OperationalAlarms.vue";
import StatusAlarms from "./StatusAlarms.vue";
import VisitorBreachAlarm from './VisitorBreachAlarm.vue';
import StaffAreaBreachAlarm from './StaffAreaBreachAlarm.vue';
import ComparisionOfinteractions from './ComparisionOfinteractions.vue';

export default {
  components: {
    DevicesStatus,
    AllStatusMap,
    Chart,
    DevicesUser,
    Covid19Test,
    TotalCompanies,
    Attendence,
    ManDownAlert,
    OperationalAlarms,
    StatusAlarms,
    StaffAreaBreachAlarm,
    VisitorBreachAlarm,
    ComparisionOfinteractions
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    ...mapState("TeltonikaStore", {
      numberOfUsers: (state) => state.pagination.total,
    }),
    username() {
      return this.user.first_name;
    },
  },
  mounted() {
    this.$store.dispatch("TeltonikaStore/init");
  },
};
</script>
<style>

.white-content  .svgicon path,.white-content  .svgicon circle{
    fill:#525f7f;
  }

.tel-widget{
    color:#fff !important;
  }
.white-content .tel-widget{
    color: #525f7f !important;
  }
  
.tel-admindash .imgicon {
  max-width: 40px;
  max-height: 40px;
}
.tel-admindash .icon-simple-remove {
  font-weight: bold;
  font-size: 20px;
  color: red;
}

.tel-admindash .num{
  width: 50px;
  float: right;
}

.tel-admindash .cl-type {
  padding: 0 10px;
  background: #f84e70;
  border-radius: 10px;
  color: #fff;
  width: 70%;
}

.tel-admindash .alarm {
  position: relative;
}
.tel-admindash .alarm-count {
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #f84e70;
  font-size: 14px;
  align-items: center;
  position: absolute;
  right: -16px;
  bottom: -14px;
}
.tel-admindash .alarm-count span {
  display: block;
  width: 100%;
  text-align: center;
  padding-bottom: 3px;
}


</style>