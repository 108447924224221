<template>
  <!-- Big Chart -->

  <card type="chart" class="big-chart">
    <template>
      <div class="row">
        <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
          <SensorsColors :sensorsToShow="sensorsIds"></SensorsColors>
        </div>
        <div class="col-lg-6 d-flex d-sm-block">
          <div
            class="btn-group btn-group-toggle"
            :class="isRTL ? 'float-left' : 'float-right'"
            data-toggle="buttons"
          >
            <label class="btn btn-sm btn-primary btn-simple" @click="chart = 'bar'" :class="{ active: chart === 'bar' }">
              <input type="radio" name="options" autocomplete="off" />
              <span class="d-none d-sm-block">{{ $t("bar") }}</span>
            </label>
            <label class="btn btn-sm btn-primary btn-simple" @click="chart = 'line'" :class="{ active: chart === 'line' }">
              <input type="radio" name="options" autocomplete="off" />
              <span class="d-none d-sm-block">{{ $t("line") }}</span>
            </label>
            <label class="btn btn-sm btn-primary btn-simple" @click="chart = 'area'" :class="{ active: chart === 'area' }">
              <input type="radio" name="options" autocomplete="off" />
              <span class="d-none d-sm-block">{{ $t("area") }}</span>
            </label>
          </div>
        </div>
      </div>
    </template>
    <div class="chart-area" style="direction: ltr !important">
      <CustomZingchart :id="getFileName" :values="currentChart.values" :data="currentChart.data" />
    </div>
  </card>
</template>
<script>
  import CustomZingchart from "./Custom-Zingchart";
  import SensorsColors from "../SensorsColors";
  import {mapGetters} from 'vuex'
  import UserPreferencesGetters from '../../global/user-preferences-store/types/getter-types';

  export default {
    components: {
      SensorsColors,
      CustomZingchart
    },
    data() {
      return {
        chart: "line",
        // area

        chartConfigArea: {
          type: "area",
          backgroundColor: "transparent",
          //stacked: true,

          legend: {
            align: "center",
            backgroundColor: "none",
            borderWidth: "0px",
            item: {
              cursor: "hand",
              fontColor: "#808080"
            },

            verticalAlign: "top"
          },

          plot: {
            activeArea: true,
            alphaArea: 0.7,
            aspect: "spline",
            marker: {
              borderColor: "#ffffff",
              shadow: false,
              size: "3px"
            },
            shadow: false
          },

          scaleX: {
            label: {
              text: this.$t("date"),
              fontSize: "20px",
              fontColor: this.isDarkMode ? "white" : "#27293D"
            },
            item: { color: "#7f8fa4" },

            // item: {
            //   color:'#7f8fa4',
            //   fontColor: "#acacac",
            //   fontFamily: "Roboto",
            //   offsetY: "2px",
            //   rules: [
            //     {
            //       offsetX: "20px",
            //       //rule: '%scale-position === 0'
            //     },
            //   ],
            // },
            //lineWidth: "0px",

            transform: {
              type: "date",
              all: "%d %M %Y <br> %H:%i"
            },
            zooming: true
          },
          scaleY: {
            item: { color: "#7f8fa4" },
            offsetStart: "10%",
            guide: {
              visible: false
            },
            label: { text: "" }
          },

          "scale-y-2": {
            item: { color: "#7f8fa4" },
            offsetStart: "10%",
            guide: {
              visible: false
            },
            label: { text: "" }
          },

          // set margins for labels around chart
          plotarea: { margin: "40 dynamic dynamic dynamic" },

          zoom: {
            preserveZoom: false // saves state so appears to not reload
          },
          preview: {
            adjustLayout: true,
            //marginRight: '20px',
            marginBottom: "5px",
            // marginLeft: '20px',
            alpha: 0.2,
            borderColor: "#aeaeae",
            borderWidth: "1px",
            handleTop: {
              borderColor: "#ccc",
              borderWidth: "1px"
            },
            handleRight: {
              borderColor: "#ccc",
              borderWidth: "1px"
            },
            handleBottom: {
              borderColor: "#ccc",
              borderWidth: "1px"
            },
            handleLeft: {
              borderColor: "#ccc",
              borderWidth: "1px"
            },
            height: "55px",
            live: true,
            mask: {
              backgroundColor: "#c7c7c7"
            },
            preserveZoom: true,
            label: {
              color: "#7f8fa4"
            }
          },
          series: []
        },

        chartConfigBar: {
          backgroundColor: "transparent",
          //theme: "classic",

          type: "bar",
          legend: {
            align: "center",
            backgroundColor: "none",
            borderWidth: "0px",
            item: {
              cursor: "hand",
              fontColor: "#808080"
            },

            verticalAlign: "top"
          },

          plot: {
            "bar-width": "15px",
            "border-width": "2px",
            "border-color": "rgba(255, 255, 255, 0.1)",
            //backgroundColor: 'transparent',
            hoverMarker: {
              type: "circle",
              borderWidth: "1px",
              size: "4px"
            },
            lineWidth: "3px",
            marker: {
              type: "circle",
              size: "3px"
            },
            shadow: false,
            tooltipText: "%t : %v"
          },

          scaleX: {
            item: { color: "#7f8fa4" },
            // set scale label
            label: {
              text: this.$t("date"),
              fontSize: "20px",
              fontColor: this.isDarkMode ? "white" : "#27293D"
            },
            zooming: true,
            // enable auto-date formatting
            transform: {
              type: "date",
              all: "%d %M %Y <br> %H:%i"
            }
          },
          zoom: {
            preserveZoom: false // saves state so appears to not reload
          },
          // enable scrollbar
          scrollX: {},
          // enable click and drag window
          preview: {
            adjustLayout: true,
            label: {
              color: "#7f8fa4"
            }
          },
          scaleY: {
            item: { color: "#7f8fa4" },
            offsetStart: "10%",
            guide: {
              visible: false
            },
            //values: "0:100:5",
            // scale label with unicode character
            label: { text: "" }
            // enable abbreviated units
            //short: true,
          },
          "scale-y-2": {
            item: { color: "#7f8fa4" },
            offsetStart: "10%",
            guide: {
              visible: false
            },
            // values: "0:900:100",
            // scale label with unicode character
            label: { text: "" }
            // enable abbreviated units
            //short: true,
          },
          // set margins for labels around chart
          plotarea: { margin: "40 dynamic dynamic dynamic" },

          series: []
        }
      };
    },

    computed: {
      ...mapGetters('UserPreferencesStore', {
        getSensorsColors : UserPreferencesGetters.GET_SENSORS_COLORS,
        getSelectedDevice : UserPreferencesGetters.GET_SELECTED_DEVICE,
      }),
      isDarkMode() {
        return this.$store.getters.isDarkMode;
      },
      getFileName() {
        const d = this.$store.state.devicesstore.selected_device;
        if (d)
          return (d.structure + " " + d.device_name + " All").replace(/ /g, "-");
        return "all-device-sensors-data";
      },
      currentChart() {
        if (this.chart == "line") {
          // eslint-disable-next-line
          this.chartConfig.series = this.values;
          return {
            values: this.values,
            data: this.chartConfig
          };
        } else if (this.chart == "area") {
          // eslint-disable-next-line
          this.chartConfigArea.series = this.values;
          return {
            values: this.values,
            data: this.chartConfigArea
          };
        } else {
          // eslint-disable-next-line
          this.chartConfigBar.series = this.values;
          return {
            values: this.values,
            data: this.chartConfigBar
          };
        }
      },
      chartConfig() {
        return {
          backgroundColor: "transparent",
          //theme: "classic",

          type: "line",
          height: "350px",
          width: "100%",
          legend: {
            align: "center",
            backgroundColor: "none",
            borderWidth: "0px",
            item: {
              cursor: "hand",
              fontColor: "#808080"
            },

            verticalAlign: "top"
          },
          plot: {
            hoverMarker: {
              type: "circle",
              borderWidth: "1px",
              size: "4px"
            },
            lineWidth: "3px",
            marker: {
              type: "circle",
              size: "4px"
            },
            shadow: false,
            tooltipText: "%t : %v<br>"
          },

          scaleX: {
            item: { color: "#7f8fa4" },
            // set scale label
            label: {
              text: this.$t("date"),
              fontSize: "20px",
              fontColor: this.isDarkMode ? "white" : "#27293D"
            },
            zooming: true,
            // enable auto-date formatting
            transform: {
              type: "date",
              all: "%d %M %Y <br> %H:%i"
            }
          },
          zoom: {
            preserveZoom: false // saves state so appears to not reload
          },
          // enable scrollbar
          scrollX: {},
          // enable click and drag window
          preview: {
            adjustLayout: true,
            label: {
              color: "#7f8fa4"
            }
          },
          scaleY: {
            item: { color: "#7f8fa4" },
            offsetStart: "10%",
            guide: {
              visible: false
            },

            // scale label with unicode character
            label: { text: "" }
          },
          "scale-y-2": {
            item: { color: "#7f8fa4" },
            offsetStart: "10%",
            guide: {
              visible: false
            },
            //values: "0:900:100",
            // scale label with unicode character
            label: { text: "" }
            // enable abbreviated units
            //short: true,
          },
          // set margins for labels around chart
          plotarea: { margin: "40 dynamic dynamic dynamic" },

          series: this.values
        };
      },
      isRTL() {
        return this.$rtl.isRTL;
      },

      values() {
        let selected_device = this.getSelectedDevice;
        let values = this.$store.state.devicesstore.sortedValues;
        let data = [];

        if (selected_device) {
          let newSensors = [];
          selected_device.sensors.forEach(item => {
            this.getSensorsColors.forEach((item2 ) =>{
              if(item.id === item2.id ){
                newSensors.push(item2);
              }
            })
          });
          let sensors = newSensors;

          for (var i = 0; i < sensors.length; i++) {
            let sensor = sensors[i];
            let plot;
            if (values[sensor.id]) {
              plot = values[sensor.id].values
                .map((sensorRead, index) => {
                if (!values.dates[index]) {
                  return null;
                }
                let time = new Date(values.dates[index]).getTime();
                if (typeof sensorRead == "number") {
                  return [time, sensorRead];
                }
                return null;
              })
                .filter(v => v != null);
              // .sort((x,y)=>x[0]-y[0]);
            }
            let scale = sensor.max > 100 ? "scale-y-2" : "scale-y";

            data.push({
              text: this.$t(sensor.name),
              values: plot,

              lineColor: sensor.color,
              scales: "scale-x," + scale,
              backgroundColor: sensor.color,
              marker: {
                backgroundColor: sensor.color
              },
              rules: [
                {
                  "background-color": sensor.color
                }
              ],
              "legend-marker": {
                type: "circle",
                borderWidth: "0px",
                cursor: "hand",
                backgroundColor: sensor.color
              },
              tooltip: {
                text: sensor.unit_name
                  ? `<div style="text-align: center; line-height: 14px; padding-top: 5px;">%kt<br><br><b>%t: %node-value ${sensor.unit_name}</b></div>`
                  : `<div style="text-align: center; line-height: 14px; padding-top: 5px;">%kt<br><br><b>%t: %node-value</b></div>`,
                marginTop: "5px",
                padding: "5px 15px",
                fontFamily: "Roboto",
                htmlMode: true
              }
            });
          }
        }

        return data;
      },
      sensorsIds(){
        return this.getSelectedDevice.sensors.map(sensor=>sensor.id)
      }
    },
    mounted() {},
    methods: {}
  };
</script>


<style >
  .big-chart {
    min-height: 400px;
  }
</style>
