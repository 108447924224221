<template>
<!--    <gmap-map :center="center" :zoom="17" style="width:100%;  height:300px" :options="options">-->
<!--      &lt;!&ndash; <gmap-polyline-->
<!--        v-if="path.length > 0"-->
<!--        :path="path"-->
<!--        :editable="false"-->
<!--        @path_changed="updateEdited($event)"-->
<!--        @rightclick="handleClickForDelete"-->
<!--        ref="polyline"-->
<!--      ></gmap-polyline> &ndash;&gt;-->
<!--    </gmap-map>-->
  <div>
    <base-map :zoom="17" :center="center" style="height: 400px">
    </base-map>
  </div>

</template>
<script>
  import BaseMap from 'src/components/BaseMap/Map'
  import MapMarker from "src/components/BaseMap/MapMarker.vue";

export default {
    components: {
      [BaseMap.name]: BaseMap,
      [MapMarker.name]: MapMarker
    },
    data(){
        return {
             options: {
       // mapTypeId: "terrain",
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      },
      center: { lat: 25.2048, lng: 55.2708 },
        }
    }
    
}
</script>