<template>
  <div style="height:100%;">
    <div>
      <h5 class="p-3 mb-0">-Repeat this Space on its own floor.</h5>
      <div class="copies-number p-4">
        <label>Number Of Copies :</label>
        <input type="number" v-model="copiesNumber" />
      </div>
      <div v-if="!showNames" style="height: 60px;">
        <p class="p-3 mb-0">
          -You Must Specify Spaces Numbers :
          <small
            style="letter-spacing: .8px;"
            :class="getHintColorClass(invalidNumberError)"
          >(alphanumeric characters only)</small>
        </p>
        <small
          style="letter-spacing: .8px;"
          class="d-block text-center"
          :class="getHintColorClass(duplicatedNumberError)"
        >(Space Number Must Be Unique On The Floor Level)</small>
      </div>
      <div v-else style="height: 60px;">
        <p class="p-3 mb-0">-Names Are Optional.</p>
      </div>
    </div>

    <div class="counter-start">
      <label style="width: 35%;">Count Starts From :</label>
      <input type="number" class="input-range" :disabled="showNames" v-model.number="counterStart" />
    </div>
    <div class="d-flex justify-content-center">
      <div class="card range-selector">
        <input
          type="text"
          class="input-range"
          :disabled="showNames"
          placeholder="Prefix"
          v-model.trim="prefix"
        />
        <p :class="{'text-muted':showNames}">#Number</p>
        <input
          type="text"
          class="input-range"
          :disabled="showNames"
          placeholder="Postfix"
          v-model.trim="postfix"
        />
        <base-button
          class="btn-info"
          size="sm"
          :disabled="showNames"
          @click.native="refresh(prefix,postfix)"
        >apply</base-button>
      </div>
    </div>

    <div class="spaces-preview">
      <label class="p-4">The New Spaces :</label>
      <div class="spaces-preview__names-switch">
        <label>Names :&nbsp;</label>
        <BaseSwitch class="mb-0" v-model="showNames" on-text="hide" off-text="show" />
      </div>
      <div
        class="spaces-preview__list"
        :class="{'error':invalidNumberError || duplicatedNumberError}"
      >
        <card
          :bodyClasses="'p-1'"
          class="high-card-shadow mb-2"
          :class="{'invalid':!space.valid && (invalidNumberError || duplicatedNumberError) }"
          v-for="(space,index) in spaces"
          :key="index"
        >
          <div class="my-divider">
            <div></div>
            <input type="text" v-if="!showNames" v-model.trim="space.number" />
            <input type="text" v-else v-model.trim="space.name" placeholder="Name" />
            <div></div>
          </div>
          <label
            class="position-absolute top-0 error"
            v-if="invalidNumberError || duplicatedNumberError"
          >{{space.errorMsg}}</label>
          <label class="position-absolute bottom-1">{{selectedSpace.type.name}}</label>
        </card>
      </div>
    </div>
    <base-button
      @click="submit"
      class="submit-btn"
      :class="{'btn-success':!dirty}"
    >{{dirty?'Validate':'Submit'}}</base-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseSwitch from "@/components/BaseSwitch";
import helper from "@/util/helper";
const debounce = helper.debounce;
export default {
  data() {
    return {
      spaces: [],
      counterStart: 1,
      copiesNumber: 1,
      prefix: "",
      postfix: "",
      invalidNumberError: false,
      duplicatedNumberError: false,
      dirty: true,
      showNames: false
    };
  },
  computed: {
    ...mapGetters(["selectedSpace", "selectedFloor"])
  },
  methods: {
    refresh(prefix, postfix) {
      if (!prefix) {
        prefix = "";
      }
      if (!postfix) {
        postfix = "";
      }
      this.spaces = [];
      for (let i = 0; i < this.copiesNumber; i++) {
        this.spaces.push({
          number: `${prefix + (this.counterStart + i) + postfix}`,
          valid: true,
          name: this.selectedSpace.name
        });
      }
    },
    submit() {
      if (this.spaces.length == 0) {
        return;
      }
      this.showNames = false;
      const unique = {};
      this.invalidNumberError = false;
      this.duplicatedNumberError = false;
      this.spaces.forEach(space => {
        // space.valid = true;
        // space.errorMsg = null;
        if (!/^[a-zA-Z0-9]+$/.test(space.number)) {
          space.valid = false;
          space.errorMsg = "Invalid Number";
          this.invalidNumberError = true;
        } else if (unique[`${space.number.toLowerCase()}`]) {
          space.valid = false;
          space.errorMsg = "Duplicated Number";
          this.duplicatedNumberError = true;
        } else {
          unique[`${space.number.toLowerCase()}`] = space;
        }
      });

      this.selectedFloor.spaces.forEach(space => {
        if (unique[`${space.number.toLowerCase()}`]) {
          unique[`${space.number.toLowerCase()}`].valid = false;
          unique[`${space.number.toLowerCase()}`].errorMsg =
            "Duplicated Number";
          this.duplicatedNumberError = true;
        }
      });

      if (this.invalidNumberError || this.duplicatedNumberError) {
        this.dirty = true;
        return;
      }
      if (!this.dirty) {
        this.$store.dispatch("duplicateSpace", {
          spaces: this.spaces,
          space_id: this.selectedSpace.id
        });
      }
      this.dirty = false;
    },
    getHintColorClass(type) {
      if (this.dirty) {
        if (type) {
          return "number-hint-error";
        } else {
          return "number-hint";
        }
      } else {
        return "text-success";
      }
    }
  },
  watch: {
    spaces: {
      handler() {
        if (this.dirty == null) {
          return;
        }
        this.dirty = true;
      },
      deep: true
    },
    copiesNumber: {
      handler: debounce(function(newVal) {
        if (newVal < 1) {
          this.copiesNumber = 1;
        } else if (newVal > 20) {
          this.copiesNumber = 20;
        } else {
          this.refresh();
        }
      }, 700)
    },
    counterStart: {
      handler: debounce(function(newVal) {
        if (newVal >= 0) {
          this.refresh();
        } else {
          newVal = 1;
        }
      })
    }
  },
  created() {
    this.spaces = [
      {
        number: this.selectedSpace.number,
        valid: true,
        name: this.selectedSpace.name
      }
    ];
  },
  components: {
    BaseSwitch
  }
};
</script>

<style scoped>
label {
  margin-bottom: 0 !important;
}
.number-hint {
  color: #525f7f;
}
.number-hint-error {
  color: #ec250d;
}
h5 {
  color: #ffffffd1;
  text-transform: capitalize;
  letter-spacing: 1px;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.white-content h5 {
  color: #1d253b;
}

.input-range {
  outline: none;
  border: none;
  width: 100px;
  background: transparent;
  color: white;
  text-align: center;
}
p.p-3.mb-0 {
  padding-bottom: 0 !important;
}
.copies-number {
  display: flex;
  padding-bottom: 0 !important;
  /* padding-top: 1rem!important; */
}
.copies-number label {
  width: 35%;
}
.copies-number input {
  outline: none;
  border: none;
  width: 100px;
  background: transparent;
  color: white;
  text-align: center;
}

.range-selector {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 90%;
  padding: 0.2rem;
  margin-bottom: 1rem;
}
.range-selector p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.range-selector button {
  font-size: 0.8rem;
  width: 60px;
}
.spaces-preview {
  position: relative;
}
.spaces-preview .spaces-preview__names-switch {
  position: absolute;
  right: 20px;
  top: 9px;
}
.spaces-preview label.p-4 {
  padding-top: 10px !important;
  padding-bottom: 0 !important;
}
.spaces-preview .spaces-preview__list {
  margin: 0.5rem 10px 0 10px;
  max-height: 40vh;
  overflow-y: auto;
  padding: 0.5rem 1rem;
}
.spaces-preview__list::-webkit-scrollbar {
  width: 4px;
}
.spaces-preview__list.error::-webkit-scrollbar-track {
  background: #ec250d;
  box-shadow: 0px 0px 20px 0px rgb(255, 6, 6);
}
.my-divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
}
.my-divider div {
  background: var(--primary);
  border: 1px solid var(--primary);
  width: 37%;
}
/* .my-divider.invalid div,
.my-divider.invalid input {
  border-color: rgba(255, 0, 0, 0.598);
  background: rgba(255, 0, 0, 0.598);
} */
.my-divider input {
  width: 25%;
  background: transparent;
  color: var(--primary);
  outline: none;
  border: none;
  text-align: center;
}
.submit-btn {
  position: absolute;
  left: 35%;
  bottom: 3%;
}
.invalid {
  background-color: #ff101452;
}
.error {
  font-weight: bold;
  letter-spacing: 0.5px;
  right: 10px;
}
.counter-start {
  display: flex;
  padding: 1rem 1.5rem 0.5rem 1.5rem;
}
</style>