<template>
  <card>
    <template slot="header">
      <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
        <div class="d-flex align-items-center">
          <inline-svg :src="require('@/assets/Icons/forklift/noun_Digitalization.svg')" width="25" height="25" />
          <h5 class="ml-2 font-color-1">Digital Status</h5>
        </div>
      </div>
    </template>
    <div class="row font-weight-bold mb-4">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <span class="font-color-4">Status</span>
        <span>
          <span class="point background-good"></span>
          <span class="ml-2 font-color-1 font-weight-bold">OK</span>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <inline-svg :class="svgColor" :src="require('@/assets/Icons/forklift/noun_Digitalization2.svg')" width="20" height="20" />
          <span class="ml-2 font-color-4">Digital Input</span>
          <span class="ml-2 font-color-1 font-weight-bold">{{ data.digital_input }}</span>
        </div>
        <div class="d-flex align-items-center">
          <inline-svg :class="svgColor" :src="require('@/assets/Icons/forklift/noun_Digitalization2.svg')" width="20" height="20" />
          <span class="ml-2 font-color-4">Digital Output</span>
          <span class="ml-2 font-color-1 font-weight-bold">{{ data.digital_output }}</span>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
export default {
  name: 'DigitalStatus',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    svgColor() {
      return this.$store.getters.themMode == "light" ? "" : "light-svg";
    }
  }
}
</script>

<style scoped>
.point {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
</style>