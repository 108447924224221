<template>
  <card class="mb-3">
    <template slot="header">
      <div class="d-flex align-items-center border-bottom pb-2">
        <inline-svg :src="require('@/assets/Icons/forklift/fire-green.svg')" width="25" height="25" />
        <h5 class="ml-2 font-color-4 font-weight-bold">Ignition</h5>
      </div>
    </template>
    <div class="row font-color-1 font-weight-bold mb-2">
      <div class="col-9">
        <inline-svg :class="svgColor" :src="require('@/assets/Icons/forklift/noun_Fire.svg')" width="20" height="20" />
        <span class="ml-2 font-color-1">Ignition Number</span>
      </div>
      <p class="col-3">
        {{ data.ignition }}
      </p>
    </div>
    <!-- <div class="row font-color-1 font-weight-900 mb-2">
      <div class="col-9 my-2">
        <inline-svg :class="svgColor" :src="require('@/assets/Icons/forklift/noun_Lightning.svg')" width="20" height="20" />
        <span class="ml-2">Power Voltage</span>
      </div>
      <p class="col-3 font-color-5">
        {{ data.power_voltage }}
      </p>
    </div>
    <div class="row font-color-1 font-weight-900">
      <div class="col-9 my-2">
        <inline-svg :class="svgColor" :src="require('@/assets/Icons/forklift/noun_Fire.svg')" width="20" height="20" />
        <span class="ml-2">Ignition Number</span>
      </div>
      <p class="col-3 font-color-5">
        {{ data.ignition }}
      </p>
    </div> -->
  </card>
</template>

<script>
export default {
  name: 'Ignition',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed:{
    svgColor() {
      return this.$store.getters.themMode == "light" ? '' : 'light-svg';
    },
  }
};
</script>

<style lang="scss">

</style>