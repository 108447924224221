<template>
    <div class="container">
        <base-input :placeholder="'name'" v-model="$v.name.$model">
            <template v-if="$v.name.$error" v-slot:error>
                <label class="error" v-if="!$v.name.required">{{ 'name' + $t("validations.isRequired")}}</label>
            </template>
        </base-input>
        <base-input :placeholder="'Alarm Email'" v-model="$v.alarmEmail.$model">
            <template v-if="$v.alarmEmail.$error" v-slot:error>
                <label class="error" v-if="!$v.alarmEmail.required">{{ 'Alarm Email' + $t("validations.isRequired")}}</label>
            </template>
        </base-input>
        <base-input :placeholder="'host'" v-model="$v.host.$model">
            <template v-if="$v.host.$error" v-slot:error>
                <label class="error" v-if="!$v.host.required">{{ 'host' + $t("validations.isRequired")}}</label>
            </template>
        </base-input>
        <base-input :placeholder="'port'" v-model="$v.port.$model">
            <template v-if="$v.port.$error" v-slot:error>
                <label class="error" v-if="!$v.port.required">{{ 'port' + $t("validations.isRequired")}}</label>
            </template>
        </base-input>
        <base-input :placeholder="'channel id'" v-model="$v.channelId.$model">
            <template v-if="$v.channelId.$error" v-slot:error>
                <label class="error" v-if="!$v.channelId.required">{{ 'channel id' + $t("validations.isRequired")}}</label>
            </template>
        </base-input>
        <base-input :placeholder="'type'" v-model="$v.type.$model">
            <template v-if="$v.type.$error" v-slot:error>
                <label class="error" v-if="!$v.type.required">{{ 'type' + $t("validations.isRequired")}}</label>
            </template>
        </base-input>
        <base-input :placeholder="'description'" v-model="$v.description.$model">
            <template v-if="$v.description.$error" v-slot:error>
                <label class="error" v-if="!$v.description.required">{{ 'description' + $t("validations.isRequired")}}</label>
            </template>
        </base-input> 
        <input
          @focus="structureBrowser=true"
          :value="spaceName"
          placeholder="Select Space"
          type="text"
          class="form-control"
        />
        <el-dialog
            title="Structure Browser"
            width="70%"
            top="7vh"
            :append-to-body="true"
            :visible.sync="structureBrowser"
            :destroy-on-close="true"
        >
            <StructureMiniBrowser @select="selecteSpace" :structure="{id:structureId}" />
        </el-dialog>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import StructureMiniBrowser from "@/pages/Structure/Structures/Structure-Mini-Browser/mini-browser";

export default {
    components: {
        StructureMiniBrowser
    },
    props: {
        data: {}
    },
    data() {
        return {
            name: undefined,
            description: undefined,
            type: undefined,
            channelId: undefined,
            alarmEmail: undefined,
            host: undefined,
            port: undefined,
            spaceId: undefined,
            spaceName: undefined,
            selectedSpace: undefined,
            structureBrowser: false
        }
    },
    validations: {
        name: { required },
        description: { required },
        type: { required },
        channelId: { required },
        alarmEmail: { required },
        host: { required },
        port: { required },
        spaceId: { required },
    },
    computed: {
        structureId() {
            let orderData = this.$store.getters['VsaasStore/WorkOrder/WorkOrderDetails/data'];
            if(orderData && orderData.structureId) {
                return orderData.structureId;
            }
        }
    },
    methods: {
        startWith() {
            this.$v.name.$model = this.data.name;
            this.$v.description.$model = this.data.description;
            this.$v.type.$model = this.data.type;
            this.$v.channelId.$model = this.data.channelId;
            this.$v.alarmEmail.$model = this.data.alarmEmail;
            this.$v.host.$model = this.data.host;
            this.$v.port.$model = this.data.port;
            this.$v.spaceId.$model = this.data.spaceId;
        },
        reset() {
            this.$v.name.$model = undefined;
            this.$v.description.$model = undefined;
            this.$v.type.$model = undefined;
            this.$v.channelId.$model = undefined;
            this.$v.alarmEmail.$model = undefined;
            this.$v.host.$model = undefined;
            this.$v.port.$model = undefined;
            this.$v.spaceId.$model = undefined;
            this.$v.$reset();
        },
        extractData()  {
            this.$v.$touch();
            if(this.$v.$invalid) {
                return;
            }
            return {
                name: this.name,
                description: this.description,
                type: this.type,
                channelId: +this.channelId,
                alarmEmail: this.alarmEmail,
                host: this.host,
                port: +this.port,
                spaceId: +this.spaceId
            }
        },
        selecteSpace(data) {
             if(data.targetType != 'space'){
                console.error('type should be a space!');
                return;
            }
            this.structureBrowser = false;
            this.selectedSpace = data.selectedSpace;
            this.$v.spaceId.$model = this.selectedSpace.id;
            this.spaceName = data.targetAddress;
        }
    },
    created() {
        if(this.data)  {
            this.startWith();
        }
    },
    watch: {
        data: {
            handler() {
                if(this.data) {
                    this.startWith();
                } else {
                    this.reset();
                }
            },
            deep: true,
        }
    }
}
</script>

<style>

</style>