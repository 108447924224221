<template>
  <div>
    <!-- device info -->
    <div class="row">
      <span v-loading="!device" class="w-100 d-block"></span>
      <div class="col-md-12" v-if="device">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12 De-Rd" v-for="card in statsCards" :key="card.title">
            <stats-card :title="card.title" :sub-title="card.subTitle" :type="card.type">
              <div slot="footer" v-html="card.footer"></div>
              <img slot="icon" :src="card.svg" alt="Communication Icon 1950311" />
            </stats-card>
          </div>
        </div>
      </div>
    </div>

    <!-- filters -->

    <div class="row mt-3">
      <div class="col-md-12">
        <card :footerClasses="'d-flex justify-content-end'">
          <template slot="header">
            <div class="d-flex justify-content-between align-items-start">
              <span>{{$tc('search.filter', 2)}}</span>
            </div>
          </template>

          <div class="row">
            <div class="col-md-4">
              <base-input :label="$t('instructions.startDate')">
                <el-date-picker type="date" :placeholder="$t('instructions.startDate')" v-model="filters.start_date"></el-date-picker>
              </base-input>
            </div>
            <div class="col-md-4">
              <base-input :label="$t('instructions.endDate')">
                <el-date-picker type="date" :placeholder="$t('instructions.endDate')" v-model="filters.end_date"></el-date-picker>
              </base-input>
            </div>
            <div class="col-md-4">
              <base-select
                :label="$t('sensorWords.sensorType')"
                v-model="filters.sensor_type"
                :options="sensors"
              ></base-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <base-multi-select
                :label="$tc('sensorWords.status', 2)"
                :placeholder="$tc('sensorWords.status', 2)"
                v-model="filters.statuses"
                :options="statusesOptions"
              ></base-multi-select>
            </div>

            <div class="col-md-4 d-flex align-items-center">
              <!-- <base-button
                style="margin-bottom:-13px"
                type="button"
                class="btn btn-success"
                @click="fetchSensorReadings"
              >{{$t('Submit')}}</base-button>-->
              <base-button
                style="margin-bottom:-13px"
                type="button"
                class="btn btn-success"
                @click="restFilters"
              >{{$t('Reset')}}</base-button>
            </div>
          </div>
        </card>
      </div>
    </div>

    <!-- data -->
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="d-flex justify-content-between">
            <h4 slot="header" class="card-title">{{ $t('data') }}</h4>
            <span v-if="selectedSensor" class="d-flex">
              <SensorIcon :name="selectedSensor.label" :status="'good'" :width="30" :height="30" />
              <h4 class="ml-2">{{selectedSensor.label}}</h4>
            </span>
          </div>

          <div>
            <el-table :row-class-name="tableRowClassName" :data="sensor_readings">
              <el-table-column :min-width="50" :label="$t('number')">
                <div slot-scope="props">
                  <span>{{props.$index +1}}</span>
                </div>
              </el-table-column>

              <el-table-column :min-width="100" :label="$t('sensorWords.value')" sortable prop="value">
                <div slot-scope="{row}">
                  <span>{{unit ? row.value +' '+ unit : row.value}}</span>
                </div>
              </el-table-column>

              <el-table-column :min-width="100" :label="$tc('sensorWords.status')">
                <div slot-scope="{row}">
                  <i class="fas fa-circle fa-xs mr-1" :class="'circle-'+row.status"></i>
                  <span> {{ $t(row.status) }} </span>
                </div>
              </el-table-column>

              <el-table-column :min-width="100" :label="$t('sensorWords.createdAt')" sortable prop="created_at">
                <div slot-scope="{row}">
                  <span>{{gDateFormat(row.created_at)}}</span>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">Showing {{ from }} to {{ to }} of {{ total }} entries</p>
            </div>
            <el-select
              class="select-primary mb-3 pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { BasePagination } from "src/components";

import StatsCard from "src/components/Cards/StatsCard";
import SmartTrackerVue from "../SmartTracker/SmartTracker.vue";
import SensorIcon from "@/components/SensorIcon.vue";

export default {
  props: ["id"],
  components: {
    BasePagination,
    StatsCard,
    SensorIcon,
    
  },
  computed: {
    statsCards() {
      if (this.device) {
        return [
          {
            title: this.device.device_name,
            subTitle: this.$t("deviceWords.deviceName"),
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Device.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },

          {
            title: this.device.structure,
            subTitle: this.$t("structureWords.name"),
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Structure.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
          {
            title: this.device.device_details,
            subTitle: this.$t("details"),
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Details.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
        ];
      }
      return [];
    },

    to() {
      return this.pagination.perPage * this.pagination.currentPage;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    selectedSensor() {
      let sensor_type = this.filters.sensor_type;
      let sensors = this.sensors;
      let sensor = sensors.find((snr) => snr.value === sensor_type);
      return sensor;
    },
    unit() {
      if (this.selectedSensor) {
        return this.selectedSensor.unit_name;
      }
      return "";
    },
  },
  data() {
    return {
      sensors: [],
      device: null,
      sensor_readings: [],
      total: 0,
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      filters: {
        sensor_type: null,
        statuses: [],
        start_date: null,
        end_date: null,
      },
      oldfilters: {},
    };
  },
  computed: {
    statusesOptions() {
      return [
        { value: "good", label: this.$t("good") },
        { value: "ok", label: this.$t("ok") },
        { value: "poor", label: this.$t("poor") },
        { value: "problem", label: this.$t("problem") },
      ];
    }
  },
  watch: {
    pagination: {
      handler() {
        this.fetchSensorReadings();
      },
      deep: true,
    },
    filters: {
      handler() {
        if (this.shouldMakeRequest()) {
          this.fetchSensorReadings();
          this.cloneFilters();
        }
      },
      deep: true,
    },
  },
  methods: {
    arraysNotEqual(arr1, arr2) {
      if (Array.isArray(arr1) && Array.isArray(arr2)) {
        return arr1.sort().join(",") != arr2.sort().join(",");
      }
      return true;
    },
    cloneFilters() {
      this.oldfilters = Object.assign({}, this.filters);
    },

    shouldMakeRequest() {
      let filters = this.filters;
      let oldfilters = this.oldfilters;
      if (filters.sensor_type != oldfilters.sensor_type) {
        return true;
      }
      if (
        this.arraysNotEqual([...filters.statuses], [...oldfilters.statuses])
      ) {
        return true;
      }
      if (filters.start_date && filters.end_date) {
        if (filters.start_date > filters.end_date) {
          this.$notify({
            group: "main",
            type: "warning",
            title: "Invalid Data",
            message: "start date can not be larger than end date",
          });
          return false;
        } else {
          return true;
        }
      }
      if (oldfilters.start_date && oldfilters.end_date) {
        return true;
      }
      return false;
    },

    setStartDate(date) {
      console.log("start date", date);
    },
    tableRowClassName({ row }) {
      // console.log('classname',row);
      if (row.status === "good") {
        return "table-good";
      } else if (row.status === "ok") {
        return "table-ok";
      } else if (row.status === "poor") {
        return "table-poor";
      } else if (row.status === "problem") {
        return "table-problem";
      }
      return "";
    },
    restFilters() {
      let ob = {
        statuses: [],
        start_date: null,
        end_date: null,
      };
      this.filters = { ...this.filters, ...ob };
    },
    fetchDeviceInfo() {
      this.$api
        .get(`iot/devices/deviceinfo/${this.id}`)
        .then((res) => {
          if (res.data.msg === "success") {
            let data = res.data.data;
            this.device = data;
            let sensors = data.sensors;
            this.sensors = sensors.map((snr) => {
              return {
                value: snr.id,
                label: snr.name,
                unit_name: snr.unit_name,
              };
            });
            this.filters.sensor_type = sensors[0].id;

            //this.fetchSensorReadings();
          }
        })
        .catch(() => {});
    },
    fetchSensorReadings() {
      let { perPage, currentPage } = this.pagination;

      let limit = perPage;
      let offset = perPage * (currentPage - 1);
      let filters = this.formingFilters();
      this.$api
        .get(
          `iot/devices/${this.id}/sensorReadings?limit=${limit}&offset=${offset}&${filters}`
        )
        .then((res) => {
          if (res.data.msg === "success") {
            this.sensor_readings = res.data.readings;
            this.total = res.data.total_records;
          }
        })
        .catch(() => {});
    },

    formingFilters() {
      let str = "";
      let { sensor_type, statuses, start_date, end_date } = this.filters;
      str += `sensor_type=${sensor_type}`;
      statuses = statuses.join(",");
      if (statuses) {
        str += `&statuses=${statuses}`;
      }

      if (start_date && end_date) {
        start_date = start_date.toISOString();
        end_date = end_date.toISOString();
        str += `&start=${start_date}`;
        str += `&end=${end_date}`;
      }

      return str;
    },
  },
  mounted() {
    this.cloneFilters();
    if (this.id) {
      this.fetchDeviceInfo();
    }
  },
};
</script>
<style >
/* .table-good{
        background-color: rgb(63, 172, 121) !important;
        }
    .table-ok{
        background-color : rgb(232, 217, 29) !important;
    }
    .table-poor{
        background-color: rgb(230, 162, 60) !important;
    }
    .table-problem{
        background-color: rgb(242, 57, 59) !important;
        border:3px solid #fff ;
        box-sizing: content-box;
    } */
</style>
