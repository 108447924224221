<template>
  <tr>
    <td>
      <button class="btn btn-info">{{$t("Completed")}}</button>
    </td>
    <td>
      <span>{{device.id}}</span>
    </td>
    <td>
      <span>{{device.device_name}}</span>
    </td>
    <td>
      <span>{{device.device_unique}}</span>
    </td>

    <td>
      <span>{{device.manufacturer}}</span>
    </td>
    <td>
      <span>{{device.device_details}}</span>
    </td>
    <td>
      <el-tooltip content="Update" effect="light" :open-delay="300" placement="top">
      <button
        type="button"
        class="btn like btn-link btn-icon btn-fab btn-info btn-sm"
        @click="showModal = true"
      >
        <!---->
        <i class="tim-icons icon-pencil"></i>
      </button>
      </el-tooltip>
      
      <el-tooltip content="Delete" effect="light" :open-delay="300" placement="top">
      <button
        @click="handleDelete"
        type="button"
        class="btn remove btn-link btn-icon btn-fab btn-danger btn-sm"
      >
        <!---->
        <i class="tim-icons icon-simple-remove"></i>
      </button>
      </el-tooltip>

      <el-tooltip content="go to readings" effect="light" :open-delay="300" placement="top">
        <button
          @click="gotToReading"
          type="button"
          class="btn remove btn-link btn-icon btn-fab btn-danger btn-sm"
        >
          <!---->
          <i class="tim-icons icon-chart-bar-32"></i>
        </button>
      </el-tooltip>
    </td>
    <!--  -->
    <el-drawer
      :visible.sync="showModal"
      :title="'Update Device-'+category.name"
      :destroy-on-close="true"
    >
      <component
        :is="deviceType"
        :lot="lot"
        :device="device"
        v-if="showModal"
        @close="showModal = false"
      />
    </el-drawer>
  </tr>
</template>
<script>
import CreateDevice from "./CreateDevice.vue";
import CreateTeltonikaDevice from "./CreateTeltonikaDevice.vue";

import { Drawer } from "element-ui";
import swal from "sweetalert2";
export default {
  props: ["device", "lot"],
  components: {
    CreateDevice,
    CreateTeltonikaDevice,
    [Drawer.name]: Drawer,
  },
  data() {
    return {
      showModal: false,
    };
  },

  computed: {
    category() {
      let device_categories = this.$store.state.device_categories;
      let category = device_categories.find(
        (cat) => cat.id === this.lot.template.category_id
      );
      return category;
    },

    deviceType() {
      let name = null;
      let category = this.category;
      if (category) {
        switch (category.name) {
          case "IOT":
            name = "CreateDevice";
            break;
          case "Teltonika":
            name = "CreateTeltonikaDevice";
            break;
        }
      }

      return name;
    },
  },

  methods: {
    deleteDevice() {},
    gotToReading() {
      if (this.category) {
        if (this.category.name === "IOT") {
          this.$router.push({ path: `/device/reading/${this.device.id}` });
        } else if (this.category.name === "Teltonika") {
          //
        }
      }
    },
    handleDelete() {
      swal({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteDevice();
          swal({
            title: "Deleted!",
            text: `You deleted ${this.device.device_name}`,
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        }
      });
    },
  },
};
</script>
<style scoped>
</style>