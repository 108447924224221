<template>
    <div v-loading="loading">
        <WorkOrderDetails :orderId="orderId" />
        <card>
            <WorkOrderDevicesTable :columns="devicesListColumns" :data="getTableData" />
        </card>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import WorkOrderDetails from '../../../core/WorkOrderDetails/index.vue';
import WorkOrderDevicesTable from './components/WorkOrderDevicesTable';

export default {
    components: {
        WorkOrderDetails,
        WorkOrderDevicesTable
    },
    computed: {
        orderId() {
            return this.$route.params.id;
        },
        ...mapGetters('VsaasStore/WorkOrder',{
            loading: 'loading',
            devicesListColumns: 'devicesListColumns',
            devicesListData: 'devicesListData',
        }),
        getTableData() {
            let result = this.devicesListData;
            let orderData = this.$store.getters['VsaasStore/WorkOrder/WorkOrderDetails/data'];
            if(!orderData)
                return result;
            let quantity = orderData.nvrQuantity + orderData.cameraQuantity;
            quantity -= result.length;
            while(quantity > 0) {
                result.push({});
                quantity--;
            }
            return result;
        }
    },
    methods: {
        ...mapActions('VsaasStore/WorkOrder',{
            fetchDevicesList: 'fetchDevicesList'
        }),
    },  
    created() {
        this.fetchDevicesList(this.orderId);
    },

}
</script>

<style>

</style>