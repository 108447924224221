<template>
  <card
    v-loading="loading"
    class="mb-md-0"
    headerClasses="high-card-shadow pt-1 pb-1"
    :bodyClasses="getClasses()"
  >
    <div slot="header" class="main-card-title row justify-content-between">
      Inner Information
      <base-button @click="showTools=!showTools" class="tools-button" size="sm">
        Tools
        <span class="arrow" :class="{'active':!showTools}">
          <span></span>
          <span></span>
        </span>
      </base-button>
    </div>

    <!-- tools card -->
    <transition name="slide">
      <card v-if="showTools" :bodyClasses="'p-1'" class="high-card-shadow tools-card">
        <div class="row justify-content-around m-0">
          <base-button :disabled="!canAddSpace" @click.native="createNewSpace" size="sm">New Space</base-button>

          <!-- floors tools -->
          <template v-if="!onNormalSpace">
            <base-button
              :disabled="!canCopy"
              v-if="!floorToCopy"
              @click.native="copy"
              size="sm"
              type="warning"
            >Copy</base-button>
            <base-button v-else @click.native="clear" size="sm" type="danger">Clear</base-button>
            <base-button :disabled="!canPaste" @click.native="paste" size="sm" type="success">Paste</base-button>
          </template>

          <!-- spaces tools -->
          <template v-else>
            <base-button
              size="sm"
              type="warning"
              @click.native="duplicate"
            >Duplicate</base-button>
          </template>

        </div>



        <label v-if="floorToCopy && !onNormalSpace" class="d-block mb-2">
          The Copied Floor is:
          <strong>{{floorToCopy.name}} &nbsp; #{{floorToCopy.index}}</strong>
        </label>
        <label v-else-if="!onNormalSpace">No Floor Copied</label>
        <template v-if="onStructure && copyMode">
          <label class="d-block mb-2">
            Paste To:
            <strong>{{selectedFloorsCount}}</strong>
          </label>
          <p class="range-select">
            <base-button
              class="apply-select"
              size="sm"
              :type="selectMode?'success':'default'"
              @click="selectMode=!selectMode"
            >{{selectMode?'Select':'Ignore'}}</base-button>

            <input
              type="number"
              class="input-range"
              placeholder="from"
              :min="1"
              :max="to"
              v-model.number="from"
            />
            ->
            <input
              type="number"
              class="input-range"
              placeholder="to"
              :min="from"
              :max="floors.length"
              v-model.number="to"
            />

            <base-button class="apply-select" size="sm" type="primary" @click="apply">apply</base-button>
          </p>
        </template>
      </card>
    </transition>

    <div class="list-all" :class="{'tools-opend':showTools,'tools-opend-extend':expandedToolsCard}">
      <!-- spacces -->
      <template v-if="!iAmIndoorSpace">
        <el-divider>
          <p>Spaces</p>
        </el-divider>
        <card
          :bodyClasses="'p-1'"
          class="high-card-shadow"
          @click.native="openSpace(space)"
          style="cursor: pointer;"
          v-for="(space) in spaces"
          :key="space.id"
        >
          <el-divider>
            <el-tooltip placement="top-start">
              <template slot="content">
                <p>name:&nbsp;{{space.name}}</p>
                <p>number:&nbsp;{{space.number}}</p>
              </template>
              <p class="pink-title">{{space.display}}</p>
            </el-tooltip>
          </el-divider>
          <label class="position-relative d-block mt-n2 mb-0">{{space.type.name}}</label>
        </card>

        <div v-if="!spaces">
          <p class="text-center">Loading</p>
        </div>
        <div v-else-if="spaces.length==0">
          <p class="text-center">
            No
            <template v-if="selectedSpace">Indoor</template> Spaces
          </p>
        </div>
      </template>

      <!-- floors -->
      <template v-if="!floors || floors.length!=0">
        <el-divider>
          <p>Floors</p>
        </el-divider>
        <div v-if="!floors">
          <p class="text-center">Loading</p>
        </div>
        <card
          :bodyClasses="'p-1'"
          class="high-card-shadow"
          @click.native="openFloor(floor)"
          style="cursor: pointer;"
          v-for="(floor, index) in floors"
          :key="'floor'+index"
        >
          <label class="position-absolute">#{{floor.index}}</label>
          <base-checkbox
            v-if="copyMode"
            @input="changeSelectedFloors"
            class="floor__checkbox"
            :disabled="floor.spaces_count!=0"
            v-model="floor.checked"
          ></base-checkbox>
          <label class="spaces-count" v-if="floor.spaces_count">spaces : {{floor.spaces_count}}</label>
          <el-divider>
            <p class="pink-title">{{floor.name}}</p>
          </el-divider>
        </card>
      </template>
      <!-- devices -->
      <template v-if="selectedSpace">
        <el-divider>
          <p>Devices</p>
        </el-divider>
        <div v-if="!devices">
          <p class="text-center">Loading</p>
        </div>
        <div v-else-if="devices.length==0">
          <p class="text-center">No Devices</p>
        </div>

        <card
          class="high-card-shadow"
          :bodyClasses="'p-1'"
          @click.native="openDevice(device)"
          style="cursor: pointer;"
          v-for="(device, index) in devices"
          :key="'device'+index"
        >
          <label
            class="position-absolute"
            v-if="!iAmIndoorSpace && selectedSpace.id!=device.space_id"
          >In {{device.space_name}}</label>
          <el-divider>
            <p class="pink-title">{{device.device_name}}</p>
          </el-divider>
        </card>
      </template>
    </div>
  </card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      unsubscribe: null,
      showTools: false,
      selectedFloorsCount: 0,
      from: null,
      to: null,
      selectMode: true,
    };
  },
  watch: {
    floors: {
      handler(newVal, old) {
        if (old && newVal && old.length == 0 && newVal.length > 0) {
          this.selectedFloorsCount = 0;
          if (this.floorToCopy) this.showTools = true;
          newVal.forEach(f => {
            if (f.spaces_count != 0) {
              f.checked = false;
            }
            if (f.checked) {
              this.selectedFloorsCount++;
            }
          });
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      structure_spaces: "structure_spaces",
      structure_floors: "structure_floors",
      selectedFloor: "selectedFloor",
      selectedSpace: "selectedSpace",
      iAmIndoorSpace: "selectedIndoorSpace",
      loading: "str_loading",
      floorToCopy: "floorToCopy"
    }),
    floors() {
      if (this.selectedFloor || this.selectedSpace) {
        return [];
      } else {
        return this.structure_floors;
      }
    },
    spaces() {
      if (this.selectedSpace) {
        return this.selectedSpace.indoorSpaces;
      } else if (this.selectedFloor) {
        return this.selectedFloor.spaces;
      } else {
        return this.structure_spaces;
      }
    },
    devices() {
      if (this.iAmIndoorSpace) {
        return this.iAmIndoorSpace.devices;
      } else if (this.selectedSpace) {
        return this.selectedSpace.devices;
      }
      return [];
    },
    canAddSpace() {
      if (this.iAmIndoorSpace) {
        return false;
      }
      if (this.selectedSpace && !this.selectedSpace.type.extendable) {
        return false;
      }
      return true;
    },
    copyMode() {
      return this.floorToCopy && this.showTools;
    },
    // onSpace(){
    //   return this.iAmIndoorSpace || this.selectedSpace || 
    // },
    onFloor() {
      return this.selectedFloor && !this.selectedSpace;
    },
    onStructure() {
      return !this.selectedSpace && !this.selectedFloor;
    },
    canCopy() {
      return this.onFloor && this.selectedFloor.spaces_count != 0;
    },
    canPaste() {
      return (
        !this.selectedSpace &&
        !this.selectedFloor &&
        this.floorToCopy &&
        this.selectedFloorsCount
      );
    },
    onNormalSpace() {
      return this.selectedFloor && this.selectedSpace && !this.iAmIndoorSpace;
    },
    expandedToolsCard() {
      return (
        (this.onStructure && this.copyMode)
      );
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type == "updateSpaces" ||
        mutation.type == "setOutdoorSpaceDevices" ||
        mutation.type == "setIndoorSpaceDevices" ||
        mutation.type == "openIndoorSpace"
      ) {
        this.$forceUpdate();
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    duplicate(){
      this.$store.commit('toolsWindow','duplicateSpace');
    },
    checkRange() {
      if (this.from == "") {
      } else if (this.from <= 0 || this.from > this.to) {
        this.to = this.floors.length;
        this.from = 1;
      }
      if (this.to == "") {
      } else if (this.to > this.floors.length || this.to < this.from) {
        this.to = this.floors.length;
        this.from = 1;
      }
    },
    apply() {
      this.checkRange();
      let from, to;
      if (!this.from || this.from == "") {
        from = 0;
      } else {
        from = this.from - 1;
      }
      if (!this.to || this.to == "") {
        to = this.floors.length - 1;
      } else {
        to = this.to - 1;
      }

      for (let i = from; i <= to; i++) {
        const f = this.floors[i];
        if (
          f.spaces_count == 0 &&
          ((!f.checked && this.selectMode) || (f.checked && !this.selectMode))
        ) {
          f.checked = this.selectMode;
          if (this.selectMode) {
            this.selectedFloorsCount++;
          } else {
            this.selectedFloorsCount--;
          }
        }
      }
      // this.$nextTick().then(_=>{
      // })
    },
    changeSelectedFloors(val) {
      if (val) {
        this.selectedFloorsCount++;
      } else {
        this.selectedFloorsCount--;
      }
    },
    copy() {
      this.$store.commit("copyCurrentFloor");
    },
    clear() {
      this.$store.commit("clearClipboard");
    },
    paste() {
      this.$store.dispatch("paste");
    },
    getClasses() {
      console.log("compute classes!");
      let str = "h-75-scroll test ";
      if (this.showTools) {
        str += "pt-0 ";
      }
      return str;
    },
    createNewSpace() {
      this.$store.commit("toolsWindow", 'newSpace');
    },
    openFloor(floor) {
      if (!this.copyMode) this.$store.dispatch("openFloor", floor);
      else {
        console.warn("Attempt to open floor in copy mode");
      }
    },
    openSpace(space) {
      if (this.selectedSpace) {
        console.log(`dispatching openIndoorSpace`);
        this.$store.dispatch("openIndoorSpace", space);
      } else {
        console.log(`dispatching openSpace`);
        this.$store.dispatch("openSpace", space);
      }
    },
    openDevice(device) {
      this.$store.dispatch("openDevice", device);
    }
  }
};
</script>

<style scoped lang="scss">
button.text-muted {
  opacity: 0.7;
  text-decoration: line-through;
}
.floor__checkbox {
  position: absolute;
  right: 0;
  top: 0;
}
.floor__checkbox.disabled {
  cursor: not-allowed;
}

/*
::v-deep .el-checkbox {
  margin-right: 0 !important;
}
::v-deep .el-checkbox__input {
  margin: 5px 7px 0 0px;
}*/

::v-deep .form-check .form-check-sign::before,
.form-check .form-check-sign::after {
  width: 15px;
  height: 15px;
}
::v-deep .form-check input[type="checkbox"]:checked + .form-check-sign::before,
.form-check input[type="checkbox"]:checked + .form-check-sign::before {
  background-color: #00d0d4;
}
::v-deep .form-check .form-check-label {
  position: unset;
}
.add-space-button {
  float: right;
  position: relative;
  top: -5px;
  padding-left: 10px;
}
.add-space-button i {
  margin-right: 6px;
}
.main-card-title {
  font-size: 1.2rem;
  margin: 0;
  letter-spacing: 1px;
}
::v-deep .card-body {
  max-height: 73.5vh;
}
::v-deep .test.card-body {
  height: 73vh;
}
::v-deep .card-body::-webkit-scrollbar {
  width: 7px;
}
::v-deep .high-card-shadow .el-divider__text {
  color: $primary !important;
  text-align: center;
  width: fit-content;
  max-width: 70%;
}
::v-deep .high-card-shadow .el-divider {
  background-color: $primary !important;
}
.pink-title {
  color: $primary-states !important;
  cursor: pointer;
  width: fit-content;
  max-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.spaces-count {
  position: absolute;
  bottom: 0;
  right: 5px;
}
.tools-card {
  position: absolute;
  z-index: 1000;
  left: 0;
}
.tools-card label {
  font-size: 0.8rem;
}
.tools-opend {
  margin-top: 90px;
}
.tools-opend-extend {
  margin-top: 160px;
}
.list-all {
  transition: margin 0.2s ease-out;
}
.slide-enter-active {
  animation: slide-in 0.2s ease-out forwards;
}
.slide-leave-active {
  animation: slide-out 0.2s ease-out forwards;
}
@keyframes slide-in {
  from {
    transform: translateY(-70px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes slide-out {
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    transform: translateY(-70px);
    opacity: 0;
  }
}
.range-select {
  justify-content: space-around;
  display: flex;
  align-items: center;
}
.input-range {
  outline: none;
  border: none;
  width: 60px;
  background: transparent;
  color: white;
  text-align: center;
}
.apply-select {
  font-size: 0.8rem;
  width: 53px;
}

.tools-button {
  padding-left: 0;
  font-size: 0.8rem;
  width: 4.5rem;
  padding-right: 0;
  justify-content: space-around;
  display: flex;
}

.arrow {
  width: 0.92rem;
  height: 0.93rem;
  position: relative;
}
.arrow span {
  top: 0.5rem;
  position: absolute;
  width: 0.55rem;
  height: 0.1rem;
  background-color: #efefef;
  display: inline-block;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.arrow span:first-of-type {
  left: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.arrow span:last-of-type {
  right: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.arrow.active span:first-of-type {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.arrow.active span:last-of-type {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>