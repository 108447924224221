<template>
  <card class="h-100 maintenance">
    <div class="d-flex pr-1 pl-1 justify-content-between align-items-start">
      <h4>Maintenance</h4>
      <div class="position-absolute top-1 right-1 main-icon">
          <inline-svg :src="require('@/assets/Icons/maintenance.svg')" width="40" height="40"  />
      </div>
      
    </div>

    
     
      <div class="row mt-5">
        <div class="col-4 text-center due-main">
          <inline-svg :src="require('@/assets/Icons/car-maintenance-1.svg')" width="40"  />
          <span class="d-block">Due Maintenance</span >
          <h1 class="font-weight-bolder">20%</h1>
        </div>
        <div class="col-4 text-center under-main">
         <inline-svg :src="require('@/assets/Icons/car-maintenance-1.svg')" width="40"  />
         <span class="d-block"> Under Maintenance</span>
          <h1 class="font-weight-bolder">45%</h1>
        </div>
        <div class="col-4 text-center overdue-main">
          <inline-svg :src="require('@/assets/Icons/car-maintenance-1.svg')" width="40"  />
          <span class="d-block">Overdue Maintenance</span>
          <h1 class="font-weight-bolder">80%</h1>
        </div>
      </div>
      <div class="mt-5">
          <ProgressBar
            
            :data="MultiProgressData"
          />
      </div>
      

     

      

  </card>
</template>
<script>
  import ProgressBar from "src/components/progress-bar.vue";
export default {
  components: {
    ProgressBar
  },
  data(){
    return{
      MultiProgressData:[
          {value:20, state: 'due-maintenance'},
          {value:3, state: 'under-maintenance'},
          {value:8, state: 'over-maintenance'},
      ]
    }
  }
};
</script>
<style  scoped>
  
</style>
<style >
    .main-icon svg path{
      fill: #d051dc;
    }
  .due-main svg path{
      fill: #fbdc2b
  }
  .under-main svg path{
    fill: #d050dc;
  }
  .overdue-main svg path{
    fill: #f95176;
  }
  .due-maintenance{
    background-color: #fef277;
  }
  .under-maintenance{
    background-color: #d94fd2;
  }
  .over-maintenance{
    background-color: #f74a66;
  }


</style>