<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <StructuresLocations />
      </div>
      <div class="col-lg-6 col-md-12">
        <solutionStatisticsCount :data="{ structureId, solutionId }" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <DevicesList
            @item-deleted="onDelete"
            :data="device_list"
            :state="state"
          />
          <TablePagination
            slot="footer"
            v-if="total"
            :total="total"
            @change="updatePagination"
          ></TablePagination>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <AlarmsCount
          :data="{ structure_id: structureId, solution_id: solutionId }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import solutionStatisticsCount from "../../core/Solution-Statistics-Count/index";
import AlarmsCount from "../../core/Alarms-Count";
import DevicesList from "@/pages/SWAM/components/DevicesList";
import TablePagination from "@/components/TablePagination.vue";
import StructuresLocations from "../../core/StructuresLocations/index";

export default {
  components: {
    solutionStatisticsCount,
    StructuresLocations,
    AlarmsCount,
    DevicesList,
    TablePagination
  },
  data() {
    return {
      solutionId: undefined,
      structureId: undefined,
      total: 0,
      offset: 0,
      limit: 10,
      state: ""
    };
  },
  created() {
    this.solutionId = this.$route.query.solution;
    if (this.$route.query.structure) {
      this.structureId = +this.$route.query.structure;
    }
    this.fetchData();
  },
  computed: {
    currentQuery() {
      return {
        solution_id: +this.solutionId,
        structure: this.structureId,
        limit: this.limit,
        offset: this.offset
      };
    },
    device_list() {
      return this.$store.getters["SwamStore/DevicesList/items"](
        this.currentQuery
      );
    }
  },
  methods: {
    fetchData() {
      this.state = "loading";
      this.$store
        .dispatch("SwamStore/DevicesList/FETCH_ITEMS", {
          key: `solution devices list`,
          query: this.currentQuery
        })
        .then(response => {
          this.state = "loaded";
          this.total = response.total_records;
        })
        .catch(error => {
          this.state = "error";
          console.error("Error while fetching devices list", error);
        });
    },
    updatePagination(data) {
      this.limit = data.limit;
      this.offset = data.offset;
    },
    onDelete(id) {
      this.total--;
    }
  },
  watch: {
    currentQuery() {
      this.fetchData();
    }
  }
};
</script>

<style></style>
