<template>
  <card>
    <template slot="header">
      <div
        class="d-flex align-items-center justify-content-between border-bottom pb-2"
      >
        <div class="d-flex align-items-center">
          <inline-svg
            :src="require('@/assets/Icons/forklift/noun_Forklift.svg')"
            width="25"
            height="25"
          />
          <h4 class="ml-2 font-color-1 font-weight-bold">Vehicle Repair Inventory</h4>
        </div>
        <div class="d-flex align-items-center">
          <span class="font-color-4 font-weight-bold">{{inventoryTotal}}</span>
          <span class="font-color-3 ml-1">Vehicle</span>
        </div>
      </div>
    </template>
    <div class="row pb-3">
      <div class="col-12" 
      >
        <el-table :data="inventoryData" max-height="350px"> 
          <el-table-column
            prop="device_name"
            label="Vehicle"
            width="160"
          />
          <el-table-column
            label="Finish Time"
            width="160"
          >
            <template slot-scope="scope">
              <div class="d-flex align-items-center">
                <inline-svg :src="require('@/assets/Icons/forklift/Layer 3.svg')"/>
                <span class="ml-2">{{ scope.row.estimated_time ? scope.row.estimated_time : "no date" }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="driver_name"
            label="Last Driven"
            width="160"
          />
          <el-table-column
            prop="created_at"
            label="Repair Time"
            width="200"
            sortable
          >
            <template slot-scope="scope">
              <div class="d-flex align-items-center">
                <span>{{ scope.row.created_at ? dateFormat(scope.row.created_at) : "no date" }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="repairHistory"
            label="Repair History"
            width="120"
            align="center"
            >
            <template slot-scope="scope">
              <span style="cursor: pointer">
                <inline-svg @click="showHistoryPopup(scope.row)" slot="reference" :src="require('@/assets/Icons/forklift/Layer 3.svg')"/>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <TablePagination
          class="mt-3"
          slot="footer"
          ref="paginator"
          v-if="inventoryTotal"
          :total="inventoryTotal"
          @change="updatePagination"
        ></TablePagination>
      </div>
    </div>
    <Modals 
      :historyPopup="historyPopup"
      @close="closeHistoryPopup"
      :forkliftID="forklift_id" 
      :data="repairHistory"
      :total="repairHistoryTotal"
      />
  </card>
</template>

<script>
import TablePagination from "@/components/TablePagination.vue";
import Modals from '../../components/Modals'
import { mapGetters } from "vuex";
import { dateFormat } from "@/util/helper"
import HistoryList from '../../components/VehicleRepairHistory'
export default {
  name: 'VehicleRepairInventory',
  components:{
    TablePagination,
    HistoryList,
    Modals
  },
  data(){
    return {
      pagination: {
        offset: 0,
        limit: 5,
      },
      popupVisible: false,
      forklift_id: "",
      historyPopup: false
    }
  },
  computed:{
    ...mapGetters(`ForkliftStore/Portfolio/VehicleRepairInventory`, [
      "inventoryData",
      "loading",
      "inventoryTotal",
      "repairHistoryTotal",
      "repairHistory"
    ]),
  },
  methods:{
    fetchInventory() {
      const payload = {
        limit: this.pagination.limit,
        offset: this.pagination.offset,
      };
      this.$store.dispatch(
        `ForkliftStore/Portfolio/VehicleRepairInventory/fetchInventoryData`,
        payload
      );
    },
    fetchRepairHistory(){
      const payload ={
        id: this.forklift_id,
        limit: this.pagination.limit,
        offset: this.pagination.offset
      }
      this.$store.dispatch(`ForkliftStore/Portfolio/VehicleRepairInventory/fetchRepairHistoryData`,payload)
    },
    updatePagination(data) {
      this.pagination.limit = data.limit;
      this.pagination.offset = data.offset;
      this.fetchInventory()
    },
    showHistoryPopup(item){
      this.historyPopup = true;
      this.forklift_id = item.forklift_id
      this.fetchRepairHistory()
    },
    closeHistoryPopup(){
      this.historyPopup = false
    },
    dateFormat
  },
  created(){
    this.fetchInventory()
  }
}
</script>

<style lang="scss" scoped>
.card {
  button {
    margin: 0;
    border-radius: 0;
    border: 1px solid $primary !important;
    width: 80px;
  }
  .btn-primary:active,
  .btn-primary:visited {
    background: $primary !important;
  }
  .btn-primary:hover {
    border-color: $primary !important;
    background: $primary !important;
    color: white;
    transform: translateY(0px);
  }
  .btn-primary:focus {
    background: $primary !important;
    color: white;
    transform: translateY(0px);
  }
}
.point {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-inline-start: 5px;
}
.popup{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 600px !important;
  min-height: 400px !important;
  z-index: 99;
  visibility: hidden;
}
.visible{
  visibility: visible !important;
}
.el-popover{
  background: transparent !important;
}
</style>