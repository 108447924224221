<template>
  <card style="min-height:83.5%" v-loading="loading">
    <template slot="header">
      <vehicle-summary :data="data"></vehicle-summary>
      <div class="d-flex mt-4 justify-content-between align-items-center">
        <div>
          <base-select
            :inline="true"
            :light="true"
            v-model="currentPeriodIndex"
            :options="periodsOptions"
          />
        </div>
        <div>
          <el-date-picker
            v-model="date"
            type="datetimerange"
            style="max-width:300px"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            :clearable="false"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div class="d-flex align-items-center">
          <inline-svg
            :src="require('@/assets/Icons/forklift/noun_Repair.svg')"
          />
          <span class="font-color-4 font-weight-bold mx-2">Repair status</span>
          <el-tooltip
            v-if="data.in_repair"
            :content="`For ${getDurationString(data.in_repair_for, '')}`"
            placement="top"
          >
            <span class="good font-color-4 font-weight-bold underline">
              In repair
            </span>
          </el-tooltip>
          <span v-else class="font-color-4 font-weight-bold">
            ready
          </span>
        </div>
        <div class="above-map-right">
          <base-button
            :round="false"
            size="sm"
            :type="'primary'"
            class="py-2 m-0"
          >
            Route
          </base-button>
          <base-button
            :round="false"
            size="sm"
            :type="'primary'"
            :simple="filter.mapType === 0"
            class="py-2 m-0"
            @click="filter.mapType = 1 - filter.mapType"
          >
            Geofencing
          </base-button>
        </div>
        <div class="above-map-left">
          <div class="d-flex align-items-center">
            <inline-svg
              :src="require('@/assets/icons-2/google-map-marker.svg')"
              width="25"
              class="mr-2"
              height="25"
            />
            <h4 slot="reference" class="font-color-1 font-weight-bold">
              Current locations
            </h4>
          </div>
          <p class="font-color-1 mt-2">
            {{ mapData.current_location }}
          </p>
        </div>
      </div>
    </template>
    <base-map
      :zoom="15"
      v-if="!loading"
      :center="center"
      :style="`height: 100%`"
    >
      <base-map-path :path="mapData.data">
        <template slot="start-popover">
          <h4 class="key font-color-4 font-weight-bold">Start point time</h4>
          <h4 class="key font-color-1">
            {{ startTime }}
          </h4>
        </template>
        <template slot="end-popover">
          <h4 class="key font-color-4 font-weight-bold">End point time</h4>
          <h4 class="key font-color-1">
            {{ endTime }}
          </h4>
          <h4 class="key font-color-4 font-weight-bold">Trip Duration</h4>
          <h4 class="key font-color-1">
            {{ tripDuration }}
          </h4>
        </template>
      </base-map-path>
      <base-map-circle
        v-if="filter.mapType"
        :center="{ lng: geofence.lng, lat: geofence.lat }"
        :radius="geofence.r"
      />
    </base-map>
    <el-dialog title="Trip Data" :visible.sync="tripDialog" width="30%">
      <trip-data-dialog :data="mapData"></trip-data-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tripDialog = false">Cancel</el-button>
      </span>
    </el-dialog>
  </card>
</template>

<script>
import BaseMap from "src/components/BaseMap/Map";
import MapPath from "src/components/BaseMap/MapPath.vue";
import MapMarker from "src/components/BaseMap/MapMarker.vue";
import MapCircle from "src/components/BaseMap/MapCircle";
import { RadioGroup, RadioButton, Popover } from "element-ui";
import { onePeriod, onePeriodInTwoDays } from "@/util/periodOptions";
import FilterTabs from "@/components/Filter-Tabs";
import ForkliftService from "../services/forklift.service";
import { dateFormat, getPercentColor, getDurationString } from "@/util/helper";
import { mapActions, mapGetters, mapState } from "vuex";
import VehicleSummary from "../pages/device/components/VehicleSummary";
import TripDataDialog from "./TripDataDialog.vue";
import moment from "moment";
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    [BaseMap.name]: BaseMap,
    [MapMarker.name]: MapMarker,
    [MapPath.name]: MapPath,
    [MapCircle.name]: MapCircle,
    [Popover.name]: Popover,
    FilterTabs,
    VehicleSummary,
    TripDataDialog
  },
  async mounted() {
    await this.updateData();
    await this.getGeofence();
  },
  data: () => ({
    filter: {
      mapType: 0
    },
    currentPeriodIndex: 6,
    date: [new Date(Date.now() - 24 * 2 * 60 * 60 * 1000), new Date()],
    loading: false,
    selectedDate: "",
    periodsOptions: onePeriodInTwoDays,
    pickerOptions: {
      disabledDate(time) {
        return time.getTime() > Date.now();
      }
    },
    mapData: {},
    geofence: {},
    tripDialog: false
  }),
  methods: {
    getDurationString,
    dateFormat,
    async updateData() {
      if (this.date.length === 0) {
        this.date[0] = this.periodsOptions[this.currentPeriodIndex]
          .from()
          .toDate();
        this.date[1] = this.periodsOptions[this.currentPeriodIndex]
          .to()
          .toDate();
      }
      this.loading = true;
      let start = this.date[0].toISOString();
      let end = this.date[1].toISOString();
      let data = await ForkliftService.getTimeline(
        this.$route.params.id,
        `?start=${start}&end=${end}`
      );
      this.loading = false;
      this.mapData = data.data;
    },
    async getGeofence() {
      this.loading = true;
      let data = await ForkliftService.getGeofence(this.$route.params.id);
      this.loading = false;
      this.geofence = data.data.data[0];
    },
    showTripData() {
      this.tripDialog = true;
    }
  },
  watch: {
    date(val) {
      this.updateData();
    },
    $route: function(val) {
      this.updateData();
    },
    currentPeriodIndex(val) {
      this.date[0] = this.periodsOptions[val].from().toDate();
      this.date[1] = this.periodsOptions[val].to().toDate();
      this.updateData();
    }
  },
  computed: {
    ...mapState({
      drivers(state, getters) {
        return getters[`ForkliftStore/Device/DriverInfo/drivers`];
      }
    }),
    markers() {
      return this.mapData.data || [];
    },
    center() {
      return (
        this.markers[this.markers.length - 1] || {
          lng: 55.1228196,
          lat: 24.962552
        }
      );
    },
    startTime() {
      if (this.mapData.data.length > 0) {
        return this.dateFormat(this.mapData.data[0].created_at);
      }
    },
    endTime() {
      if (this.mapData.data.length > 0) {
        let endPoint = this.mapData.data[this.mapData.data.length - 1];

        if (this.mapData.data[0].created_at == endPoint.created_at)
          return `Arrived at ${moment(endPoint.created_at).format(
            "MMMM Do YYYY, h:mm:ss a"
          )}`;
        else return this.dateFormat(endPoint.created_at);
      }
    },
    tripDuration() {
      return this.getDurationString(this.mapData.trip_duration, "");
    }
  }
};
</script>

<style lang="scss" scoped>
.underline {
  border-bottom: 0.5px dotted #7da995;
}
.above-map-right {
  position: absolute;
  top: 130px;
  right: 14px;
  z-index: 9;
}
.above-map-left {
  position: absolute;
  top: 185px;
  left: 14px;
  z-index: 9;
  width: 300px;
  padding: 8px;
  background: #8d8d8de3;
  p,
  h4 {
    color: white !important;
  }
}
.el-popover {
  font-size: 16px !important;
}
</style>
