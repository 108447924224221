<template>
  <div class="row">
    <div class="col-md-12">
      <AlarmsFilter
        :initialFilter="{}"
        v-loading="loadingState"
        @filter="updateFilter"
      >
        <template slot="extra" slot-scope="{ filter }">
          <ExtraHistoryFilters :filter="filter" />
        </template>
      </AlarmsFilter>
    </div>
    <div class="col-12">
      <card v-loading="loadingState" bodyClasses="p-0" class="mb-2">
        <AlarmHistoryTable
          :data="table == 'status' ? statusAlarms : operationalAlarms"
          :type="table == 'status' ? 'status' : 'operational'"
        />
        <TablePagination
          slot="footer"
          ref="paginator"
          v-if="
            table == 'status' ? statusAlarms.length : operationalAlarms.length
          "
          :total="totalRecords"
          @change="updatePagination"
        />
      </card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import AlarmHistoryTable from "./components/AlarmHistoryTable";
import TablePagination from "@/components/TablePagination";
import AlarmsFilter from "@/pages/SWAM/components/AlarmsComponents/AlarmsFilter.vue";
import ExtraHistoryFilters from '@/pages/SWAM/components/AlarmsComponents/ExtraHistoryFilters.vue';

export default {
  components: {
    AlarmHistoryTable,
    TablePagination,
    AlarmsFilter,
    ExtraHistoryFilters,
  },
  data() {
    return {
      table: "status",
      date: '',
    };
  },
  created() {
    this.table =
      this.$route.name.includes("status") ? "status" : "operational";
    // if (this.table == "status") {
    //   this.fetchStatus();
    // } else {
    //   this.fetchOperational();
    // }
  },
  computed: {
    ...mapGetters("SwamStore/AlarmsHistory", {
      statusAlarms: "statusAlarms",
      operationalAlarms: "operationalAlarms",
      loadingState: "loadingState",
      totalRecords: "totalRecords",
      currentFilter: "getCurrentFilter",
    }),
  },
  methods: {
    ...mapActions("SwamStore/AlarmsHistory", {
      fetchOperational: "fetchOperationalData",
      fetchStatus: "fetchStatusData",
    }),
    ...mapMutations("SwamStore/AlarmsHistory", {
      setPaginationData: "setPaginationData",
      setFilter: "setFilter",
    }),
    updateFilter(data) {
      this.setFilter({...data,limit:10,offset:0});
      this.updateData();
      if (this.$refs.paginator) this.$refs.paginator.goTo(10, 0);
    },
    updatePagination(data) {
      this.setPaginationData(data);
      if(this.currentFilter.limit!=data.limit && this.currentFilter.offset!=data.offset)
      this.updateData();
    },
    updateData() {
      if (this.table == "status") {
        this.fetchStatus();
      } else {
        this.fetchOperational();
      }
    },
  },
};
</script>

<style>
</style>
