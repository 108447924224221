<template>
  <card class="mb-0" :headerClasses="'high-card-shadow pb-2'" :bodyClasses="'strc-info'">
    <el-page-header @back="back" slot="header" content="Floor Profile"></el-page-header>
    <slot name="path"></slot>
    <div class="row p-2" v-if="floor">
      <div class="col-md-5">
        <SingleInfoCard title="Name" :value="floor.name" />
      </div>
      <div class="col-md-3">
        <SingleInfoCard title="Spaces Count" :value="floor.spaces_count" />
      </div>
      <el-divider v-if="floor.spaces.length" slot="header">Total Spaces Types</el-divider>
      <div v-for="(value,key) in spacesTypes" :key="key" class="col-6 col-md-4">
        <SingleInfoCard :title="key+'s'" :value="value" />
      </div>
    </div>
  </card>
</template>

<script>
import { PageHeader } from "element-ui";
import { mapGetters } from "vuex";
export default {
  components: {
    [PageHeader.name]: PageHeader
  },
  data() {
    return {
      spacesTypes: null
    };
  },
  methods: {
    back() {
      this.$store.dispatch("toStructure");
    },
    calc() {
      this.spacesTypes = this.floor.spacesTypes;
    }
  },
  computed: {
    ...mapGetters({
      floor: "selectedFloor"
    })
  },
  mounted() {
    this.calc();
  },
  watch: {
    floor: {
      handler(newVal) {
        if (newVal) {
          this.calc();
        }
      },
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type == "updateSpaces") {
        this.calc();
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  }
};
</script>

<style scoped>
</style>