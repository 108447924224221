<template>
  <div>
    <h5 class="p-3" v-if="onStructure">
      Independent Space
      <small>(space that is directly related to structure)</small>
    </h5>
    <h5 class="p-3" v-else-if="onSpace">
      Indoor Space
      <small>(space that is part of another space)</small>
    </h5>
    <h5 class="p-3" v-else-if="onFloor">
      Normal Space
      <small>(space that is related to floor)</small>
    </h5>
    <div class="row justify-content-center top">
      <div class="col-lg-3">
        <p>Name</p>
      </div>
      <div class="col-lg-8">
        <base-input type="text" placeholder="Name" v-model="name">
          <template v-if="!isValid.name" v-slot:error>
            <label class="error">Space Name is Required</label>
          </template>
        </base-input>
      </div>
      <div class="col-lg-3">
        <p>Number</p>
      </div>
      <div class="col-lg-8">
        <base-input type="text" placeholder="Number" v-model.trim="number">
          <template v-if="!isValid.number" v-slot:error>
            <label class="error">Space Number Should be Alphanumeric only (Without whitespaces)</label>
          </template>
        </base-input>
      </div>
      <div class="col-lg-3">
        <p>Type</p>
      </div>
      <div class="col-lg-8">
        <base-select placeholder="Type" :options="options" v-model="type">
          <template v-if="!isValid.type" v-slot:error>
            <label class="error">Space Type is Required</label>
          </template>
        </base-select>
      </div>
      <div class="col-11 mt-1">
        <textarea
          placeholder="Description"
          class="form-control"
          name="desc"
          id="desc"
          rows="25"
          v-model="desc"
        ></textarea>
      </div>
      <div class="col-12 mt-5 text-center">
        <base-button @click.native="submit">Submit</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      name: "",
      number: "",
      type: null,
      desc: null,
      isValid: {
        number: true,
        name: true,
        type: true
      }
    };
  },
  watch: {
    number: {
      handler(newVal) {
        this.validateNumber();
      }
    },
    name: {
      handler(newVal) {
        this.validateName();
      }
    },
    type: {
      handler(newVal) {
        console.log(newVal);
        this.validateType();
      }
    }
  },
  computed: {
    ...mapGetters({
      spaceOptions: "spaceOptions",
      structure:"structureInformation"
    }),
    onFloor() {
      return this.$store.state.StructureStore.selectedFloor;
    },
    onSpace() {
      return this.$store.state.StructureStore.selectedSpace;
    },
    onStructure() {
      return !this.onFloor && !this.onSpace;
    },
    options() {
      return this.spaceOptions.filter(o => {
        if (this.onSpace && o.extendable) {
          return false;
        }
        if(o.structure_type && this.structure.structure_type_id != o.structure_type){
          return false;
        }
        if(o.building_type && this.structure.building_type_id != o.building_type){
          return false
        }
        if(o.building_sub_type && this.structure.building_sub_type_id != o.building_sub_type){
          return false
        }

        return true;
      });
    }
  },
  methods: {
    validateName() {
      if (this.onStructure || this.onSpace) {
        if (this.name.length != 0) this.isValid.name = true;
        else this.isValid.name = false;
      }
    },
    validateNumber() {
      if (this.onSpace || this.onStructure) {
        this.isValid.number = /^[a-zA-Z0-9]*$/.test(this.number);
      } else {
        this.isValid.number = /^[a-zA-Z0-9]+$/.test(this.number);
      }
    },
    validateType() {
      if (typeof this.type == "number") {
        this.isValid.type = true;
      } else {
        this.isValid.type = false;
      }
    },
    validate() {
      this.validateNumber();
      this.validateName();
      this.validateType();
      if (this.isValid.name && this.isValid.number && this.isValid.type) {
        return true;
      }
      return false;
    },
    reset() {
      this.name = "";
      this.number = "";
      this.type = null;
      this.desc = null;
      this.$nextTick().then(_ => {
        this.isValid = {
          number: true,
          name: true,
          type: true
        };
      });
    },
    submit() {
      if (this.validate()) {
        this.$store
          .dispatch("submitNewSpace", {
            name: this.name,
            number: this.number,
            type_id: this.type,
            description: this.desc
          })
          .then(_ => {
            this.reset();
          })
          .catch(console.error);
      }
    }
  }
};
</script>

<style scoped>
h5 {
  color: #ffffffd1;
  text-transform: capitalize;
  letter-spacing: 1px;
}
.white-content h5 {
  color: #1d253b;
}

p {
  font-size: 1.2rem;
}
textarea {
  font-size: 1rem;
  height: 10rem;
  max-height: none;
}
@media (min-width: 800px) {
  .top {
    position: relative;
    top: 15%;
  }
}
.row {
  margin: 0 !important;
}
</style>