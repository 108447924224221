<template>
    <div>
        <el-dialog
            :title="(data ? 'Update' : 'Create') + ' Device'"
            :visible.sync="show"
            direction="rtl"
            :before-close="close"
            :append-to-body="true"
        >
            <WorkOrderDeviceForm :data="data" ref="form" />
            <div class="d-flex flex-row-reverse mt-2">
                <base-button native-type="submit" @click.prevent="submit" type="primary">{{ data ? 'Update' : 'Create' }}</base-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import WorkOrderDeviceForm from './WorkOrderDeviceForm';
import { Dialog } from 'element-ui';
export default {
    components: {
        [Dialog.name]: Dialog,
        WorkOrderDeviceForm,
    },
    props: {
        data: {}
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        ...mapActions('VsaasStore/WorkOrder',{
            createDevice: 'createDevice',
            updateDevice: 'updateDevice',
        }),
        async submit() {
            let formData = this.$refs.form.extractData();
            console.log(formData);
            if(!formData)
                return;
            if(this.data) {

            } else {
                formData.orderId = +this.$route.params.id;
                await this.createDevice(formData);
                this.close();
            }
        },
        open() {
            this.show = true;
        },
        close() {
            this.$refs.form.reset();
            this.show = false;
        }
    },
}
</script>

<style>

</style>