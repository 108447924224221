<template>
  <div>
    <router-link class="d-none" :to="{path:'/maintenance/list'}">{{$t('Back to List')}}</router-link>
    <div class="row justify-content-md-start justify-content-center">
      <card class="col-11 col-md-12">
        <label class="card-title" for="MO">{{$t('maintenanceOrder.info')}}</label>
        <form id="MO" @submit.prevent class="form-style-2 card p-3">
          <div class="row justify-content-center">
            <!-- Subject -->
            <div class="col-md-6 col-10 form-group">
              <label for="subject">{{$t('subject')}}</label>
              <input
                class="form-control mb-1"
                type="text"
                id="subject"
                v-model="subject"
                :placeholder="$t('subject')"
              />
              <template v-if="$v.subject.$error">
                <span class="error" v-if="!$v.subject.required">{{$t('subject')}} {{$t('validations.isRequired')}}</span>
              </template>
            </div>
            <!-- Device / Space -->
            <div class="col-md-6 col-10 form-group">
              <label
                for="Device-Space"
                v-if="!(selectedSpace || selectedDevice)"
              >{{$t('device')}} / {{$t('maintenanceOrder.space')}}</label>
              <label for="Device-Space" v-if="(selectedSpace || selectedDevice)">
                {{selectedDevice?'Device':(selectedSpace?'Space':'')
                }}
              </label>
              <div>
                <input
                  class="form-control text-center"
                  type="text"
                  id="Device-Space"
                  :value="targetAddress"
                  :placeholder="$t('device')+' / '+$t('maintenanceOrder.space')"
                  @focus="showModal = true"
                  :dir="lang=='en'&&targetAddress.length?'rtl':'ltr'"
                />
              </div>
              <template v-if="$v.targetAddress.$error">
                <span
                  class="error"
                  v-if="!$v.targetAddress.required"
                >{{ $t('maintenanceOrder.space') + ' ' + $t('validations.isRequired')}}</span>
              </template>
            </div>
            <!-- Maintenance Types -->
            <div class="col-md-6 col-10 row mr-0 ml-0">
              <div class="form-group col-6 pl-0">
                <label for="type">{{$t('maintenanceOrder.type')}}</label>
                <el-select
                  class="select-danger"
                  :placeholder="$t('instructions.selectMaintenanceType')"
                  v-model="type"
                >
                  <el-option
                    v-for="mt in MaintenanceTypes"
                    :key="mt.id"
                    :value="mt.id"
                    class="select-danger"
                    :label="mt.name"
                  ></el-option>
                </el-select>
                <template v-if="$v.type.$error">
                  <span class="error" v-if="!$v.type.required">{{$t('maintenanceOrder.type')}} {{$t('validations.isRequired')}}</span>
                </template>
              </div>
              <div class="form-group col-6 pr-0">
                <label for="priority">{{$t('maintenanceOrder.priority')}}</label>

                <el-select
                  class="select-danger"
                  :placeholder="$t('instructions.selectPriority')"
                  v-model="priority"
                >
                  <el-option
                    v-for="pr in priorities"
                    :key="pr.id"
                    :value="pr.id"
                    class="select-danger"
                    :label="pr.name"
                  ></el-option>
                </el-select>

                <!-- <select class="form-control" id="priority" v-model="priority">
              <option v-if="priority==null" :value="null">Select Priority</option>
              <option v-for="pr in priorities" :key="pr.id" :value="pr.name">{{pr.name}}</option>
                </select>-->
                <template v-if="$v.priority.$error">
                  <span class="error" v-if="!$v.priority.required">{{$t('maintenanceOrder.priority') + ' ' + $t('validations.isRequired')}}</span>
                </template>
              </div>
            </div>

            <!-- Schedule -->
            <div class="col-md-6 col-10 row mr-0 ml-0">
              <label class="demonstration">{{$t('maintenanceOrder.schedule')}}</label>
              <el-date-picker
                v-model="schedule"
                type="datetimerange"
                :picker-options="pickerOptions"
                :range-separator="$t('to')"
                :start-placeholder="$t('maintenanceOrder.startDate')"
                :end-placeholder="$t('maintenanceOrder.endDate')"
                align="right"
              ></el-date-picker>
            </div>
            <!-- AMC -->
            <div class="col-md-6 col-10 form-group">
              <label title="Annual Maintenance Company" for="amc">{{$t('AMC')}}</label>
              <autocomplete
                id="amc"
                :placeholder="$t('Annual Maintenance Company')"
                :title="$t('Annual Maintenance Company')"
                ref="amcAutoComplete"
                :source="amcSearch"
                results-property="data"
                :results-display="'name'"
                @input="selectAmc"
              ></autocomplete>
              <template v-if="$v.amc.$error">
                <span class="error" v-if="!$v.amc.required">{{ $t('AMC') + ' ' + $t('validations.isRequired')}}</span>
              </template>
            </div>
            <!-- RMO -->
            <div class="col-md-6 col-10 form-group">
              <label for="rmo">{{$t('maintenanceOrder.relevant')}}</label>
              <autocomplete
                id="rmo"
                :placeholder="$t('instructions.selectMaintenanceOrder')"
                ref="rmoAutoComplete"
                :source="rmoSearch"
                results-property="data"
                results-display="subject"
                @input="selectRmo"
                :disabled="!(selectedSpace || selectedDevice)"
              ></autocomplete>
            </div>
            <!-- Description -->
            <div class="col-md-6 col-10 form-group">
              <label for="desc">{{$t('description')}}</label>
              <textarea
                v-model="desc"
                class="form-control"
                id="desc"
                :placeholder="$t('maintenanceOrder.description')"
                style="height:100%"
              ></textarea>
            </div>
            <!-- attachments -->
            <div class="col-md-6 col-10 form-group">
              <label
                v-if="attachments.length != 0"
                for="attachments"
                class="files-title"
                id="attachmentsLabel"
              >{{$t('instructions.tapAddAttachments')}}</label>
              <div class="file-area">
                <p
                  v-if="attachments.length == 0"
                  class="text-white text-center files-title"
                >{{$t('Tap To add Attachments')}}</p>
                <input
                  type="file"
                  class="file-upload"
                  multiple
                  id="attachments"
                  @change="uploadFieldChange"
                />
                <div class="button-list">
                  <button
                    v-for="(att,index) in attachments"
                    :key="index"
                    class="file-btn"
                    @click="deleteFile(index)"
                  >
                    {{att.name
                    }}
                    <i class="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </card>
      <!-- <button class="btn btn-success" @click="addTask">
        Tasks
        <i class="fa fa-plus"></i>
      </button>-->
      <card class="col-11 col-md-12">
        <p
          @click="addTask"
          class="card-title files-title"
          style="width: fit-content; cursor: pointer;"
        >{{$t('maintenanceOrder.addTask')}}</p>
        <form @submit.prevent class="form-style-2 card p-3 row">
          <p v-if="tasks.length==0" class="h5 text-white align-self-center">{{$t('maintenanceOrder.noTasks')}} !</p>
          <div
            class="col-10 form-group d-flex task"
            v-for="(task,index) in $v.tasks.$each.$iter"
            :key="index"
          >
            <input
              v-model="task.name.$model"
              class="form-control my-input"
              type="text"
              id="taskTitle"
              :placeholder="$t('maintenanceOrder.taskTitle')"
            />
            <template v-if="task.name.$error">
              <span class="error" v-if="!task.name.required">{{$t('maintenanceOrder.taskTitle') + ' ' + $t('validations.isRequired')}}</span>
            </template>
            <input
              v-model="task.inside_location.$model"
              class="form-control my-input"
              type="text"
              id="taskPos"
              :placeholder="$t('maintenanceOrder.taskLocation')"
            />
            <button class="btn btn-outline-danger task-delete" @click="deleteTask(index)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </form>
      </card>
      <div class="col-12">
        <button role="button" class="btn btn-success" @click="submit">{{$t('submit')}}</button>
      </div>
    </div>
    <el-dialog :visible.sync="showModal" :lock-scroll="true" :modal="false">
      <MiniBrowser :deviceMode="true" @select="targetSelected" />
    </el-dialog>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Autocomplete from "./../../components/BaseAutoComplete";
import Axios from "axios";
import MiniBrowser from "./../Structure/Structures/Structure-Mini-Browser/mini-browser";
import { Dialog, DatePicker } from "element-ui";
import { dateFormat } from "../../util/helper";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      subject: null,
      type: null,
      priority: null,
      selectedSpace: null,
      selectedDevice: null,
      targetAddress: "",
      target: null,
      amc: null,
      desc: null,
      attachments: [],
      tasks: [],
      rmo: null,
      lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
      showModal: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "Next week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Next month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "On Next 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      schedule: ""
    };
  },
  methods: {
    fillInitalData(data) {
      this.subject = data.device_name + " - " + data.device_unique;

      this.selectedDevice = {
        id: data.id,
        space_id: data.space_id,
        device_name: data.device_name,
        template_id: data.template_id,
        contract_duration: data.contract_duration,
        amc_name: data.amc_name,
        amc_id: data.amc_id,
        customer: data.customer,
        fmc: data.fmc,
        communication: data.communication,
        structure: data.structure
      };

      this.selectedSpace = {
        id: data.space_id,
        name: data.space_name
      };

      this.targetAddress = data.structure + "  >>  " + data.device_name;

      this.amc = {
        id: data.amc_id,
        name: data.amc_name
      };
      this.target = {
        community:{
          id:data.community
        }
      }
    },
    targetSelected(target) {
      this.selectedSpace = target.selectedSpace;
      this.selectedDevice = target.device;
      this.targetAddress = target.targetAddress;
      this.target = target;
      this.showModal = false;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$notify({
          message: "There are Invalid Fields",
          title: "Invalid Data",
          type: "danger"
        });
      } else {
        let schedule = {};
        if (this.schedule && this.schedule.length == 2) {
          schedule = {
            schedule_from: dateFormat(this.schedule[0]),
            schedule_to: dateFormat(this.schedule[1])
          };
        }
        this.$store.commit("setSpinner", true);
        const data = {
          subject: this.subject,
          type_id: this.type,
          prio_id: this.priority,
          assets_id: this.amc.id,
          description: this.desc,
          parentOrderId: this.rmo,
          tasks: this.tasks,
          community_id: this.target.community.id,
          ...schedule
        };
        if (this.selectedDevice) {
          data.device_id = this.selectedDevice.id;
        }
        if (this.selectedSpace) {
          data.space_id = this.selectedSpace.id;
        } else {
          return;
        }
        console.log(data);
        this.$api.post("maintenance/order", data).then(res => {
          Promise.all(
            this.attachments.map(file => {
              return this.$store.dispatch("uploadFile", {
                url: `maintenance/order/${res.data.mo_id}/attachment`,
                file
              });
            })
          ).finally(() => {
            this.$router.push("/maintenance/list");
          });
        });
      }
    },
    getSD(structure, device) {
      this.selectedSpace = structure;
      this.selectedDevice = device;
      this.targetAddress = null;
      if (structure) {
        this.targetAddress = structure.structure_name;
      } else if (device) {
        this.targetAddress = device.device_name;
      }
      this.showModal = false;
    },
    deleteTask(index) {
      this.tasks.splice(index, 1);
    },
    deleteFile(index) {
      this.attachments.splice(index, 1);
    },
    addTask() {
      this.tasks.push({ name: null, inside_location: null });
    },
    uploadFieldChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      for (var i = files.length - 1; i >= 0; i--) {
        if (files[i].size > 10485760) {
          //10MB
          this.$notify({
            title: "Limit Exceeded",
            message: "The Maximum Size of Attachment Must Be Less Than 10MB",
            type: "danger"
          });
          continue;
        }
        this.attachments.push(files[i]);
      }
      document.getElementById("attachments").value = [];
    },
    amcSearch(input) {
      return Axios.defaults.baseURL + `maintenance/company/${input}`;
    },
    rmoSearch(input) {
      let qp = "";
      if (this.selectedDevice) {
        qp = "device_id=" + this.selectedDevice.id;
        // qp += "&space_id=null";
      } else if (this.selectedSpace) {
        qp = "space_id=" + this.selectedSpace.id;
      } else {
        qp = "space_id=" + -1;
      }
      return Axios.defaults.baseURL + `maintenance/parent?` + qp;
    },
    selectAmc(option) {
      let value = null;
      if (option) {
        value = option.selectedObject;
      }
      this.$v.amc.$model = value;
    },
    selectRmo(option) {
      let value = null;
      if (option) {
        value = option.selectedObject;
      }
      this.rmo = value;
    }
  },
  computed: {
    ...mapGetters(["MaintenanceTypes", "priorities"])
  },
  mounted() {
    console.log("mounted");
  },
  created() {
    console.log("created");
    this.$store.dispatch("getMOPrimaryInfo");
    let startWith = localStorage.getItem("tempData");
    if (startWith) {
      startWith = JSON.parse(startWith);
      this.fillInitalData(startWith);
      localStorage.removeItem("tempData");
    }
  },
  watch: {
    selectedDevice: {
      handler(newVal) {
        if (newVal) {
          if (newVal.amc_id) {
            this.amc = {
              id: newVal.amc_id,
              name: newVal.amc_name
            };
            this.$v.amc.$model = this.amc;
            this.$refs.amcAutoComplete.setValue(newVal.amc_name);
          }
        }
      }
    }
  },
  components: {
    Autocomplete,
    [Dialog.name]: Dialog,
    [DatePicker.name]: DatePicker,
    MiniBrowser
  },
  validations: {
    subject: {
      required
    },

    type: {
      required
    },
    priority: {
      required
    },
    targetAddress: {
      required
    },
    amc: {
      required
    },
    tasks: {
      $each: {
        name: {
          required
        },
        inside_location: {}
      }
    }
  }
};
</script>
<style scoped>
::v-deep .el-dialog {
  width: 78%;
  background: transparent;
}
.file-btn {
  cursor: pointer;
  background: #00f2c3;
  background-image: linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3);
  background-size: 210% 210%;
  background-position: top right;
  background-color: #00f2c3;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff;
  border-width: 2px;
  border: none;
  position: relative;
  overflow: hidden;
  margin: 4px 1px;
  border-radius: 0.4285rem;
  z-index: 1000;
}
.files-title:hover {
  text-decoration: underline;
  color: aqua;
}
.task {
  height: fit-content;
}
.tasks-form {
  min-height: 25vh;
}
.task:hover button {
  display: inline;
}
i {
  color: white;
}
#taskTitle {
  width: 50%;
}
#taskPos {
  width: 37%;
}
.my-input {
  height: 3rem;
  margin: 0 0.5rem;
  color: white;
  padding: 0 1rem;
  font-size: 1.3rem;
  background: transparent;
  border: transparent;
  border-bottom: transparent 1px solid;
}
.my-input:focus {
  border-bottom: var(--primary) 1px solid;
}
textarea {
  height: 99px;
  max-height: 100px;
}
.file-area {
  width: 95%;
  max-height: 75%;
  border: 1px dashed #cccccc;
  position: relative;
  min-height: 123px;
}
.file-upload {
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.button-list {
  position: absolute;
  left: 0;
  top: 0;
  overflow: auto;
  max-height: 115px;
  z-index: 1020;
}
.button-list button {
  margin: 0.2rem 0.4rem;
}
#attachmentsLabel {
  width: 100%;
  text-align: center;
}
#Device-Space {
  text-overflow: ellipsis;
}
</style>
