<template>
 

  <component :is="showCom"   />




</template>
<script>
import HardwareLotList from './components/HardwareLotList.vue';
import WorkOrderList from './components/WorkOrderList.vue';
import LotDetails from './components/LotDetails.vue';

export default {

computed:{
      showCom(){
        return this.$store.state.workorderstore.show;
      },
},
components:{
  HardwareLotList,
  WorkOrderList,
  LotDetails
},
mounted(){
  if (this.$route.name == "wo-details") {
      let wo_id = this.$route.params.wo_id;
      if(wo_id){
          this.getWORecord(this.$route.params.wo_id);
      }
      
    }
},
methods:{
  getWORecord(wo_id){
    this.$api
        .get(`orders/${wo_id}`)
        .then(res => {
          if (res.data.msg === "success") {
            var blocklots = res.data.data.blockLOTS;
            this.$store.dispatch("workorderstore/setWorkorderView", {
              show: "HardwareLotList",
              order:res.data.data,
              lot_list: blocklots,
              lot_details: null
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
  }
},
destroyed(){
  //this.$store.dispatch('workorderstore/reset');
}
 
};
</script>
<style scoped>
</style>
<style>

  .device-photo{
    text-align: center;
    margin:0 0 10px 0 ;
  }
</style>