<template>
  <card
    v-loading="!info"
    class="mb-0"
    :headerClasses="'high-card-shadow pt-2'"
    :bodyClasses="'strc-info'"
  >
    <div class="row" slot="header">
      <p class="col-4 main-card-title">{{$t('Structure Profile')}}</p>
      <div class="col-4">
        <p
          class="text-center m-0"
          id="struc-id"
        >{{$generateID(id,'struc')}}</p>
      </div>
      <div class="col-4">
        <p class="text-right">
          <el-tooltip
            content="Explor This Structure"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <i @click="expand" class="my-info-icon tim-icons icon-alert-circle-exc"></i>
          </el-tooltip>
        </p>
      </div>
    </div>
    <div v-if="info" class="row p-2">
      <!-- Name -->
      <div class="col-md-5">
        <card
          :bodyClasses="'d-flex justify-content-center align-items-center'"
          class="high-card-shadow my-card"
        >
          <i class="tim-icons icon-pencil card-edit-button"></i>
          <el-divider slot="header">{{$t('Name')}}</el-divider>
          <el-tooltip :content="info.structure_name" placement="top">
            <p>{{info.structure_name}}</p>
          </el-tooltip>
        </card>
      </div>
      <!-- Number -->
      <div class="col-md-3">
        <card
          :bodyClasses="'d-flex justify-content-center align-items-center'"
          class="high-card-shadow my-card"
        >
          <el-divider slot="header">{{$t('Number')}}</el-divider>
          <p>{{info.structure_number}}</p>
        </card>
      </div>
      <!-- Type -->
      <div class="col-md-4">
        <card
          :bodyClasses="'row justify-content-center align-items-center'"
          class="high-card-shadow my-card"
        >
          <el-divider slot="header">{{$t('Type')}}</el-divider>

          <p class="pl-1 pr-1">{{structure_type}},</p>
          <p v-if="info.building_type" class="pl-1 pr-1">{{info.building_type.name}},</p>
          <p v-if="info.building_sub_type" class="pl-1 pr-1">{{info.building_sub_type}}</p>
        </card>
      </div>
      <!-- Community -->
      <div class="col-md-5">
        <card
          :bodyClasses="'row justify-content-center align-items-center'"
          class="high-card-shadow my-card"
        >
          <el-divider slot="header">{{$t('Community')}}</el-divider>

          <p class="pl-1 pr-1">{{info.community.community_name}}</p>
        </card>
      </div>
      <!-- City -->
      <div class="col-md-3">
        <card
          :bodyClasses="'row justify-content-center align-items-center'"
          class="high-card-shadow my-card"
        >
          <el-divider slot="header">{{$t('City')}}</el-divider>

          <p class="pl-1 pr-1">{{info.city}}</p>
        </card>
      </div>
      <!-- Country -->
      <div class="col-md-4">
        <card
          :bodyClasses="'row justify-content-center align-items-center'"
          class="high-card-shadow my-card"
        >
          <el-divider slot="header">{{$t('Country')}}</el-divider>

          <p class="pl-1 pr-1">{{info.country}}</p>
        </card>
      </div>
      <!-- Floors -->
      <div class="col-md-4">
        <card
          :bodyClasses="'row justify-content-center align-items-center'"
          class="high-card-shadow my-card"
        >
          <el-divider slot="header" class="floors">
            <el-tooltip
              content="Explor Structure Floors"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <div class="el-divider__text is-center" @click="expand">{{$t('Floors')}}</div>
            </el-tooltip>
          </el-divider>

          <p class="pl-1 pr-1">{{info.floor_count}}</p>
        </card>
      </div>
      <!-- Parking -->
      <div class="col-md-4">
        <card
          :bodyClasses="'row justify-content-center align-items-center'"
          class="high-card-shadow my-card"
        >
          <el-divider slot="header">{{$t('Parking')}}</el-divider>

          <p class="pl-1 pr-1">{{info.parking.name}} {{$t('Parking')}}</p>
        </card>
      </div>
      <!-- Date OF Construction -->
      <div class="col-md-4">
        <card
          :bodyClasses="'row justify-content-center align-items-center'"
          class="high-card-shadow my-card"
        >
          <el-divider slot="header">{{$t('Date OF Construction')}}</el-divider>

          <p class="pl-1 pr-1">{{gDateFormat(info.date_of_construction).substr(0,10)}}</p>
        </card>
      </div>
      <!-- Address -->
      <div class="col-md-8">
        <card
          :bodyClasses="'row justify-content-center align-items-center'"
          class="high-card-shadow my-card mb-0"
        >
          <el-divider slot="header">{{$t('address')}}</el-divider>

          <p class="pl-1 pr-1">{{info.address}}</p>
        </card>
      </div>
      <!-- Location -->
      <div class="col-md-4">
        <card class="high-card-shadow my-card mb-0">
          <el-divider slot="header">{{$t('Location')}}</el-divider>

          <div>
            <p class="my-span-title">{{$t('Latitude')}}:</p>
            <p class="my-span">{{info.lat}}</p>
          </div>
          <div>
            <p class="my-span-title">{{$t('longitude')}}:</p>
            <p class="my-span">{{info.lng}}</p>
          </div>
          <div>
            <p class="my-span-title">{{$t('map')}}:</p>
            <p class="my-span">
              <base-switch v-model="showMap" on-text="ON" off-text="OFF" style="margin-bottom: 0;"></base-switch>
            </p>
          </div>
        </card>
      </div>
      <transition name="slide">
        <div class="col-md-12 mt-3" v-show="showMap">
          <base-map :zoom="12" :center="info.center" :lng="info.center" style="height: 400px">
            <template v-for="(marker, index) in info.markers">
              <base-map-marker
                :key="index"
                :position="marker.position"
                @click="center=marker.position"
              />
            </template>
          </base-map>
        </div>
      </transition>
    </div>
  </card>
</template>

<script>
import { mapGetters } from "vuex";
//import { Divider } from "element-ui";
import BaseSwitch from "@/components/BaseSwitch";
import BaseMap from 'src/components/BaseMap/Map'
import MapMarker from "src/components/BaseMap/MapMarker.vue";

export default {
  data() {
    return {
      id: null,
      showMap: false
    };
  },
  watch: {
    showMap() {
      this.$nextTick().then(() => {
        var messageBody = document.getElementsByClassName("strc-info")[0];
        if (messageBody)
          messageBody.scrollTop =
            messageBody.scrollHeight - messageBody.clientHeight;
      });
    }
  },
  components: {
    // [Divider.name]: Divider,
    BaseSwitch,
    [BaseMap.name]: BaseMap,
    [MapMarker.name]: MapMarker,
  },
  created() {
    this.id = this.$route.params.id;
  },
  computed: {
    ...mapGetters({
      info: "structureInformation",
      structure_type: "s_type"
    })
  },
  methods: {
    expand() {
      this.$store.dispatch("getFloors");
      this.showMap = false;
      this.$emit("explor");
    }
  }
};
</script>

<style scoped lang="scss">
.main-card-title {
  font-size: 1rem;
  margin: 0;
  letter-spacing: 1px;
}
.my-span {
  display: inline-block !important;
  width: 50%;
  text-align: center;
  color: $primary-states;
  text-decoration: underline;
  letter-spacing: 1.5px;
}
.my-span-title {
  display: inline-block !important;
  width: 50%;
}
.my-card {
  height: 90%;
}
.my-card::v-deep .card-header {
  padding: 0;
}
.my-card::v-deep .card-body {
  padding-top: 0;
}
.my-card::v-deep .card-body p {
  text-transform: capitalize;
  font-size: 1.1rem;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  min-width: fit-content;
}
.type-block p {
  margin-bottom: 5px !important;
}
::v-deep .el-divider__text {
  color: $primary !important;
  text-align: center;
}
::v-deep .el-divider {
  background-color: $primary !important;
}
::v-deep .floors .el-divider__text:hover {
  color: $primary-states !important;
  cursor: pointer;
}
.mapSwitch {
  flex: 0 0 50%;
  justify-content: center;
  padding: 0 15px;
  padding-top: 5px;
}
.mapSwitch label {
  margin: 0 10px;
  font-size: 0.9rem;
}
::v-deep .card-body::-webkit-scrollbar {
  width: 7px;
}
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-8 {
  padding: 0 7px;
}
.my-info-icon {
  font-size: 1.2rem;
  font-weight: bold;
  color: $primary;
  cursor: pointer;
}
.my-info-icon:hover {
  color: $primary-states;
}
.slide-enter-active {
  animation: slide-in 0.2s linear forwards;
}
.slide-leave-active {
  animation: slide-out 0.2s linear forwards;
}
@keyframes slide-in {
  from {
    transform: translateX(100px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes slide-out {
  from {
    transform: translateX(0px);
    opacity: 1;
  }
  to {
    transform: translateX(100px);
    opacity: 0;
  }
}
.card-edit-button {
  display: none;
}
::v-deep .my-card:hover .card-edit-button {
  display: inline;
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  cursor: pointer;
}
#struc-id {
  color: $primary; padding-top: 3px;
}
</style>
