<template>
  <card class="h-100 custom-border custom-bg">
    <div class="row ">
      <div class="col-md-6">
        <div class="row align-items-end h-100">
          <div class="col-4 text-center">
            <inline-svg :src="require('@/assets/Icons/car-name.svg')" width="70" height="60"  class="mb-1" />
            <h4 class="m-0">VECHILE</h4>
            <h4 class="h-2">{{vehicle_name}}</h4>

          </div>
          <div class="col-4 text-center">
            <inline-svg :src="require('@/assets/Icons/khmer.svg')" width="40"  class="mb-1"  />
            <h4 class="m-0">Total Kmag</h4>
            <h4 class="h-2">{{total_distance}} km</h4>
          </div>
          <div class="col-4 text-center">
            <inline-svg :src="require('@/assets/Icons/temperature.svg')" width="40" class="mb-1"  />
            <h4 class="m-0">Engine Temp</h4>
            <h4 class="h-2">{{engine_temperature}}  °C</h4>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row justify-content-end">
          
         
          <div class="col-md-6 text-center">
            <inline-svg :src="require('@/assets/Icons/clock.svg')" width="40" />
            <div class="info-label one mt-3">
              <span class="d-block">Total Driving Time</span>
              <span>{{total_work_time}}</span>
            </div>
            <div class="info-label two mt-2">
              <span class="d-block">Total Stopping Time</span>
              <span>00:00</span>
            </div>
            <div class="info-label three mt-2">
              <span class="d-block">Total Utilisation</span>
              <span>00:00</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>
<script>
import {mapState} from 'vuex';

export default {

  computed:{
    ...mapState('CarTrackerStore',{
      carData:state=>state.carData
    }),
    total_distance(){
      return this.carData.total_distance || 0;
    },
    engine_temperature(){
      return this.carData.engine_temperature || '-';
    },
    vehicle_name(){
      return this.carData.vehicle_name;
    },
    total_work_time(){
      return this.carData.total_work_time || '00:00';
    }
  }

};
</script>
<style scoped>
.h-2{
  color:#3ca271;
}
.custom-border{
  border-left: 20px solid green;
}
.info-label {
  border-radius: 20px;
}
.one {
  background-color: #2598f6;
}
.two {
  background-color: #43fb23;
}
.three {
  background-color: #f84f72;
}
.custom-bg{
  background-image: url('/img/world-map.svg');
  background-position: center;
  background-size: cover;
}
</style>