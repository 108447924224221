<template>
  <div class="device-map-wrapper">
    <div class="row devicereading">
      <!-- Stats Cards -->
      <span v-loading="!device" class="w-100 d-block"></span>
      <template v-if="device">
        <div class="col-lg-3 col-md-6 col-12 De-Rd" v-if="person">
          <stats-card :title="person.email" :sub-title="'Name'" :type="'warning'">
            <img v-if="image" slot="icon" :src="image" />
            <img v-else slot="icon" :src="image" />
          </stats-card>
        </div>
        <div class="col-lg-3 col-md-6 col-12 De-Rd" v-else>
          <stats-card title="NONE" :sub-title="'Assign User'" :type="'warning'">
            <img slot="icon" src="@/assets/Icons/user.svg" alt="user" />
            <span>NO USER</span>
          </stats-card>
        </div>
        <div class="col-lg-3 col-md-6 col-12 De-Rd" v-for="card in statsCards" :key="card.title">
          <stats-card :title="card.title" :sub-title="card.subTitle" :type="card.type">
            <div slot="footer" v-html="card.footer"></div>
            <img slot="icon" :src="card.svg" alt="Communication Icon 1950311" />
          </stats-card>
        </div>
      </template>
    </div>

    <br />

    <!--  -->

    <div class="centeral">
      <div>
        <card class="device-path-map">
          <template slot="header">
            <div class="row">
              <div class="col-md-6">
                <h3>Time Line</h3>
              </div>
              <div class="col-md-6">
                <base-input>
                  <el-date-picker type="date" placeholder="Date Time Picker" v-model="date"></el-date-picker>
                </base-input>
              </div>
            </div>
          </template>
          <base-map :center="center" style="height: 300px">
<!--            <gmap-polyline-->
<!--              v-if="path.length > 0"-->
<!--              :path="path"-->
<!--              :editable="false"-->
<!--              :options="lineOptions"-->
<!--              ref="polyline"-->
<!--            ></gmap-polyline>-->
            <base-map-polyline
              v-if="path.length > 0"
              :path="path"
              :editable="false"
              :options="lineOptions"
              ref="polyline"
            ></base-map-polyline>
            <template v-for="(marker, index) in line">
              <base-map-marker
                :key="index"
                :position="marker"
                :icon="markerOptions"
              />
            </template>
            <base-map-marker
              v-if="locationStart"
              :icon="require('@/assets/Icons/location-start.svg')"
              :position="locationStart"
            />
            <base-map-marker
              v-if="locationEnd"
              :icon="require('@/assets/Icons/location-end.svg')"
              :position="locationEnd"
            />
          </base-map>
        </card>
      </div>
      <div>
        <Chart />
      </div>

      <div class="device-proximity">
        <card>
          <div slot="header">
            <h4 class="card-title">Proximity People</h4>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>SN</th>
                    <th>Name</th>
                    <!-- <th>MAC</th> -->
                    <th>Duration (sec)</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(record,index) in queriedData"
                    :key="index"
                    :class="{'live':record.live_indecator}"
                  >
                    <td>
                      <div class="tel-photo">
                        <img src="@/assets/Icons/gps.png" alt="Table image" />
                      </div>
                    </td>
                    <td>{{index+1}}</td>
                    <td>
                      <div
                        style="cursor: pointer;"
                        @click="setDevice(record.proximity.other_device)"
                      >{{record.proximity.other_device ? record.proximity.other_device.device_name : 'Unkowen'}}</div>
                    </td>
                    <!-- <td>
                        <div >{{record.proximity.other_device.mac}}</div>
                    </td>-->
                    <td>
                      <div>{{record.proximity.duration}}</div>
                    </td>
                    <td>
                      <div>{{gDateFormat(record.timestamp)}}</div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- <el-table :data="queriedData" >
                <el-table-column min-width="50" label="#" align="center">
                  <div class="tel-photo">
                    <img src="@/assets/Icons/gps.png" alt="Table image" />
                  </div>
                </el-table-column>

                <el-table-column min-width="150" label="Name">
                  <div
                    slot-scope="props"
                  >{{props.row.proximity.other_device ? props.row.proximity.other_device.device_name : 'Unkowen'}}</div>
                </el-table-column>

                <el-table-column min-width="150" label="MAC">
                  <div slot-scope="props">{{props.row.proximity.other_device.mac}}</div>
                </el-table-column>

                <el-table-column min-width="150" label="Duration (sec)">
                  <div slot-scope="props">{{props.row.proximity.duration}}</div>
                </el-table-column>

                <el-table-column min-width="150" label="Date">
                  <div slot-scope="props">{{gDateFormat(props.row.timestamp)}}</div>
                </el-table-column>
              </el-table>-->
            </div>
            <!-- pagination -->
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class>
                <p class="card-category">Showing {{ from }} to {{ to }} of {{ total }} entries</p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
    <!-- assign person -->

    <assignPerson v-if="openAssignPersonModal" :id="id" @close="openAssignPersonModal = false" />
  </div>
</template>
<script>
//import {Marker} from 'VueGoogleMaps'
//import Proximity from "./Proximity.vue";
import StatsCard from "src/components/Cards/StatsCard";
import Chart from "./Chart.vue";
import { TimeSelect, DatePicker } from "element-ui";
import assignPerson from "./assignPerson.vue";
import { Table, TableColumn } from "element-ui";
import BaseMap from 'src/components/BaseMap/Map'
import MapMarker from "src/components/BaseMap/MapMarker.vue";
import MapPolyline from "src/components/BaseMap/MapPolyline.vue";

//socket info
// deviceId: 50
// otherDevice: {…}
// battery_current: 0
// battery_level: 30
// battery_voltage: 4
// created_at: "2020-07-30T19:38:18.000Z"
// device_details: "undefined\nspace 59"
// device_name: "9551"
// gsm_operator: 42402
// gsm_signal: 3
// id: 53
// imei: 359633107929551
// last_zone_update: "2020-08-29T15:17:04.000Z" ​​
// mac: null
// manufacturer: null
// manufacturer_id: null
// order_detail_id: 12​​
// id: null ​​
// solution_name: "Proximity"​​
// space_id: 59
// template_id: 8
// updated_at: null

// event duration
// deviceId: 50
// duration: 13

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    //Proximity,
    StatsCard,
    Chart,
    assignPerson,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [BaseMap.name]: BaseMap,
    [MapMarker.name]: MapMarker,
    [MapPolyline.name]: MapPolyline,
  },
  data() {
    return {

      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [3, 10, 25, 50],
        total: 0,
      },
      id: null,
      device: null,
      proximity: [],
      time_line: [],
      date: new Date(),
      options: {
        //mapTypeId: "satellite",
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
      lineOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: 2.0,
        strokeWeight: 5,
      },
      markerOptions: {
        path: "M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0",
        strokeOpacity: 0.7,
        strokeWeight: 4,
        strokeColor: "rgb(243,114,114)",
        fillColor: "rgb(255,255,255)",
        fillOpacity: 0.7,
        scale: 1,
      },
      openAssignPersonModal: false,
    };
  },
  watch: {
    date() {
      this.fetchTimeline();
    },
  },

  computed: {

    
    queriedData() {
      return this.proximity.slice(this.from, this.to);
    },
    total() {
      return this.proximity.length;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    
    statsCards() {
      if (this.device) {
        let info = this.device;
        return [
          {
            title: info.device_name,
            subTitle: "Device Name",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Device.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
          {
            title: info.device_details,
            subTitle: "Details",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Communication.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },

          {
            title: info.mac,
            subTitle: "MAC",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/mac.svg"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
          {
            title: info.imei,
            subTitle: "IMEI",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/simcard.svg"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
          {
            title: "90%",
            subTitle: "Battery Life",
            type: "warning",
            icon: "tim-icons icon-chat-33",
            svg: require("@/assets/Icons/Se.png"),
            footer: '<i class="tim-icons icon-refresh-01"></i> ',
          },
        ];
      }
      return [];
    },

    person() {
      if (this.device) {
        return this.device.person;
      }
      return null;
    },
    image() {
      if (this.person) {
        return this.$api.defaults.baseURL + this.person.img_path;
      }
      return null;
    },

    // id() {
    //   if (this.device) {
    //     let info = this.device.device;
    //     return info.id;
    //   }
    //   return null;
    // },
    path() {
      return this.time_line.map((record) => {
        return {
          lat: record.lat,
          lng: record.lng,
        };
      });
    },
    // line() {
    //   let path = [...this.path];
    //   path.shift();
    //   path.pop();
    //   return path;
    // },
    locationEnd() {
      if (this.path.length > 0) {
        return this.path[0];
      }
      return null;
    },
    locationStart() {
      if (this.path.length > 0) {
        return this.path[this.path.length - 1];
      }
      return null;
    },
    center() {
      let result = { lat: 25.1201, lng: 55.3782 };
      if (this.path.length > 0) {
        result = this.path[0];
      }
      return result;
    },
  },

  mounted() {
    this.init();
  },
  methods: {

    setSocketEvents() {
      let id = this.id;
      let vm = this;
      

      this.$TeltonikaSocket.emit('join room',{ room: id });

      this.$TeltonikaSocket.subscribe('proximity violation',function (data) {
          console.log("proximity violation socket event", data);

          let record = {
            live_indecator: true,
            timestamp: new Date(),
            device: data.deviceId,
            proximity: {
              other_device: data.otherDevice,
              duration: null,
            },
          };

          vm.proximity.unshift(record);
          vm.removeIndecator();
        });


        this.$TeltonikaSocket.subscribe('proximity duration',function (data) {
          console.log("proximity duration socket event", data);
          let other_imei = data.other_imei;
          //todo update only devices with other-imei
          let duration = data.duration;
          vm.proximity.forEach((obj) => {
            if (!obj.proximity.duration) {
              obj.proximity.duration = duration;
            }
          });
        })




      // if (this.socket && this.is_connected) {

      //   console.log('teltonika socket start...');

        
        

      //   let vm = this;
      //   this.socket.emit("join room", { room: id });

      //   this.socket.on("proximity violation", function (data) {
      //     console.log("proximity violation socket event", data);

      //     let record = {
      //       live_indecator: true,
      //       timestamp: new Date(),
      //       device: data.deviceId,
      //       proximity: {
      //         other_device: data.otherDevice,
      //         duration: null,
      //       },
      //     };

      //     vm.proximity.unshift(record);
      //     vm.removeIndecator();
      //   });

      //   this.socket.on("proximity duration", function (data) {
      //     console.log("proximity duration socket event", data);

      //     let duration = data.duration;
      //     vm.proximity.forEach((obj) => {
      //       if (!obj.proximity.duration) {
      //         obj.proximity.duration = duration;
      //       }
      //     });
      //   });
        




      // }
    },
    init() {
      this.device = null;

      let id = this.$route.params.id;
      if (id) {
        
        this.id = id;
        this.setSocketEvents();
        this.fetchDevice();
        this.fetchProximity();
        this.fetchTimeline();
      }
    },
    setDevice(device) {
      let id = this.id;
      if (device && id) {
        this.$router.push({ path: `/healthcare/deviceinfo/${device.id}` });
        this.$$TeltonikaSocket.emit("leave room", { room: id });
        this.init();
      }
    },
    removeIndecator() {
      let vm = this;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(function () {
        vm.proximity.forEach((obj) => {
          obj.live_indecator = false;
        });
      }, 3000);
    },

    fetchDevice() {
      this.$api
        .get(`teltonika/devices/${this.id}`)
        .then((res) => {
          if (res.data.msg === "success") {
            this.device = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchProximity() {
      this.$api
        .get(`teltonika/proximity/${this.id}`)
        .then((res) => {
          if (res.data.msg === "success") {
            this.proximity = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchTimeline() {
      if (this.date) {
        let date = new Date(this.date.getTime());
        date.setHours(0, 0, 0, 0);
        let startDate = new Date(date.getTime()).toISOString();
        let endDate = new Date(date.getTime());
        endDate.setDate(endDate.getDate() + 1);
        endDate = endDate.toISOString();
        this.$api
          .get(
            `teltonika/devices/${this.id}/timeline?start=${startDate}&end=${endDate}`
          )
          .then((res) => {
            if (res.data.msg === "success") {
              this.time_line = res.data.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    handleClickForDelete() {},
  },
  beforeDestroy() {
    let id = this.id;
    this.$TeltonikaSocket.unSubscribeAll();
    if (id) {
      this.$TeltonikaSocket.emit("leave room", { room: id });
      
    }
  },
};
</script>
<style scoped>
.device-proximity img {
  max-width: 40px;
}
.live {
  background-color: cadetblue;
}
.device-map-wrapper .centeral {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1em;

  /* grid-auto-rows: minmax(471px,auto); */
}

/* media 981px */

@media only screen and (min-width: 981px) {
  .device-map-wrapper .centeral {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-gap: 1em;

    /* grid-auto-rows: minmax(471px,auto); */
  }
  .device-map-wrapper .device-proximity {
    grid-column: 1/3;
  }
}

.device-map-wrapper .device-icon {
  max-width: 32px;
}

.device-path-map {
  height: 100%;
  min-height: 400px;
}

.list-enter-active {
  transition: all 5s;
}
.list-enter {
  background: yellow;
}
</style>