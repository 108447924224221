<template>
  <div class="col-md-5">
    <div><label>{{ $tc('sensorWords.status') }}: </label></div>
    <div class="d-flex">
      <base-checkbox
        v-for="(f, i) in options"
        :key="i"
        v-model="f.value"
        class="col-4"
        @input="change(i)"
      >
        {{ $t(f.title) }}
      </base-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  props: ["filter", "options", "initialFilter"],
  created() {
    if (this.initialFilter) {
      this.initialFilter.forEach((init, i) => {
        const option = this.options.find((o) => o.title == init);
        if (option) {
          option.value = true;
        }
        this.$forceUpdate();
      });
    }
  },
  methods: {
    change(index) {
      if (!this.filter.status) this.filter.status = [];
      if (this.options[index].value) {
        this.filter.status.push(this.options[index].title);
      } else {
        const i = this.filter.status.findIndex(
          (x) => x == this.options[index].title
        );
        this.filter.status.splice(i, 1);
        if (!this.filter.status.length) this.filter.status = undefined;
      }
    },
  },
  watch: {
    filter: {
      handler(v) {
        this.options.forEach((f) => {
          if (!v.status || v.status.every((sf) => sf != f.title)) {
            f.value = false;
          } else {
            f.value = true;
          }
        });
        this.$forceUpdate();
      },
      deep: true,
    },
  },
  destroyed() {
    this.options.forEach((o) => (o.value = false));
  },
};
</script>


<style scoped>
</style>
