<template>
  <div>
    <SensorsColorsDialog :data="colorData" v-if="colorData.visible" />
    <i
      class="fas fa-palette sensor-color-icon sensor-color-icon-drawer"
      :class="{ 'text-white': isDarkMode }"
      @click="drawer = true"
    ></i>
    <el-drawer
      :visible.sync="drawer"
      :direction="'rtl'"
      class="main-drawer-area"
    >
      <h4 slot="title" class="font-weight-bold">{{ $t("choseNewColor") }}</h4>
      <div class="drawer-body">
        <div class="search-input-area">
          <base-input
            :placeholder="$t('search.search')"
            v-model="searchValue"
          ></base-input>
        </div>
        <div class="sensors-color-area">
          <div
            v-for="(item, index) in filteredSensorsList"
            class="sensor-item d-flex is-justify-space-between align-content-center"
            :key="index"
            @click="openColorDialog(item)"
          >
            <div class="w-50 font-color-2 font-weight-900">{{ item.name }}</div>
            <div class="sensor-color d-flex justify-content-end">
              <span class="color-rec" :style="{ backgroundColor: item.color }">
                <i class="fas fa-palette sensor-color-icon text-white"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { Drawer } from "element-ui";
import { mapGetters } from "vuex";
// import SensorsColorsDialog from "./SensorsColorsDialog";
import UserPreferencesGetters from "../global/user-preferences-store/types/getter-types";
export default {
  name: "SensorsColors",
  props: {
    sensorsToShow: {
      type: Array,
      default: () => [],
      description: "you can pass sensors ids for filtring sensors by this ids",
    },
  },
  components: {
    SensorsColorsDialog: ()=> import("./SensorsColorsDialog"),
    [Drawer.name]: Drawer,
  },
  data() {
    return {
      /**
       * @type array
       * @desc drawer shown value
       * @author karam mustafa
       *
       * */
      drawer: false,
      /**
       * @type array
       * @desc available color data
       * @author karam mustafa
       *
       * */
      colorData: {
        visible: false,
        info: {},
      },
      /**
       * @type string
       * @desc available color data
       * @author karam mustafa
       *
       * */
      searchValue: "",
    };
  },
  methods: {
    /**
     * @param {Object} item
     * @desc open color dialog
     * @author karam mustafa
     * */
    openColorDialog(item) {
      this.colorData.visible = true;
      this.colorData.info = item;
    },
  },
  computed: {
    ...mapGetters(["isDarkMode"]),
    ...mapGetters("UserPreferencesStore", {
      allSensors: UserPreferencesGetters.GET_SENSORS_COLORS,
    }),
    sensorsList() {
      if (this.sensorsToShow.length == 0) {
        return this.allSensors;
      }
      return this.allSensors.filter((sensor) => {
        return this.sensorsToShow.some((sensorId) => sensorId == sensor.id);
      });
    },
    filteredSensorsList() {
      if (this.searchValue === "") {
        return this.sensorsList;
      }
      return this.sensorsList.filter(
        (item) =>
          item.name.startsWith(this.searchValue) ||
          item.name.startsWith(this.searchValue.toUpperCase())
      );
    },
    /**
     * @desc determine page direction
     * @author karam mustafa
     * */
    dir() {
      return this.$rtl.isRTL ? "rtl" : "ltr";
    },
  },
};
</script>

<style scoped lang="scss">
.sensor-color-icon {
  padding-inline-start: 20px;
  cursor: pointer;
  font-size: 20px;
}

/deep/ .main-drawer-area .sensors-color-area {
  height: 85% !important;

  overflow-y: auto !important;
}

.sensor-color-icon-drawer {
  font-size: 25px !important;
  padding-inline-start: 25px;
  padding-top: 3px;
}

.drawer-body {
  padding: 0 10px;
  height: 100% !important;
}

.search-input-area {
  margin: 0 10px;
}

.sensor-item {
  cursor: pointer;
  margin: 10px 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid;
  box-shadow: 0px 3px 6px #0000003f;
}

.sensor-color {
  width: 50%;
}

.color-rec {
  width: 40px;
  height: 30px;
  font-size: 12px;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.color-rec i {
  padding: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
@media (min-width: 1000px) {
  /deep/ .main-drawer-area .el-drawer {
    width: 30% !important;
  }
}
</style>
