<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="customtableheader">
            <h4 slot="header" class="card-title">Webhooks Table-{{company_name}}</h4>

            <el-tooltip content="Create Webhook" effect="light" :open-delay="300" placement="top">
              <base-button type="primary" round icon @click="WebhookCreate">
                <i class="tim-icons icon-simple-add"></i>
              </base-button>
            </el-tooltip>
          </div>

          <div>
            <el-table :data="queriedData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              ></el-table-column>

              <el-table-column :min-width="135" align="right" label="Actions">
                <div slot-scope="props">
                  <base-button
                    @click.native="WebhookUpdate(props.row)"
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button
                    @click.native="handleDelete(props.$index, props.row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class>
                <p class="card-category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
              </div>
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>

              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import Fuse from "fuse.js";
import swal from "sweetalert2";

export default {
  props: ["id"],
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  data() {
    return {
      company_name: "",
      hooks_list: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["name", "email", "age"],
      tableColumns: [
        {
          prop: "id",
          label: "Id",
          minWidth: 100,
        },
        {
          prop: "url",
          label: "Url",
          minWidth: 200,
        },
        {
          prop: "token",
          label: "Token",
          minWidth: 200,
        },
      ],
      searchedData: [],
      fuseSearch: null,
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.hooks_list;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.hooks_list.length;
    },
  },

  methods: {
    WebhookCreate() {
      this.$router.push({
        path: `/company/${this.id}/webhook/create`,
        query: { name: this.company_name },
      });
    },
    WebhookUpdate(hook) {
      console.log(hook);
      this.$router.push({
        path: `/company/${hook.company_id}/webhook/${hook.id}/update`,
        query: { name: this.company_name },
      });
    },
    fetchWebhooks() {
      this.$api
        .get(`webhook/company/${this.id}`)
        .then((res) => {
          if (res.data.msg === "success") {
            this.hooks_list = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleDelete(index, row) {
      swal({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteHook(row);
          //   swal({
          //     title: "Deleted!",
          //     text: `You deleted ${row.name}`,
          //     type: "success",
          //     confirmButtonClass: "btn btn-success btn-fill",
          //     buttonsStyling: false,
          //   });
        }
      });
    },
    deleteHook(row) {
      this.$api
        .delete(`webhook/${row.id}`)
        .then((res) => {
          console.log("delete", res);
          if (res.status === 204) {
            swal({
              title: "Deleted!",
              text: `You deleted ${row.url}`,
              type: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
            this.deleteRow(row);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteRow(row) {
      let indexToDelete = this.hooks_list.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.hooks_list.splice(indexToDelete, 1);
      }
    },
  },
  mounted() {
    this.company_name = this.$route.query.name;
    this.fetchWebhooks();
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["name", "email"],
      threshold: 0.3,
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.hooks_list;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>
<style scoped>
</style>
