<template>
  <div class="">
   
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="customtableheader">
            <h4 slot="header" class="card-title">{{$t("Table.WORK ORDER- HARDWARE LOT")}}</h4>

            <div>
            <el-tooltip content="Go back" effect="light" :open-delay="300" placement="top">
           <base-button type="primary" round icon @click="back">
                <i class="tim-icons icon-minimal-left"></i>
          </base-button>
            </el-tooltip>
         
          <el-tooltip content="Add More Order Blocks" effect="light" :open-delay="300" placement="top">
           <base-button type="primary" round icon @click="createOrderDetails">
                <i class="tim-icons icon-simple-add"></i>
          </base-button>
          </el-tooltip>
          
            </div>

          

          
          </div>
          <div>
            
            <el-table :data="hardwarelot">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              ></el-table-column>
              
              <el-table-column :min-width="135" align="right"  :label="$t('Actions')">
                
                  <div slot-scope="props">
                    <el-tooltip content="Show Block Devices" effect="light" :open-delay="300" placement="top">
                   <base-button
                    @click.native="showlotdetails(props.row)"
                    class="like btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-notes"></i>
                  </base-button>
                    </el-tooltip>

                  <el-tooltip content="Edit" effect="light" :open-delay="300" placement="top">
                  <base-button
                    @click.native="handleEdit(props.row)"
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  </el-tooltip>
                  <!-- <el-tooltip content="Delete" effect="light" :open-delay="300" placement="top">
                  <base-button
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                  </el-tooltip> -->
                </div>
                
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            
            

          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>



import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
//import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },

   
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */

    hardwarelot() {
      let list = this.$store.state.workorderstore.lot_list;
      if (list) {
        return list;
      }
      return [];
    },
    
    
    
  },
  data() {
    return {
      
     
      
      tableColumns: [
        // {
        //     prop:'id',
        //     label:'PO Series',
        //     minWidth:100
        // },
        {
            prop:'name',
            label:this.$i18n.t("Title"),
            minWidth:250
        },
        {
            prop:'solution.name',
            label:this.$i18n.t("Solution"),
            minWidth:200
        },
        {
            prop:'quantity',
            label:this.$i18n.t("Quantity"),
            minWidth:200
        },
        {
            prop:'auth.name',
            label:this.$i18n.t("AUTHORITY"),
            minWidth:200
        },

       
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {
    back(){
        this.$store.dispatch('workorderstore/setWorkorderView',{
            show:'WorkOrderList',
            order:null,
            lot_list:null,
            lot_details:null,
            devices:[]
          })
    },
    createOrderDetails(){
      if(this.hardwarelot.length > 0){
          let orderid = this.hardwarelot[0].order_id;
          this.$router.push({path:`createLot/${orderid}`})
      }
      
    },
     enddate(date) {
      let contract_duration = date.split("|");
      let end = moment(contract_duration[1]).format("MM/DD/YYYY");
      return end;
    },
    showlotdetails(lot){

      this.$store.dispatch('workorderstore/getDevices',lot);

        // let order = this.$store.state.workorderstore.workorder.order;

        // this.$store.dispatch('workorderstore/setWorkorderView',{
        //     show:'LotDetails',
        //     order,
        //     lot_list:this.hardwarelot,
        //     lot_details:lot
        //   })
    },
    
    handleEdit(row) {
      this.$router.push({path:`updateLot/${row.order_id}/${row.id}`})
    },
    // handleDelete(index, row) {
    //   swal({
    //     title: "Are you sure?",
    //     text: `You won't be able to revert this!`,
    //     type: "warning",
    //     showCancelButton: true,
    //     confirmButtonClass: "btn btn-success btn-fill",
    //     cancelButtonClass: "btn btn-danger btn-fill",
    //     confirmButtonText: "Yes, delete it!",
    //     buttonsStyling: false
    //   }).then(result => {
    //     if (result.value) {
    //       this.deleteRow(row);
    //       swal({
    //         title: "Deleted!",
    //         text: `You deleted ${row.name}`,
    //         type: "success",
    //         confirmButtonClass: "btn btn-success btn-fill",
    //         buttonsStyling: false
    //       });
    //     }
    //   });
    // },
    // deleteRow(row) {
    //   let indexToDelete = this.tableData.findIndex(
    //     tableRow => tableRow.id === row.id
    //   );
    //   if (indexToDelete >= 0) {
    //     this.tableData.splice(indexToDelete, 1);
    //   }
    // }
  },
  
  
};
</script>
<style scoped>

</style>
