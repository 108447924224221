<template>
  <div>
    <vehicle-repair-history 
      :data="data"
      :total="total"
      :forkliftID="forkliftID" 
      :isOpen="historyPopup"
      @close="close" />
  </div>
</template>

<script>
import VehicleRepairHistory from './VehicleRepairHistory'
export default {
  name: "",
  props:{
    title: {
      type: String
    },
    historyPopup:{
      type: Boolean
    },
    forkliftID: {},
    data:{
      default: () => {}
    },
    total: {}
  },
  components:{
    VehicleRepairHistory,
  },
  computed:{
    show:{
        get(){
          return this.isOpen;
        },
        set(val){
          this.$emit('close')
        }
    }
  },
  methods:{
    close(){
      this.$emit('close')
    },
  }
}
</script>