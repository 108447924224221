<template>
  <v-popover
    offset="16"
    :popoverClass="'sensor-popover'"
    :popoverWrapperClass="'sensor-wrapper'"
    :popoverInnerClass="'sensor-info'"
  >
    <i class="fa fa-info-circle info-icon"></i>
    <template slot="popover">
      <div class="text-left" dir="ltr">
        <h4>{{information.name}}</h4>
        <p>{{information.sensorInfo}}</p>
        <template v-if="information.high && information.high.length > 0">
          <h4 class="mt-4">High {{information.name}}</h4>
          <ul>
            <li v-for="(desc, index) in information.high" :key="index">
              <p>{{desc.text}}</p>
            </li>
          </ul>
        </template>
        <template v-if="information.medium&&information.medium.length > 0">
          <h4 class="mt-4">Medium {{information.name}}</h4>
          <ul>
            <li v-for="(desc, index) in information.medium" :key="index">
              <p>{{desc.text}}</p>
            </li>
          </ul>
        </template>
        <template v-if="information.low&&information.low.length > 0">
          <h4 class="mt-4">Low {{information.name}}</h4>
          <ul>
            <li v-for="(desc, index) in information.low" :key="index">
              <p>{{desc.text}}</p>
            </li>
          </ul>
        </template>
      </div>
    </template>
  </v-popover>
</template>


<style>
.sensor-popover {
  max-width: 500px;
  width: 500px;
}
.sensor-info h4,
.sensor-info p,
.sensor-info li {
  color: #000 !important;
}
.sensor-info {
  padding: 10px;
}
</style>

<script>
export default {
  name: "info",
  props: {
    information: {
      required: true
    }
  }
};
</script>