<template>
  <card class="h-100 tel-widget">
    <div class="d-flex mr-2 ml-2 mb-4 justify-content-between">
        <h2 class="m-0">Man Down Alert</h2>
     
        <img src="@/assets/Icons/man-down.svg" class="imgicon" />
    
    </div>

     <div class="d-flex justify-content-between mt-3 mr-2 ml-2">
      <span><inline-svg 
      :src="require('@/assets/Icons/falling_white.svg')" 
      width="20" 
      height="20"
      class="mr-2 svgicon align-top"
      fill="#000"
       />on high alart</span>
      <span class=" border d-block text-center  rounded num">3</span>
    </div>
    <div class="d-flex justify-content-between mt-3 mr-2 ml-2">
      <span><inline-svg 
      :src="require('@/assets/Icons/falling_white.svg')" 
      width="20" 
      height="20"
      class="mr-2 svgicon align-top"
      fill="#000"
       />on high alart</span>
      <span class=" border d-block text-center  rounded num">3</span>
    </div>
    <div class="d-flex justify-content-between mt-3 mr-2 ml-2">
      <span><inline-svg 
      :src="require('@/assets/Icons/falling_white.svg')" 
      width="20" 
      height="20"
      class="mr-2 svgicon align-top"
      fill="#000"
       />on high alart</span>
      <span class=" border d-block text-center  rounded num">3</span>
    </div>
    

    
  </card>
</template>
<script>
export default {};
</script>
<style scoped>
</style>