<template>
  <card class="px-3">
    <template slot="header">
      <locks-header />
    </template>
    <!-- <base-select
      :light="false"
      v-model="value"
      :options="options"
      placeholder="Filter"
      :editStyle="true"
      class="mb-3 mr-2 w-100"
      popperClass="select"
    /> -->
    <div class="row">
      <div class="col-2">
        <span >
          <base-checkbox class="mb-4 mr-2" v-model="lockType.ble2" >
            <span class="title"> 2G BLE PadLocks</span>
          </base-checkbox>

        </span>
      </div>
      <div class="col-2">
        <base-checkbox class="mb-4 mr-2" v-model="lockType.ble" @click="filterLockType"
          ><span class="title"> BLE PadLocks</span></base-checkbox
        >
      </div>
      <div class="col-2">
        <base-checkbox class="mb-4 mr-2" v-model="lockType.door" @click="filterLockType"
          ><span class="title"> Door Locks</span></base-checkbox
        >
      </div>
      <div class="col-2">
        <base-checkbox class="mb-4 mr-2" v-model="lockType.access" @click="filterLockType"
          ><span class="title"> Access Control</span></base-checkbox
        >
      </div>
      <div class="col-2">
        <base-checkbox class="mb-4 mr-2" v-model="lockType.parink" @click="filterLockType"
          ><span class="title"> Parking Locks</span></base-checkbox
        >
      </div>
    </div>
    <el-table :data="locks" class="striped-table">
      <el-table-column
        prop="name"
        label="Name"
        min-width="100px"
        align="center"
      >
        <div slot-scope="prop">
          <router-link
            tag="span"
            :to="`/smartlock/locks/${prop.row.mac}`"
            style="cursor: pointer"
          >
            {{ prop.row.mac ? prop.row.mac : "info" }}
          </router-link>
        </div>
      </el-table-column>
      <el-table-column min-width="100px" label="QR Code" align="center">
        <template slot-scope="prop">
          <base-button
            type="primary"
            icon
            @click="showQRCode(prop.row)"
            class="mr-2"
          >
            <inline-svg
              :src="require('@/assets/Icons/smartlock/qr_code-24px.svg')"
            />
          </base-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="location"
        label="Location"
        min-width="100px"
        align="center"
      >
        <div slot-scope="prop">
          {{ prop.row.location ? prop.row.last_unlocked_by : "info" }}
        </div>
      </el-table-column>
      <el-table-column
        prop="last_unlocked"
        label="Last Unlocked"
        min-width="150px"
        align="center"
      >
        <div slot-scope="prop">
          {{ prop.row.last_unlocked ? prop.row.last_unlocked : "info" }}
        </div>
      </el-table-column>
      <el-table-column
        prop="last_unlocked_by"
        min-width="150px"
        label="Last Unlocked By"
        align="center"
      >
        <div slot-scope="prop">
          {{ prop.row.last_unlocked_by ? prop.row.last_unlocked_by : "info" }}
        </div>
      </el-table-column>
      <el-table-column
        prop="last_reported_by"
        min-width="150px"
        label="Last Reported By"
        align="center"
      >
        <div slot-scope="prop">
          {{ prop.row.last_reported_by ? prop.row.last_reported_by : "info" }}
        </div>
      </el-table-column>
      <el-table-column
        prop="status"
        min-width="100px"
        label="Status"
        align="center"
      >
        <div slot-scope="prop">
          {{ prop.row.status ? "Online" : "Offline" }}
        </div>
      </el-table-column>
      <el-table-column
        prop="status"
        min-width="160px"
        label="Times Unlocked"
        align="center"
      >
        <div slot-scope="prop">
          {{
            prop.row.times_unlocked
              ? prop.row.times_unlocked
              : "20/10.000 times"
          }}
        </div>
      </el-table-column>
      <el-table-column
        prop="battery_life"
        :min-width="150"
        align="left"
        label="Battery Lift"
      >
        <div slot-scope="prop" class="mw-10">
          <radial-progress-bar
            :diameter="80"
            :completed-steps="Math.round(30)"
            :startColor="'#53AA83'"
            :innerStrokeColor="'#B8E2CF'"
            :total-steps="100"
            :innerStrokeWidth="5"
            :strokeWidth="5"
          >
            <p class="font-weight-bold font-size-16" style="color: #41B581">
              {{ Math.round(30) }}%
            </p>
          </radial-progress-bar>
        </div>
      </el-table-column>
      <el-table-column
        prop="status"
        min-width="150px"
        label="Next Charge Due"
        align="center"
      >
        <div slot-scope="prop">
          {{
            prop.row.next_charge_due
              ? prop.row.next_charge_due
              : "Feb 18th, 2021"
          }}
        </div>
      </el-table-column>
      <el-table-column min-width="100px" label="Access" align="center">
        <template slot-scope="{}">
          <div class="d-flex align-items-center justify-content-center">
            <base-button type="primary" icon class="mr-2" @click="showQuick">
              <inline-svg
                :src="require('@/assets/Icons/smartlock/login-24px.svg')"
              />
            </base-button>
            <base-button
              type="primary"
              icon
              class="mr-2"
              @click="showScheduled"
            >
              <inline-svg
                :src="require('@/assets/Icons/smartlock/schedule-24px.svg')"
              />
            </base-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="250px" label="Action" align="center">
        <template slot-scope="prop">
          <div class="d-flex align-items-center justify-content-center">
            <inline-svg
              v-if="prop.row.is4G"
              class="mx-1"
              style="cursor: pointer"
              width="30"
              height="30"
              @click="handleUnlock(prop)"
              :src="require(`@/assets/Icons/smartlock/${prop.row.locked ? 'vpn_key-locked-24px': 'vpn_key-unlocked-24px'}.svg`)"
            />
            <inline-svg
              @click="showAlertSettings"
              class="mx-1"
              style="cursor: pointer"
              width="30"
              height="30"
              :src="require('@/assets/Icons/smartlock/settings-24px.svg')"
            />
            <inline-svg
              class="mx-1"
              style="cursor: pointer"
              width="30"
              height="30"
              :src="require('@/assets/Icons/smartlock/restore-24px.svg')"
            />
            <inline-svg
              class="mx-1"
              style="cursor: pointer"
              width="30"
              height="30"
              :src="require('@/assets/Icons/smartlock/border_color-24px.svg')"
            />
            <inline-svg
              class="mx-1"
              style="cursor: pointer"
              width="30"
              height="30"
              :src="require('@/assets/Icons/smartlock/delete-24px.svg')"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <TablePagination
      slot="footer"
      ref="paginator"
      :total="10"
      @change="updatePagination"
    ></TablePagination>
    <Modals
      :createQuickPopupVisible="createQuickPopupVisible"
      :createScheduledPopupVisible="createScheduledPopupVisible"
      :alertSettingsPopupVisible="alertSettingsPopupVisible"
      :qrCodePopupVisible="qrCodePopupVisible"
      @close="closeCreateQuickPopup"
      :qr="qr"
      :title="type"
    />
  </card>
</template>

<script>
import LocksHeader from "./components/LocksHeader";
import RadialProgressBar from "vue-radial-progress";
import TablePagination from "@/components/TablePagination.vue";
import Modals from "./components/Modals";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Locks",
  components: {
    LocksHeader,
    RadialProgressBar,
    TablePagination,
    Modals
  },
  data() {
    return {
      lockType: {
        ble: false,
        door: false,
        access: false,
        parking: false
      },
      data: [
        {
          id: "1234",
          name: "Info",
          location: "Info",
          last_unlocked: "Info",
          last_unlocked_by: "Info",
          last_reported_by: "21/1/2021 9:00",
          battery_life: 20,
          status: "Locked"
        }
      ],
      pagination: {
        offset: 0,
        limit: 5
      },
      createQuickPopupVisible: false,
      createScheduledPopupVisible: false,
      alertSettingsPopupVisible: false,
      qrCodePopupVisible: false,
      type: "",
      qr: {
        number: "",
        code: ""
      }
    };
  },
  computed: {
    ...mapGetters("SmartLockStore/Locks", {
      locks: "locks"
    })
  },
  methods: {
    ...mapActions("SmartLockStore/Locks", {
      getLocks: "fetchData",
      updateLock: "updateLock"
    }),
    handleUnlock(val){
      console.log(val.row)
      if(!val.row.online){
        this.$notify({
          title: 'Error',
          message: 'Lock should be connected and online',
          type: 'warning'
        });
        return;
      }
      const payload = {
        mac: val.row.mac
      };
      this.updateLock(payload);
    },
    updatePagination(data) {
      this.pagination.limit = data.limit;
      this.pagination.offset = data.offset;
    },
    showQuick() {
      this.createScheduledPopupVisible = false;
      this.createQuickPopupVisible = true;
      this.alertSettingsPopupVisible = false;
      this.qrCodePopupVisible = false;
    },
    showScheduled() {
      this.createScheduledPopupVisible = true;
      this.createQuickPopupVisible = false;
      this.alertSettingsPopupVisible = false;
      this.qrCodePopupVisible = false;
    },
    showAlertSettings() {
      this.createScheduledPopupVisible = false;
      this.createQuickPopupVisible = false;
      this.alertSettingsPopupVisible = true;
      this.qrCodePopupVisible = false;
    },
    closeCreateQuickPopup() {
      this.createQuickPopupVisible = false;
      this.createScheduledPopupVisible = false;
      this.alertSettingsPopupVisible = false;
      this.qrCodePopupVisible = false;
    },
    showQRCode(prop) {
      let size = "300x300";
      let val = prop.qr;
      let baseUrl = "http://api.qrserver.com/v1/create-qr-code/";
      let url = `${baseUrl}?data=${val}&size=${size}`;
      this.qr.code = prop.qr;
      this.qr.number = prop.imei;
      this.qr.qrUrl = url;
      this.qrCodePopupVisible = true;
      this.createScheduledPopupVisible = false;
      this.createQuickPopupVisible = false;
      this.alertSettingsPopupVisible = false;
    },
    filterLockType(){
    }
  },
  async mounted() {
    await this.getLocks();
  }
};
</script>
