<template>
  <el-dialog title="Create Scheduled Access" width="900px" :visible.sync="show" custom-class="lock-quick-access">
      <div class="row">
        <div class="col-xl-6">
          <div class="mb-3">
            <base-input
              placeholder="Name"
              class="mb-0">
            </base-input>
          </div>
          <div class="mb-3">
            <base-input
              placeholder="Email"
              class="mb-0">
            </base-input>
          </div>
          <div class="d-flex align-items-center mb-3">
            <base-select
              v-model="value"
              :options="options"
              placeholder="+1 (ca)"
              class="mb-0"
              style="box-shadow: none !important; width: 25%"
            />
            <base-input
              placeholder="Phone number"
              class="mb-0 ml-2"
              style="width: 75%"
              >
            </base-input>
          </div>
          <div class="mb-3">
            <base-input
              placeholder="Company (Optional)"
             class="mb-0">
            </base-input>
          </div>
          <div class="mb-3">
            <base-input
              placeholder="Reason"
              class="mb-0">
            </base-input>
          </div>
          <div class="mt-5">
            <span class="font-color-4 font-weight-bold mb-0">Send access via</span>
            <div class="row">
              <div class="col-lg-4" v-for="(item,i) in via" :key="i">
                <base-checkbox v-model="item.visible" :checked="item.visible" class="mx-2">
                  {{ item.label }}
                </base-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <el-date-picker class="date-class" type="date" placeholder="Start" v-model="start_date"></el-date-picker>
            <base-select
              v-model="value"
              :options="options"
              placeholder="Time"
              class="mb-0 ml-2 date-class"
              style="box-shadow: none !important"
            />
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <el-date-picker class="date-class" type="date" placeholder="End" v-model="end_date"></el-date-picker>

            <base-select
              v-model="value"
              :options="options"
              placeholder="Time"
              class="mb-0  ml-2 date-class"
              style="box-shadow: none !important"
            />
          </div>
          <div class="d-flex align-items-center justify-content-between mb-2">
            <span class="font-color-4 font-weight-bold mb-0">Set unlock frequency</span>
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label class="btn btn-sm btn-primary btn-simple" @click="checked = 'Yes'" :class="{ active: checked == 'Yes' }">
              <input type="radio" name="options" autocomplete="off" />
              <span class="d-none d-sm-block">Yes</span>
            </label>
            <label class="btn btn-sm btn-primary btn-simple" @click="checked = 'No'" :class="{ active: checked == 'No' }">
              <input type="radio" name="options" autocomplete="off" />
              <span class="d-none d-sm-block">No</span>
            </label>
          </div>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <base-select
              v-model="value"
              :options="options"
              placeholder="Times"
              class="mb-0 date-class"
              style="box-shadow: none !important"
            />
            <base-select
              v-model="value"
              :options="options"
              placeholder="Daily"
              class="mb-0 ml-2 date-class"
              style="box-shadow: none !important"
            />
          </div>
          <div class="mt-3" >                
            <span class="font-color-4 font-weight-bold">Send Days</span>
            <div class="row mt-2">
              <div class="col-lg-4" v-for="(item,i) in days" :key="i">
                <base-checkbox v-model="item.visible" :checked="item.visible" class="mx-2">
                  {{ item.label }}
                </base-checkbox>
              </div>
            </div>
          </div>
          <div class="mt-3 mb-1 px-3 py-3 bg-grey">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-color-4 font-weight-bold">Supervisor :</h4>
              <span >Ahmad Ali</span>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-color-4 font-weight-bold">Call :</h4>
              <span >+971 56 63 234 44</span>
            </div>
            <base-checkbox v-model="sendAlert" :checked="sendAlert" class="mx-2">
              Send Alert to supervisor if there is breach
            </base-checkbox>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-2 ">
        <base-button style="border-radius: 1px" class="btn btn-primary btn-simple" @click="close">Cancel</base-button>
        <base-button style="border-radius: 1px" class="btn btn-primary ">Submit</base-button>
      </div>
  </el-dialog>
</template>

<script>

export default {
  name: "create-quicke-access",
  props:{
    isOpen: {
        type: Boolean
    },
    type:{
      type: String
    }
  },
  data(){
    return{
      options: [],
      value: "",
      checked: "Yes",
      via: [
        {
          label: "Whatsapp",
          visible: false
        },
        {
          label: "Email",
          visible: false
        },
        {
          label: "Sms",
          visible: false
        },
        {
          label: "Telegram",
          visible: true
        },
        {
          label: "Signal",
          visible: true
        },
      ],
      days: [
        {
          label: "Sunday",
          visible: false
        },
        {
          label: "Monday",
          visible: false
        },
        {
          label: "Tuesday",
          visible: false
        },
        {
          label: "Wednesday",
          visible: true
        },
        {
          label: "Thursday",
          visible: true
        },
        {
          label: "Friday",
          visible: true
        },
      ],
      start_date: "",
      end_date: "",
      sendAlert: false
      
    }
  },
  computed:{
    show:{
        get(){
          return this.isOpen;
        },
        set(val){
          this.$emit('close')
        }
    }
  },
  methods:{
    close(){
      this.$emit('close')
    }
  }
}
</script>

<style >
.white-content .lock-quick-access{
  background: white !important 
}
.lock-quick-access .date-class{
  width: 50% !important
}
.white-content .bg-grey{
  background: #DCDFE6
}
</style>