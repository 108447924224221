<template>
    
      
          <card class="h-100">
          <tabs type="success" size="sm" >
            <tab-pane label="Devices Map" size="sm">
                <DeviceLocationMap />
            </tab-pane>
            <tab-pane label="Alarms Map" size="sm" >
              <AlarmLocationMap />
            </tab-pane>
          </tabs>
          </card>
        
</template>
<script>
    import AlarmLocationMap from './AlarmLocationMap.vue';
    import DeviceLocationMap from './DeviceLocationMap.vue';

    import { TabPane, Tabs } from 'src/components';
export default {
    components:{
        AlarmLocationMap,
        DeviceLocationMap,
        TabPane,
        Tabs

    }
}
</script>
<style scoped>
.nav-item{
  background-color: chartreuse;
}
.map-mar{
    margin-bottom: 0px !important;;
}

</style>