<template>
  <div class="row">
    <div class="col-md-2-5">
      <!-- <base-multi-select
        :collapseTags="true"
        label="Alarm Action Type"
        placeholder="Alarm Type"
        v-model="alarmType"
        :options="alarmTypes"
      /> -->
      <base-select
        :label="$t('actionType')"
        :placeholder="$t('instructions.select') + $t('actionType')"
        v-model="alarmType"
        :options="alarmTypes"
      />
    </div>
    <div class="col-md-2-5">
      <base-input
        :label="$t('alarmID')"
        v-model.trim="alarmId"
        :placeholder="$t('alarmID')"
      />
    </div>
    <div class="col-md-2-5">
      <AutoComplete
        :label="$t('deviceWords.deviceName')"
        :placeholder="$t('search.searchFor') + ' ' + $t('device')"
        :source="endPoint"
        v-model="selectedTargetValue"
        :resultsDisplay="'name'"
        resultsProperty="data"
        ref="search"
      />
    </div>
    <div class="col w-100">
      <base-input :label="$t('date')">
        <el-date-picker
          v-model="date"
          type="datetimerange"
          range-separator="--"
          :start-placeholder="$t('from')"
          :end-placeholder="$t('to')"
          class="d-flex align-items-center"
        >
        </el-date-picker>
      </base-input>
    </div>
  </div>
</template>

<script>
import { DatePicker } from "element-ui";
import AutoComplete from "@/components/BaseAutoComplete.vue";
import axios from "@/axiosConfig";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    AutoComplete,
  },
  props: ["filter"],
  data() {
    return {
      alarmId: "",
      date: "",
      deviceId: null,
      selectedTargetValue: null,
      alarmType: undefined,
      // alarmType: [undefined],
      alarmTypes: [
        { label: "All" },
        { label: "termination", value: "terminate" },
        { label: "escalation", value: "escalate" },
        { label: "status change", value: "status change" },
        { label: "create", value: "create" },
        { label: "note", value: "comment" },
        { label: "acknowledgement", value: "acknowledge" },
      ],
    };
  },
  created() {
    this.$set(this.filter, "alarm_id", undefined);
    this.$set(this.filter, "device_id", undefined);
    this.$set(this.filter, "start", undefined);
    this.$set(this.filter, "end", undefined);
    this.$set(this.filter, "type", undefined);
  },
  methods: {
    endPoint(query) {
      return axios.defaults.baseURL + "devices/search/" + query;
    },
  },
  watch: {
    alarmType: {
      handler() {
        // let temp;
        // if(this.alarmType){
        //   if(this.alarmType.some(t=>!t)){
        //     this.alarmType = [undefined];
        //   }
        //   temp= this.alarmType;
        // }else{
        //   this.alarmType = [undefined];
        // }
        // this.filter.type = temp;
        if (this.alarmType) {
          this.filter.type = [this.alarmType];
        } else {
          this.filter.type = undefined;
        }
      },
      deep: true,
    },
    selectedTargetValue() {
      if (this.selectedTargetValue) {
        this.deviceId = this.selectedTargetValue.selectedObject.id;
      } else {
        this.deviceId = undefined;
      }
      this.filter.device_id = this.deviceId;
    },
    alarmId() {
      if (!this.alarmId) {
        this.filter.alarm_id = undefined;
        return;
      }
      if (this.alarmId.match(/[^0-9]/g)) {
        let result = this.alarmId;
        let matches = this.alarmId.match(/[^0-9]/g);
        for (let i = 0; i < matches.length; i++) {
          result = result.replace(matches[i], "");
        }
        this.alarmId = result;
      } else {
        if(this.alarmId == ""){
          this.alarmId = undefined
        }
        this.filter.alarm_id = this.alarmId;
      }
    },
    date() {
      if (!this.date) return;
      this.filter.start = new Date(this.date[0]);
      this.filter.end = new Date(this.date[1]);
    },
    filter: {
      handler() {
        if (!this.filter.type) {
          this.alarmType = undefined;
        }
        if (!this.filter.device_id) {
          this.deviceId = undefined;
          this.selectedTargetValue = null;
          this.$refs["search"].clear();
        }
        if (!this.filter.alarm_id) {
          this.alarmId = undefined;
        }
        if (!this.filter.start) {
          this.date = undefined;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
::v-deep .el-date-editor .el-range__icon,
::v-deep .el-date-editor .el-range__close-icon {
  line-height: 20px;
}
</style>
