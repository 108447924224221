<template>
  <card class="h-100">
    <template slot="header">
      <div class="row">
        <div class="col-md-4">
          <h3>Trip Map</h3>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-6">
              <base-input>
                <el-date-picker type="datetime" placeholder="Start Date" v-model="startDate" @change="setNewDate"></el-date-picker>
              </base-input>
            </div>
            <div class="col-md-6">
              <base-input>
                <el-date-picker type="datetime" placeholder="End Date" v-model="endDate" @change="setNewDate"></el-date-picker>
              </base-input>
            </div>
          </div>
        </div>
      </div>
    </template>
    <gmap-map :center="center" :zoom="16" style="width:100%;  height:600px" :options="options">
      <gmap-polyline
        v-if="path.length > 0"
        :path="path"
        :editable="false"
        :options="lineOptions"
        ref="polyline"
      ></gmap-polyline>
      <!-- <gmap-marker
              :key="index"
              v-for="(m, index) in line"
              :icon="markerOptions"
              :position="m"
      ></gmap-marker>-->
      <gmap-marker
        v-if="locationStart"
        :icon="{ 
                url: require('@/assets/Icons/location-start.svg'),
                scaledSize: { height: 50, width: 50 },
                }"
        :position="locationStart"
      ></gmap-marker>
      <gmap-marker
        v-if="locationEnd"
        :icon="{ 
                url: require('@/assets/Icons/car.svg'),
                scaledSize: { height: 50, width: 50 },
                }"
        :position="locationEnd"
      ></gmap-marker>
    </gmap-map>
  </card>
</template>
<script>
import { TimeSelect, DatePicker } from "element-ui";
import { mapState, mapActions } from "vuex";

    let date = new Date();
    //copy
    let startDate = new Date(date.getTime());
    startDate.setDate(startDate.getDate() -1);
    let endDate = new Date(date.getTime());

export default {
  props: ["id"],
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    //Proximity,
  },
  data() {
    return {
      
      startDate:startDate,
      endDate:endDate,
      
      options: {
        //mapTypeId: "satellite",
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
      lineOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.5,
        strokeWeight: 5,
        geodesic: true,
      },
      markerOptions: {
        path: "M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0",
        strokeOpacity: 0.7,
        strokeWeight: 4,
        strokeColor: "rgb(243,114,114)",
        fillColor: "rgb(255,255,255)",
        fillOpacity: 0.7,
        scale: 1,
      },
    };
  },
  // watch: {
  //   dateRange:{
  //     handler(){
  //       let id = this.id;
  //       let {startDate,endDate} = this.dateRange;
  //       if(id && startDate && endDate){
  //         this.fetchTimeline({id,startDate,endDate})
  //       }


  //     },
  //     deep:true
  //   }
  // },

  computed: {
    ...mapState("CarTrackerStore", {
      time_line: (state) => state.time_line,
    }),

    path() {
      return this.time_line.map((record) => {
        return {
          lat: record.lat,
          lng: record.lng,
        };
      });
    },
    locationEnd() {
      if (this.path.length > 0) {
        return this.path[0];
      }
      return null;
    },
    locationStart() {
      if (this.path.length > 0) {
        return this.path[this.path.length - 1];
      }
      return null;
    },
    center() {
      let result = { lat: 25.1201, lng: 55.3782 };
      if (this.path.length > 0) {
        result = this.path[0];
      }
      return result;
    },
  },

  mounted() {
    
    this.init();
  },
  methods: {
    setNewDate(date){

      let id = this.id;
      let startDate = this.startDate;
      let endDate = this.endDate;
      console.log(startDate,endDate);
      if(endDate > Date.now()){
          this.$notify({
            group: "main",
            type: "warning",
            title: "Invalid Data",
            message: "Invalid date",
          });
          return;
      }
      if(startDate > endDate){
        this.$notify({
            group: "main",
            type: "warning",
            title: "Invalid Data",
            message: "start date can not be larger than end date",
          });
          return;
      }
      
      if(id && startDate && endDate ){
        this.fetchTimeline({id,startDate,endDate})
      }
      
    },
    
    ...mapActions("CarTrackerStore", ["fetchTimeline", "updateTimeLine"]),

    setSocketEvents() {
      let id = this.id;
      let vm = this;

      this.$TeltonikaSocket.emit("join room", { room: id });

      this.$TeltonikaSocket.subscribe("live location", function (data) {
        console.log("live location socket event", data);

        data.forEach((obj) => {
          let coor = {
            lat: obj.lat,
            lng: obj.lng,
          };
          // todo dispatch store action
          vm.updateTimeLine(coor);
        });
      });
    },

    init() {
      let id = this.id;
      let startDate = this.startDate;
      let endDate = this.endDate;
      
      if (id && startDate && endDate) {
        this.setSocketEvents();
        this.fetchTimeline({ id, startDate,endDate });
      }
    },

    // fetchDevice() {
    //   this.$api
    //     .get(`teltonika/devices/${this.id}`)
    //     .then((res) => {
    //       if (res.data.msg === "success") {
    //         this.device = res.data.data;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // fetchTimeline() {
    //   if (this.date) {
    //     let date = new Date(this.date.getTime());
    //     date.setHours(0, 0, 0, 0);
    //     let startDate = new Date(date.getTime()).toISOString();
    //     let endDate = new Date(date.getTime());
    //     endDate.setDate(endDate.getDate() + 1);
    //     endDate = endDate.toISOString();
    //     this.$api
    //       .get(
    //         `teltonika/devices/${this.id}/timeline?start=${startDate}&end=${endDate}`
    //       )
    //       .then((res) => {
    //         if (res.data.msg === "success") {
    //           this.time_line = res.data.data;
    //         }
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   }
    // },
  },
  beforeDestroy() {
    let id = this.id;
    this.$TeltonikaSocket.unSubscribeAll();
    if (id) {
      this.$TeltonikaSocket.emit("leave room", { room: id });
    }
  },
};
</script>
<style scoped>
</style>