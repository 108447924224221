<template>
  <el-dialog
    :visible.sync="show"
    :append-to-body="true"
    :custom-class="'escalate-dialog'"
    :top="'4%'"
  >
    <slot name="title">
      <div class="text-left" style="margin-top: -15px">
        <h4>{{ `${$t('escalate')} ${$tc('alarm')} ${$t('to')}` }}</h4>
      </div>
    </slot>
    <!-- <card card-body-classes="table-full-width"> -->
    <el-table :data="deviceCompanies">
      <el-table-column prop="name" :label="$t('companyName')" />
      <el-table-column prop="type" :label="$t('theType')" :formatter="(row, col, cellValue) => {
        return this.$t(cellValue);
      }"/>
      <el-table-column>
        <template slot-scope="{ row }">
          <base-checkbox v-model="row.selected" />
        </template>
      </el-table-column>
    </el-table>
    <div v-if="$v.escelateForm.deviceCompanies.$error">
      <label class="error" v-if="!$v.escelateForm.deviceCompanies.required"
        >{{ $t('validations.selectAtLeastOnDeviceCompany') }} </label
      >
    </div>
    <!-- </card> -->

    <div class="col-12">
      <label for="comment">{{ $tc('comment') }}</label>
      <base-input
        name="comment"
        id="comment"
        v-model="escelateForm.comment"
        :placeholder="$tc('comment')"
      >
        <template v-if="$v.escelateForm.comment.$error" v-slot:error>
          <label class="error" v-if="!$v.escelateForm.comment.required"
            >{{ $tc('comment') + ' ' + $t('validations.isRequired')}}</label
          >
          <label class="error" v-if="!$v.escelateForm.comment.maxLength"
            >{{ $tc('comment') + ' ' + $t('validations.lessThan', [100])}}</label
          >
        </template>
      </base-input>
    </div>

    <div class="d-flex justify-content-end">
      <button @click="submitEscalation" class="btn btn-primary">{{ $t('Submit') }}</button>
    </div>
  </el-dialog>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      escelateForm: {
        deviceCompanies: [],
        comment: "",
      },
      show: false,
    };
  },
  props: ["isOpen", "deviceCompanies"],
  created() {
    this.show = this.isOpen;
  },
  methods: {
    resetEscelateForm() {
      this.escelateForm.comment = "";
      this.$v.$reset();
    },
    submitEscalation() {
      this.setSelectedDeviceCompanies();
      this.$v.$touch();

      if (this.$v.$invalid) return;
      this.show = false;
      this.$emit("submit", this.escelateForm);
    },
    setSelectedDeviceCompanies() {
      this.escelateForm.deviceCompanies = [];

      this.deviceCompanies.forEach((deviceCompany) => {
        if (deviceCompany.selected)
          this.escelateForm.deviceCompanies.push(deviceCompany.id);
      });

      this.$v.escelateForm.deviceCompanies.$touch();
    },
  },
  watch: {
    show: {
      handler(val) {
        if (!val) this.$emit("close");
      },
    },
    isOpen: {
      handler(v) {
        this.show = v;
        if (v) this.resetEscelateForm();
      },
    },
  },
  validations: {
    escelateForm: {
      comment: {
        required,
        maxLength: maxLength(100),
      },
      deviceCompanies: {
        required,
      },
    },
  },
};
</script>

<style>
.escalate-dialog .el-dialog__body {
  padding-top: 0;
}
</style>