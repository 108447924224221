import { render, staticRenderFns } from "./DriverScore.vue?vue&type=template&id=6d604d9f&scoped=true&"
import script from "./DriverScore.vue?vue&type=script&lang=js&"
export * from "./DriverScore.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d604d9f",
  null
  
)

export default component.exports