<template>
  <div>
    <div class="row">
      <div class="col-6">
        <h4 class="key">Start point time</h4>
      </div>
      <div class="col-6">
        <h4 class="value">
          {{ startTime }}
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <h4 class="key">End point time</h4>
      </div>
      <div class="col-6">
        <h4 class="value">
          {{ endTime }}
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <h4 class="key">Trip duration</h4>
      </div>
      <div class="col-6">
        <h4 class="value">
            {{ tripDuration }}
        </h4>
      </div>
    </div>
  
  </div>
</template>

<script>
import { dateFormat, getDurationString } from "@/util/helper";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    dialogVisible: false
  }),
  watch: {
    show(val) {
      this.dialogVisible = true;
    }
  },
  methods: {
    dateFormat,
    getDurationString
  },
  computed: {
    startTime() {
      if (this.data && this.data.data.length > 0) {
        return this.dateFormat(this.data.data[0].created_at);
      }
    },
    endTime() {
      if (this.data && this.data.data.length > 0) {
        return this.dateFormat(this.data.data[this.data.data.length - 1].created_at);
      }
    },
    tripDuration() {
      console.log(this.getDurationString(this.data.trip_duration, ''), '222')
        return this.getDurationString(this.data.trip_duration, '')
    }
  }
};
</script>

<style></style>
