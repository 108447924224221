<template>
  <card class="h-100 custom-border custom-bg">
    <div class="row ">
      <div class="col-md-6">
        <div class="row align-items-end h-100">
          <div class="col-4 text-center">
            <inline-svg :src="require('@/assets/Icons/driver.svg')" width="40" class="mb-1" />
            <h4 class="m-0">VECHILES</h4>
            <h1>45</h1>
          </div>
          <div class="col-4 text-center">
            <inline-svg :src="require('@/assets/Icons/trip.svg')" width="40" class="mb-1"  />
            <h4 class="m-0">Total Trips</h4>
            <h1>20</h1>
          </div>
          <div class="col-4 text-center">
            <inline-svg :src="require('@/assets/Icons/khmer.svg')" width="40" class="mb-1"  />
            <h4 class="m-0">Total Kmage</h4>
            <h1>1500 KM</h1>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 text-center">
            <inline-svg :src="require('@/assets/Icons/mobile.svg')" width="40"  />
            
              <div class="info-label one mt-3">
                <span class="d-block">All Devices</span>
                <span>112</span>
              </div>
              <div class="info-label two mt-2">
                <span class="d-block">Active Devices</span>
                <span>100</span>
              </div>
              <div class="info-label three mt-2">
                <span class="d-block">Disconnected</span>
                <span>12</span>
              </div>
            
          </div>
         
          <div class="col-md-6 text-center">
            <inline-svg :src="require('@/assets/Icons/clock.svg')" width="40" />
            <div class="info-label one mt-3">
              <span class="d-block">Total Driving Time</span>
              <span>11:17</span>
            </div>
            <div class="info-label two mt-2">
              <span class="d-block">Total Stopping Time</span>
              <span>2:02</span>
            </div>
            <div class="info-label three mt-2">
              <span class="d-block">Total Utilisation</span>
              <span>10:10</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>
<script>
export default {};
</script>
<style scoped>
h1{
  color:#3ca271;
}
.custom-border{
  border-left: 20px solid green;
}
.info-label {
  border-radius: 20px;
}
.one {
  background-color: #2598f6;
}
.two {
  background-color: #43fb23;
}
.three {
  background-color: #f84f72;
}
.custom-bg{
  background-image: url('/img/world-map.svg');
  background-position: center;
  background-size: cover;
}
</style>