<template>
  <card>
    <template slot="header">
      <div
        class="d-flex align-items-center justify-content-between border-bottom pb-2"
      >
        <div class="d-flex align-items-center ">
          <inline-svg
            :src="require('@/assets/Icons/forklift/noun_Forklift.svg')"
            width="25"
            height="25"
          />
          <h4 class="ml-2 font-color-1 font-weight-bold">Vehicle List</h4>
        </div>
      </div>
    </template>
    <div class="row pb-3">
      <div class="col-12">
        <el-table :data="data" max-height="350px">
          <el-table-column
            prop="name"
            label="Vehicle"
            width="160"
          />
          <el-table-column
            prop="in_repair"
            label="Status"
            width="80"
          >
            <template slot-scope="scope">
              <div class="d-flex align-items-center">
                <span :class="['point', scope.row.in_repair === true ? 'background-good' : 'background-problem']" />
                <span >{{ scope.row.in_repair ? 'ON' : 'OFF' }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="location"
            label="Location"
            width="160"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{scope.row.current_location.split("-")[2]}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="last_driver_name"
            label="Last Driver"
            width="160"
          />
          <el-table-column
            prop="alarms"
            label="Alarms"
            width="100"
          >
            <template slot-scope="scope">
              <div class="d-flex align-items-center">
                <span class="point background-problem"></span>
                <span>{{ scope.row.alarms }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="repair_from"  label="Repair Status" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.repair_from ? dateFormat(scope.row.repair_from) : "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="model"
            label="Model"
            width="140"
          />
          <el-table-column
            prop="registration_plate"
            label="Plate No"
            width="160"
          />
        </el-table>
      </div>
    </div>
  </card>
</template>

<script>
import { dateFormat } from "@/util/helper"

export default {
  name: 'VehicleList',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  methods:{
    dateFormat
  }
}
</script>

<style lang="scss" scoped>
.card {
  button {
    margin: 0;
    border-radius: 0;
    border: 1px solid $primary !important;
    width: 80px;
  }
  .btn-primary:active,
  .btn-primary:visited {
    background: $primary !important;
  }
  .btn-primary:hover {
    border-color: $primary !important;
    background: $primary !important;
    color: white;
    transform: translateY(0px);
  }
  .btn-primary:focus {
    background: $primary !important;
    color: white;
    transform: translateY(0px);
  }
}

.point {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-inline-start: 5px;
  margin-right: 5px
}
</style>