<template>
  <card class="h-100">
    <div class="d-flex pr-1 pl-1 justify-content-between align-items-start">
      <h4>
        Visitor Breach Alarm
        <span class="alarm position-relative ml-1">
          <!-- <img src="@/assets/Icons/bell.svg" class="imgicon" /> -->
          <inline-svg :src="require('@/assets/Icons/bell.svg')" width="40" height="40" />
          <span class="alarm-count position-absolute top-3 left-4 rounded-circle">
            <span>34</span>
          </span>
        </span>
      </h4>
      <div class="position-absolute top-1 right-1">
        <inline-svg :src="require('@/assets/Icons/menu.svg')" width="40" />
      </div>
    </div>

    <div class="mt-3 alerts-body">

      <!--  -->
      <div class="row mb-3 align-items-center">
        <div class="col-2">
            <inline-svg
              :src="require('@/assets/Icons/high-speed.svg')"
              width="40"
              height="40"
              class="rounded-circle p-1"
            />
          
        </div>
        <div class="col-8">
          <div class="d-flex alert-item justify-content-between pr-3 pl-3 align-items-center">
            <p>High Speed</p>
            <p>(5)</p>
          </div>
        </div>
        <div class="col-2">
          <a href="#" ><i data-v-4af0aa13 class="tim-icons icon-simple-remove"></i></a>
        </div>
      </div>

      <!--  -->

      <div class="row mb-3 align-items-center">
        <div class="col-2">
          <inline-svg
            :src="require('@/assets/Icons/accident-2.svg')"
            width="40"
            height="40"
            class="rounded-circle p-1"
          />
        </div>
        <div class="col-8">
          <div class="d-flex alert-item justify-content-between pr-3 pl-3 align-items-center">
            <p>Accidents</p>
            <p>(5)</p>
          </div>
        </div>
        <div class="col-2">
          <a href="#" ><i data-v-4af0aa13 class="tim-icons icon-simple-remove"></i></a>
        </div>
      </div>

      <!--  -->
      <div class="row mb-3 align-items-center">
        <div class="col-2">
          <inline-svg
            :src="require('@/assets/Icons/maintenance-2.svg')"
            width="40"
            height="40"
            class="rounded-circle p-2 maintenance-icon"
          />
        </div>
        <div class="col-8">
          <div class="d-flex alert-item justify-content-between pr-3 pl-3 align-items-center">
            <p>Maintenance</p>
            <p>(5)</p>
          </div>
        </div>
        <div class="col-2">
          <a href="#" ><i data-v-4af0aa13 class="tim-icons icon-simple-remove"></i></a>
        </div>
      </div>
    </div>
  </card>
</template>
<script>
export default {
  components: {},
};
</script>
<style scoped>
.alarm-count{
  width:25px;
  height: 25px;
  background-color: crimson;
  text-align: center;
  padding-right: 1px ;
}
.alerts-body .alert-item {
  background-color: crimson;
  height: 30px;
  border-radius: 20px;
}
.alerts-body svg{
  background-color: crimson;
}

.maintenance-icon  path{
  fill:#fff;
}
.alerts-body i {
  color: crimson;
  font-weight: bolder;
  font-size: 18px;
}
</style>