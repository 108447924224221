export default (element) => {
    let pos = { top: 0, left: 0, x: 0, y: 0 }

    element.style.cursor = 'grab'

    const mouseDownHandler = (e) => {
        pos = {
            left: element.scrollLeft,
            top: element.scrollTop,
            x: e.clientX,
            y: e.clientY,
        }

        element.addEventListener('mousemove', mouseMoveHandler)
        element.addEventListener('mouseup', mouseUpHandler)
    }
    const mouseMoveHandler = (e) => {
        element.style.cursor = 'grabbing'
        element.style.userSelect = 'none'

        const dx = e.clientX - pos.x
        const dy = e.clientY - pos.y

        element.scrollTop = pos.top - dy
        element.scrollLeft = pos.left - dx
    }

    const mouseUpHandler = () => {
        element.style.cursor = 'grab'
        element.style.removeProperty('user-select')
        element.removeEventListener('mousemove', mouseMoveHandler)
    }

    element.addEventListener('mousedown', mouseDownHandler)
}
