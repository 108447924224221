<template>
  <card>
    <template slot="header" v-loading="chartLoading">
      <div
        class="d-flex align-items-center justify-content-between border-bottom pb-2"
      >
        <div class="d-flex align-items-center">
          <inline-svg
            :src="require('@/assets/Icons/forklift/noun_speed.svg')"
            width="25"
            height="25"
            class="background-svg-good"
          />
          <h4 class="ml-2 font-color-1 font-weight-bold">Interval Speed Comparison</h4>
        </div>
      </div>
    </template>
    <div class="row pb-3">
      <div class="col-12">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center" style="width: 100%">
            <div class="d-block">
              <span
                :class="$v.forkliftID.$error && !forkliftID ? 'text-danger' : ''"
                style="width: 35%"
                ><b>Forklift</b></span>
              <Select
                v-model="forkliftID"
                placeholder="choose forklift"
                class="selector mr-2 mt-2"
                required
              >
                <Option
                  v-for="forklift in forklifts"
                  :key="forklift.id"
                  :label="forklift.name"
                  :value="forklift.id"
                >
                  <span style="color: #42b883">{{ forklift.name }}</span>
                </Option>
              </Select>
            </div>
            <div class="d-block mx-2">
              <span class="h4 mr-3 mb-0 mb-2" style="width: 100%"><b>Date 1: </b></span>
              <el-date-picker
                v-model="date1"
                type="datetimerange"
                style="max-width:400px"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                :clearable="false"
                :picker-options="pickerOptions1"
                class="mt-2"
              >
              </el-date-picker>
              <div class="ml-5" style="width: 100%">
              </div>
            </div>
            <div class="d-block mx-2">
              <span class="h4" style="width: 100%"><b>Date 2: </b></span>
              <el-date-picker
                v-model="date2"
                type="datetimerange"
                style="max-width:400px"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                :clearable="false"
                :picker-options="pickerOptions2"
                class="mt-2"
              >
              </el-date-picker>
              <div class="ml-5" style="width: 100%">
              </div>
            </div>
            <base-button
              class="btn btn-primary ml-2 mt-4"
              style="width: 15%"
              @click="comparisonForklift"
              >Compare</base-button
            >
          </div>
        </div>
      </div>
      <div class="col-12 mt-4 text-center" v-if="intervalChart.dates">
        <ZingchartWrapper
          :customConfiguration="{}"
          :series="chartSeries"
          :labels="chartLabels"
          height="350px"
          :requireZoom="false"
        />
      </div>
      <div v-else class="col-12 mt-4 text-center"><h2>Graph</h2></div>
    </div>
  </card>
</template>

<script>
import ZingchartWrapper from "@/components/Charts/zingchart-wrapper";
import { Select, Option } from "element-ui";
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { colors } from "@/util/helper";
export default {
  name: "IntervalSpeedComparison",
  components: { ZingchartWrapper, Select, Option },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      date1: [new Date(Date.now() - 24 * 2 * 60 * 60 * 1000), new Date()],
      date2: [new Date(Date.now() - 24 * 2 * 60 * 60 * 1000), new Date()],
      pickerOptions1: {
        disabledDate(time) {
          return (
            time.getTime() > Date.now()
            // || time.getTime() < Date.now() - 2 * 3600 * 1000 * 24
          );
        }
      },
      pickerOptions2: {
        disabledDate(time) {
          return (
            time.getTime() > Date.now()
            // || time.getTime() < Date.now() - 2 * 3600 * 1000 * 24
          );
        }
      },
      date: new Date(),
      forkliftID: undefined,
    };
  },
  validations: {
    forkliftID: {
      required,
    },
  },
  computed: {
    ...mapState("ForkliftStore/Portfolio", [
      "chartLoading",
      "forklifts",
      'intervalChart'
    ]),
    // chartSeries() {
    //   let series = [
    //     {
    //       values: this.forkliftChart.forklift1,
    //       label: ["forklift 1"],
    //       color: colors.ok,
    //     },
    //     {
    //       values: this.forkliftChart.forklift2,
    //       label: ["forklift 2"],
    //       color: colors.poor,
    //     },
    //   ];

    //   return series;
    // },
    // chartLabels() {
    //   let labels = [{ labels: this.forkliftChart.dates }];
    //   return labels;
    // },
  },
  methods: {
    ...mapActions("ForkliftStore/Portfolio", [
      "fetchChartIntervalComparison",
      "fetchForklifts",
    ]),
    comparisonForklift() {
      this.$v.$touch();
      console.log(this.$v);
      if (this.$v.$invalid) {
        console.log(this.$v);
        console.log("error data");
        return;
      } else {
        const data = {
          forklift_id: this.forkliftID,
          start1: this.date1[0].toISOString(),
          end1: this.date1[1].toISOString(),
          start2: this.date2[0].toISOString(),
          end2: this.date2[1].toISOString()
        };
        console.log(data)
        this.fetchChartIntervalComparison(data);
      }
    },
    initializeSeries(dataValuesArray, color, title) {
      return {
        values: dataValuesArray,
        text: title,
        "line-color": color,
        aspect: "spline",
        "background-color": `${color} none`,
        "alpha-area": ".4",
        marker: {
          "background-color": color,
          size: 7,
          "border-color": "#fff",
          "border-width": 3,
        },
        "legend-marker": {
          "background-color": color,
        },
      };
    },
  },
  created() {
    this.fetchForklifts();
  },
};
</script>

<style>
</style>