<template>
<card class="h-100">
    <div class="row">
        <div class="col-md-6">
                <Department />
        </div>
        <div class="col-md-6">
            <Companies />
        </div>
    </div>
</card>

    
</template>

<script>
    import Department from '@/components/Charts/Departments.vue';
    import Companies from '@/components/Charts/Companies.vue';

export default {
    components:{
        Department,
        Companies
    }
    
}
</script>
<style lang="stylus" scoped>

</style>