<template>
  <tr>
    <td>
      <button class="btn btn-info">{{$t("Pending")}}</button>
    </td>
    <td>
      <span>-</span>
    </td>
    <td>
      -
    </td>
    <td>
      -
    </td>

    <td>
      -
    </td>
    <td>
      -
    </td>
    <td>
      <!-- <button class="btn btn-success" @click="save">{{$t("Save")}}</button> -->
      <div class="cell">
        <div >
          <el-tooltip content="Add Device" effect="light" :open-delay="300" placement="top">
          <button
            @click="showModal = true"
            type="button"
            class="btn like btn-link btn-icon btn-fab btn-info btn-sm"
          >
            <!---->
            <i   class="tim-icons icon-notes"></i>
          </button>
          </el-tooltip>
          <el-tooltip content="Delete" effect="light" :open-delay="300" placement="top">
          <button
            
            
            type="button"
            class="btn remove btn-link btn-icon btn-fab btn-danger btn-sm"
            @click="$emit('decreaseQuantity')"
          >
            <!---->
            <i   class="tim-icons icon-simple-remove"></i>
          </button>
          </el-tooltip>
          
        </div>
      </div>
    </td>
    <!--  -->
    <!-- <modal :show="showModal" @close="showModal = false" :modalClasses="'fullscreenmodal'">
        
         <CreateDevice :lot="lot" @close="showModal = false" />
    </modal> -->
    <el-drawer :visible.sync="showModal" :title="'Create Device-'+category.name" :destroy-on-close	="true">
         <component :is="deviceType" :lot="lot" v-if="showModal" @close="showModal = false" />
    </el-drawer>
  </tr>
</template>

<script>

import CreateDevice from "./CreateDevice.vue";
import CreateTeltonikaDevice from './CreateTeltonikaDevice.vue';

import { Drawer } from "element-ui";
export default {
  props: ["lot"],
  data() {
    return {
      showModal:false,
      
    };
  },
  computed:{

    category(){
      let device_categories = this.$store.state.device_categories;
      let category = device_categories.find(cat=>cat.id === this.lot.template.category_id);
      return category;

    },

    deviceType(){
      let name = null;
      let category = this.category;
      if(category){
          switch(category.name){
            case 'IOT':
              name = 'CreateDevice';
              break;
            case 'Teltonika':
              name = 'CreateTeltonikaDevice';
              break;
            

          }
      }

      return name
      

    }
  },
 
  components: {
    CreateDevice,
    CreateTeltonikaDevice,
    [Drawer.name]:Drawer
  },
  
};
</script>
<style scoped>
</style>
