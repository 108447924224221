<template>
<div class="striped-table"> 
  <el-table :data="data">
    <div slot="empty" v-if="state == 'loading'">{{ $t('loading' )}}</div>
    <div slot="empty" v-else-if="state == 'error'">{{ $t('somethingWrong') }}</div>
    <div slot="empty" v-else-if="state == 'loaded'">{{ $t('noData') }}</div>

    <el-table-column :min-width="110" align="left" :label="$t('id')">
      <div slot-scope="props">
        <base-button
          class="w-100"
          @click.native="openDeviceProfile(props.row.id)"
          type="primary"
          size="sm"
          >{{ props.row.device_unique }}</base-button
        >
      </div>
    </el-table-column>
    <el-table-column
      sortable
      v-for="column in tableColumns"
      :key="column.label"
      :min-width="column.minWidth"
      :prop="column.prop"
      :label="column.label"
    ></el-table-column>
    <el-table-column
      sortable
      prop="last_measurement"
      :min-width="150"
      align="left"
      :label="$t('lastReported')"
    >
      <div slot-scope="{ row }">
        {{ row.last_measurement ? gDateFormat(row.last_measurement) : "-" }}
      </div>
    </el-table-column>
    <!-- battery -->
    <el-table-column :min-width="150" align="left" :label="$t('deviceWords.batteryLife')">
      <div slot-scope="props" class="mw-10">
        <radial-progress-bar
          :diameter="80"
          :completed-steps="Math.round(+props.row.battery_percentage)"
          :startColor="'#53AA83'"
          :innerStrokeColor="'#B8E2CF'"
          :total-steps="100"
          :innerStrokeWidth="5"
          :strokeWidth="5"
        >
          <p class="font-weight-bold font-size-16" style="color: #41B581">{{ Math.round(+props.row.battery_percentage) }}%</p>
        </radial-progress-bar>
      </div>
    </el-table-column>

    <!-- sensor status -->
    <el-table-column :min-width="120" align="left" :label="$tc('sensorWords.sensor', 2)">
      <div slot-scope="props">
        <sensor-statuses :sensors="props.row.sensors" />
      </div>
    </el-table-column>

    <!-- actions -->
    <el-table-column :min-width="75" align="right" :label="$tc('report.action', 2)">
      <div slot-scope="props">
        <el-tooltip
          :content="$t('instructions.delete')"
          effect="light"
          :open-delay="300"
          placement="top"
        >
          <base-button
            @click.native="onDelete(props.row,deleteDevice)"
            class="remove btn-link"
            type="danger"
            size="sm"
            icon
          >
            <i class="tim-icons icon-simple-remove"></i>
          </base-button>
        </el-tooltip>
        <el-tooltip
          content="View logs"
          effect="light"
          :open-delay="300"
          placement="top"
        >
          <base-button
            @click.native="ViewLogs(props.row)"
            class="remove btn-link"
            type="danger"
            size="sm"
            icon
          >
               <i class="fa fa-history"></i>
          </base-button>
        </el-tooltip>
      </div>
    </el-table-column>
  </el-table>
</div>
</template>

<script>
import { deleteHandler } from "@/util/helper";
import SensorStatuses from "./SensorStatuses.vue";
import { batteryStatus } from "src/util/helper.js";
import RadialProgressBar from "vue-radial-progress";
export default {
  props: {
    data: {
      required: true,
      type: Array,
    },
    state:{
        required:true,
        type:String // "loaded" | "loading" | "error"
    }
  },
  computed: {
    tableColumns() {
      return [
        {
          prop: "device_name",
          label: this.$i18n.t("Name"),
          minWidth: 150,
        },
        {
          prop: "customer",
          label: this.$i18n.t("Customer"),
          minWidth: 120,
        },
        {
          prop: "fmc",
          label: this.$i18n.t("FMC"),
          minWidth: 120,
        },

        {
          prop: "structure",
          label: this.$i18n.tc("structureWords.structure"),
          minWidth: 150,
        },
      ];
    },
  },
  methods: {
    ViewLogs(device) {

      this.$router.push({
        path: `/logs/list`,
        query:{
          target_type:'device_id',
          target_id:device.id,
          target_name:device.device_name
        }
      });
    },
    openDeviceProfile(id) {
      this.$router.push({ path: `/device/reading/${id}` });
    },
    getBatteryStatus(device) {
      let ranges = device.battery_ranges;
      let battery_percentage = device.battery_percentage;
      return batteryStatus(ranges, battery_percentage);
    },
    onDelete: deleteHandler,
    deleteDevice(device) {
      this.$api
        .delete(`/devices/${device.id}`)
        .then((res) => {
          console.log("delete device", res);
          if (res.status === 204) {
            this.deleteRow(device);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteRow(device) {
      console.log(device);
      let index = this.data.findIndex((dev) => dev.id === device.id);
        console.log(index);
      if (index > -1) {
        this.data.splice(index, 1);
        if (this.total > 0) {
          this.total -= 1;
        }
      }
      this.$emit("item-deleted",device.id);
    },
  },
  components:{
      SensorStatuses,
      RadialProgressBar
  },
  name: "devices-list",
};
</script>