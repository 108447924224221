<template>
  <div class="device-sensor-status">
    <div v-for="(sensor,index) in sensors" :key="index">
      
      <SensorIcon :name="sensor.name" :status="sensor.last_status" :lastValue="getLastValue(sensor)"/>
    </div>
  </div>
</template>
<script>
  import SensorIcon from '@/components/SensorIcon.vue';

export default {
  props: {
    sensors: Array
  },

  components:{
    SensorIcon
  },
  methods: {
    getLastValue(sensor){
      let value;
      let unit;
      if(sensor.last_value != null ){
          value = sensor.last_value;
      }else{
        value = '-';
      }
      if(sensor.unit){
        unit = sensor.unit; 
      }else{
        unit = ''
      }
      return value + ' ' + unit;
        
    }

  }
};
</script>
<style scoped>

.device-sensor-status {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap:1em;
}
</style>