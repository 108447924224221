<template>
  <card v-if="!loading">
    <div class="row" v-if="data">
      <div class="col-lg-5">
        <div class="col-lg-11">
          <span class="title" style="width: 100%; font-size: 24px">
            <img
              :src="require('@/assets/Icons/forklift/report-main.svg')"
              class="mr-3"
            />
            Create Service Request
            <img
              slot="reference"
              :src="require('@/assets/Icons/forklift/report-service.svg')"
              class="ml-4"
              style="cursor: pointer !important"
              @click="showHistoryPopup"
            />
          <Modals 
            :historyPopup="historyPopup"
            @close="closeHistoryPopup"
            :forkliftID="forkID" 
            :data="repairHistory"
            :total="repairHistoryTotal"
            />
          </span>
          <card class="mt-3">
            <span class="title">Vechile: </span>
            <p class="value">{{ data.vehicle.name }}</p>
            <span class="title">Plate No: </span>
            <p class="value">{{ data.vehicle.registration_plate }}</p>
            <span class="title">Driver: </span>
            <p class="value">{{ data.driver.name }}</p>
            <span class="title">Contact: </span>
            <p class="value">{{ data.driver.phone }}</p>
          </card>
        </div>
        <div class="col-lg-11">
          <card>
            <div>
              <span class="title">Issue:</span>
              <p>{{ data.issue }}</p>
            </div>
            <div class="mt-2">
              <span class="title">Description: </span>
              <p>{{ data.description }}</p>
            </div>
          </card>
        </div>
        <div class="col-lg-11">
          <span style="font-size: 19px"><b>Additional Comments:</b></span>
          <card style="min-height: 150px" class="mt-2">
            {{ data.driver_comment }}
          </card>
        </div>
        <div class="col-lg-11 mt-5">
          <card>
            <div>
              <span class="title" style="width: 100%">Select an Autoshop</span>
            </div>
            <div class="d-flex align-items-center">
              <Select
                v-model="selectedAutoshop"
                style="width: 100%"
                :placeholder="
                  data.autoshop == null
                    ? 'please select autoshop'
                    : data.autoshop.name
                "
                class="mr-2"
              >
                <Option
                  v-for="item in autoshopData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="color: #42b883">{{ item.name }}</span>
                </Option>
              </Select>
              <base-button
                type="primary"
                round
                icon
                class="mr-2"
                @click="updateReport(forkID)">
                <i class="tim-icons icon-simple-add"></i>
              </base-button>
            </div>
            <span class="title" style="width: 70%">Select date to send for repair</span>
            <div class="d-flex align-items-center justify-content-center">
              <DatePicker v-model="date" placeholder="pick date" class="mr-2" />
              <base-button
                type="primary"
                round
                icon
                class="mr-2"
                @click="updateReport(forkID)"
              >
                <i class="tim-icons icon-simple-add"></i>
              </base-button>
            </div>
          </card>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mb-5">
          <ul class="list-unstyled text-right header-card w-100 pr-5">
            <li class="mr-2"><b>Priority:</b></li>
            <li class="bg ml-2 mr-5">
              <span
                class="text-white"
                :class="data.priority == 'high' ? 'bg-danger' : 'bg-yellow'"
                ><b>{{ data.priority }}</b></span
              >
            </li>
            <li class="ml-5 mr-3"><b>Current Status:</b></li>
            <li class="dot mr-4">
              <span
                :class="!data.is_completed ? 'bg-danger' : 'bg-success'"
              ></span>
              {{ !data.is_completed ? "Not completed" : "Repaired" }}
            </li>
          </ul>
        </div>
        <forklift-slider :issueData="data.issue" />
        <div class="row mt-5 pr-5">
          <ul class="list-unstyled d-flex justify-content-end align-items-center text-right w-100">
            <li class="mr-4">
              <router-link :to="`/forklift/complete/${data.id}`">
                <base-button class="btn btn-primary"
                  >Send to Autoshop</base-button>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { DatePicker, Select, Option } from "element-ui";
import { mapActions, mapGetters } from "vuex";
import ForkliftSlider from "../components/ForkliftSlider";
import Modals from '../../components/Modals'

export default {
  name: "report",
  components: {
    DatePicker,
    Select,
    Option,
    ForkliftSlider,
    Modals
  },
  data() {
    return {
      options: [
        {
          value: "all",
          label: "All",
        },
        {
          value: "other",
          label: "Other",
        },
      ],
      selectedAutoshop: "",
      items: [
        {
          img: require("@/assets/Icons/forklift/slider_img/slide_1.svg"),
          errorSide: "Right",
          id: 0,
          selected: false,
          error: true,
        },
        {
          img: require("@/assets/Icons/forklift/slider_img/slide_2.svg"),
          errorSide: "Left",
          id: 1,
          selected: false,
          error: false,
        },
        {
          img: require("@/assets/Icons/forklift/slider_img/slide_3.svg"),
          errorSide: "Front",
          id: 2,
          selected: false,
          error: false,
        },
        {
          img: require("@/assets/Icons/forklift/slider_img/slide_4.svg"),
          errorSide: "D",
          id: 3,
          selected: false,
          error: false,
        },
        {
          img: require("@/assets/Icons/forklift/slider_img/slide_5.svg"),
          errorSide: "Back",
          id: 4,
          selected: false,
          error: false,
        },
      ],
      forkID: "",
      date: "",
      pagination: {
        offset: 0,
        limit: 5,
      },
      popupVisible: false,
      historyPopup: false
    };
  },

  computed: {
    ...mapGetters("ForkliftStore/Report", ["data", "autoshopData", "loading"]),
    ...mapGetters(`ForkliftStore/Portfolio/VehicleRepairInventory`, ["repairHistory", "repairHistoryTotal"]),
  },
  watch: {
    data: {
      handler() {
        this.selectedAutoshop = this.data.autoshop.id;
        this.date = this.data.due_date;
        if (this.data) {
          this.items.map((item) => {
            if (item.errorSide == this.data.side) {
              console.log(this.data.side);
            }
          });
        }
      },
    },
  },
  methods: {
    ...mapActions("ForkliftStore/Report", ["fetchData", "getAutoshops"]),
    updateReport() {
      const payload = {
        forkID: this.forkID,
        data: {
          priority: this.data.priority,
          cost: 0,
          is_completed: this.data.is_completed,
          supervisor_comment: this.data.supervisor_comment || "_",
          autoshop_id: this.selectedAutoshop,
          due_date: this.date.toISOString(),
        },
      };
      this.$store.dispatch("ForkliftStore/Report/updateReport", payload);
    },
    fetchRepairHistory(){
      const payload ={
        id: this.forkID,
        limit: this.pagination.limit,
        offset: this.pagination.offset
      }
      this.$store.dispatch(`ForkliftStore/Portfolio/VehicleRepairInventory/fetchRepairHistoryData`,payload)
    },
    showHistoryPopup(item){
      this.historyPopup = true;
      this.forklift_id = item.forklift_id
      this.fetchRepairHistory()
    },
    closeHistoryPopup(){
      this.historyPopup = false
    },
  },
  created() {
    this.forkID = this.$route.params.id;
    this.fetchData(this.forkID);
    this.getAutoshops();
  },
  beforeDestroy() {
    this.$store.commit("ForkliftStore/Report/setData", []);
  },
}
</script>
<style lang="scss" scoped>
.title {
  width: 40%;
  display: inline-block;
  font-size: 1.07rem;
  margin-bottom: 10px;
}
.value {
  width: 60%;
  display: inline-block;
}
// Slider Contain
.header-card li {
  display: inline-block;
  font-size: 18px;
  border: none;
}
.header-card li.bg span {
  padding: 5px 10px;
  display: inline-block;
}
.header-card li.dot span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.icon-cover svg {
  filter: inherit !important;
}
.icon-cover.active .circul {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
}
.slide-cover {
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px;
  border: 1px solid transparent !important;
  border-radius: 5px;
}
.span-active {
  border: 1px solid #42b883 !important;
}
.icon-cover .char {
  position: absolute;
  top: -10px;
  left: -10px;
  background: #f5f5f5;
  color: #484848;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 6px;
  font-weight: bold;
  box-shadow: 0px 1px 5px 0px #333333b8;
}
.icon-cover.hidden .char {
  visibility: hidden;
}
.slide-img {
  height: 65%;
  width: 65%;
  top: 45%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
</style>