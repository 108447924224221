<template>
  <div class="">
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="customtableheader">
            <h4 slot="header" class="card-title">Order Devices of type - {{type}}</h4>
            <div>
              <el-tooltip content="Go back" effect="light" :open-delay="300" placement="top">
              <base-button type="primary" round icon @click="back">
                <i class="tim-icons icon-minimal-left"></i>
              </base-button>
              </el-tooltip>
              <el-tooltip content="Add More Devices" effect="light" :open-delay="300" placement="top">
              <base-button type="primary" round icon @click="increaseQuantity">
                <i class="tim-icons icon-simple-add"></i>
              </base-button>
              </el-tooltip>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">{{$t("STATUS")}}</th>
                      <th scope="col">{{$t("SERIES")}}</th>
                      <th scope="col">{{$t("DEVICE NAME")}}</th>
                      <th scope="col">{{$t("DEVICE ID")}}</th>
                      <th scope="col">{{$t("MANUFACTURE")}}</th>
                      <th scope="col">{{$t("DEVICE DETAILS")}}</th>
                      <th scope="col">{{$t("Action")}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="device in devices">
                      <!--  -->
                      <DeviceRow :key="'dev'+device.id" :device="device" :lot="lot" />
                    </template>
                    <template v-for="i in not_installed">
                      <LotDetailsRow :key="i" :lot="lot" @decreaseQuantity="decreaseQuantity" />
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          ></div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
//import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import LotDetailsRow from "./LotDetailsRow.vue";
import DeviceRow from "./DeviceRow.vue";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LotDetailsRow,
    DeviceRow
  },

  computed: {
    

    devices(){
      return this.$store.state.workorderstore.devices;
    },
    not_installed(){
        return this.lot.quantity - this.devices.length;
    },

    lot() {
      return this.$store.state.workorderstore.selected_lot;
      
    },
    type(){
      let device_categories = this.$store.state.device_categories;
      let category = device_categories.find(cat=>cat.id === this.lot.template.category_id);
      if(category){
         return category.name;
      }

      return '';
    },
  },
  data() {
    return {

      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {
    
    back() {
      let lot_list = this.$store.state.workorderstore.lot_list;
      let order = this.$store.state.workorderstore.order;

      this.$store.dispatch("workorderstore/setWorkorderView", {
        show: "HardwareLotList",
        order,
        lot_list,
        lot_details: null,
        devices:[]
      });
    },

    increaseQuantity() {
      let data = {
        id: this.lot.id,
        orderid: this.lot.order_id,
        op: "add"
      };
      this.$api.post(`orders/quantity`,data).then(res=>{
            if(res.status === 200){
              this.$store.commit('workorderstore/setQuantity','add');
            }
            
      }).catch(err=>{console.log(err)});
    },

    decreaseQuantity() {
      let data = {
        id: this.lot.id,
        orderid: this.lot.order_id,
        op: "minus"
      };
      this.$api.post(`orders/quantity`,data).then(res=>{
            if(res.status === 200){
              this.$store.commit('workorderstore/setQuantity','minus');
            }
            
      }).catch(err=>{console.log(err)});
    },

    // handleDelete(index, row) {
    //   swal({
    //     title: "Are you sure?",
    //     text: `You won't be able to revert this!`,
    //     type: "warning",
    //     showCancelButton: true,
    //     confirmButtonClass: "btn btn-success btn-fill",
    //     cancelButtonClass: "btn btn-danger btn-fill",
    //     confirmButtonText: "Yes, delete it!",
    //     buttonsStyling: false
    //   }).then(result => {
    //     if (result.value) {
    //       this.deleteRow(row);
    //       swal({
    //         title: "Deleted!",
    //         text: `You deleted ${row.name}`,
    //         type: "success",
    //         confirmButtonClass: "btn btn-success btn-fill",
    //         buttonsStyling: false
    //       });
    //     }
    //   });
    // },
    // deleteRow(row) {
    //   let indexToDelete = this.tableData.findIndex(
    //     tableRow => tableRow.id === row.id
    //   );
    //   if (indexToDelete >= 0) {
    //     this.tableData.splice(indexToDelete, 1);
    //   }
    // }



  }
};
</script>
<style scoped>
</style>
