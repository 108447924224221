<template>
<el-table-column :fixed="$rtl.isRTL?false:'right'" :label="$tc('report.action', 2)" width="175">
  <div slot-scope="{ row }" class="row actions-row">
    <div class="col-12" style="text-align: left">
      <base-button
        :type="getButtonAcknowledgeState(row).type"
        size="sm"
        round
        class="ack_button text-capitalize font-weight-normal font-size-14"
        @click="$emit('change-ack-state', row)"
        :disabled="!!row.acknowledgement"
        :simple="!!row.acknowledgement"
      >
        {{ $t(getButtonAcknowledgeState(row).action) }}
      </base-button>
    </div>
    <base-button
      simple
      size="sm"
      type="primary"
      class="btn-escalate text-capitalize font-weight-normal font-size-14"
      @click="$emit('escalate', row)"
    >
      {{ $t('escalate') }}
    </base-button>
    <a class="comments-button" @click="$emit('open-comments', row)">
      <i class="tim-icons icon-email-85"></i>
      <sup :content="row.comments_count" class="ic-em">
        {{ row.comments_count }}</sup
      >
    </a>
  </div>
</el-table-column>
</template>

<script>
import AlarmButtonsStateMixin from "../../../../util/AlarmButtonsStateMixin"
export default {
  mixins:[AlarmButtonsStateMixin],
}
</script>

<style scoped>
.ack_button {
  word-break: keep-all;
  margin-bottom: 15px !important;
  border-radius: 6px;
  width: 100%;
  padding: 5px 15px;
}

/* .ack_button:not(.btn-simple) {
  background-color: #41B581;
} */

.btn-escalate {
  background-color: transparent;
  color: #41b581;
  border-radius: 6px;
  text-transform: capitalize;
  font-weight: normal;
}

:v-deep .el-table__fixed-right {
    top: 1px !important;
}

.actions-row {
    justify-content: space-around;
}

.comments-button {
    font-size: 1.5rem;
    cursor: pointer;
}

.comments-button sup {
    font-size: 60%;
    padding-left: 5px;
    color: #ccc;
}

.comments-button sup[content="0"] {
    color: var(--gray);
}

::v-deep th div.cell {
    letter-spacing: 1px;
}
</style>
