<template>
  <card class="h-100 maintenance">
    <div class="d-flex pr-1 pl-1 justify-content-between align-items-start">
      <h4>Maintenance</h4>
      <div class="position-absolute top-1 right-1 main-icon">
          <inline-svg :src="require('@/assets/Icons/maintenance.svg')" width="40" height="40"  />
      </div>
      
    </div>

    
     
      <div class="row mt-5">
        <div class="col-12 text-center due-main">
          <inline-svg :src="require('@/assets/Icons/car-maintenance-1.svg')" width="60" height="60"  />
          <h2 class="d-block ">Due Maintenance</h2>
        </div>
        
        
      </div>
      
      

     

      

  </card>
</template>
<script>
export default {
  components: {
  },
  data(){
    return{
      MultiProgressData:[
          {value:20},
          {value:3},
          {value:8},
      ]
    }
  }
};
</script>
<style  scoped>
  
</style>
<style >
    .main-icon svg path{
      fill: #d051dc;
    }
  .due-main svg path{
      fill: #fbdc2b
  }
  .under-main svg path{
    fill: #d050dc;
  }
  .overdue-main svg path{
    fill: #f95176;
  }
  .due-maintenance{
    background-color: #fef277;
  }
  .under-maintenance{
    background-color: #d94fd2;
  }
  .over-maintenance{
    background-color: #f74a66;
  }


</style>