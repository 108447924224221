<template>
  <div v-if="data">
    <div class="col-12 d-flex  font-color-1 justify-content-between my-4 pl-0">
      <div class="d-flex mr-2">
        <inline-svg
          :class="`mr-3 ${svgColor}`"
          :src="require(`@/assets/Icons/forklift/Union 8.svg`)"
          width="20"
          height="20"
        />
        <span>10 degrees move</span>
      </div>
      <div class="d-flex align-items-center">
        <span class="point background-problem mr-1"></span>
        <span>{{ data["10 degrees move"] }}</span>
      </div>
    </div>
    <div class="col-12 d-flex  font-color-1 justify-content-between my-4 pl-0">
      <div class="d-flex mr-2">
        <inline-svg
          :class="`mr-3 ${svgColor}`"
          :src="require(`@/assets/Icons/forklift/noun_distance.svg`)"
          width="20"
          height="20"
        />
        <span>Vehicle drives 100m</span>
      </div>
      <div class="d-flex align-items-center">
        <span class="point background-problem mr-1"></span>
        <span>{{ data['drives 100m'] }}</span>
      </div>
    </div>
    <div class="col-12 d-flex  font-color-1 justify-content-between my-4 pl-0">
      <div class="d-flex mr-2">
        <inline-svg
          :class="`mr-3 ${svgColor}`"
          :src="require(`@/assets/Icons/forklift/noun_hibernate.svg`)"
          width="20"
          height="20"
        />
        <span>300 seconds passes</span>
      </div>
      <div class="d-flex align-items-center">
        <span class="point background-problem mr-1"></span>
        <span>{{ data["300 seconds pass"] }}</span>
      </div>
    </div>
    <div class="col-12 d-flex  font-color-1 justify-content-between my-4 pl-0">
      <div class="d-flex mr-2">
        <inline-svg
          :class="`mr-3 ${svgColor}`"
          :src="require(`@/assets/Icons/forklift/noun_hibernate.svg`)"
          width="20"
          height="20"
        />
        <span>Idling</span>
      </div>
      <div class="d-flex align-items-center">
        <span class="point background-problem mr-1"></span>
        <span>{{ data.idling }}</span>
      </div>
    </div>
    <div class="col-12 d-flex  font-color-1 justify-content-between my-4 pl-0">
      <div class="d-flex mr-2">
        <inline-svg
          :class="`mr-3 ${svgColor}`"
          :src="require(`@/assets/Icons/forklift/noun_speed.svg`)"
          width="20"
          height="20"
        />
        <span>Overspeeding</span>
      </div>
      <div class="d-flex align-items-center">
        <span class="point background-problem mr-1"></span>
        <span>{{ data.overspeeding }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    svgColor() {
      return this.$store.getters.themMode == "light" ? "" : "light-svg";
    }
  }
};
</script>

<style></style>
