<template>
  <zingchart :data="chartConfig" :height="'200px'" :width="'100%'"></zingchart>
</template>
<script>
export default {
  data() {
    return {
      chartConfig: {
        backgroundColor:'transparent',
        type: "line",
        title: {
          visible: false,
        },
        plot: {
          tooltip: {
            visible: false,
          },
          marker: {
            visible: false,
          },
        },
        plotarea: {
          margin: "50 dynamic dynamic dynamic",
        },
        scaleX: {
          step: 1000,
          transform: {
            type: "date",
            all: "%h:%i:%s %a",
          },
          
        },
        scaleY: {
          label: {
            visible:false
          },
          
        },
        crosshairX: {
          plotLabel: {
            text: '<span style="color:%color">%t:</span> %v mV',
            padding: "15px",
            fontSize: "15px",
            headerText: "%kv",
          },
          shared: true,
        },

        series: [
          {
            text: "Sensor 2",
            values: [1, 1, 92, 22, 12, 1, 2, 3, 5, 6, 7],
            lineColor: "#ff4081",
            lineWidth: "4px",
            midpoint: false,
          },
        ],
      },
    };
  },
};
</script>
<style scoped>
</style>